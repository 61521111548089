import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "react-modal";
import ReactHtmlParser from "react-html-parser";
import S3 from "react-aws-s3";
import localStorage from "mobx-localstorage";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "../../fbase";
import CryuHeader from "../../components/CryuHeader/CryuHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Resizer from "react-image-file-resizer";
import Compressor from "compressorjs";
import FLOWER_IMG from "../../assets/images/flower-4.png";

const getToday = () => {
    let today = new Date();
    let year = today.getFullYear();
    let mon = today.getMonth() + 1;
    let day = today.getDate();

    let fullYears = `${year}-${mon < 10 ? "0" + mon : mon}-${
        day < 10 ? "0" + day : day
    }T12:00`;
    return fullYears;
};

const invatationContentArray = [
    {
        content: `항상 귀댁의 평안과 만복을 기원합니다.\n아뢰올 말씀은 지난번 혼례때에 \n귀한 시간내시어 참석해주셔서\n무사히 혼례를 마치게 하여 주신 데 대하여\n진심으로 감사를 드립니다.\n\n마땅히 찾아 뵙고 인사드리는 것이\n도리이오나 우선 서신으로 대하게 됨을\n송구스럽게 생각하오며 내내 건강하시고\n뜻하시는 모든 일이 형통하시길 기원합니다.`,
    },
    {
        content: `저희의 작은 사랑이\n큰 사랑으로 결실을\n맺게 되었습니다.\n\n함께 기뻐해주시고\n축복해주신 분들께\n진심으로 감사드립니다.\n\n저희에게 주신 사랑을 더해\n더 많이 사랑하고 아끼며\n살겠습니다.`,
    },
    {
        content: `진심으로 아껴주시고\n축복해 주시는 소중한 분들께\n감사드립니다.\n\n변치않는 사랑으로 서로 아끼며\n존중하는 부부가 되겠습니다.`,
    },
    {
        content: `저희의 작은 사랑이\n큰 사랑으로 결실을\n맺게 되었습니다.\n\n함께 기뻐해주시고\n축복해주신 분들께\n진심으로 감사드립니다.\n\n저희에게 주신 사랑을 더해\n더 많이 사랑하고 아끼며\n살겠습니다.`,
    },
    {
        content: `인연이란 말은 참 아름답습니다.\n살면서 만나는 소중하고 귀한 인연들에게\n포도주 담그듯 기다림과 정성의 의미를\n그래서 더욱 값진 수확의 의미를\n함께 하고 싶습니다.`,
    },
    {
        content: `따뜻한 애정과 관심으로\n아껴주시는 마음에 감사드립니다.\n더 많이 아끼고 사랑하며\n예쁘고 행복하게 살겠습니다.`,
    },
    {
        content: `애정을 갖고\n따뜻하게 지켜봐주신 은혜\n진심으로 감사드립니다.\n\n더 큰사랑으로 행복한 가정\n만들어 나가겠습니다.`,
    },
];

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#fff",
        width: 290,
        maxHeight: 500,
        paddingBottom: 50,
        paddingLeft: 15,
        paddingRight: 15,
    },
    overlay: {
        backgroundColor: "rgb(0,0,0,0.5)",
        zIndex: 3000,
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: 300,
        },

        "& .MuiTextField-root": {
            width: 300,
        },
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
    nested: {
        paddingLeft: theme.spacing(0),
    },
}));

const ThanksOrderScreen = (props) => {
    const [userId, setUserId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [mainImg, setMainImg] = useState("");
    const [thumbImg, setThumbImg] = useState("");
    const [metaImg, setMetaImg] = useState("");

    const [uploadThumb, setUploadThumb] = useState("");
    const [uploadMeta, setUploadMeta] = useState("");
    const [uploadMain, setUploadMain] = useState("");

    const [husbandFamilyName, setHusbandFamilyName] = useState("");
    const [husbandName, setHusbandName] = useState("");
    const [husbandFirstSon, setHusbandFirstSon] = useState("");
    const [husbandPapa, setHusbandPapa] = useState("");
    const [husbandMama, setHusbandMama] = useState("");
    const [husbandPapaIsDead, setHusbandPapaIsDead] = useState(false);
    const [husbandMamaIsDead, setHusbandMamaIsDead] = useState(false);

    const [wifeFamilyName, setWifeFamilyName] = useState("");
    const [wifeName, setWifeName] = useState("");
    const [wifeFirstDau, setWifeFirstDau] = useState("");
    const [wifePapa, setWifePapa] = useState("");
    const [wifeMama, setWifeMama] = useState("");
    const [wifePapaIsDead, setWifePapaIsDead] = useState(false);
    const [wifeMamaIsDead, setWifeMamaIsDead] = useState(false);

    const [invatationContent, setInvatationContent] = useState("");

    const [weddingDate, setWeddingDate] = useState("");

    const [fontSize, setFontSize] = useState(1);
    const [bgm, setBgm] = useState("0");
    const [isPhoto, setIsPhoto] = useState(false);

    const [additionCollapse, setAdditionCollapse] = useState(false);
    const [kakaoCollapse, setKakaoCollapse] = useState(false);
    const [urlCallapse, setUrlCallapse] = useState(false);

    const [kakaoTitle, setKakaoTitle] = useState("");
    const [kakaoDes, setKakaoDes] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDes, setMetaDes] = useState("");

    const [isSubmit, setIsSumbmit] = useState(false);
    const [letterVer, setLetterVer] = useState(1);

    const classes = useStyles();

    // s3설정
    const config = {
        bucketName: "self-cryucard",
        dirName: weddingDate.slice(0, 10) /* optional */,
        region: "ap-northeast-2",
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    // s3설정

    const getEmail = async () => {
        let getUserId = "";

        if (localStorage.getItem("userId")) {
            getUserId = await localStorage.getItem("userId");
            await setIsLoading(true);
            setUserId(getUserId);
        } else {
            window.location.href = "/#/login";
            // await localStorage.setItem("userId", "51543483");
        }
    };

    const handleChange = (event) => {
        setFontSize(event.target.value);
    };

    const radioChange = (event) => {
        const { name, value } = event.target;

        if (name === "bgm") {
            setBgm(value);
        }
    };

    const handleClick = (target) => {
        if (target === "additionCollapse") {
            setAdditionCollapse(!additionCollapse);
        } else if (target === "kakaoCollapse") {
            setKakaoCollapse(!kakaoCollapse);
        } else if (target === "urlCallapse") {
            setUrlCallapse(!urlCallapse);
        }
    };

    const onChange = (event) => {
        // console.log(event.target.name);
        const {
            target: { name, value, checked },
        } = event;

        if (name === "content") {
            setInvatationContent(value);
        } else if (name === "weddingDate") {
            const limitDate = new Date();
            limitDate.setDate(limitDate.getDate() + 210);

            const valueDate = new Date(value);

            if (limitDate > valueDate) {
                setWeddingDate(value);
            } else {
                alert("6개월 이내의 예식일만 선택할 수 있습니다.");
                // return false;
                console.log(value);
            }
        } else if (name === "husbandFamilyName") {
            setHusbandFamilyName(value);
        } else if (name === "husbandName") {
            setHusbandName(value);
        } else if (name === "husbandFirstSon") {
            setHusbandFirstSon(value);
        } else if (name === "husbandPapa") {
            setHusbandPapa(value);
        } else if (name === "husbandMama") {
            setHusbandMama(value);
        } else if (name === "husbandPapaIsDead") {
            setHusbandPapaIsDead(checked);
        } else if (name === "husbandMamaIsDead") {
            setHusbandMamaIsDead(checked);
        } else if (name === "wifeFamilyName") {
            setWifeFamilyName(value);
        } else if (name === "wifeName") {
            setWifeName(value);
        } else if (name === "wifeFirstDau") {
            setWifeFirstDau(value);
        } else if (name === "wifePapa") {
            setWifePapa(value);
        } else if (name === "wifeMama") {
            setWifeMama(value);
        } else if (name === "wifePapaIsDead") {
            setWifePapaIsDead(checked);
        } else if (name === "wifeMamaIsDead") {
            setWifeMamaIsDead(checked);
        } else if (name === "isPhoto") {
            setIsPhoto(checked);
        } else if (name === "kakaoTitle") {
            setKakaoTitle(value);
        } else if (name === "kakaoDes") {
            setKakaoDes(value);
        } else if (name === "metaTitle") {
            setMetaTitle(value);
        } else if (name === "metaDes") {
            setMetaDes(value);
        }
    };

    function blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        // theBlob.lastModifiedDate = new Date();
        // theBlob.name = fileName;
        return theBlob;
    }

    const onFileChange = (event, index) => {
        const {
            target: { files, name },
        } = event;

        const theFile = files[0];
        const reader = new FileReader();

        reader.onloadend = async (finishedEvent) => {
            // console.log(finishedEvent.target.result);
            const {
                currentTarget: { result },
            } = finishedEvent;

            if (name === "mainImg") {
                try {
                    new Compressor(theFile, {
                        quality: 0.8,
                        maxWidth: 3000,
                        maxHeight: 3000,
                        success(res) {
                            setMainImg(result);
                            const compressedFile = blobToFile(res, name);
                            setUploadMain(compressedFile);
                        },
                        error(err) {
                            console.log(err.message);
                        },
                    });
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "thumbImg") {
                try {
                    // console.log(event.target.files[0]);

                    const compressedFile = await resizeFile(theFile);
                    setThumbImg(result);

                    setUploadThumb(compressedFile);
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "metaImg") {
                try {
                    // console.log(event.target.files[0]);

                    const compressedFile = await resizeFile(theFile);
                    setMetaImg(result);

                    setUploadMeta(compressedFile);
                } catch (err) {
                    console.log(err);
                }
            }
        };
        theFile && reader.readAsDataURL(theFile);
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1000,
                1000,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const submitFunc = async () => {
        setIsSumbmit(true);
        if (husbandName === "") {
            alert("신랑 성함을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (husbandFirstSon === "") {
            alert("신랑 서열을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (wifeName === "") {
            alert("신부 성함을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (wifeFirstDau === "") {
            alert("신부 서열을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (invatationContent === "") {
            alert("인삿말을 입력해주세요");
            setIsSumbmit(false);

            return false;
        }

        let submitMainImg = "";
        let submitThumb = "";
        let submitMeta = "";

        if (uploadMain) {
            await ReactS3Client.uploadFile(uploadMain, `${userId}-${uuidv4()}`)
                .then((data) => {
                    submitMainImg = data.location;
                })
                .catch((err) => console.error(err));
        }

        if (uploadThumb) {
            await ReactS3Client.uploadFile(uploadThumb, `${userId}-${uuidv4()}`)
                .then((data) => {
                    submitThumb = data.location;
                })
                .catch((err) => console.error(err));
        }

        if (uploadMeta) {
            await ReactS3Client.uploadFile(uploadMeta, `${userId}-${uuidv4()}`)
                .then((data) => {
                    submitMeta = data.location;
                })
                .catch((err) => console.error(err));
        }

        const content = await {
            createdAt: Date.now(),
            userId: userId,
            mainImg: submitMainImg,
            thumbImg: submitThumb,
            metaImg: submitMeta,
            husbandFamilyName,
            husbandName,
            husbandFirstSon,
            husbandPapa,
            husbandMama,
            husbandPapaIsDead,
            husbandMamaIsDead,
            wifeFamilyName,
            wifeName,
            wifeFirstDau,
            wifePapa,
            wifeMama,
            wifePapaIsDead,
            wifeMamaIsDead,
            invatationContent: invatationContent
                ? invatationContent
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : invatationContent,
            weddingDate,
            fontSize: fontSize * 1,
            bgm: bgm * 1,
            isPhoto,
            additionCollapse,
            kakaoTitle,
            kakaoDes: kakaoDes
                ? kakaoDes
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : kakaoDes,
            metaTitle,
            metaDes: metaDes
                ? metaDes
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : metaDes,
            waterMark: false,
            letterVer: letterVer * 1,
        };

        await dbService
            .collection("thanksLetter")
            .add(content)
            .then((res) => {
                alert("제작이 완료되었습니다 !");
                window.location.href = "/#/list";
            });
    };

    const getParams = async () => {
        let searchParams = new URLSearchParams(
            window.location.href.split("?")[1]
        );
        let paramsNum = searchParams.get("num");
        if (paramsNum) {
            await setLetterVer(paramsNum);
        }
    };

    useEffect(() => {
        getParams();
        setWeddingDate(getToday);
        getEmail();
    }, []);

    return (
        <div>
            {isLoading ? (
                <div
                    style={{
                        backgroundColor: "rgb(238 238 238)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#fff",
                            maxWidth: 480,
                        }}
                    >
                        {/* 헤더 */}
                        <CryuHeader userId={userId} />

                        {/* 헤더 */}

                        <div>
                            <div style={{ padding: "20px 20px 20px" }}>
                                <p
                                    style={{
                                        padding: 0,
                                        fontSize: 20,
                                        fontWeight: "bold",
                                        lineHeight: "40px",
                                    }}
                                >
                                    메인사진을 선택해주세요 📸
                                </p>
                                <p style={{ fontSize: 12, color: "#777" }}>
                                    가로, 세로에 상관없이 추가가능합니다.
                                </p>
                            </div>

                            <div style={{ margin: 6, padding: "0 14px" }}>
                                <div
                                    style={{
                                        margin: 6,
                                        display: "inline-block",
                                        width: 150,
                                    }}
                                >
                                    {mainImg !== "" ? (
                                        <div
                                            style={{
                                                width: 200,
                                                height: 200,
                                                border: "1px solid #000",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: "#000",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    src={mainImg}
                                                    style={{
                                                        maxHeight: 200,
                                                        maxWidth: 200,
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(0, 0, 0,0.6)",
                                                        height: 50,
                                                        width: 200,
                                                        position: "absolute",
                                                        bottom: 0,
                                                        display: "flex",
                                                    }}
                                                >
                                                    <label
                                                        for="mainImg"
                                                        style={{
                                                            flex: 1,
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <CreateIcon
                                                            style={{
                                                                color: "#fff",
                                                                fontSize: 22,
                                                            }}
                                                        />
                                                    </label>
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                        onClick={() =>
                                                            setMainImg("")
                                                        }
                                                    >
                                                        <CloseIcon
                                                            style={{
                                                                color: "#fff",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <label
                                            for="mainImg"
                                            style={{
                                                width: 200,
                                                height: 200,
                                                border: "1px dashed rgb(206, 212, 218)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: 70,
                                                    fontWeight: "100",
                                                    color: "#999",
                                                }}
                                            >
                                                +
                                            </span>
                                        </label>
                                    )}

                                    <input
                                        id="mainImg"
                                        name="mainImg"
                                        type="file"
                                        accept="image/*"
                                        onChange={onFileChange}
                                        style={{ width: 1, height: 1 }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: "0px 16px" }}>
                            <div style={{ padding: "0px 7px 20px" }}>
                                <p
                                    style={{
                                        padding: 0,
                                        fontSize: 20,
                                        fontWeight: "bold",
                                        lineHeight: "40px",
                                    }}
                                >
                                    예식일자를 알려주세요 🗓
                                </p>
                            </div>

                            <TextField
                                id="datetime-local"
                                type="datetime-local"
                                name="weddingDate"
                                label="예식일자"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={weddingDate}
                                onChange={onChange}
                            />
                        </div>

                        <div style={{ padding: "0px 14px" }}>
                            <form
                                className={classes.root}
                                noValidate
                                autoComplete="off"
                            >
                                <div style={{ padding: "20px 0px 20px" }}>
                                    <p
                                        style={{
                                            padding: 0,
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            lineHeight: "40px",
                                        }}
                                    >
                                        신랑측 정보를 알려주세요 🤵🏻
                                    </p>
                                    <p style={{ fontSize: 12, color: "#777" }}>
                                        기입하시는 내용만 청첩장에 표기됩니다
                                    </p>
                                </div>
                                <div style={{ display: "flex", width: 300 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="신랑 성"
                                        variant="outlined"
                                        name="husbandFamilyName"
                                        value={husbandFamilyName}
                                        onChange={onChange}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="신랑 이름"
                                        variant="outlined"
                                        style={{ marginLeft: 5 }}
                                        name="husbandName"
                                        value={husbandName}
                                        onChange={onChange}
                                    />
                                </div>

                                <TextField
                                    id="outlined-basic"
                                    label="신랑 서열 (장남, 차남, 아들)"
                                    variant="outlined"
                                    name="husbandFirstSon"
                                    value={husbandFirstSon}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신랑 아버님 성함"
                                    variant="outlined"
                                    name="husbandPapa"
                                    value={husbandPapa}
                                    onChange={onChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            name="husbandPapaIsDead"
                                            checked={husbandPapaIsDead}
                                            onChange={onChange}
                                        />
                                    }
                                    label="고인이시면 체크하여주세요"
                                    id="custom_check"
                                />

                                <TextField
                                    id="outlined-basic"
                                    label="신랑 어머님 성함"
                                    variant="outlined"
                                    name="husbandMama"
                                    value={husbandMama}
                                    onChange={onChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            name="husbandMamaIsDead"
                                            checked={husbandMamaIsDead}
                                            onChange={onChange}
                                        />
                                    }
                                    label="고인이시면 체크하여주세요"
                                    id="custom_check"
                                />

                                <div style={{ padding: "20px 0px 20px" }}>
                                    <p
                                        style={{
                                            padding: 0,
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            lineHeight: "40px",
                                        }}
                                    >
                                        신부측 정보를 알려주세요 👰🏻
                                    </p>
                                    <p style={{ fontSize: 12, color: "#777" }}>
                                        기입하시는 내용만 청첩장에 표기됩니다
                                    </p>
                                </div>

                                <div style={{ display: "flex", width: 300 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="신부 성"
                                        variant="outlined"
                                        name="wifeFamilyName"
                                        value={wifeFamilyName}
                                        onChange={onChange}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="신부 이름"
                                        variant="outlined"
                                        style={{ marginLeft: 5 }}
                                        name="wifeName"
                                        value={wifeName}
                                        onChange={onChange}
                                    />
                                </div>

                                <TextField
                                    id="outlined-basic"
                                    label="신부 서열 (장녀, 차녀, 딸)"
                                    variant="outlined"
                                    name="wifeFirstDau"
                                    value={wifeFirstDau}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신부 아버님 성함"
                                    variant="outlined"
                                    name="wifePapa"
                                    value={wifePapa}
                                    onChange={onChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            name="wifePapaIsDead"
                                            checked={wifePapaIsDead}
                                            onChange={onChange}
                                        />
                                    }
                                    label="고인이시면 체크하여주세요"
                                    id="custom_check"
                                />

                                <TextField
                                    id="outlined-basic"
                                    label="신부 어머님 성함"
                                    variant="outlined"
                                    name="wifeMama"
                                    value={wifeMama}
                                    onChange={onChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            name="wifeMamaIsDead"
                                            checked={wifeMamaIsDead}
                                            onChange={onChange}
                                        />
                                    }
                                    label="고인이시면 체크하여주세요"
                                    id="custom_check"
                                />

                                {/* 인삿말 */}
                                <div style={{ marginBottom: 30 }}>
                                    <div style={{ padding: "20px 0px 20px" }}>
                                        <p
                                            style={{
                                                padding: 0,
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                lineHeight: "40px",
                                            }}
                                        >
                                            인삿말을 알려주세요 📝
                                        </p>
                                        <p
                                            style={{
                                                fontSize: 12,
                                                color: "#777",
                                            }}
                                        >
                                            원하시는 인삿말으로 직접 추가도
                                            가능합니다
                                        </p>
                                    </div>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="인삿말"
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                        variant="outlined"
                                        name={"content"}
                                        value={invatationContent}
                                        onChange={onChange}
                                        style={{ fontSize: 14 }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 20 }}
                                        onClick={() => {
                                            setIsOpenModal(true);
                                        }}
                                    >
                                        샘플 인삿말 보기
                                    </Button>
                                    <Modal
                                        isOpen={isOpenModal}
                                        style={customStyles}
                                        shouldCloseOnOverlayClick
                                        onRequestClose={() =>
                                            setIsOpenModal(false)
                                        }
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    원하시는 인삿말을 골라주세요
                                                </span>
                                                <CloseIcon
                                                    onClick={() =>
                                                        setIsOpenModal(false)
                                                    }
                                                />
                                            </div>
                                            {invatationContentArray.map(
                                                (item) => {
                                                    return (
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                marginTop: 20,
                                                                padding:
                                                                    "20px 10px",
                                                                width: "100%",
                                                                backgroundColor:
                                                                    "#f5f5f5",
                                                                fontSize: 12,
                                                                lineHeight:
                                                                    "20px",
                                                            }}
                                                            onClick={() => {
                                                                setInvatationContent(
                                                                    item.content
                                                                );
                                                                // console.log(item.content);
                                                                setIsOpenModal(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "center",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        FLOWER_IMG
                                                                    }
                                                                    style={{
                                                                        width: 30,
                                                                        height: 30,
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        paddingTop: 10,
                                                                    }}
                                                                >
                                                                    {item.content
                                                                        ? ReactHtmlParser(
                                                                              item.content.replace(
                                                                                  /\n/g,
                                                                                  "<br/>"
                                                                              )
                                                                          )
                                                                        : item.content}
                                                                </div>
                                                            </div>
                                                        </Button>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </Modal>
                                </div>
                                {/* 인삿말 */}

                                {/* 부가적인 기능 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("additionCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {additionCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="부가적인 기능 ⚙️"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={additionCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div style={{ padding: "10px 0" }}>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: 300 }}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    폰트 크기
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={fontSize}
                                                    onChange={handleChange}
                                                    label="Age"
                                                >
                                                    <MenuItem value={1}>
                                                        보통
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        크게
                                                    </MenuItem>
                                                    {/* <MenuItem value={3}>더 크게</MenuItem> */}
                                                </Select>
                                            </FormControl>

                                            {/* 청첩장 확대 금지 */}
                                            <div style={{ marginTop: 20 }}>
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                        paddingBottom: 10,
                                                    }}
                                                >
                                                    청첩장 확대 금지
                                                    <br />
                                                    <span
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        (카카오톡 공유 시 확대
                                                        금지)
                                                    </span>
                                                </p>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            }
                                                            checkedIcon={
                                                                <CheckBoxIcon fontSize="small" />
                                                            }
                                                            name="isPhoto"
                                                            checked={isPhoto}
                                                            onChange={onChange}
                                                            style={{
                                                                paddingLeft: 0,
                                                            }}
                                                        />
                                                    }
                                                    label="청첩장 내 확대를 금지합니다"
                                                    id="custom_check"
                                                />
                                            </div>
                                            {/* 청첩장 확대 금지 */}
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 부가적인 기능 */}

                                {/* 카카오톡 공유 썸네일 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("kakaoCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {kakaoCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="카카오톡 공유 시"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={kakaoCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    margin: 6,
                                                    marginLeft: 0,
                                                    display: "inline-block",
                                                    width: 150,
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        paddingBottom: 15,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    카카오톡 썸네일 사진
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#999",
                                                        }}
                                                    >
                                                        (최적사이즈 400*550)
                                                    </span>
                                                </p>
                                                {thumbImg !== "" ? (
                                                    <div
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            border: "1px solid #000",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    "#000",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src={thumbImg}
                                                                style={{
                                                                    maxHeight: 200,
                                                                    maxWidth: 200,
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        "rgb(0, 0, 0,0.6)",
                                                                    height: 50,
                                                                    width: 200,
                                                                    position:
                                                                        "absolute",
                                                                    bottom: 0,
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <label
                                                                    for="thumbImg"
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <CreateIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontSize: 22,
                                                                        }}
                                                                    />
                                                                </label>
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                    onClick={() =>
                                                                        setThumbImg(
                                                                            ""
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <label
                                                        for="thumbImg"
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            border: "1px dashed rgb(206, 212, 218)",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: 70,
                                                                fontWeight:
                                                                    "100",
                                                                color: "#999",
                                                            }}
                                                        >
                                                            +
                                                        </span>
                                                    </label>
                                                )}

                                                <input
                                                    id="thumbImg"
                                                    name="thumbImg"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={onFileChange}
                                                    style={{
                                                        width: 1,
                                                        height: 1,
                                                    }}
                                                />
                                            </div>
                                            <TextField
                                                id="outlined-basic"
                                                label="카카오톡 제목"
                                                variant="outlined"
                                                name="kakaoTitle"
                                                value={kakaoTitle}
                                                onChange={onChange}
                                            />

                                            <TextField
                                                id="outlined-multiline-static"
                                                label="카카오톡 내용"
                                                multiline
                                                rows={4}
                                                defaultValue=""
                                                variant="outlined"
                                                style={{ marginTop: 15 }}
                                                name="kakaoDes"
                                                value={kakaoDes}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 카카오톡 공유 썸네일 */}

                                {/* URL 공유 썸네일 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("urlCallapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {urlCallapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="URL 공유 시"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={urlCallapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    margin: 6,
                                                    marginLeft: 0,
                                                    display: "inline-block",
                                                    width: 150,
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        paddingBottom: 15,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    URL 썸네일 사진
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#999",
                                                        }}
                                                    >
                                                        (최적사이즈 1200*630)
                                                    </span>
                                                </p>
                                                {metaImg !== "" ? (
                                                    <div
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            border: "1px solid #000",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    "#000",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src={metaImg}
                                                                style={{
                                                                    maxHeight: 200,
                                                                    maxWidth: 200,
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        "rgb(0, 0, 0,0.6)",
                                                                    height: 50,
                                                                    width: 200,
                                                                    position:
                                                                        "absolute",
                                                                    bottom: 0,
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <label
                                                                    for="metaImg"
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <CreateIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontSize: 22,
                                                                        }}
                                                                    />
                                                                </label>
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                    onClick={() =>
                                                                        setMetaImg(
                                                                            ""
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <label
                                                        for="metaImg"
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            border: "1px dashed rgb(206, 212, 218)",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: 70,
                                                                fontWeight:
                                                                    "100",
                                                                color: "#999",
                                                            }}
                                                        >
                                                            +
                                                        </span>
                                                    </label>
                                                )}

                                                <input
                                                    id="metaImg"
                                                    name="metaImg"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={onFileChange}
                                                    style={{
                                                        width: 1,
                                                        height: 1,
                                                    }}
                                                />
                                            </div>
                                            <TextField
                                                id="outlined-basic"
                                                label="URL 제목"
                                                variant="outlined"
                                                name="metaTitle"
                                                value={metaTitle}
                                                onChange={onChange}
                                            />

                                            <TextField
                                                id="outlined-multiline-static"
                                                label="URL 내용"
                                                defaultValue=""
                                                variant="outlined"
                                                style={{ marginTop: 15 }}
                                                name="metaDes"
                                                value={metaDes}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                                {/* URL 공유 썸네일 */}
                            </form>
                        </div>

                        {/* 저장하기 버튼 */}
                        <div
                            style={{
                                backgroundColor: "#F76C6C",
                                height: 50,
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                maxWidth: "480px",
                                cursor: "pointer",
                                marginTop: 100,
                            }}
                            onClick={() => {
                                if (!isSubmit) {
                                    submitFunc();
                                }
                            }}
                        >
                            {!isSubmit ? (
                                "제작하기"
                            ) : (
                                <CircularProgress
                                    style={{
                                        width: 25,
                                        height: 25,
                                        marginTop: 3,
                                    }}
                                />
                            )}
                        </div>
                        {/* 저장하기 버튼 */}
                    </div>
                </div>
            ) : (
                false
            )}
        </div>
    );
};

export default ThanksOrderScreen;
