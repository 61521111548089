import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { dbService } from "../../fbase";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { DataGrid } from "@material-ui/data-grid";

const columns = [
    { field: "id", headerName: "id", width: 150 },
    {
        field: "userEmail",
        headerName: "userEmail",
        width: 150,
    },
    {
        field: "quantity",
        headerName: "quantity",
        width: 150,
    },
    {
        field: "createdAt",
        headerName: "createdAt",
        width: 150,
    },
];

const rows = [
    { createdAt: 2432443, id: "cryu", quantity: 2 },
    { id: "cryu1", quantity: 2, createdAt: 2432443 },
    { id: "cryu2", quantity: 2, createdAt: 2432443 },
    { id: "cryu3", quantity: 2, createdAt: 2432443 },
];

var arrBuyList = [];

const CouponScreen = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& > *": {
                margin: theme.spacing(1),
                width: "25ch",
            },
        },
    }));
    const classes = useStyles();

    const [id, setId] = useState("");
    const [thanksId, setThanksId] = useState("");
    const [coupon, setCoupon] = useState("");
    const [couponNum, setCouponNum] = useState(1);
    const [thanksNum, setThanksNum] = useState(1);
    const [couponNum2, setCouponNum2] = useState(1);

    const [isLoading, setIsLoading] = useState(false);

    const onChange = (event) => {
        const {
            target: { name, value, checked },
        } = event;

        if (name === "textId") {
            setId(value);
            console.log(value);
        } else if (name === "textCouponNum") {
            setCouponNum(value);
            console.log(value);
        } else if (name === "thanksId") {
            setThanksId(value);
            console.log(value);
        } else if (name === "thanksNum") {
            setThanksNum(value);
            console.log(value);
        } else if (name === "coupon") {
            setCoupon(value);
            console.log(value);
        } else if (name === "couponNum2") {
            setCouponNum2(value);
            console.log(value);
        }
    };

    const getLetter = () => {
        var buyListRef = dbService.collection("buyList");

        if (id) {
            buyListRef
                .doc(id)
                .get()
                .then((res) => {
                    if (res.data() === undefined) {
                        console.log("hello");

                        dbService
                            .collection("buyList")
                            .doc(id)
                            .set({
                                id: id,
                                userEmail: id,
                                quantity: couponNum,
                                createdAt: Date.now(),
                            })
                            .then((res) => {
                                alert("입력이 완료되었습니다 !");
                            });
                    } else {
                        const num = res.data().quantity;

                        dbService
                            .collection("buyList")
                            .doc(id)
                            .update({
                                quantity: num + couponNum,
                            })
                            .then((res) => {
                                alert("수정이 완료되었습니다.");
                            });
                    }
                });
        } else {
            alert("id를 입력해주세요");
        }
    };

    const getThanksLetter = () => {
        var buyListRef = dbService.collection("thanksBuyList");

        if (thanksId) {
            buyListRef
                .doc(thanksId)
                .get()
                .then((res) => {
                    if (res.data() === undefined) {
                        console.log("hello");

                        dbService
                            .collection("thanksBuyList")
                            .doc(thanksId)
                            .set({
                                userEmail: thanksId,
                                quantity: thanksNum,
                                createdAt: Date.now(),
                            })
                            .then((res) => {
                                alert("입력이 완료되었습니다 !");
                            });
                    } else {
                        const num = res.data().quantity;

                        dbService
                            .collection("thanksBuyList")
                            .doc(thanksId)
                            .update({
                                quantity: num + thanksNum,
                            })
                            .then((res) => {
                                alert("수정이 완료되었습니다.");
                            });
                    }
                });
        } else {
            alert("id를 입력해주세요");
        }
    };

    const getCoupon = () => {
        var buyListRef = dbService.collection("couponList");

        if (coupon) {
            buyListRef
                .doc(coupon)
                .get()
                .then((res) => {
                    if (res.data() === undefined) {
                        console.log("hello");

                        dbService
                            .collection("couponList")
                            .doc(coupon)
                            .set({
                                couponNumber: coupon,
                                quantity: couponNum2,
                                createdAt: Date.now(),
                            })
                            .then((res) => {
                                alert("입력이 완료되었습니다 !");
                            });
                    } else {
                        const num = res.data().quantity;

                        dbService
                            .collection("couponList")
                            .doc(coupon)
                            .update({
                                quantity: num + couponNum2,
                            })
                            .then((res) => {
                                alert("수정이 완료되었습니다.");
                            });
                    }
                });
        } else {
            alert("쿠폰를 입력해주세요");
        }
    };

    const getList = () => {
        var buyListRef = dbService.collection("buyList");

        buyListRef
            .orderBy("createdAt", "desc")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());

                    arrBuyList.push(doc.data());
                });
                setIsLoading(true);
                console.log(arrBuyList);
            });
    };

    useEffect(() => {
        getList();
    }, []);

    return (
        <div>
            {isLoading ? (
                <div>
                    <Tabs>
                        <div style={{ padding: "0px 14px", marginTop: 60 }}>
                            <TabList>
                                <Tab>
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "500",
                                            color: "#333",
                                        }}
                                    >
                                        모바일청첩장
                                    </span>
                                </Tab>
                                <Tab>
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "500",
                                            color: "#333",
                                        }}
                                    >
                                        모바일감사장
                                    </span>
                                </Tab>
                                <Tab>
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "500",
                                            color: "#333",
                                        }}
                                    >
                                        쿠폰
                                    </span>
                                </Tab>
                            </TabList>
                        </div>

                        <TabPanel>
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        borderRadius: 5,
                                        padding: 10,
                                        paddingTop: 100,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <TextField
                                        id="outlined-basic"
                                        label="아이디"
                                        variant="outlined"
                                        name={"textId"}
                                        value={id}
                                        onChange={onChange}
                                    />
                                    <div style={{ marginTop: 20 }}>
                                        <TextField
                                            id="standard-number"
                                            label="Number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name={"textCouponNum"}
                                            value={couponNum}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{
                                            width: 200,
                                            marginTop: 30,
                                            height: 50,
                                        }}
                                        onClick={() => {
                                            getLetter();
                                        }}
                                    >
                                        제출하기
                                    </Button>
                                </div>

                                <div
                                    style={{
                                        height: 400,
                                        width: "100%",
                                        marginTop: 50,
                                    }}
                                >
                                    {arrBuyList ? (
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={5}
                                            checkboxSelection
                                            disableSelectionOnClick
                                        />
                                    ) : (
                                        false
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    borderRadius: 5,
                                    padding: 10,
                                    paddingTop: 100,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    label="아이디"
                                    variant="outlined"
                                    name={"thanksId"}
                                    value={thanksId}
                                    onChange={onChange}
                                />
                                <div style={{ marginTop: 20 }}>
                                    <TextField
                                        id="standard-number"
                                        label="Number"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name={"thanksNum"}
                                        value={thanksNum}
                                        onChange={onChange}
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        width: 200,
                                        marginTop: 30,
                                        height: 50,
                                    }}
                                    onClick={() => {
                                        getThanksLetter();
                                    }}
                                >
                                    제출하기
                                </Button>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    borderRadius: 5,
                                    padding: 10,
                                    paddingTop: 100,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    label="아이디"
                                    variant="outlined"
                                    name={"coupon"}
                                    value={coupon}
                                    onChange={onChange}
                                />
                                <div style={{ marginTop: 20 }}>
                                    <TextField
                                        id="standard-number"
                                        label="Number"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name={"couponNum2"}
                                        value={couponNum2}
                                        onChange={onChange}
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    color="#000"
                                    style={{
                                        width: 200,
                                        marginTop: 30,
                                        height: 50,
                                    }}
                                    onClick={() => {
                                        getCoupon();
                                    }}
                                >
                                    제출하기
                                </Button>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            ) : (
                false
            )}
        </div>
    );
};

export default CouponScreen;
