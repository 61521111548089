import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import localStorage from "mobx-localstorage";
const { userAgent, languages } = window.navigator;
var str = userAgent;
var word1 = "Instagram";
var word2 = "KAKAOTALK";
var iVal1 = str.indexOf(word1);
var iVal2 = str.indexOf(word2);

const { naver } = window;

const CallbackPage = ({ path }) => {
    const location = useLocation();

    const getNaverToken = (naverLogin) => {
        if (!location.hash) return;
        const token = location.hash.split("=")[1].split("&")[0];
        console.log(token);
        axios
            .get(
                `https://us-central1-self-cryucard.cloudfunctions.net/api/login/${token}`
            )
            .then(async (res) => {
                //200 이면 청첩장 제작 페이지 리다이렉트
                // console.log(res.data);
                // console.log(res.data.userId);
                localStorage.setItem("userId", res.data.userId);

                if (iVal1 === -1 && iVal2 === -1) {
                    await window.opener.gotoSNSlogin();
                    window.close();
                }

                window.location.href = `/#/${
                    path === "new"
                        ? ""
                        : path === "old"
                        ? "orderList"
                        : path === "hidi"
                        ? "hidi"
                        : ""
                }`;
            });
    };

    useEffect(() => {
        getNaverToken();
    }, []);
    return <div></div>;
};

export default CallbackPage;
