import React from "react";
import StepperComponent from "../../../components/StepperComponent/StepperComponent";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import NextButton from "../../../components/NextButton/NextButton";
import Header from "../../../components/Header/Header";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginTop: 20,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const GreetingScreen = () => {
    const classes = useStyles();
    return (
        <div
            style={{
                backgroundColor: "rgb(238 238 238)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ backgroundColor: "#fff", maxWidth: 480 }}>
                <Header />
                <StepperComponent step={3} />
                <div style={{ padding: "20px 20px 20px" }}>
                    <p
                        style={{
                            padding: 0,
                            fontSize: 20,
                            fontWeight: "bold",
                            lineHeight: "40px",
                        }}
                    >
                        인삿말을 선택해주세요 🎉
                    </p>
                    <p style={{ fontSize: 12, color: "#777" }}>
                        마음에 드는 인삿말이 없으시다면
                        <br />
                        직접 기입도 가능합니다 😊
                    </p>
                </div>

                <div
                    style={{
                        padding: 20,
                        paddingTop: 1,
                        backgroundColor: "#fff",
                    }}
                >
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography
                                className={classes.pos}
                                color="textSecondary"
                            >
                                인삿말 샘플 1
                            </Typography>
                            <Typography variant="body2" component="p">
                                각자 서로 다른 길을 <br />
                                걸어온 저희가 이제 부부의 연으로 <br />
                                한 길을 걸어가고자 합니다. <br />
                                <br />
                                항상 처음을 생각하며,
                                <br />
                                서로 아껴주고 사랑하며 살겠습니다.
                                <br />
                                <br />
                                부디 참석해 주시어 저희의 약속을
                                <br />
                                따뜻한 격려로 축복해 주시기 바랍니다.
                            </Typography>
                        </CardContent>
                        {/* <CardActions>
            <Button size="small">선택</Button>
          </CardActions> */}
                    </Card>{" "}
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography
                                className={classes.pos}
                                color="textSecondary"
                            >
                                인삿말 샘플 2
                            </Typography>
                            <Typography variant="body2" component="p">
                                코로나19 바이러스로 인해
                                <br />
                                걱정이 많았지만 종식을 예측할 수 없어
                                <br />
                                결혼식을 일정대로 진행하기로 했습니다.
                                <br />
                                <br />
                                저희를 축하해주시는 마음은 모두 같으니
                                <br />
                                참석에 대한 부담감을 갖지 않으시길 바라며
                                <br />
                                축하해주신 모든 분들께 감사드립니다.
                            </Typography>
                        </CardContent>
                        {/* <CardActions>
            <Button size="small">선택</Button>
          </CardActions> */}
                    </Card>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography
                                className={classes.pos}
                                color="textSecondary"
                            >
                                인삿말 샘플 3
                            </Typography>
                            <Typography variant="body2" component="p">
                                저희 두 사람의 작은 만남이
                                <br />
                                사랑의 결실을 이루어
                                <br />
                                소중한 결혼식을 올리게 되었습니다.
                                <br />
                                <br />
                                평생 서로 귀하게 여기며
                                <br />
                                첫 마음 그대로 존중하고 배려하며 살겠습니다.
                                <br />
                                <br />
                                오로지 믿음과 사랑을 약속하는 날<br />
                                오셔서 축복해 주시면 더없는
                                <br />
                                기쁨으로 간직하겠습니다.
                            </Typography>
                        </CardContent>
                        {/* <CardActions>
            <Button size="small">선택</Button>
          </CardActions> */}
                    </Card>
                </div>

                <NextButton path="/order/location" />
            </div>
        </div>
    );
};

export default GreetingScreen;
