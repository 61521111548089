import React, { useEffect } from "react";
import banner from "../../assets/images/logo.png";
const { userAgent, languages } = window.navigator;
var str = userAgent;
var word1 = "Instagram";
var iVal1 = str.indexOf(word1);

const { naver } = window;

const NewLoginScreen = () => {
  const naverLogin = () => {
    console.log("fuc naverLogin");
    var naverLogin = new naver.LoginWithNaverId({
      clientId: "UFZAB_49k9HdAHhyPHA4",
      callbackUrl:
        "https://smartstore.cryucompany.com/#/Api/Member/Oauth2ClientCallback/naver/",
      isPopup: iVal1 === -1 ? true : false,
      loginButton: { color: "green", type: 3, height: "47" },
    });
    naverLogin.init();
  };

  useEffect(() => {
    naverLogin();
    // console.log(window);
    // localStorage.setItem("userId", "51543483");

    window.gotoSNSlogin = () => {
      window.location.href = "/";
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center", paddingBottom: 20 }}>
        <img src={banner} style={{ height: 100 }} />
      </div>
      <div id="naverIdLogin"></div>
    </div>
  );
};

export default NewLoginScreen;
