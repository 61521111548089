import React, { useState, useCallback, useEffect } from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";
import { dbService } from "../../fbase";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";
import CheckIcon from "@material-ui/icons/Check";
import Compressor from "compressorjs";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";

const STORAGE_URL = "https://storage.cryucard.com";

const outroArray = [
  {
    title: "",
    content: `감사의 말씀 올립니다.<br /><br />언제나 곁에서 응원해주시는<br />양가 부모님들과 주변 지인분들에게<br />무한한 감사의 말씀 올립니다.<br />행복하게 살겠습니다.`,
  },
  {
    title: "",
    content: `예쁜 예감이 들었다.<br/>우리는 언제나 손을 잡고 있게 될 것이다.<br/><br/><em>이이체, 연인</em>`,
  },
  {
    title: "",
    content: `장담하건대, 세상이 다 겨울이어도<br />우리 사랑은 늘 봄처럼 따뜻하고<br />간혹, 여름처럼 뜨거울 겁니다.<br /><br /><em>이수동, 사랑가</em>`,
  },
  {
    title: "",
    content: `최고의 사랑은 영혼을 일깨우고<br />더 많이 소망하게 하고<br />가슴에는 열정을, 마음에는 평화를 주지<br /><br /><em>영화, 노트북</em>`,
  },
  {
    title: "",
    content: `우리는 매일 우리의 삶을 여행합니다.<br />우리가 할 수 있는건,<br />이 멋진 여행을 즐기기 위해<br />매일 최선을 다하는 것입니다.<br /><br /><em>영화, 어바웃타임</em>`,
  },
];

const imgArray = [
  {
    url: "https://storage.cryucard.com/image/2023-05-20/UC4HKxoQYP0mWzaSNyhG-a5a03cff-fe37-4a1f-9baf-b3a65adeec9d.png",
  },
  {
    url: "https://storage.cryucard.com/image/2023-05-20/UC4HKxoQYP0mWzaSNyhG-8b19c336-10ac-42d2-8b9d-96c1cd700994.jpeg",
  },
  {
    url: "https://storage.cryucard.com/image/2023-05-20/UC4HKxoQYP0mWzaSNyhG-fabd6893-7762-4a5d-9597-002c19062a55.jpeg",
  },
  {
    url: "https://storage.cryucard.com/image/2023-05-20/UC4HKxoQYP0mWzaSNyhG-9a0bf9bc-715f-4bb6-815c-00a81444f239.jpeg",
  },
  {
    url: "https://storage.cryucard.com/image/2023-05-20/UC4HKxoQYP0mWzaSNyhG-d0f132e5-3da4-4243-9e66-699d7ec15743.jpeg",
  },
];

const SampleComponent = ({
  outroTitle,
  outroContent,
  setIsModal,
  setOutroTitle,
  setOutroContent,
  onSubmit,
}) => {
  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "20px 16px",
        borderRadius: 5,
        cursor: "pointer",
        fontSize: 14,
        color: "#333",
        marginTop: 20,
      }}
      onClick={() => {
        setIsModal(false);
        setOutroTitle(outroTitle);
        setOutroContent(outroContent);
        onSubmit({ _outroTitle: outroTitle });
        onSubmit({ _outroContent: outroContent });
      }}
    >
      {outroTitle && <div style={{ marginBottom: 10 }}>{outroTitle}</div>}
      <div>{ReactHtmlParser(outroContent)}</div>
    </div>
  );
};

const ImgSelectComponent = ({
  outroImg,
  outroImgIndex,
  setOutroImgIndex,
  setOutroImg,
  onSubmit,
  onFileChange,
}) => {
  return (
    <div>
      <div
        style={{
          fontSize: "1rem",
          fontWeight: "500",
          margin: "12px 0px 8px",
          color: "#767676",
        }}
      >
        아웃트로 사진
      </div>

      <div
        style={{
          display: "flex",
          marginTop: 10,
          overflow: "auto",
        }}
      >
        <input
          id="outroImg"
          name="outroImg"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => {
            console.log("hello");
            onFileChange(e);
          }}
          style={{
            width: 1,
            height: 1,
            position: "absolute",
            display: "none",
          }}
        />
        <label for="outroImg" style={{}}>
          <div
            style={{
              width: 100,
              height: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #e9e9e9",
              borderRadius: 8,
              cursor: "pointer",
              margin: "10px 5px",
              position: "relative",
            }}
          >
            {outroImgIndex === 99 && (
              <img
                src={outroImg}
                style={{
                  width: 100,
                  height: 100,
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  borderRadius: 8,
                  objectFit: "cover",
                }}
              />
            )}

            <span
              style={{
                fontSize: 10,
                width: 100,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 100,
                backgroundColor:
                  outroImgIndex === 99 ? "rgba(0,0,0,0.3)" : "#e9ecec",
                borderRadius: 8,
                color: outroImgIndex === 99 ? "#fff" : "#000",
              }}
            >
              직접 추가하기
            </span>
          </div>
        </label>
        {imgArray.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #e9e9e9",
                borderRadius: 8,
                cursor: "pointer",
                margin: "10px 5px",
                position: "relative",
              }}
              key={index}
              onClick={() => {
                setOutroImgIndex(index);
                onSubmit({ _outroImgIndex: index });
                if (index !== 0) {
                  onSubmit({ _outroImg: item.url });
                } else {
                  onSubmit({ _outroImg: "" });
                }
              }}
            >
              {outroImgIndex === index && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.3)",
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                  }}
                >
                  <CheckIcon fontSize="small" />
                </div>
              )}

              <img
                src={item.url}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 8,
                  objectFit: "cover",
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ThanksComponent = ({ state, getData, letterId }) => {
  // s3설정
  const config = {
    bucketName: "new-cryucard",
    dirName: `image/${state.weddingDate}`,
    region: "ap-northeast-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);
  // s3설정

  const uploadMainImg = async ({ _uploadMain }) => {
    let submitMainImg = "";
    console.log(_uploadMain);
    if (_uploadMain) {
      await ReactS3Client.uploadFile(_uploadMain, `${letterId}-${uuidv4()}`)
        .then((data) => {
          submitMainImg = data.location.split("image")[1];
          onSubmit({ _uploadImg: submitMainImg });
          onSubmit({ _outroImgIndex: 99 });
        })
        .catch((err) => console.error(err));
    }
  };

  const [outroTitle, setOutroTitle] = useState(
    state.outroTitle !== undefined ? state.outroTitle : "감사의 말씀 올립니다."
  );
  const [outroContent, setOutroContent] = useState(
    state.outroContent !== undefined
      ? state.outroContent
      : "언제나 곁에서 응원해주시는<br/>양가 부모님들과 주변 지인분들에게<br/>무한한 감사의 말씀 올립니다.<br/>행복하게 살겠습니다."
  );

  const [outroImgIndex, setOutroImgIndex] = useState(
    state.outroImgIndex !== undefined ? state.outroImgIndex : 0
  );

  const [outroImg, setOutroImg] = useState(
    state.outroImg !== undefined ? state.outroImg : ""
  );

  const [isModal, setIsModal] = useState(false);

  const handleChange = (text) => {
    setOutroContent(text);
    getText(text, "content");
  };

  const getText = (text, _parameter) => {
    // console.log(data.text);
    // pushData(data.text);

    debounceGetText({
      _parameter: _parameter,
      text: text,
    });
  };

  const debounceGetText = useCallback(
    debounce(({ _parameter, text }) => {
      if (_parameter === "content") {
        onSubmit({ _outroContent: text });
      } else if (_parameter === "title") {
        onSubmit({ _outroTitle: text });
      }
    }, 500),
    []
  );

  const onSubmit = async ({
    _outroContent,
    _outroTitle,
    _outroImg,
    _outroImgIndex,
    _uploadImg,
  }) => {
    let content = {};

    if (_outroContent !== undefined) {
      content = {
        outroContent: _outroContent,
      };
      console.log(_outroContent);
    } else if (_outroTitle !== undefined) {
      content = {
        outroTitle: _outroTitle,
      };
      console.log(_outroTitle);
    } else if (_outroImg !== undefined) {
      content = {
        outroImg: _outroImg,
      };
      console.log(_outroImg);
    } else if (_outroImgIndex !== undefined) {
      content = {
        outroImgIndex: _outroImgIndex,
      };
      console.log(_outroImgIndex);
    } else if (_uploadImg !== undefined) {
      content = {
        outroImg:
          _uploadImg === "" ? _uploadImg : `${STORAGE_URL}/image${_uploadImg}`,
      };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  const pushData = () => {
    getData({ isSaved: true });
  };

  const onFileChange = (event, index) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const reader = new FileReader();

    reader.onloadend = async (finishedEvent) => {
      // console.log(finishedEvent.target.result);
      const {
        currentTarget: { result },
      } = finishedEvent;

      try {
        new Compressor(theFile, {
          quality: 0.8,
          maxWidth: 2000,
          maxHeight: 2000,
          success(res) {
            setOutroImg(result);
            setOutroImgIndex(99);

            uploadMainImg({ _uploadMain: res });
          },
          error(err) {
            console.log(err.message);
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    theFile && reader.readAsDataURL(theFile);
  };

  return (
    <div>
      <div>
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          아웃트로
        </p>
        <p
          style={{
            marginTop: 5,
            fontSize: "0.9rem",
            color: "rgb(119, 119, 119)",
          }}
        >
          청첩장 내에 아웃트로가 추가됩니다.
          <br />
          <span style={{ fontSize: "0.7rem" }}>
            (아웃트로는 청첩장 최하단에 추가되며,
            <br />
            감사인사 혹은 예쁜 문구를 추가할 수 있습니다.)
          </span>
        </p>
      </div>
      <ImgSelectComponent
        outroImgIndex={outroImgIndex}
        setOutroImgIndex={setOutroImgIndex}
        setOutroImg={setOutroImg}
        outroImg={outroImg}
        onSubmit={onSubmit}
        onFileChange={onFileChange}
      />

      <div style={{ marginTop: 20 }}>
        {(state.version === undefined || state.version < 2) && (
          <div>
            <SubTitleComponent title="제목" />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "title");
              }}
              _value={outroTitle}
            />
          </div>
        )}

        <div style={{ marginTop: 16 }}>
          <ReactQuill
            theme={"snow"}
            onChange={(e) => {
              handleChange(e, "content");
            }}
            value={outroContent}
            modules={{
              toolbar: [
                [
                  "bold",
                  "italic",
                  "underline",
                  // { list: "ordered" },
                  // { list: "bullet" },
                  // { align: ["center", "", "right"] },
                ],
              ],
              clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
              },
            }}
            formats={[
              "bold",
              "italic",
              "underline",
              // "list",
              // "bullet",
              "align",
            ]}
            bounds={".app"}
            placeholder={"내용을 입력하세요"}
          />
        </div>
        <div
          style={{
            textAlign: "right",
          }}
          onClick={() => {
            setIsModal(true);
          }}
        >
          <div className="prod-button" style={{ marginTop: 16 }}>
            샘플보기
          </div>
        </div>
      </div>

      {isModal && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.4)",
            top: 0,
            left: 0,
            zIndex: 10000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: 380,
              margin: 16,
              borderRadius: 15,
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                top: -45,
                right: 0,
                zIndex: 1000,
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsModal(false);
                console.log("hepp");
              }}
            >
              <CloseIcon />
            </div>
            <div
              style={{
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 50,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              아웃트로 샘플
            </div>
            <div
              style={{
                padding: "0 16px 20px",
                height: 500,
                overflowY: "scroll",
              }}
            >
              {outroArray.map((item, index) => {
                return (
                  <SampleComponent
                    outroTitle={item.title}
                    outroContent={item.content}
                    setOutroContent={setOutroContent}
                    setOutroTitle={setOutroTitle}
                    key={index}
                    isModal={isModal}
                    onSubmit={onSubmit}
                    setIsModal={setIsModal}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ThanksComponent);
