import React, { useEffect, useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
export default function FormTextInputComponent({
  placeholder,
  getText,
  onlyNumber,
  type,
  maxLength,
  _value,
  isPassword,
  warning,
  getBlur,
}) {
  const [value, setValue] = useState(_value);
  const [password, setPassword] = useState(
    isPassword !== undefined ? isPassword : false
  );
  const pushData = (data) => {
    if (onlyNumber === true) {
      getText({
        text: data.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"),
        type: type,
      });
    } else {
      getText({ text: data, type: type });
    }
  };

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          border: "1px solid",
          borderColor: warning ? "red" : "#e5e4e4",
          borderRadius: 8,
          overflow: "hidden",
          padding: "0 8px",
          backgroundColor: "#fefcfc",
          position: "relative",
        }}
      >
        <input
          placeholder={placeholder}
          onChange={(e) => {
            // console.log(e.target.value);
            pushData(e.target.value);
            setValue(
              onlyNumber == true
                ? e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1")
                : e.target.value
            );
          }}
          value={value}
          style={{
            width: "100%",
            height: 40,
            border: "none",
            fontSize: "1rem",
            backgroundColor: "#fefcfc",
          }}
          class="prod-text-input"
          maxLength={maxLength}
          type={password ? "password" : "text"}
          autocomplete="off"
        />

        {isPassword && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 30,
              height: 30,
              cursor: "pointer",
              position: "absolute",
              right: 10,
              top: 5,
            }}
            onClick={() => {
              setPassword(!password);
            }}
          >
            {password ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </div>
        )}
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: 12,
          color: "red",
          paddingLeft: 5,
        }}
      >
        {warning}
      </div>
    </div>
  );
}
