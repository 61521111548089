import React from "react";
import ReactTooltip from "react-tooltip";
import AddIcon from "@material-ui/icons/QuestionAnswer";

export default function SubTitleComponent({ title, tooltip, index }) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    margin: "12px 0 8px",
                    color: "#767676",
                }}
            >
                {title}
            </div>
            {tooltip ? (
                <div style={{ display: "flex" }}>
                    <div
                        data-tip
                        data-for={`global${index ? index : ""}`}
                        style={{
                            fontSize: 11,
                            fontWeight: "bold",
                            backgroundColor: "#333",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 16,
                            height: 16,
                            borderRadius: 8,
                            marginTop: 5,
                            marginLeft: 5,
                            cursor: "pointer",
                        }}
                    >
                        ?
                    </div>
                    <ReactTooltip
                        id={`global${index ? index : ""}`}
                        aria-haspopup="true"
                        role="example"
                    >
                        <div>{tooltip}</div>
                    </ReactTooltip>
                </div>
            ) : (
                false
            )}
        </div>
    );
}
