import React from "react";
import styles from "./Footer.module.css";
import logoWhite from "../../../assets/images/logo_white.png";
import { Link } from "@material-ui/core";
export default function Footer({ userId }) {
    return (
        <div className={styles.footer}>
            <div style={{}}>CRYUCARD</div>

            <div className={styles.footer_element_container}>
                <a href="mailto:cryucard@kakao.com">제휴문의</a>
                <a
                    href="https://www.notion.so/cryu/ad53a459ad3c42bab061c3b83bec0376"
                    target="_blank"
                    rel="noreferrer"
                >
                    개인정보처리방침
                </a>
                <a
                    href="https://www.notion.so/cryu/89b4a7e95a6e40d6b7c046b6eefb3500"
                    target="_blank"
                    rel="noreferrer"
                >
                    이용약관
                </a>
                {userId ? <a href="/#/logout">로그아웃</a> : <></>}

                {/* <div>공지사항</div> */}
                {/* <div>고객센터</div> */}
            </div>
            <div style={{ fontSize: 12, paddingTop: 15 }}>
                © Copyright cryucompany 2023 - All Rights Reserved
            </div>
        </div>
    );
}
