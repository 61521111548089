import React, { useCallback, useState } from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import arrow from "../../assets/images/next.svg";
import { debounce } from "lodash";
import { dbService } from "../../fbase";
import ReactHtmlParser from "react-html-parser";
import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";
import S3 from "react-aws-s3";

const STORAGE_URL = "https://storage.cryucard.com";

export default function ThumbnailComponent({ state, getData, letterId }) {
  const [kakaoContent, setKakaoContent] = useState(
    state.kakaoContent !== undefined
      ? state.kakaoContent.replace("<br/>", "\n")
      : ""
  );
  const [kakaoTitle, setKakaoTitle] = useState(
    state.kakaoTitle !== undefined ? state.kakaoTitle : ""
  );

  const [kakaoThumb, setKakaoThumb] = useState(
    state.kakaoThumb ? state.kakaoThumb : ""
  );

  const [urlContent, setUrlContent] = useState(
    state.urlContent !== undefined ? state.urlContent : ""
  );
  const [urlTitle, setUrlTitle] = useState(
    state.urlTitle !== undefined ? state.urlTitle : ""
  );

  const [urlThumb, setUrlThumb] = useState(
    state.urlThumb ? state.urlThumb : ""
  );

  // s3설정
  const config = {
    bucketName: "new-cryucard",
    dirName: `image/${state.weddingDate}`,
    region: "ap-northeast-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);
  // s3설정

  const transDate = ({ weddingDate, weddingTime }) => {
    var weekday = new Array();
    weekday[0] = "일요일";
    weekday[1] = "월요일";
    weekday[2] = "화요일";
    weekday[3] = "수요일";
    weekday[4] = "목요일";
    weekday[5] = "금요일";
    weekday[6] = "토요일";

    var date = "";
    var year = "";
    var month = "";
    var day = "";
    var hours = "";
    var min = "";
    var week = "";

    if (weddingDate) {
      date = new Date(weddingDate + "T09:00:00Z");
      year = date.getFullYear();
      month = date.getMonth() + 1 + "월";
      day = date.getDate() + "일";
      week = date.getDay();
    }
    if (weddingTime) {
      hours =
        weddingTime.slice(0, 2) === "24" || weddingTime.slice(0, 2) === "12"
          ? "낮 12시"
          : weddingTime.slice(0, 2) > 12
          ? " 오후 " + (weddingTime.slice(0, 2) * 1 - 12) + "시"
          : " 오전 " + weddingTime.slice(0, 2) * 1 + "시";

      min =
        weddingTime.slice(3, 5) === "00" ? "" : weddingTime.slice(3, 5) + "분";
    }

    return `${month} ${day} ${weekday[week]} ${hours}${min}`;
  };

  const getText = (text, _parameter) => {
    if (_parameter === "kakaoTitle") {
      setKakaoTitle(text);
    } else if (_parameter === "urlTitle") {
      setUrlTitle(text);
    } else if (_parameter === "urlContent") {
      setUrlContent(text);
    }
    debounceGetText({
      _parameter: _parameter,
      text: text,
    });
  };

  const getBlurText = (text, _parameter) => {
    if (_parameter === "kakaoTitle") {
      onSubmit({ _kakaoTitle: text });
    } else if (_parameter === "urlTitle") {
      onSubmit({ _urlTitle: text });
    } else if (_parameter === "urlContent") {
      onSubmit({ _urlContent: text });
    } else if (_parameter === "kakaoContent") {
      onSubmit({ _kakaoContent: text });
    }
  };

  const debounceGetText = useCallback(
    debounce(({ _parameter, text }) => {
      if (_parameter === "kakaoTitle") {
        onSubmit({ _kakaoTitle: text });
      } else if (_parameter === "urlTitle") {
        onSubmit({ _urlTitle: text });
      } else if (_parameter === "urlContent") {
        onSubmit({ _urlContent: text });
      } else if (_parameter === "kakaoContent") {
        onSubmit({ _kakaoContent: text });
      }
    }, 1000),
    []
  );

  const onSubmit = async ({
    _kakaoTitle,
    _kakaoThumb,
    _urlThumb,
    _urlTitle,
    _urlContent,
    _kakaoContent,
  }) => {
    let content = {};

    if (_kakaoTitle !== undefined) {
      content = {
        kakaoTitle: _kakaoTitle,
      };
    } else if (_kakaoThumb !== undefined) {
      content = {
        kakaoThumb: `${STORAGE_URL}/image${_kakaoThumb}`,
      };
    } else if (_urlThumb !== undefined) {
      content = {
        urlThumb: `${STORAGE_URL}/image${_urlThumb}`,
      };
    } else if (_urlTitle !== undefined) {
      content = { urlTitle: _urlTitle };
    } else if (_urlContent !== undefined) {
      content = { urlContent: _urlContent };
    } else if (_kakaoContent !== undefined) {
      content = { kakaoContent: _kakaoContent };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  const pushData = () => {
    getData({ isSaved: true });
  };

  const getCurInputTextLineCnt = useCallback((text) => {
    const lines = text?.split(/\r|\r\n|\n/);
    const count = lines?.length;
    return count;
  }, []);

  const onChange = (d) => {
    if (getCurInputTextLineCnt(d.target.value) < 3) {
      setKakaoContent(d.target.value);
      debounceGetText({
        _parameter: "kakaoContent",
        text: d.target.value.replace("\n", "<br/>"),
      });
    }
  };

  const KakaoThumbnail = () => {
    return (
      <div
        style={{
          width: 216,
          borderRadius: 10,
          overflow: "hidden",
          boxShadow: "0px 5px 10px rgb(0 0 0 / 18%)",
        }}
      >
        <label for="kakaoThumb" style={{ cursor: "pointer" }}>
          <div
            style={{
              width: 216,
              height: 324,
              backgroundColor: "#e0e0e0",
            }}
          >
            <img
              src={kakaoThumb}
              style={{
                width: 216,
                height: 324,
                objectFit: "cover",
              }}
            />
          </div>
        </label>
        <div
          style={{
            backgroundColor: "#fff",
            height: "100%",
            padding: "10px 10px",
          }}
        >
          <div style={{ fontSize: 14, color: "#333" }}>
            {kakaoTitle
              ? kakaoTitle
              : `${state.hName ? state.hName : "신랑"} ❤️ ${
                  state.wName ? state.wName : "신부"
                } 결혼합니다.`}
          </div>

          {state.weddingDate && state.weddingTime && (
            <div
              style={{
                fontSize: 12,
                marginTop: 3,
                color: "#8f8d94",
              }}
            >
              {kakaoContent
                ? ReactHtmlParser(kakaoContent.replace(/\n/g, "<br/>"))
                : transDate({
                    weddingDate: state.weddingDate,
                    weddingTime: state.weddingTime,
                  })}
            </div>
          )}

          <div
            style={{
              backgroundColor: "#ebebeb",
              fontSize: 13,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              borderRadius: 5,
              color: "#333",
            }}
          >
            모바일 청첩장
          </div>

          <div
            style={{
              fontSize: 10,
              marginTop: 5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#8f8d94",
            }}
          >
            <span>크류카드</span>
            <img src={arrow} style={{ width: 10, height: 10 }} />
          </div>
        </div>
      </div>
    );
  };

  const UrlThumbnail = () => {
    return (
      <div>
        <div
          style={{
            boxShadow: "0px 5px 10px rgb(0 0 0 / 18%)",
            width: 250,
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <label for="urlThumb" style={{ cursor: "pointer" }}>
            <div
              style={{
                width: 250,
                height: 130,
                backgroundColor: "#e0e0e0",
                borderRadius: "10px 10px 0 0 ",
              }}
            >
              <img
                src={urlThumb}
                style={{
                  width: 250,
                  height: 130,
                  objectFit: "cover",
                }}
              />
            </div>
          </label>

          <div style={{ padding: "8px 10px" }}>
            <div style={{ fontSize: 14, color: "#333" }}>
              {urlTitle
                ? urlTitle
                : `${state.hName ? state.hName : "신랑"} ❤️ ${
                    state.wName ? state.wName : "신부"
                  } 결혼합니다.`}
            </div>
            <div style={{ color: "#8f8d94", fontSize: 13 }}>
              {urlContent
                ? ReactHtmlParser(urlContent.replace(/\n/g, "<br/>"))
                : transDate({
                    weddingDate: state.weddingDate,
                    weddingTime: state.weddingTime,
                  })}
            </div>
            <div style={{ color: "#8f8d94", fontSize: 12 }}>m.cryucard.com</div>
          </div>
        </div>
      </div>
    );
  };

  const uploadImg = async ({ _kakaoThumb, _urlThumb }) => {
    if (_kakaoThumb) {
      await ReactS3Client.uploadFile(_kakaoThumb, `${letterId}-${uuidv4()}`)
        .then((data) => {
          onSubmit({ _kakaoThumb: data.location.split("image")[1] });
        })
        .catch((err) => console.error(err));
    } else if (_urlThumb) {
      await ReactS3Client.uploadFile(_urlThumb, `${letterId}-${uuidv4()}`)
        .then((data) => {
          onSubmit({ _urlThumb: data.location.split("image")[1] });
        })
        .catch((err) => console.error(err));
    }
  };

  const onKakaoFileChange = (event, index) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const reader = new FileReader();

    reader.onloadend = async (finishedEvent) => {
      // console.log(finishedEvent.target.result);
      const {
        currentTarget: { result },
      } = finishedEvent;

      try {
        new Compressor(theFile, {
          quality: 0.8,
          maxWidth: 2000,
          maxHeight: 2000,
          success(res) {
            setKakaoThumb(result);
            uploadImg({ _kakaoThumb: res });
          },
          error(err) {
            console.log(err.message);
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    theFile && reader.readAsDataURL(theFile);
  };

  const onUrlFileChange = (event, index) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const reader = new FileReader();

    reader.onloadend = async (finishedEvent) => {
      // console.log(finishedEvent.target.result);
      const {
        currentTarget: { result },
      } = finishedEvent;

      try {
        new Compressor(theFile, {
          quality: 0.8,
          maxWidth: 2000,
          maxHeight: 2000,
          success(res) {
            setUrlThumb(result);
            uploadImg({ _urlThumb: res });
          },
          error(err) {
            console.log(err.message);
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    theFile && reader.readAsDataURL(theFile);
  };

  return (
    <div>
      <div>
        <SubTitleComponent
          title="카카오톡 썸네일"
          tooltip={
            <div>
              해당 썸네일은 미리보기 기능으로 실제 전송 썸네일과 차이가 있을 수
              있습니다
            </div>
          }
          index="1"
        />

        <div style={{ marginTop: 5 }}>
          <input
            id="kakaoThumb"
            name="kakaoThumb"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={onKakaoFileChange}
            style={{
              width: 1,
              height: 1,
              position: "absolute",
              display: "none",
            }}
          />
          {kakaoThumb ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <KakaoThumbnail />
            </div>
          ) : (
            <label for="kakaoThumb">
              <div
                style={{
                  height: 200,
                  border: "1px dashed #888",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#888",
                  cursor: "pointer",
                  fontSize: "0.9rem",
                }}
              >
                이 곳을 클릭해 썸네일을 업로드해주세요
              </div>
            </label>
          )}

          <div style={{ marginTop: 20 }}>
            <SubTitleComponent title="카카오톡 썸네일 제목" />
            <TextInputComponent
              getText={(e) => {
                getText(e.text, "kakaoTitle");
              }}
              _value={state.kakaoTitle ? state.kakaoTitle : ""}
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "kakaoTitle");
                }
              }}
            />
          </div>

          <div>
            <SubTitleComponent title="카카오톡 썸네일 내용" />
            <textarea
              value={kakaoContent}
              onChange={onChange}
              style={{
                height: 40,
                backgroundColor: "#fefcfc",
                width: "calc(100% - 16px)",
                border: "1px solid #e5e4e4",
                padding: "8px 8px",
                borderRadius: 10,
                resize: "none",
              }}
            ></textarea>
          </div>
        </div>
      </div>

      <div>
        <SubTitleComponent
          title="URL 썸네일"
          tooltip={
            <div>
              해당 썸네일은 미리보기 기능으로 실제 전송 썸네일과 차이가 있을 수
              있습니다
            </div>
          }
          index="2"
        />
        <input
          id="urlThumb"
          name="urlThumb"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={onUrlFileChange}
          style={{
            width: 1,
            height: 1,
            position: "absolute",
            display: "none",
          }}
        />
        {urlThumb ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UrlThumbnail />
          </div>
        ) : (
          <label for="urlThumb">
            <div
              style={{
                height: 200,
                border: "1px dashed #888",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#888",
                cursor: "pointer",
                fontSize: "0.9rem",
              }}
            >
              이 곳을 클릭해 썸네일을 업로드해주세요
            </div>
          </label>
        )}
        <div style={{ marginTop: 20 }}>
          <SubTitleComponent title="URL 썸네일 제목" />
          <TextInputComponent
            getText={(e) => {
              getText(e.text, "urlTitle");
            }}
            _value={state.urlTitle ? state.urlTitle : ""}
            getBlur={({ isBlur, text }) => {
              if (isBlur) {
                debounceGetText.cancel();
                getBlurText(text, "urlTitle");
              }
            }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <SubTitleComponent title="URL 썸네일 내용" />
          <TextInputComponent
            getText={(e) => {
              getText(e.text, "urlContent");
            }}
            _value={state.urlContent ? state.urlContent : ""}
            getBlur={({ isBlur, text }) => {
              if (isBlur) {
                debounceGetText.cancel();
                getBlurText(text, "urlContent");
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
