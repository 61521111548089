import React, { useState } from "react";
import NextButton from "../../../components/NextButton/NextButton";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import StepperComponent from "../../../components/StepperComponent/StepperComponent";
import Header from "../../../components/Header/Header";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: 300,
        },
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

const WeddingLocationScreen = () => {
    const [weddingAddress, setWeddingAddress] = useState("");
    const [longitude, setLongitude] = useState("");
    const [latitude, setLatitude] = useState("");

    const classes = useStyles();

    var map = null;
    var eventMarker = null;

    const serchMap = (keyword) => {
        console.log(map);
        // 장소 검색 객체를 생성합니다
        var ps = window.kakao.maps.services.Places();

        // 키워드로 장소를 검색합니다
        ps.keywordSearch(keyword, placesSearchCB);
    };

    const onChange = (event) => {
        // console.log(event.target.name);
        const {
            target: { name, value },
        } = event;

        if (name === "weddingAddress") {
            setWeddingAddress(value);
            // console.log(value);
        }
    };

    const placesSearchCB = (data, status, pagination) => {
        if (status === window.kakao.maps.services.Status.OK) {
            var mapContainer = document.getElementById("kakaoMap"), // 지도를 표시할 div
                mapOption = {
                    center: window.kakao.maps.LatLng(data[0].y, data[0].x), // 지도의 중심좌표
                    level: 3, // 지도의 확대 레벨
                };

            // 지도를 생성합니다
            map = window.kakao.maps.Map(mapContainer, mapOption);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
            // LatLngBounds 객체에 좌표를 추가합니다
            var bounds = window.kakao.maps.LatLngBounds();

            // 지도 중심 좌표 변화 이벤트를 등록한다
            window.kakao.maps.event.addListener(
                map,
                "center_changed",
                function () {
                    // console.log(map.getCenter());
                    if (eventMarker !== null) {
                        eventMarker.setMap(null);
                    }

                    // 마커를 생성합니다
                    eventMarker = window.kakao.maps.Marker({
                        position: window.kakao.maps.LatLng(
                            map.getCenter().Ma,
                            map.getCenter().La
                        ),
                    });

                    setLatitude(map.getCenter().Ma);
                    setLongitude(map.getCenter().La);
                    // 마커가 지도 위에 표시되도록 설정합니다
                    eventMarker.setMap(map);
                }
            );
            // console.log(data);

            bounds.extend(window.kakao.maps.LatLng(data[0].y, data[0].x));
            setLatitude(data[0].y);
            setLongitude(data[0].x);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
            map.setBounds(bounds);
        }
    };

    return (
        <div
            style={{
                backgroundColor: "rgb(238 238 238)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    backgroundColor: "#fff",
                    maxWidth: 480,
                    padding: "0 14px",
                }}
            >
                {/* <div
        style={{
          width: "100%",
          height: 50,
          borderBottom: "1px solid #333",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ fontSize: 16, fontWeight: "500" }}>크류카드</span>
      </div> */}

                <Header />

                <StepperComponent step={4} />

                <div style={{ padding: "20px 20px 20px" }}>
                    <p
                        style={{
                            padding: 0,
                            fontSize: 20,
                            fontWeight: "bold",
                            lineHeight: "40px",
                        }}
                    >
                        예식장 위치와
                        <br />
                        예식일자를 알려주세요
                    </p>
                </div>

                <div style={{}}>
                    <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="datetime-local"
                            label="예식일자"
                            type="datetime-local"
                            defaultValue="2021-05-24T12:00"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {/* <div className="custom-input">
            <label>결혼식장 주소</label>
            <input
              name="weddingAddress"
              value={weddingAddress}
              onBlur={() => {
                serchMap(weddingAddress);
                console.log("blur");
              }}
              onChange={onChange}
              type="text"
            />
          </div> */}

                        <TextField
                            id="outlined-basic"
                            label="결혼식장 주소"
                            name="weddingAddress"
                            variant="outlined"
                            value={weddingAddress}
                            onBlur={() => {
                                serchMap(weddingAddress);
                                console.log("blur");
                            }}
                            onChange={onChange}
                        />

                        {/* 지도 */}

                        <div
                            id={`kakaoMap`}
                            style={{
                                width: "100%",
                                height: "300px",
                                maxWidth: 300,
                            }}
                        ></div>

                        {/* 지도 */}

                        <TextField
                            id="outlined-basic"
                            label="예식장 주소"
                            variant="outlined"
                        />
                        <TextField
                            id="outlined-basic"
                            label="예식장 명"
                            variant="outlined"
                        />
                        <TextField
                            id="outlined-basic"
                            label="예식장 층과 홀"
                            variant="outlined"
                        />
                        <TextField
                            id="outlined-basic"
                            label="예식장 연락처 (ex.02-000-0000)"
                            variant="outlined"
                        />
                    </form>

                    <NextButton path="/order/complete" />
                </div>
            </div>
        </div>
    );
};

export default WeddingLocationScreen;
