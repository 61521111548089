import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/images/new-logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

export default function HeaderVer2({ userId, letterId }) {
    const headerRef = useRef();
    const [isOpened, setIsOpened] = useState(false);

    return (
        <div
            className="new-header"
            ref={headerRef}
            style={{ height: 50, overflow: "hidden" }}
        >
            <div className="new-header-wrap">
                <Link to="/" style={{ display: "flex", alignItems: "center" }}>
                    <img src={logo} style={{ width: 36, height: 36 }} />
                </Link>
                <div className="new-header-desktop">
                    {/* <Link className="new-header-element" to="/price">
                        가격안내
                    </Link> */}
                    <Link className="new-header-element" to="/question">
                        자주 묻는 질문
                    </Link>
                    {/* <Link className="new-header-element" to="/event/1">
                        이벤트
                    </Link> */}
                    <Link
                        className="new-header-element"
                        to={userId ? "/mypage" : "/newlogin"}
                    >
                        제작내역
                    </Link>
                    <Link
                        to={userId ? "/first" : "/newlogin"}
                        className="new-header-making-button new-header-element"
                    >
                        제작하기
                    </Link>
                </div>

                <div className="new-header-mobile">
                    <Link
                        to={userId ? "/first" : "/newlogin"}
                        className="new-header-making-button"
                    >
                        제작하기
                    </Link>

                    <span
                        style={{
                            width: 40,
                            height: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            marginLeft: 5,
                        }}
                        onClick={() => {
                            if (headerRef.current.style.height !== "150px") {
                                headerRef.current.style.height = "150px";
                                headerRef.current.style.background = "#fff";
                                headerRef.current.style.color = "#333";
                                setIsOpened(true);
                            } else {
                                headerRef.current.style.height = "50px";
                                headerRef.current.style.background = "";
                                headerRef.current.style.color = "";
                                setIsOpened(false);
                            }
                        }}
                    >
                        {isOpened ? (
                            <CloseIcon fontSize="medium" />
                        ) : (
                            <MenuIcon fontSize="medium" />
                        )}
                    </span>
                </div>
            </div>

            <div className="new-header-mobile-menu">
                <Link
                    to={userId ? "/mypage" : "/newlogin"}
                    className="new-header-mobile-menu-link"
                >
                    제작내역
                </Link>
                {/* <Link to="/price" className="new-header-mobile-menu-link">
                    가격안내
                </Link> */}
                <Link to="/question" className="new-header-mobile-menu-link">
                    자주 묻는 질문
                </Link>
                {/* <Link to="/event/1" className="new-header-mobile-menu-link">
                    이벤트
                </Link> */}
            </div>
        </div>
    );
}
