import React, { useState, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";
import { dbService } from "../../fbase";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";
import CheckIcon from "@material-ui/icons/Check";
import Compressor from "compressorjs";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";

import { MdUpload } from "react-icons/md";
import GreetingImg from "../../assets/images/greeting-example.png";

const STORAGE_URL = "https://storage.cryucard.com";

const greetingArray = [
  {
    content: `각자 서로 다른 길을<br />걸어온 저희가 이제 부부의 연으로<br />한 길을 걸어가고자 합니다.<br /><br />항상 처음을 생각하며,<br />서로 아껴주고 사랑하며 살겠습니다.<br /><br />부디 참석해 주시어 저희의 약속을<br />따뜻한 격려로 축복해 주시기 바랍니다.`,
  },
  {
    content: `코로나19 바이러스로 인해<br />걱정이 많았지만 종식을 예측할 수 없어<br />결혼식을 일정대로 진행하기로 했습니다.<br /><br />저희를 축하해주시는 마음은 모두 같으니<br />참석에 대한 부담감을 갖지 않으시길 바라며<br />축하해주신 모든 분들께 감사드립니다.`,
  },
  {
    content: `서로에게 행복을 주는 사람을 만났습니다.<br />웃는 모습이 너무나 예쁜 그 사람을 만났습니다.<br />배려하는 마음이 따뜻한 그 사람을 만났습니다. <br /><br />운명처럼 만나게 된 우리의 인연<br />그 인연에 이끌려 이제 영원을<br />함께 약속하려 합니다. <br /><br />저희의 하나 됨을 지켜보아 주시고 <br />격려해 주시면 더없는 기쁨으로<br />간직하겠습니다.`,
  },
  {
    content: `평생을 같이하고 싶은 사람을 만났습니다.<br /><br />첫 마음 그대로 존중하고 배려하며<br />예쁘게 사랑하겠습니다.<br /><br />저희의 약속 위에 따뜻한 격려로 축복해 주시어<br />힘찬 출발의 디딤이 되어주시면<br />더없는 기쁨으로 간직하겠습니다.`,
  },
  {
    content: `저희 두 사람의 작은 만남이<br />사랑의 결실을 이루어<br />소중한 결혼식을 올리게 되었습니다.<br /><br />평생 서로 귀하게 여기며<br />첫 마음 그대로 존중하고 배려하며 살겠습니다.<br /><br />오로지 믿음과 사랑을 약속하는 날<br />오셔서 축복해 주시면 더없는 기쁨으로<br />간직하겠습니다.`,
  },
  {
    content: `결혼은 또 하나의 <br />새로운 인생의 시작이라고 합니다.<br /><br />오늘이 있기까지 많은 사랑과 <br />관심을 기울여 주신 <br />여러 어르신과 친지분들을 모시고 <br />저희 두 사람이 백년해로의 <br />진실한 가약을 맺고자 합니다. <br /><br />부디 참석하시어 저희가 내딛는 <br />새 인생의 첫걸음을 <br />격려와 축복으로 빛내 주시기 바랍니다.`,
  },
  {
    content: `어제의 너와 내가 <br />오늘 우리가 되어 <br />저희 두 사람 이제 <br />한길을 같이 걷고자 합니다. <br /><br />저희 첫 디딤에 부디 오시어 따뜻한 <br />사랑으로 축복해 주십시오. <br /><br />보다 힘찬 내디딤이 될 것입니다.`,
  },
  {
    content: `새로이 시작하는 작은사랑이 <br />보다 깊고 향 짙게 꽃피려 합니다. <br /><br />저희의 뜻깊은 백 년의 약속의 날 <br />함께 하셔서 축복해 주십시오. <br /><br />사랑으로 가득 채워 <br />즐거움은 나누고 어려움은 이겨내는 <br />함께 나아가는 삶을 꾸리겠습니다.`,
  },
  {
    content: `너무 곱게 키우면 여릴까 봐 <br />너무 험하게 키우면 거칠까 봐 <br />노심초사 하루도 편할 날 없이<br />정성 들였습니다. <br /><br />하지만 막상 혼인에 예를 갖춰<br />어른들 세상에 내보내려니 <br />새삼 두려움이 앞섭니다. <br /><br />살펴주시고 가르쳐 주시고 <br />가야 할 먼 길 훤히 밝혀 주시기 바랍니다.`,
  },
  {
    content: `저희 아들과 딸이 한 가정을 이루고자 <br />혼인의 예를 올리게 되었습니다.<br /><br />두 사람이 행복한 가정을<br />이룰 수 있도록 따뜻한 마음으로<br />축복해주시면 감사하겠습니다.`,
  },
];

const imgArray = [
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/illustration-2.png",
  },

  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-14.png",
  },
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-15.png",
  },
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-16.png",
  },
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-17.png",
  },
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-18.png",
  },
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-13.png",
  },
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-19.png",
  },
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-6.png",
  },

  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-7.png",
  },
  {
    url: "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/hidi-8.png",
  },
];

const SampleComponent = ({
  greetingSample,
  setIsGreetingSampleModal,
  setGreeting,
}) => {
  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "20px 16px",
        borderRadius: 5,
        cursor: "pointer",
        fontSize: 14,
        color: "#333",
        marginTop: 20,
      }}
      onClick={() => {
        setIsGreetingSampleModal(false);
        setGreeting(greetingSample);
      }}
    >
      {ReactHtmlParser(greetingSample)}
    </div>
  );
};

const IllustrationComponent = ({
  onFileChange,
  greetingImgIndex,
  greetingImg,
  setGreetingImgIndex,
  onSubmit,
  setIsIllustrationModal,
  isIllustrationModal,
}) => {
  return (
    <div>
      <div
        style={{
          fontSize: "1rem",
          color: "#767676",
          fontWeight: "500",
        }}
      >
        인삿말 아이콘
      </div>
      <div
        style={{
          display: "flex",
          marginTop: 10,
          overflow: "auto",
        }}
      >
        <input
          id="greetingImg"
          name="greetingImg"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => {
            console.log("hello");
            onFileChange(e);
          }}
          style={{
            width: 1,
            height: 1,
            position: "absolute",
            display: "none",
          }}
        />
        <div
          style={{
            width: 100,
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #e9e9e9",
            borderRadius: 8,
            cursor: "pointer",
            margin: "10px 5px",
            position: "relative",
            objectFit: "cover",
          }}
          onClick={() => {
            setIsIllustrationModal(true);
          }}
        >
          {greetingImgIndex === 99 && (
            <img
              src={greetingImg}
              style={{
                width: 100,
                height: "auto",
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                borderRadius: 8,
              }}
            />
          )}

          <span
            style={{
              width: 100,
              height: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 100,
              backgroundColor:
                greetingImgIndex === 99 ? "rgba(0,0,0,0.3)" : "#e9ecec",
              borderRadius: 8,
              color: greetingImgIndex === 99 ? "#fff" : "#000",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <MdUpload
              style={{
                width: 25,
                height: 25,
                color: greetingImgIndex === 99 ? "#fff" : "#666",
              }}
            />

            <span
              style={{
                fontSize: 10,
                color: greetingImgIndex === 99 ? "#fff" : "#666",
              }}
            >
              직접 추가하기
            </span>
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #e9e9e9",
            borderRadius: 8,
            cursor: "pointer",
            margin: "10px 5px",
            position: "relative",
          }}
          onClick={() => {
            setGreetingImgIndex(0);
            onSubmit({ _greetingImgIndex: 0 });

            onSubmit({ _greetingImg: "" });
          }}
        >
          {greetingImgIndex === 0 && (
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.3)",
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
              }}
            >
              <CheckIcon fontSize="small" />
            </div>
          )}

          <span
            style={{
              fontSize: 10,
              width: 100,
              height: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 100,
              // backgroundColor:
              //     greetingImgIndex === 99
              //         ? "rgba(0,0,0,0.3)"
              //         : "#e9ecec",
              borderRadius: 8,
              // color: greetingImgIndex === 99 ? "#fff" : "#000",
            }}
          >
            꽃 일러스트
          </span>
        </div>

        {imgArray.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // border: "1px solid #e9e9e9",
                borderRadius: 8,
                cursor: "pointer",
                margin: "10px 5px",
                position: "relative",
              }}
              key={index + 1}
              onClick={() => {
                setGreetingImgIndex(index + 1);
                onSubmit({ _greetingImgIndex: index + 1 });
                if (index + 1 !== 0) {
                  onSubmit({ _greetingImg: item.url });
                } else {
                  onSubmit({ _greetingImg: "" });
                }
              }}
            >
              {greetingImgIndex === index + 1 && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.3)",
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                  }}
                >
                  <CheckIcon fontSize="small" />
                </div>
              )}

              <img
                src={item.url}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 8,
                  objectFit: "cover",
                }}
              />
            </div>
          );
        })}
      </div>

      {isIllustrationModal === true && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.4)",
            top: 0,
            left: 0,
            zIndex: 10000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: 380,
              margin: 16,
              borderRadius: 15,
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                top: -45,
                right: 0,
                zIndex: 1000,
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsIllustrationModal(false);
                console.log("hepp");
              }}
            >
              <CloseIcon />
            </div>
            <div
              style={{
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 50,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              직접 추가하기
            </div>
            <div
              style={{
                padding: "0 16px 20px",
                height: 500,
                overflowY: "scroll",
              }}
            >
              <div>
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "30px 0 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: 20,
                        height: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontSize: 12,
                        backgroundColor: "#724ff3",
                        borderRadius: 15,
                        fontWeight: "bold",
                        margin: "0 auto",
                        lineHeight: "12px",
                      }}
                    >
                      1
                    </div>

                    <div
                      style={{
                        fontSize: 12,
                        paddingTop: 5,
                      }}
                    >
                      일러스트나 이미지 파일을
                      <br />
                      <b style={{ color: "#724ff3" }}>인삿말 아이콘</b>
                      으로 추가할 수 있습니다.
                      <br />
                      <span style={{ fontSize: 10 }}>
                        (업로드시 배경이 없는{" "}
                        <b style={{ color: "#724ff3" }}>PNG파일</b>로 추가해
                        주세요)
                      </span>
                    </div>
                  </div>
                  <img
                    src={GreetingImg}
                    style={{
                      width: "90%",
                      height: "auto",
                    }}
                  />

                  <label for="greetingImg" style={{}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        backgroundColor: "#724ff3",
                        width: 260,
                        height: 45,
                        cursor: "pointer",
                        margin: "0 auto",
                        borderRadius: 10,
                        marginTop: 0,
                        fontSize: 14,
                      }}
                    >
                      이미지 업로드
                    </div>
                  </label>

                  <div
                    style={{
                      textAlign: "center",
                      padding: "50px 0 20px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        padding: "5px 8px",

                        color: "#fff",
                        fontSize: 12,
                        backgroundColor: "#724ff3",
                        borderRadius: 15,
                        fontWeight: "bold",
                        margin: "0 auto",
                        lineHeight: "12px",
                      }}
                    >
                      일러스트 제작은?
                    </div>

                    <div
                      style={{
                        fontSize: 12,
                        paddingTop: 5,
                      }}
                    >
                      우리 둘만의 웨딩사진을
                      <br />
                      일러스트로 남기시고 싶다면
                      <br />
                      <b style={{ color: "#724ff3" }}>하이디 일러스트</b>
                      로 연락주시면
                      <br />
                      일러스트 제작 가능합니다.
                    </div>

                    <a
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#724ff3",
                        backgroundColor: "#fff",
                        border: "1px solid #724ff3",
                        width: 260,
                        height: 45,
                        cursor: "pointer",
                        margin: "0 auto",
                        borderRadius: 10,
                        marginTop: 20,
                        fontSize: 14,
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                      href="https://pf.kakao.com/_fxdBxoC"
                      target="_blank"
                      rel="noreferrer"
                    >
                      하이디 일러스트 문의하기
                    </a>
                  </div>

                  <p style={{ fontSize: 12 }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default function GreetingComponent({ getData, state, letterId }) {
  const [greeting, setGreeting] = useState(
    state.greeting ? state.greeting : ""
  );
  const [isGreetingSampleModal, setIsGreetingSampleModal] = useState(false);
  const [isIllustrationModal, setIsIllustrationModal] = useState(false);

  const [greetingImgIndex, setGreetingImgIndex] = useState(
    state.greetingImgIndex !== undefined ? state.greetingImgIndex : 0
  );

  const [greetingImg, setGreetingImg] = useState(
    state.greetingImg !== undefined ? state.greetingImg : ""
  );

  // s3설정
  const config = {
    bucketName: "new-cryucard",
    dirName: `image/${state.weddingDate}`,
    region: "ap-northeast-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);
  // s3설정

  const handleChange = (text) => {
    setGreeting(text);
    debounceGetText({ text });
  };
  const pushData = () => {
    getData({ isSaved: true });
  };

  const onSubmit = async ({
    text,
    _greetingImg,
    _greetingImgIndex,
    _uploadImg,
  }) => {
    let content = {};

    if (text !== undefined) {
      content = {
        greeting: text,
      };
    } else if (_greetingImg !== undefined) {
      content = {
        greetingImg: _greetingImg,
      };
      console.log(_greetingImg);
    } else if (_greetingImgIndex !== undefined) {
      content = {
        greetingImgIndex: _greetingImgIndex,
      };
      console.log(_greetingImgIndex);
    } else if (_uploadImg !== undefined) {
      content = {
        greetingImg:
          _uploadImg === "" ? _uploadImg : `${STORAGE_URL}/image${_uploadImg}`,
      };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  const uploadMainImg = async ({ _uploadMain }) => {
    let submitMainImg = "";
    console.log(_uploadMain);
    if (_uploadMain) {
      await ReactS3Client.uploadFile(_uploadMain, `${letterId}-${uuidv4()}`)
        .then((data) => {
          submitMainImg = data.location.split("image")[1];
          onSubmit({ _uploadImg: submitMainImg });
          onSubmit({ _greetingImgIndex: 99 });
          setIsIllustrationModal(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const debounceGetText = useCallback(
    debounce(({ text }) => {
      onSubmit({ text: text });
    }, 1000),
    []
  );

  const onFileChange = (event, index) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const reader = new FileReader();

    reader.onloadend = async (finishedEvent) => {
      // console.log(finishedEvent.target.result);
      const {
        currentTarget: { result },
      } = finishedEvent;

      try {
        new Compressor(theFile, {
          quality: 0.8,
          maxWidth: 500,
          maxHeight: 500,
          success(res) {
            setGreetingImg(result);
            setGreetingImgIndex(99);

            uploadMainImg({ _uploadMain: res });
          },
          error(err) {
            console.log(err.message);
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    theFile && reader.readAsDataURL(theFile);
  };

  return (
    <div>
      <IllustrationComponent
        onFileChange={onFileChange}
        greetingImgIndex={greetingImgIndex}
        greetingImg={greetingImg}
        setGreetingImgIndex={setGreetingImgIndex}
        onSubmit={onSubmit}
        setIsIllustrationModal={setIsIllustrationModal}
        isIllustrationModal={isIllustrationModal}
      />

      <div style={{ marginTop: 20 }}>
        <ReactQuill
          theme={"snow"}
          onChange={handleChange}
          value={greeting}
          modules={{
            toolbar: [
              [
                "bold",
                "italic",
                "underline",
                // { list: "ordered" },
                // { list: "bullet" },
                // { align: ["center", "", "right"] },
              ],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
          formats={[
            "bold",
            "italic",
            "underline",
            // "list",
            // "bullet",
            "align",
          ]}
          bounds={".app"}
          placeholder={"인삿말을 입력하세요"}
        />
      </div>

      <div
        style={{
          textAlign: "right",
        }}
        onClick={() => {
          setIsGreetingSampleModal(true);
        }}
      >
        <div className="prod-button" style={{ marginTop: 16 }}>
          샘플보기
        </div>
      </div>

      {isGreetingSampleModal && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.4)",
            top: 0,
            left: 0,
            zIndex: 10000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: 380,
              margin: 16,
              borderRadius: 15,
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                top: -45,
                right: 0,
                zIndex: 1000,
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsGreetingSampleModal(false);
                console.log("hepp");
              }}
            >
              <CloseIcon />
            </div>
            <div
              style={{
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 50,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              인삿말 샘플
            </div>
            <div
              style={{
                padding: "0 16px 20px",
                height: 500,
                overflowY: "scroll",
              }}
            >
              {greetingArray.map((item, index) => {
                return (
                  <SampleComponent
                    greetingSample={item.content}
                    key={index}
                    setIsGreetingSampleModal={setIsGreetingSampleModal}
                    setGreeting={setGreeting}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
