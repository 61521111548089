import React, { useEffect, useState } from "react";
import Footer from "../../components/NewComponents/Footer/Footer";

import Header from "../../components/NewComponents/Header/Header";
import localStorage from "mobx-localstorage";

export default function ExampleScreen() {
    const [userId, setUserId] = useState("");

    const getUserId = async () => {
        let _getUserId = "";

        if (localStorage.getItem("userId")) {
            _getUserId = localStorage.getItem("userId");
            setUserId(_getUserId);

            // console.log("userId=>" + _getUserId);

            // await getList(getUserId);
            // await getThanksList(getUserId);
        } else {
            console.log("noEmail");
            // window.location.href = "/#/login";
        }
    };

    useEffect(() => {
        getUserId();
    }, []);

    return (
        <div>
            <Header userId={userId} />
            <div
                style={{
                    maxWidth: 1320,
                    margin: "0 auto",
                    marginTop: 100,
                    marginBottom: 100,
                }}
            >
                <div>제작사례</div>
            </div>
        </div>
    );
}
