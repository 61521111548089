import React, { useEffect, useState } from "react";

export default function MockupComponent({ _isSaved, bottomMockup, letterId }) {
    return (
        <div
            className={`${
                bottomMockup ? "bt-prod-mockup-wrap" : "prod-mockup-wrap"
            }`}
        >
            <div
                className={`prod-mockup ${_isSaved ? "blinking" : ""}`}
                style={{}}
            >
                <div
                    className="save-button"
                    style={{
                        width: 140,
                        height: 60,
                        backgroundColor: "#182e0c",
                        borderRadius: 30,
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "bold",
                        position: "absolute",
                        right: 20,
                        top: 40,
                        opacity: 0,
                        display: "none",
                    }}
                >
                    저장완료
                </div>

                <div
                    style={{
                        overflow: "scroll",
                    }}
                >
                    <iframe
                        frameBorder={0}
                        width="100%"
                        height="800"
                        src={`https://mcard.cryucard.com/snapshot/${letterId}`}
                        // src={`http://localhost:3001/snapshot/${letterId}`}
                    ></iframe>
                </div>
            </div>
        </div>
    );
}
