import React, { useRef, useState, useEffect } from "react";
import { NaverMap, RenderAfterNavermapsLoaded, Marker } from "react-naver-maps";
import { debounce } from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const debounceSomethingFunc = debounce((center, setLatitude, setLongitude) => {
  setLatitude(center._lat);
  setLongitude(center._lng);
  console.log(center);
}, 100);

export default function NaverMapComponent({
  lat,
  lng,
  getMapGeoData,
  getIsMapData,
  getIsMapFixed,
  _isMap,
  _isFixed,
}) {
  const [geo, setGeo] = useState({ lat: lat, lng: lng });
  const [isMap, setIsMap] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const [zoom, setZoom] = useState(16);

  const onChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;

    if (name === "isMap") {
      setIsMap(checked);
      pushIsMapData(checked);
    } else if (name === "isFixed") {
      setIsFixed(checked);
      pushIsMapFixed(checked);
    }
  };

  const debouncePushGeoData = debounce((data) => {
    getMapGeoData(data);
    setGeo(data);
  }, 100);

  const pushIsMapData = (data) => {
    getIsMapData({ isMap: data });
  };

  const pushIsMapFixed = (data) => {
    getIsMapFixed({ isMapFixed: data });
  };

  useEffect(() => {
    setGeo({ lat: lat, lng: lng });
  }, [lat]);

  useEffect(() => {
    setGeo({ lat: lat, lng: lng });
  }, [lng]);

  useEffect(() => {
    setIsMap(_isMap);
  }, [_isMap]);

  useEffect(() => {
    setIsFixed(_isFixed);
  }, [_isFixed]);

  return (
    <div>
      {geo.lat !== 0 && geo.lng !== 0 ? (
        <div style={{ marginTop: 20 }}>
          <RenderAfterNavermapsLoaded clientId={"cv0ho1ymtm"}>
            <NaverMap
              id={"map"}
              mapDivId={"react-naver-map"} // default name
              style={{
                width: "100%",
                maxWidth: 330,
                height: 330,
              }}
              // defaultCenter={{ lat: latitude, lng: longitude }}
              center={geo}
              onCenterChanged={(center) => {
                // debouncePushGeoData({
                //     lat: center._lat,
                //     lng: center._lng,
                // });
              }}
              onZoomChanged={(res) => {
                console.log(res);
              }}
              defaultZoom={16}
              onClick={(res) => {
                console.log(res.latlng);
                debouncePushGeoData({
                  lat: res.latlng._lat,
                  lng: res.latlng._lng,
                });
              }}
            >
              <Marker position={geo} />
            </NaverMap>
          </RenderAfterNavermapsLoaded>
          <div
            style={{
              backgroundColor: "#eeeeee",
              width: "100%",
              maxWidth: 330,
              textAlign: "center",
              fontSize: 11,
              paddingTop: 5,
              paddingBottom: 5,
              color: "#333",
            }}
          >
            지도를 클릭하여 원하는 위치로 핀을 이동시킬 수 있습니다.
          </div>

          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  name="isMap"
                  checked={isMap}
                  onChange={onChange}
                  style={{
                    paddingLeft: 0,
                  }}
                />
              }
              label="청첩장 내 지도를 표시합니다."
              id="custom_check"
            />

            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  name="isFixed"
                  checked={isFixed}
                  onChange={onChange}
                  style={{
                    paddingLeft: 0,
                  }}
                />
              }
              label="지도가 움직이지 않도록 고정합니다."
              id="custom_check"
            />
          </div>
        </div>
      ) : (
        false
      )}
    </div>
  );
}
