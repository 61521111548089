import React, { useState, useEffect } from "react";
import HidiHeader from "../../components/HidiHeader/HidiHeader";
import CardComponent from "../../components/CardComponent/CardComponent";
import ThanksCardComponent from "../../components/ThanksCardComponent/ThanksCardComponent";
import localStorage from "mobx-localstorage";
import { dbService } from "../../fbase";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import NewCardComponent from "../../components/NewCardComponent/NewCardComponent";

const HidiListScreen = () => {
    const [isLoading, setIsLoading] = useState("");
    const [userId, setUserId] = useState("");
    const [userLetterList, setUserLetterList] = useState([]);
    const [userThanksLetterList, setUserThanksLetterList] = useState([]);

    const getEmail = async () => {
        let getUserId = "";

        if (localStorage.getItem("userId")) {
            getUserId = await localStorage.getItem("userId");
            setUserId(getUserId);

            await getList(getUserId);
            await getThanksList(getUserId);
        } else {
            console.log("noEmail");
            window.location.href = "/#/login";
        }

        // await console.log(getUserId);
    };

    const dday = (day) => {
        var Dday = new Date(day);
        var now = new Date();
        // console.log(Dday);
        // console.log(day);

        var gap = now.getTime() - Dday.getTime();
        var result = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1 - 1;
        return result;
    };

    const getList = (userId) => {
        var letterRef = dbService.collection("letters");

        letterRef
            .where("userId", "==", userId)
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    // console.log(res.docs[0].data());

                    await Promise.all(
                        res.docs.map(async (item) => {
                            var addArr = item.data();
                            addArr.id = item.id;

                            if (addArr.expire) {
                                setUserLetterList((oldArray) => [
                                    ...oldArray,
                                    addArr,
                                ]);
                            } else if (dday(addArr.weddingDate) >= -30) {
                                setUserLetterList((oldArray) => [
                                    ...oldArray,
                                    addArr,
                                ]);
                            }
                        })
                    );
                } else {
                }
            });
    };

    const getThanksList = (userId) => {
        var letterRef = dbService.collection("thanksLetter");

        letterRef
            .where("userId", "==", userId)
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    // console.log(res.docs[0].data());

                    await Promise.all(
                        res.docs.map(async (item) => {
                            var addArr = item.data();
                            addArr.id = item.id;
                            await setUserThanksLetterList((oldArray) => [
                                ...oldArray,
                                addArr,
                            ]);
                        })
                    );

                    await setIsLoading(true);
                } else {
                    await setIsLoading(true);
                }
            });
    };

    useEffect(() => {
        getEmail();
    }, []);

    return (
        <div>
            {isLoading ? (
                <div
                    style={{
                        backgroundColor: "rgb(238 238 238)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#fff",
                            maxWidth: 480,
                            width: "100%",
                        }}
                    >
                        <HidiHeader userId={userId} />

                        <div style={{ padding: "20px 10px 100px" }}>
                            <div
                                style={{
                                    fontSize: 20,
                                    fontWeight: "500",
                                    paddingBottom: 20,
                                    paddingLeft: 15,
                                }}
                            >
                                제작내역
                            </div>
                            <Tabs>
                                <div style={{ padding: "0px 14px" }}>
                                    <TabList>
                                        <Tab>
                                            <span
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: "500",
                                                    color: "#333",
                                                }}
                                            >
                                                모바일청첩장
                                            </span>
                                        </Tab>
                                    </TabList>
                                </div>

                                <TabPanel>
                                    <div>
                                        <div style={{ padding: "0 15px" }}>
                                            {/* <NewCardComponent /> */}
                                            {userLetterList.length ? (
                                                userLetterList.map((item) => {
                                                    return (
                                                        <NewCardComponent
                                                            user={item}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        paddingBottom: 25,
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: 100,
                                                        }}
                                                    >
                                                        💌
                                                    </p>
                                                    <span
                                                        style={{
                                                            fontSize: 14,
                                                            color: "#999",
                                                        }}
                                                    >
                                                        제작된 청첩장이 없습니다
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div>
                                        <div style={{ padding: "0 15px" }}>
                                            {userThanksLetterList.length ? (
                                                userThanksLetterList.map(
                                                    (item) => {
                                                        return (
                                                            <ThanksCardComponent
                                                                user={item}
                                                            />
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        paddingBottom: 25,
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: 100,
                                                        }}
                                                    >
                                                        💌
                                                    </p>
                                                    <span
                                                        style={{
                                                            fontSize: 14,
                                                            color: "#999",
                                                        }}
                                                    >
                                                        제작된 감사장이 없습니다
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>

                        <FooterComponent />
                    </div>
                </div>
            ) : (
                false
            )}
        </div>
    );
};

export default HidiListScreen;
