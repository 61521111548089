import React from "react";
import { useState } from "react";
import SubTitleComponent from "../../components/SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../../components/TextInputComponent/TextInputComponent";
import { dbService } from "../../fbase";

export default function NewCouponScreen() {
    const [pageNum, setPageNum] = useState(0);
    const [isSending, setIsSending] = useState(false);
    const [invText, setInvText] = useState("");
    const [newText, setNewText] = useState("");
    const [thxText, setThxText] = useState("");
    const [couponText, setCouponText] = useState("");
    const [expire, setExpire] = useState(false);

    const saveInv = () => {
        var buyListRef = dbService.collection("buyList");

        if (invText) {
            buyListRef
                .doc(invText)
                .get()
                .then((res) => {
                    if (res.data() === undefined) {
                        console.log("hello");

                        dbService
                            .collection("buyList")
                            .doc(invText)
                            .set({
                                id: invText,
                                userEmail: invText,
                                quantity: 1,
                                expire: expire,
                                createdAt: Date.now(),
                            })
                            .then((res) => {
                                alert("입력이 완료되었습니다 !");
                            });
                    } else {
                        const num = res.data().quantity;

                        dbService
                            .collection("buyList")
                            .doc(invText)
                            .update({
                                expire: expire,
                                quantity: num + 1,
                            })
                            .then((res) => {
                                alert("수정이 완료되었습니다.");
                            });
                    }
                });
        } else {
            alert("id를 입력해주세요");
        }
    };

    const saveNew = () => {
        var buyListRef = dbService.collection("newbuyList");

        if (newText) {
            buyListRef
                .doc(newText)
                .get()
                .then((res) => {
                    if (res.data() === undefined) {
                        console.log("hello");

                        dbService
                            .collection("newbuyList")
                            .doc(newText)
                            .set({
                                id: newText,
                                userEmail: newText,
                                quantity: 1,
                                expire: expire,
                                createdAt: Date.now(),
                            })
                            .then((res) => {
                                alert("입력이 완료되었습니다 !");
                            });
                    } else {
                        const num = res.data().quantity;

                        dbService
                            .collection("newbuyList")
                            .doc(newText)
                            .update({
                                expire: expire,
                                quantity: num + 1,
                            })
                            .then((res) => {
                                alert("수정이 완료되었습니다.");
                            });
                    }
                });
        } else {
            alert("id를 입력해주세요");
        }
    };

    const saveThx = () => {
        var buyListRef = dbService.collection("thanksBuyList");

        if (thxText) {
            buyListRef
                .doc(thxText)
                .get()
                .then((res) => {
                    if (res.data() === undefined) {
                        console.log("hello");

                        dbService
                            .collection("thanksBuyList")
                            .doc(thxText)
                            .set({
                                userEmail: thxText,
                                quantity: 1,
                                createdAt: Date.now(),
                            })
                            .then((res) => {
                                alert("입력이 완료되었습니다 !");
                            });
                    } else {
                        const num = res.data().quantity;

                        dbService
                            .collection("thanksBuyList")
                            .doc(thxText)
                            .update({
                                quantity: num + 1,
                            })
                            .then((res) => {
                                alert("수정이 완료되었습니다.");
                            });
                    }
                });
        } else {
            alert("id를 입력해주세요");
        }
    };

    const saveCoupon = () => {
        var buyListRef = dbService.collection("newcouponList");

        if (couponText) {
            buyListRef
                .doc(couponText)
                .get()
                .then((res) => {
                    if (res.data() === undefined) {
                        console.log("hello");

                        dbService
                            .collection("newcouponList")
                            .doc(couponText)
                            .set({
                                couponNumber: couponText,
                                quantity: 1,
                                expire: expire,
                                createdAt: Date.now(),
                            })
                            .then((res) => {
                                alert("입력이 완료되었습니다 !");
                            });
                    } else {
                        const num = res.data().quantity;

                        dbService
                            .collection("newcouponList")
                            .doc(couponText)
                            .update({
                                expire: expire,
                                quantity: num + 1,
                            })
                            .then((res) => {
                                alert("수정이 완료되었습니다.");
                            });
                    }
                });
        } else {
            alert("쿠폰를 입력해주세요");
        }
    };

    const getInvText = ({ text }) => {
        console.log(text);

        setInvText(text);
    };

    const getNewText = ({ text }) => {
        console.log(text);

        setNewText(text);
    };

    const getThxText = ({ text }) => {
        console.log(text);

        setThxText(text);
    };

    const getCouponText = ({ text }) => {
        console.log(text);
        setCouponText(text);
    };

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                minHeight: "100vh",
                backgroundColor: "rgb(232, 236, 238)",
            }}
        >
            <div
                style={{
                    maxWidth: 480,
                    height: "100%",
                    minHeight: "100vh",
                    margin: "0 auto",
                    paddingTop: 20,
                }}
            >
                <div>
                    <div style={{ display: "flex", padding: "0 16px" }}>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                height: 45,
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor:
                                    pageNum === 0 ? "#5046e4" : "#fff",
                                color: pageNum === 0 ? "#fff" : "#333",
                                cursor: "pointer",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setPageNum(0);
                                setInvText("");
                                setNewText("");
                                setThxText("");
                                setCouponText("");
                                setExpire(false);
                            }}
                        >
                            ㅊㅊㅈ
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                height: 45,
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "0 5px",
                                backgroundColor:
                                    pageNum === 1 ? "#5046e4" : "#fff",
                                color: pageNum === 1 ? "#fff" : "#333",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setPageNum(1);
                                setInvText("");
                                setNewText("");

                                setThxText("");
                                setCouponText("");
                                setExpire(false);
                            }}
                        >
                            ㄱㅅㅈ
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                height: 45,
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor:
                                    pageNum === 2 ? "#5046e4" : "#fff",
                                color: pageNum === 2 ? "#fff" : "#333",
                                cursor: "pointer",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setPageNum(2);
                                setInvText("");
                                setNewText("");
                                setThxText("");
                                setCouponText("");
                                setExpire(false);
                            }}
                        >
                            ㅋㅍ
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                height: 45,
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor:
                                    pageNum === 3 ? "#5046e4" : "#fff",
                                color: pageNum === 3 ? "#fff" : "#333",
                                cursor: "pointer",
                                margin: "0 5px",
                            }}
                            onClick={() => {
                                setPageNum(3);
                                setInvText("");
                                setNewText("");
                                setThxText("");
                                setCouponText("");
                                setExpire(false);
                            }}
                        >
                            NEW
                        </div>
                    </div>
                </div>
                {pageNum === 0 ? (
                    <div
                        style={{
                            padding: "0 16px",
                            marginTop: 20,
                        }}
                    >
                        <SubTitleComponent title="구매자 아이디" />
                        <TextInputComponent
                            getBlur={() => {}}
                            getText={getInvText}
                            _value={invText}
                        />

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 10,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setExpire(!expire);
                            }}
                        >
                            <div
                                style={{
                                    display: "inline-block",
                                    border: "1px solid rgb(229, 228, 228)",
                                    backgroundColor: expire
                                        ? "#5046e4"
                                        : "#fff",
                                    width: 20,
                                    height: 20,
                                    borderRadius: 5,
                                }}
                            ></div>
                            <span
                                style={{
                                    marginLeft: 6,
                                    color: "rgb(118, 118, 118)",
                                }}
                            >
                                영구 소장
                            </span>
                        </div>
                    </div>
                ) : pageNum === 1 ? (
                    <div
                        style={{
                            padding: "0 16px",
                            marginTop: 20,
                        }}
                    >
                        <SubTitleComponent title="구매자 아이디" />
                        <TextInputComponent
                            getBlur={() => {}}
                            getText={getThxText}
                            _value={thxText}
                        />
                    </div>
                ) : pageNum === 2 ? (
                    <div
                        style={{
                            padding: "0 16px",
                            marginTop: 20,
                        }}
                    >
                        <SubTitleComponent title="쿠폰 번호" />
                        <TextInputComponent
                            getBlur={() => {}}
                            getText={getCouponText}
                            _value={couponText}
                        />

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 10,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setExpire(!expire);
                            }}
                        >
                            <div
                                style={{
                                    display: "inline-block",
                                    border: "1px solid rgb(229, 228, 228)",
                                    backgroundColor: expire
                                        ? "#5046e4"
                                        : "#fff",
                                    width: 20,
                                    height: 20,
                                    borderRadius: 5,
                                }}
                            ></div>
                            <span
                                style={{
                                    marginLeft: 6,
                                    color: "rgb(118, 118, 118)",
                                }}
                            >
                                영구 소장
                            </span>
                        </div>
                    </div>
                ) : pageNum === 3 ? (
                    <div
                        style={{
                            padding: "0 16px",
                            marginTop: 20,
                        }}
                    >
                        <SubTitleComponent title="구매자 아이디" />
                        <TextInputComponent
                            getBlur={() => {}}
                            getText={getNewText}
                            _value={newText}
                        />

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 10,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setExpire(!expire);
                            }}
                        >
                            <div
                                style={{
                                    display: "inline-block",
                                    border: "1px solid rgb(229, 228, 228)",
                                    backgroundColor: expire
                                        ? "#5046e4"
                                        : "#fff",
                                    width: 20,
                                    height: 20,
                                    borderRadius: 5,
                                }}
                            ></div>
                            <span
                                style={{
                                    marginLeft: 6,
                                    color: "rgb(118, 118, 118)",
                                }}
                            >
                                영구 소장
                            </span>
                        </div>
                    </div>
                ) : (
                    false
                )}

                <div style={{ marginTop: 50, padding: "0 16px" }}>
                    <div
                        style={{
                            backgroundColor: "#5046e4",
                            width: "100%",
                            height: 50,
                            borderRadius: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            fontSize: 16,
                            fontWeight: "bold",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            if (pageNum === 0) {
                                saveInv();
                            } else if (pageNum === 1) {
                                saveThx();
                            } else if (pageNum === 2) {
                                saveCoupon();
                            } else if (pageNum === 3) {
                                saveNew();
                            }
                        }}
                    >
                        {pageNum === 0
                            ? "청첩장 입력"
                            : pageNum === 1
                            ? "감사장 입력"
                            : pageNum === 2
                            ? "쿠폰 입력"
                            : pageNum === 3
                            ? "청첩장 입력"
                            : false}
                    </div>
                </div>
            </div>
        </div>
    );
}
