import React, { useState, useCallback, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import { debounce } from "lodash";
import { dbService } from "../../fbase";

export default function WeddingRootComponent({ getData, state, letterId }) {
    const [rootContent, setRootContent] = useState("");
    const [rootContent1, setRootContent1] = useState(
        state.rootContent1 ? state.rootContent1 : ""
    );
    const [rootContent2, setRootContent2] = useState(
        state.rootContent2 ? state.rootContent2 : ""
    );
    const [rootContent3, setRootContent3] = useState(
        state.rootContent3 ? state.rootContent3 : ""
    );
    const [rootContent4, setRootContent4] = useState(
        state.rootContent4 ? state.rootContent4 : ""
    );

    const handleChange = (text, _parameter) => {
        console.log(_parameter);

        switch (_parameter) {
            case 0:
                setRootContent1(text);
                debounceGetRootContent(_parameter, text);
                break;
            case 1:
                setRootContent2(text);
                debounceGetRootContent(_parameter, text);

                break;
            case 2:
                setRootContent3(text);
                debounceGetRootContent(_parameter, text);

                break;
            case 3:
                setRootContent4(text);
                debounceGetRootContent(_parameter, text);

                break;

            default:
                break;
        }
    };

    const debounceGetRootContent = useCallback(
        debounce((_parameter, _rootContent) => {
            if (_parameter === 0) {
                onSubmit({ _rootContent1: _rootContent });
            } else if (_parameter === 1) {
                onSubmit({ _rootContent2: _rootContent });
            } else if (_parameter === 2) {
                onSubmit({ _rootContent3: _rootContent });
            } else if (_parameter === 3) {
                onSubmit({ _rootContent4: _rootContent });
            }
        }, 1500),
        []
    );

    const pushData = () => {
        getData({ isSaved: true });
    };

    const onSubmit = async ({
        _rootTitle1,
        _rootTitle2,
        _rootTitle3,
        _rootTitle4,
        _rootContent1,
        _rootContent2,
        _rootContent3,
        _rootContent4,
    }) => {
        let content = {};

        if (_rootTitle1 !== undefined) {
            content = {
                rootTitle1: _rootTitle1,
            };
            console.log(_rootTitle1);
        } else if (_rootTitle2 !== undefined) {
            content = {
                rootTitle2: _rootTitle2,
            };
            console.log(_rootTitle2);
        } else if (_rootTitle3 !== undefined) {
            content = {
                rootTitle3: _rootTitle3,
            };
            console.log(_rootTitle3);
        } else if (_rootTitle4 !== undefined) {
            content = {
                rootTitle4: _rootTitle4,
            };
            console.log(_rootTitle4);
        } else if (_rootContent1 !== undefined) {
            content = {
                rootContent1: _rootContent1,
            };
        } else if (_rootContent2 !== undefined) {
            content = {
                rootContent2: _rootContent2,
            };
        } else if (_rootContent3 !== undefined) {
            content = {
                rootContent3: _rootContent3,
            };
        } else if (_rootContent4 !== undefined) {
            content = {
                rootContent4: _rootContent4,
            };
        }

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                pushData();
            });
    };

    const debounceGetText = useCallback(
        debounce(({ _parameter, _rootTitle }) => {
            if (_parameter === 0) {
                onSubmit({ _rootTitle1: _rootTitle });
            } else if (_parameter === 1) {
                onSubmit({ _rootTitle2: _rootTitle });
            } else if (_parameter === 2) {
                onSubmit({ _rootTitle3: _rootTitle });
            } else if (_parameter === 3) {
                onSubmit({ _rootTitle4: _rootTitle });
            }
        }, 1500),
        []
    );

    const getText = (data, _parameter) => {
        // console.log(data.text);
        // pushData(data.text);

        debounceGetText({
            _parameter: _parameter,
            _rootTitle: data.text,
        });
    };

    const getBlurText = (text, _parameter) => {
        if (_parameter === 0) {
            onSubmit({ _rootTitle1: text });
        } else if (_parameter === 1) {
            onSubmit({ _rootTitle2: text });
        } else if (_parameter === 2) {
            onSubmit({ _rootTitle3: text });
        } else if (_parameter === 3) {
            onSubmit({ _rootTitle4: text });
        } else if (_parameter === 10) {
            onSubmit({ _rootContent1: text });
        } else if (_parameter === 11) {
            onSubmit({ _rootContent2: text });
        } else if (_parameter === 12) {
            onSubmit({ _rootContent3: text });
        } else if (_parameter === 13) {
            onSubmit({ _rootContent4: text });
        }
    };

    useEffect(() => {
        // console.log(state);
    }, []);

    return (
        <div>
            <div>
                <div>
                    <SubTitleComponent title="교통수단 1" />
                    <TextInputComponent
                        placeholder={"지하철, 자가용, 버스"}
                        getText={(e) => {
                            getText(e, 0);
                        }}
                        _value={state.rootTitle1 ? state.rootTitle1 : ""}
                        getBlur={({ isBlur, text }) => {
                            if (isBlur) {
                                debounceGetText.cancel();
                                getBlurText(text, 0);
                            }
                        }}
                    />
                </div>
                <div style={{ marginTop: 16 }}>
                    <ReactQuill
                        theme={"snow"}
                        onChange={(e) => {
                            handleChange(e, 0);
                        }}
                        value={rootContent1 || ""}
                        defaultValue=""
                        modules={{
                            toolbar: [
                                [
                                    "bold",
                                    "italic",
                                    "underline",
                                    // { list: "ordered" },
                                    // { list: "bullet" },
                                ],
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            },
                        }}
                        formats={[
                            "bold",
                            "italic",
                            "underline",
                            // "list",
                            // "bullet",
                            "align",
                        ]}
                        bounds={".app"}
                        placeholder={"오시는길 내용을 입력하세요"}
                        onBlur={() => {
                            debounceGetText.cancel();
                            getBlurText(rootContent1, 10);
                        }}
                    />
                </div>
            </div>

            <div>
                <div>
                    <SubTitleComponent title="교통수단 2" />
                    <TextInputComponent
                        placeholder={"지하철, 자가용, 버스"}
                        getText={(e) => {
                            getText(e, 1);
                        }}
                        _value={state.rootTitle2 ? state.rootTitle2 : ""}
                        getBlur={({ isBlur, text }) => {
                            if (isBlur) {
                                debounceGetText.cancel();
                                getBlurText(text, 1);
                            }
                        }}
                    />
                </div>
                <div style={{ marginTop: 16 }}>
                    <ReactQuill
                        theme={"snow"}
                        onChange={(e) => {
                            handleChange(e, 1);
                        }}
                        value={rootContent2 || ""}
                        defaultValue=""
                        modules={{
                            toolbar: [
                                [
                                    "bold",
                                    "italic",
                                    "underline",
                                    // { list: "ordered" },
                                    // { list: "bullet" },
                                ],
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            },
                        }}
                        formats={[
                            "bold",
                            "italic",
                            "underline",
                            // "list",
                            // "bullet",
                            "align",
                        ]}
                        bounds={".app"}
                        placeholder={"오시는길 내용을 입력하세요"}
                        onBlur={() => {
                            debounceGetText.cancel();
                            getBlurText(rootContent2, 11);
                        }}
                    />
                </div>
            </div>

            <div>
                <div>
                    <SubTitleComponent title="교통수단 3" />
                    <TextInputComponent
                        placeholder={"지하철, 자가용, 버스"}
                        getText={(e) => {
                            getText(e, 2);
                        }}
                        _value={state.rootTitle3 ? state.rootTitle3 : ""}
                        getBlur={({ isBlur, text }) => {
                            if (isBlur) {
                                debounceGetText.cancel();
                                getBlurText(text, 2);
                            }
                        }}
                    />
                </div>
                <div style={{ marginTop: 16 }}>
                    <ReactQuill
                        theme={"snow"}
                        onChange={(e) => {
                            handleChange(e, 2);
                        }}
                        value={rootContent3 || ""}
                        defaultValue=""
                        modules={{
                            toolbar: [
                                [
                                    "bold",
                                    "italic",
                                    "underline",
                                    // { list: "ordered" },
                                    // { list: "bullet" },
                                ],
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            },
                        }}
                        formats={[
                            "bold",
                            "italic",
                            "underline",
                            // "list",
                            // "bullet",
                            "align",
                        ]}
                        bounds={".app"}
                        placeholder={"오시는길 내용을 입력하세요"}
                        onBlur={() => {
                            console.log("blur");
                            debounceGetText.cancel();
                            getBlurText(rootContent3, 12);
                        }}
                    />
                </div>
            </div>

            <div>
                <div>
                    <SubTitleComponent title="교통수단 4" />
                    <TextInputComponent
                        placeholder={"지하철, 자가용, 버스"}
                        getText={(e) => {
                            getText(e, 3);
                        }}
                        _value={state.rootTitle4 ? state.rootTitle4 : ""}
                        getBlur={({ isBlur, text }) => {
                            if (isBlur) {
                                debounceGetText.cancel();
                                getBlurText(text, 3);
                            }
                        }}
                    />
                </div>
                <div style={{ marginTop: 16 }}>
                    <ReactQuill
                        theme={"snow"}
                        onChange={(e) => {
                            handleChange(e, 3);
                        }}
                        value={rootContent4 || ""}
                        defaultValue=""
                        modules={{
                            toolbar: [
                                [
                                    "bold",
                                    "italic",
                                    "underline",
                                    // { list: "ordered" },
                                    // { list: "bullet" },
                                ],
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            },
                        }}
                        formats={[
                            "bold",
                            "italic",
                            "underline",
                            // "list",
                            // "bullet",
                            "align",
                        ]}
                        bounds={".app"}
                        placeholder={"오시는길 내용을 입력하세요"}
                        onBlur={() => {
                            console.log("blur");
                            debounceGetText.cancel();
                            getBlurText(rootContent4, 13);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
