import React, { useEffect, useState } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { debounce } from "lodash";
import { dbService } from "../../fbase";

const SortableItem = sortableElement(({ value, isActive }) => (
    <div
        style={{
            borderLeft: "0.5px solid #e5e4e4",
            borderRight: "0.5px solid #e5e4e4",
            borderTop: "0.5px solid #e5e4e4",
            borderBottom: "0.5px solid #e5e4e4",
            padding: "12px 12px",
            color: "#333",
            cursor: "pointer",
            borderRadius: 5,
            margin: "5px 0",
            backgroundColor: "#fff",
        }}
    >
        {value}
    </div>
));

const SortableContainer = sortableContainer(({ children }) => {
    return <div style={{}}>{children}</div>;
});

export default function LayoutComponent({ state, getData, letterId }) {
    let a = [...state.layoutArr, { key: 8, label: "비디오" }];
    const [layoutArr, setLayoutArr] = useState(
        state.layoutArr !== undefined
            ? state.layoutArr.length > 8
                ? state.layoutArr
                : a
            : []
    );

    const debounceGetText = React.useCallback(
        debounce((data) => {
            onSubmit({ _layoutArr: data });
        }, 500),
        []
    );

    const onSubmit = async ({ _layoutArr }) => {
        let content = {};

        if (_layoutArr !== undefined) {
            content = {
                layoutArr: _layoutArr,
            };
        }

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                pushData();
            });
    };

    const pushData = () => {
        getData({ isSaved: true });
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const draggedData = arrayMoveImmutable(layoutArr, oldIndex, newIndex);
        setLayoutArr(draggedData);

        console.log(draggedData);
        debounceGetText(draggedData);

        // pushData(draggedData);
    };

    return (
        <div>
            <SortableContainer onSortEnd={onSortEnd}>
                {layoutArr.map((item, index) => (
                    <SortableItem
                        key={index}
                        index={index}
                        value={item.label}
                        isActive={item.isActive}
                    />
                ))}
            </SortableContainer>
        </div>
    );
}
