import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MobxProvider } from "./MobxContext";
import { RenderAfterNavermapsLoaded } from "react-naver-maps";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

ReactDOM.render(
    <React.StrictMode>
        <RenderAfterNavermapsLoaded ncpClientId={"cv0ho1ymtm"}>
            <App />
        </RenderAfterNavermapsLoaded>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// window.ChannelIO("boot", {
//     pluginKey: process.env.REACT_APP_CHENNEL_TALK_KEY,
// });
