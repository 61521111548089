import React, { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Modal from "react-modal";
import ReactHtmlParser from "react-html-parser";
import DaumPostcode from "react-daum-postcode";
import S3 from "react-aws-s3";
import { useParams } from "react-router-dom";
import localStorage from "mobx-localstorage";
import { v4 as uuidv4 } from "uuid";
import { authService, dbService, storageService } from "../../fbase";
import QrReader from "react-qr-reader";
import HidiHeader from "../../components/HidiHeader/HidiHeader";
import Resizer from "react-image-file-resizer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Compressor from "compressorjs";

import kakaopayGuide_1 from "../../assets/images/kakaopayGuide_1.jpeg";
import kakaopayGuide_2 from "../../assets/images/kakaopayGuide_2.jpeg";
import { set } from "mobx";
import LayoutChangeComponent from "../../components/LayoutChangeComponent/LayoutChangeComponent";

const _layoutArr = [
    { key: 0, label: "인삿말" },
    { key: 1, label: "갤러리" },
    { key: 2, label: "식전영상" },
    { key: 3, label: "달력, 디데이" },
    { key: 4, label: "오시는길" },
    { key: 5, label: "공지사항" },
    { key: 6, label: "방명록" },
    { key: 7, label: "마음전하실 곳" },
];

const getToday = () => {
    let today = new Date();
    let year = today.getFullYear();
    let mon = today.getMonth() + 1;
    let day = today.getDate();

    let fullYears = `${year}-${mon < 10 ? "0" + mon : mon}-${
        day < 10 ? "0" + day : day
    }T12:00`;
    return fullYears;
};

// 이미지 사이즈 설정
const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 800,
};
// 이미지 사이즈 설정

const invatationContentArray = [
    {
        content: `각자 서로 다른 길을\n걸어온 저희가 이제 부부의 연으로\n한 길을 걸어가고자 합니다.\n\n항상 처음을 생각하며,\n서로 아껴주고 사랑하며 살겠습니다.\n\n부디 참석해 주시어 저희의 약속을\n따뜻한 격려로 축복해 주시기 바랍니다.`,
    },
    {
        content: `코로나19 바이러스로 인해\n걱정이 많았지만 종식을 예측할 수 없어\n결혼식을 일정대로 진행하기로 했습니다.\n\n저희를 축하해주시는 마음은 모두 같으니\n참석에 대한 부담감을 갖지 않으시길 바라며\n축하해주신 모든 분들께 감사드립니다.`,
    },
    {
        content: `서로에게 행복을 주는 사람을 만났습니다.\n웃는 모습이 너무나 예쁜 그 사람을 만났습니다.\n배려하는 마음이 따뜻한 그 사람을 만났습니다. \n\n운명처럼 만나게 된 우리의 인연\n그 인연에 이끌려 이제 영원을\n함께 약속하려 합니다. \n\n저희의 하나 됨을 지켜보아 주시고 \n격려해 주시면 더없는 기쁨으로\n간직하겠습니다.`,
    },
    {
        content: `평생을 같이하고 싶은 사람을 만났습니다.\n\n첫 마음 그대로 존중하고 배려하며\n예쁘게 사랑하겠습니다.\n\n저희의 약속 위에 따뜻한 격려로 축복해 주시어\n힘찬 출발의 디딤이 되어주시면\n더없는 기쁨으로 간직하겠습니다.`,
    },
    {
        content: `저희 두 사람의 작은 만남이\n사랑의 결실을 이루어\n소중한 결혼식을 올리게 되었습니다.\n\n평생 서로 귀하게 여기며\n첫 마음 그대로 존중하고 배려하며 살겠습니다.\n\n오로지 믿음과 사랑을 약속하는 날\n오셔서 축복해 주시면 더없는 기쁨으로\n간직하겠습니다.`,
    },
    {
        content: `결혼은 또 하나의 \n새로운 인생의 시작이라고 합니다.\n\n오늘이 있기까지 많은 사랑과 \n관심을 기울여 주신 \n여러 어르신과 친지분들을 모시고 \n저희 두 사람이 백년해로의 \n진실한 가약을 맺고자 합니다. \n\n부디 참석하시어 저희가 내딛는 \n새 인생의 첫걸음을 \n격려와 축복으로 빛내 주시기 바랍니다.`,
    },
    {
        content: `어제의 너와 내가 \n오늘 우리가 되어 \n저희 두 사람 이제 \n한길을 같이 걷고자 합니다. \n\n저희 첫 디딤에 부디 오시어 따뜻한 \n사랑으로 축복해 주십시오. \n\n보다 힘찬 내디딤이 될 것입니다.`,
    },
    {
        content: `새로이 시작하는 작은사랑이 \n보다 깊고 향 짙게 꽃피려 합니다. \n\n저희의 뜻깊은 백 년의 약속의 날 \n함께 하셔서 축복해 주십시오. \n\n사랑으로 가득 채워 \n즐거움은 나누고 어려움은 이겨내는 \n함께 나아가는 삶을 꾸리겠습니다.`,
    },
    {
        content: `너무 곱게 키우면 여릴까 봐 \n너무 험하게 키우면 거칠까 봐 \n노심초사 하루도 편할 날 없이\n정성 들였습니다. \n\n하지만 막상 혼인에 예를 갖춰\n어른들 세상에 내보내려니 \n새삼 두려움이 앞섭니다. \n\n살펴주시고 가르쳐 주시고 \n가야 할 먼 길 훤히 밝혀 주시기 바랍니다.`,
    },
    {
        content: `저희 아들과 딸이 한 가정을 이루고자 \n혼인의 예를 올리게 되었습니다.\n\n두 사람이 행복한 가정을\n이룰 수 있도록 따뜻한 마음으로\n축복해주시면 감사하겠습니다.`,
    },
];

const _surveyTitle = "참석 여부 전달 안내";

const _surveyContent = `특별한 날 축하의 마음으로 참석해주시는 모든\n분들을 한 분 한 분 더욱 귀하게 모실 수있도록,\n아래 버튼을 클릭하여 신랑·신부에게\n참석여부를 전달 부탁드립니다.`;

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#fff",
        width: 290,
        maxHeight: 500,
        paddingBottom: 50,
        paddingLeft: 15,
        paddingRight: 15,
    },
    overlay: {
        backgroundColor: "rgb(0,0,0,0.5)",
        zIndex: 3000,
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: 300,
        },

        "& .MuiTextField-root": {
            width: 300,
        },
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
    nested: {
        paddingLeft: theme.spacing(0),
    },
}));

const HidiModifyScreen = (props) => {
    const [userId, setUserId] = useState("");
    const [_letterVer, setLetterVer] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenPostcode, setIsOpenPostcode] = useState(false);
    const [isKakaoMap, setIsKakaoMap] = useState(false);
    const [isHusbandKakaoModal, setIsHusbandKakaoModal] = useState(false);
    const [isWifeKakaoModal, setIsWifeKakaoModal] = useState(false);

    const [mainImg, setMainImg] = useState("");
    const [thumbImg, setThumbImg] = useState("");
    const [metaImg, setMetaImg] = useState("");

    const [originMainImg, setOriginMainImg] = useState("");
    const [originThumbImg, setOriginThumbImg] = useState("");
    const [originMetaImg, setOriginMetaImg] = useState("");

    const [uploadThumb, setUploadThumb] = useState("");
    const [uploadMeta, setUploadMeta] = useState("");
    const [galleryArray, setGalleryArray] = useState([]);
    const [uploadMain, setUploadMain] = useState("");
    const [uploadGalleryArray, setUploadGalleryArray] = useState("");

    const [husbandFamilyName, setHusbandFamilyName] = useState("");
    const [husbandName, setHusbandName] = useState("");
    const [husbandPhone, setHusbandPhone] = useState("");
    const [husbandFirstSon, setHusbandFirstSon] = useState("");
    const [husbandPapa, setHusbandPapa] = useState("");
    const [husbandPapaPhone, setHusbandPapaPhone] = useState("");
    const [husbandMama, setHusbandMama] = useState("");
    const [husbandMamaPhone, setHusbandMamaPhone] = useState("");
    const [husbandPapaIsDead, setHusbandPapaIsDead] = useState(false);
    const [husbandMamaIsDead, setHusbandMamaIsDead] = useState(false);

    const [wifeFamilyName, setWifeFamilyName] = useState("");
    const [wifeName, setWifeName] = useState("");
    const [wifePhone, setWifePhone] = useState("");
    const [wifeFirstDau, setWifeFirstDau] = useState("");
    const [wifePapa, setWifePapa] = useState("");
    const [wifePapaPhone, setWifePapaPhone] = useState("");
    const [wifeMama, setWifeMama] = useState("");
    const [wifeMamaPhone, setWifeMamaPhone] = useState("");
    const [wifePapaIsDead, setWifePapaIsDead] = useState(false);
    const [wifeMamaIsDead, setWifeMamaIsDead] = useState(false);

    const [invatationContent, setInvatationContent] = useState("");

    const [weddingDate, setWeddingDate] = useState("");
    const [isCalendar, setIsCalendar] = useState(true);
    const [isDday, setIsDday] = useState(true);
    const [autoPlay, setAutoPlay] = useState(false);

    const [weddingAddress, setWeddingAddress] = useState("");
    const [weddingLocationName, setWeddingLocationName] = useState("");
    const [weddingLocationFloor, setWeddingLocationFloor] = useState("");
    const [weddingNumber, setWeddingNumber] = useState("");
    const [longitude, setLongitude] = useState("");
    const [latitude, setLatitude] = useState("");
    const [isMap, setIsMap] = useState(true);
    const [isMapFixed, setIsMapFixed] = useState(false);

    const [weddingHow, setWeddingHow] = useState([]);

    const [accountVer, setAccountVer] = useState("2");
    const [galleryVer, setGalleryVer] = useState("1");

    const [accountTitle, setAccountTitle] = useState("마음 전하실 곳");

    const [isHusbandAccount, setIsHusbandAccount] = useState(true);
    const [isWifeAccount, setIsWifeAccount] = useState(true);
    const [isHusbandPapaAccount, setIsHusbandPapaAccount] = useState(false);
    const [isHusbandMamaAccount, setIsHusbandMamaAccount] = useState(false);
    const [isWifePapaAccount, setIsWifePapaAccount] = useState(false);
    const [isWifeMamaAccount, setIsWifeMamaAccount] = useState(false);

    const [husbandAccountTitle, setHusbandAccountTitle] = useState("신랑측");
    const [husbandAccount, setHusbandAccount] = useState("");
    const [husbandAccountName, setHusbandAccountName] = useState("");
    const [husbandAccountBank, setHusbandAccountBank] = useState("");

    const [wifeAccountTitle, setWifeAccountTitle] = useState("신부측");
    const [wifeAccount, setWifeAccount] = useState("");
    const [wifeAccountName, setWifeAccountName] = useState("");
    const [wifeAccountBank, setWifeAccountBank] = useState("");

    const [husbandPapaAccountTitle, setHusbandPapaAccountTitle] =
        useState("신랑측 아버지");
    const [husbandPapaAccount, setHusbandPapaAccount] = useState("");
    const [husbandPapaAccountName, setHusbandPapaAccountName] = useState("");
    const [husbandPapaAccountBank, setHusbandPapaAccountBank] = useState("");

    const [husbandMamaAccountTitle, setHusbandMamaAccountTitle] =
        useState("신랑측 어머니");
    const [husbandMamaAccount, setHusbandMamaAccount] = useState("");
    const [husbandMamaAccountName, setHusbandMamaAccountName] = useState("");
    const [husbandMamaAccountBank, setHusbandMamaAccountBank] = useState("");

    const [wifePapaAccountTitle, setWifePapaAccountTitle] =
        useState("신부측 아버지");
    const [wifePapaAccount, setWifePapaAccount] = useState("");
    const [wifePapaAccountName, setWifePapaAccountName] = useState("");
    const [wifePapaAccountBank, setWifePapaAccountBank] = useState("");

    const [wifeMamaAccountTitle, setWifeMamaAccountTitle] =
        useState("신부측 어머니");
    const [wifeMamaAccount, setWifeMamaAccount] = useState("");
    const [wifeMamaAccountName, setWifeMamaAccountName] = useState("");
    const [wifeMamaAccountBank, setWifeMamaAccountBank] = useState("");

    const [videoUrl, setVideoUrl] = useState("");

    const [noticeTitle, setNoticeTitle] = useState("");
    const [noticeContent, setNoticeContent] = useState("");
    const [noticeURL, setNoticeURL] = useState("");
    const [noticeButtomTitle, setNoticeButtomTitle] = useState("");

    const [fontSize, setFontSize] = useState(1);
    const [fontVer, setFontVer] = useState(1);
    const [effect, setEffect] = useState("0");
    const [bgm, setBgm] = useState("0");
    const [isSharu, setIsSharu] = useState(true);
    const [isAccountClose, setIsAccountClose] = useState(false);
    const [isPhoto, setIsPhoto] = useState(false);
    const [isSubmit, setIsSumbmit] = useState(false);

    const [rootCollapse, setRootCollapse] = useState(false);
    const [weddingHowCollapse, setWeddingHowCollapse] = useState(false);
    const [galleryCollapse, setGalleryCollapse] = useState(false);
    const [accountCollapse, setAccountCollapse] = useState(false);
    const [videoCollapse, setVideoCollapse] = useState(false);
    const [musicCollapse, setMusicCollapse] = useState(false);
    const [flowerPresentCollapse, setFlowerPresentCollapse] = useState(false);

    const [additionCollapse, setAdditionCollapse] = useState(false);
    const [noticeCollapse, setNoticeCollapse] = useState(false);
    const [kakaoCollapse, setKakaoCollapse] = useState(true);
    const [urlCallapse, setUrlCallapse] = useState(true);

    const [husbandKakao, setHusbandKakao] = useState("");
    const [wifeKakao, setWifeKakao] = useState("");

    const [kakaoTitle, setKakaoTitle] = useState("");
    const [kakaoDes, setKakaoDes] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDes, setMetaDes] = useState("");

    const [weddingHowTitle_1, setWeddingHowTitle_1] = useState("");
    const [weddingHowTitle_2, setWeddingHowTitle_2] = useState("");
    const [weddingHowTitle_3, setWeddingHowTitle_3] = useState("");
    const [weddingHowTitle_4, setWeddingHowTitle_4] = useState("");

    const [weddingHowContent_1, setWeddingHowContent_1] = useState("");
    const [weddingHowContent_2, setWeddingHowContent_2] = useState("");
    const [weddingHowContent_3, setWeddingHowContent_3] = useState("");
    const [weddingHowContent_4, setWeddingHowContent_4] = useState("");

    const [isFlowerPresent, setIsFlowerPresent] = useState("0");
    const [commentCollapse, setCommentCollapse] = useState(false);
    const [isComment, setIsComment] = useState("0");
    const [masterPassword, setMasterPassword] = useState("");
    const [masterPasswordCheck, setMasterPasswordCheck] = useState("");
    const [customVideo, setCustomVideo] = useState("");
    const [mainEffect, setMainEffect] = useState("");
    const [layoutCollapse, setLayoutCollapse] = useState(false);

    const [layoutArr, setLayoutArr] = useState([]);

    const [surveyCollapse, setSurveyCollapse] = useState(false);
    const [isSurvey, setIsSurvey] = useState("0");
    const [surveyTitle, setSurveyTitle] = useState("");
    const [surveyContent, setSurveyContent] = useState("");

    const [galleryIndex, setGalleryIndex] = useState(-1);
    const { letterId } = useParams();

    const qrRef = useRef(null);

    const classes = useStyles();

    let map = null;
    let eventMarker = null;

    // s3설정
    const config = {
        bucketName: "self-cryucard",
        dirName: weddingDate.slice(0, 10) /* optional */,
        region: "ap-northeast-2",
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    // s3설정

    const getLayoutArr = (getData) => {
        const { data } = getData;
        setLayoutArr(data);
        // console.log(getData);
    };

    const getLetter = (getUserId) => {
        var letterRef = dbService.collection("letters");

        letterRef
            .doc(letterId)
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    const letterData = res.data();

                    const {
                        letterVer,
                        accountCollapse,
                        noticeCollapse,
                        accountTitle,
                        accountVer,
                        galleryVer,
                        additionCollapse,
                        bgm,
                        effect,
                        fontSize,
                        fontVer,
                        galleryCollapse,
                        husbandAccount,
                        husbandAccountBank,
                        husbandAccountName,
                        husbandAccountTitle,
                        husbandFamilyName,
                        husbandFirstSon,
                        husbandMama,
                        husbandMamaAccount,
                        husbandMamaAccountBank,
                        husbandMamaAccountName,
                        husbandMamaAccountTitle,
                        husbandMamaIsDead,
                        husbandMamaPhone,
                        husbandName,
                        husbandPapa,
                        husbandPapaAccount,
                        husbandPapaAccountBank,
                        husbandPapaAccountName,
                        husbandPapaAccountTitle,
                        husbandPapaIsDead,
                        husbandPapaPhone,
                        husbandPhone,
                        invatationContent,
                        isCalendar,
                        isDday,
                        isHusbandAccount,
                        isHusbandMamaAccount,
                        isHusbandPapaAccount,
                        isMap,
                        isMapFixed,
                        isPhoto,
                        isSharu,
                        isWifeAccount,
                        isWifeMamaAccount,
                        isWifePapaAccount,
                        kakaoDes,
                        kakaoTitle,
                        latitude,
                        longitude,
                        mainImg,
                        metaDes,
                        metaTitle,
                        musicCollapse,
                        noticeContent,
                        noticeTitle,
                        rootCollapse,
                        videoCollapse,
                        videoUrl,
                        flowerPresentCollapse,
                        weddingAddress,
                        weddingDate,
                        weddingHowCollapse,
                        weddingHowContent_1,
                        weddingHowContent_2,
                        weddingHowContent_3,
                        weddingHowContent_4,
                        weddingHowTitle_1,
                        weddingHowTitle_2,
                        weddingHowTitle_3,
                        weddingHowTitle_4,
                        weddingLocationFloor,
                        weddingLocationName,
                        weddingNumber,
                        wifeAccount,
                        wifeAccountBank,
                        wifeAccountName,
                        wifeAccountTitle,
                        wifeFamilyName,
                        wifeFirstDau,
                        wifeMama,
                        wifeMamaAccount,
                        wifeMamaAccountBank,
                        wifeMamaAccountName,
                        wifeMamaAccountTitle,
                        wifeMamaIsDead,
                        wifeMamaPhone,
                        wifeName,
                        wifePapa,
                        wifePapaAccount,
                        wifePapaAccountBank,
                        wifePapaAccountName,
                        wifePapaAccountTitle,
                        wifePapaIsDead,
                        wifePapaPhone,
                        wifePhone,
                        metaImg,
                        thumbImg,
                        gallery,
                        husbandKakao,
                        wifeKakao,
                        autoPlay,
                        isAccountClose,

                        commentCollapse,
                        surveyCollapse,
                        isComment,
                        isSurvey,
                        masterPassword,
                        surveyTitle,
                        surveyContent,
                        customVideo,
                        mainEffect,
                        isFlowerPresent,

                        noticeButtomTitle,
                        noticeURL,
                        layoutArr,
                        layoutCollapse,
                    } = res.data();

                    if (
                        getUserId === res.data().userId ||
                        "51543483" == getUserId
                    ) {
                        setAccountCollapse(accountCollapse);
                        setNoticeCollapse(
                            noticeCollapse ? noticeCollapse : false
                        );
                        setAccountTitle(
                            accountTitle
                                ? accountTitle.replace(/<br ?\/?>/g, "\n")
                                : accountTitle
                        );
                        setAccountVer(accountVer.toString());
                        setGalleryVer(galleryVer ? galleryVer.toString() : "1");
                        setAdditionCollapse(additionCollapse);
                        setBgm(bgm.toString());
                        setEffect(effect.toString());
                        setFontSize(fontSize.toString());
                        setFontVer(fontVer ? fontVer.toString() : "1");
                        setGalleryCollapse(galleryCollapse);
                        setHusbandAccount(husbandAccount);
                        setHusbandAccountBank(husbandAccountBank);
                        setHusbandAccountName(husbandAccountName);
                        setHusbandAccountTitle(husbandAccountTitle);
                        setHusbandFamilyName(husbandFamilyName);
                        setHusbandFirstSon(husbandFirstSon);
                        setHusbandMama(husbandMama);
                        setHusbandMamaAccount(husbandMamaAccount);
                        setHusbandMamaAccountBank(husbandMamaAccountBank);
                        setHusbandMamaAccountName(husbandMamaAccountName);
                        setHusbandMamaAccountTitle(husbandMamaAccountTitle);
                        setHusbandMamaIsDead(husbandMamaIsDead);
                        setHusbandMamaPhone(husbandMamaPhone);
                        setHusbandName(husbandName);
                        setHusbandPapa(husbandPapa);
                        setHusbandPapaAccount(husbandPapaAccount);
                        setHusbandPapaAccountBank(husbandPapaAccountBank);
                        setHusbandPapaAccountName(husbandPapaAccountName);
                        setHusbandPapaAccountTitle(husbandPapaAccountTitle);
                        setHusbandPapaIsDead(husbandPapaIsDead);
                        setHusbandPapaPhone(husbandPapaPhone);
                        setHusbandPhone(husbandPhone);
                        setInvatationContent(
                            invatationContent
                                ? invatationContent.replace(/<br ?\/?>/g, "\n")
                                : invatationContent
                        );
                        setIsCalendar(isCalendar);
                        setIsDday(isDday);
                        setIsHusbandAccount(isHusbandAccount);
                        setIsHusbandMamaAccount(isHusbandMamaAccount);
                        setIsHusbandPapaAccount(isHusbandPapaAccount);
                        setIsMap(isMap);
                        setIsMapFixed(isMapFixed);
                        setIsPhoto(isPhoto);
                        setIsSharu(isSharu);
                        setIsAccountClose(
                            isAccountClose ? isAccountClose : false
                        );
                        setIsWifeAccount(isWifeAccount);
                        setIsWifeMamaAccount(isWifeMamaAccount);
                        setIsWifePapaAccount(isWifePapaAccount);
                        setKakaoDes(
                            kakaoDes
                                ? kakaoDes.replace(/<br ?\/?>/g, "\n")
                                : kakaoDes
                        );
                        setKakaoTitle(kakaoTitle);
                        setLatitude(latitude);
                        setLongitude(longitude);
                        setMetaDes(
                            metaDes
                                ? metaDes.replace(/<br ?\/?>/g, "\n")
                                : metaDes
                        );
                        setMetaTitle(metaTitle);
                        setMusicCollapse(musicCollapse);
                        setNoticeContent(
                            noticeContent
                                ? noticeContent.replace(/<br ?\/?>/g, "\n")
                                : noticeContent
                        );
                        setNoticeTitle(noticeTitle);
                        setSurveyTitle(surveyTitle ? surveyTitle : "");
                        setSurveyContent(
                            surveyContent
                                ? surveyContent.replace(/<br ?\/?>/g, "\n")
                                : ""
                        );
                        setRootCollapse(rootCollapse);
                        setVideoCollapse(videoCollapse);
                        setCommentCollapse(
                            commentCollapse ? commentCollapse : false
                        );
                        setFlowerPresentCollapse(
                            flowerPresentCollapse
                                ? flowerPresentCollapse
                                : false
                        );
                        setCommentCollapse(
                            surveyCollapse ? surveyCollapse : false
                        );
                        setVideoUrl(videoUrl);
                        setWeddingAddress(weddingAddress);
                        setWeddingDate(weddingDate);
                        setWeddingHowCollapse(weddingHowCollapse);
                        setWeddingHowContent_1(
                            weddingHowContent_1
                                ? weddingHowContent_1.replace(
                                      /<br ?\/?>/g,
                                      "\n"
                                  )
                                : weddingHowContent_1
                        );
                        setWeddingHowContent_2(
                            weddingHowContent_2
                                ? weddingHowContent_2.replace(
                                      /<br ?\/?>/g,
                                      "\n"
                                  )
                                : weddingHowContent_2
                        );
                        setWeddingHowContent_3(
                            weddingHowContent_3
                                ? weddingHowContent_3.replace(
                                      /<br ?\/?>/g,
                                      "\n"
                                  )
                                : weddingHowContent_3
                        );
                        setWeddingHowContent_4(
                            weddingHowContent_4
                                ? weddingHowContent_4.replace(
                                      /<br ?\/?>/g,
                                      "\n"
                                  )
                                : weddingHowContent_4
                        );
                        setWeddingHowTitle_1(weddingHowTitle_1);
                        setWeddingHowTitle_2(weddingHowTitle_2);
                        setWeddingHowTitle_3(weddingHowTitle_3);
                        setWeddingHowTitle_4(weddingHowTitle_4);
                        setWeddingLocationFloor(weddingLocationFloor);
                        setWeddingLocationName(weddingLocationName);
                        setWeddingNumber(weddingNumber);
                        setWifeAccount(wifeAccount);
                        setWifeAccountBank(wifeAccountBank);
                        setWifeAccountName(wifeAccountName);
                        setWifeAccountTitle(wifeAccountTitle);
                        setWifeFamilyName(wifeFamilyName);
                        setWifeFirstDau(wifeFirstDau);
                        setWifeMama(wifeMama);
                        setWifeMamaAccount(wifeMamaAccount);
                        setWifeMamaAccountBank(wifeMamaAccountBank);
                        setWifeMamaAccountName(wifeMamaAccountName);
                        setWifeMamaAccountTitle(wifeMamaAccountTitle);
                        setWifeMamaIsDead(wifeMamaIsDead);
                        setWifeMamaPhone(wifeMamaPhone);
                        setWifeName(wifeName);
                        setWifePapa(wifePapa);
                        setWifePapaAccount(wifePapaAccount);
                        setWifePapaAccountBank(wifePapaAccountBank);
                        setWifePapaAccountName(wifePapaAccountName);
                        setWifePapaAccountTitle(wifePapaAccountTitle);
                        setWifePapaIsDead(wifePapaIsDead);
                        setWifePapaPhone(wifePapaPhone);
                        setWifePhone(wifePhone);
                        setMainImg(mainImg);
                        setOriginMainImg(mainImg);
                        setMetaImg(metaImg);
                        setOriginMetaImg(metaImg);
                        setThumbImg(thumbImg);
                        setOriginThumbImg(thumbImg);
                        setGalleryArray(gallery);
                        setUploadGalleryArray(gallery);
                        setHusbandKakao(husbandKakao);
                        setWifeKakao(wifeKakao);
                        setAutoPlay(autoPlay ? autoPlay : false);
                        setIsFlowerPresent(
                            isFlowerPresent === true ? "1" : "0"
                        );
                        setIsComment(isComment === true ? "1" : "0");
                        setIsSurvey(isSurvey === true ? "1" : "0");
                        setMasterPassword(masterPassword ? masterPassword : "");
                        setMasterPasswordCheck(
                            masterPassword ? masterPassword : ""
                        );
                        setCustomVideo(customVideo ? customVideo : "");
                        setMainEffect(mainEffect ? mainEffect : "");
                        setNoticeURL(noticeURL ? noticeURL : "");
                        setNoticeButtomTitle(
                            noticeButtomTitle ? noticeButtomTitle : ""
                        );
                        setLetterVer(letterVer);

                        setLayoutArr(layoutArr ? layoutArr : _layoutArr);
                        setLayoutCollapse(
                            layoutCollapse ? layoutCollapse : false
                        );

                        setIsLoading(true);

                        getMap(latitude, longitude);
                    } else {
                        alert("비정상적인 접근입니다.");
                        window.location.href = "/#/hidilogin";
                    }
                } else {
                }
            });
    };

    const getEmail = async () => {
        let getUserId = "";

        if (localStorage.getItem("userId")) {
            getUserId = await localStorage.getItem("userId");
            setUserId(getUserId);

            getLetter(getUserId);
        } else {
            window.location.href = "/#/hidilogin";
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === "fontSize") {
            setFontSize(value);
        } else if (name === "fontVer") {
            setFontVer(value);
        }
    };

    const radioChange = (event) => {
        const { name, value } = event.target;

        if (name === "effect") {
            setEffect(value);
        } else if (name === "bgm") {
            setBgm(value);
        } else if (name === "accountVer") {
            setAccountVer(value);
        } else if (name === "galleryVer") {
            setGalleryVer(value);
        } else if (name === "isComment") {
            if (value === "0") {
                setMasterPassword("");
                setMasterPasswordCheck("");
            }
            setIsComment(value);
        } else if (name === "isSurvey") {
            if (value === "0") {
                setSurveyTitle("");
                setSurveyContent("");
            } else if (value === "1") {
                setSurveyTitle(_surveyTitle);
                setSurveyContent(_surveyContent);
            }
            setIsSurvey(value);
        } else if (name === "isFlowerPresent") {
            setIsFlowerPresent(value);
        }
    };

    const handleClick = (target) => {
        if (target === "weddingHowCollapse") {
            setWeddingHowCollapse(!weddingHowCollapse);
        } else if (target === "galleryCollapse") {
            setGalleryCollapse(!galleryCollapse);
        } else if (target === "accountCollapse") {
            setAccountCollapse(!accountCollapse);
        } else if (target === "videoCollapse") {
            setVideoCollapse(!videoCollapse);
        } else if (target === "commentCollapse") {
            setCommentCollapse(!commentCollapse);
        } else if (target === "surveyCollapse") {
            setSurveyCollapse(!surveyCollapse);
        } else if (target === "layoutCollapse") {
            setLayoutCollapse(!layoutCollapse);
        } else if (target === "musicCollapse") {
            setMusicCollapse(!musicCollapse);
        } else if (target === "flowerPresentCollapse") {
            setFlowerPresentCollapse(!flowerPresentCollapse);
        } else if (target === "additionCollapse") {
            setAdditionCollapse(!additionCollapse);
        } else if (target === "noticeCollapse") {
            setNoticeCollapse(!noticeCollapse);
        } else if (target === "kakaoCollapse") {
            setKakaoCollapse(!kakaoCollapse);
        } else if (target === "urlCallapse") {
            setUrlCallapse(!urlCallapse);
        }
    };

    const serchMap = (keyword) => {
        setIsKakaoMap(true);

        // 장소 검색 객체를 생성합니다
        var ps = new window.kakao.maps.services.Places();
        // 키워드로 장소를 검색합니다
        ps.keywordSearch(keyword, placesSearchCB);
    };

    const placesSearchCB = (data, status, pagination) => {
        if (status === window.kakao.maps.services.Status.OK) {
            var mapContainer = document.getElementById("kakaoMap"), // 지도를 표시할 div
                mapOption = {
                    center: new window.kakao.maps.LatLng(data[0].y, data[0].x), // 지도의 중심좌표
                    level: 3, // 지도의 확대 레벨
                };

            // 지도를 생성합니다
            map = new window.kakao.maps.Map(mapContainer, mapOption);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
            // LatLngBounds 객체에 좌표를 추가합니다
            var bounds = new window.kakao.maps.LatLngBounds();

            // 지도 중심 좌표 변화 이벤트를 등록한다
            window.kakao.maps.event.addListener(
                map,
                "center_changed",
                function () {
                    if (eventMarker !== null) {
                        eventMarker.setMap(null);
                    }

                    // 마커를 생성합니다
                    eventMarker = new window.kakao.maps.Marker({
                        position: new window.kakao.maps.LatLng(
                            map.getCenter().Ma,
                            map.getCenter().La
                        ),
                    });

                    setLatitude(map.getCenter().Ma);
                    setLongitude(map.getCenter().La);
                    // 마커가 지도 위에 표시되도록 설정합니다
                    eventMarker.setMap(map);
                }
            );

            bounds.extend(new window.kakao.maps.LatLng(data[0].y, data[0].x));
            setLatitude(data[0].y);
            setLongitude(data[0].x);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
            map.setBounds(bounds);
        }
    };

    const getMap = async (lan, lon) => {
        if (lan && lon) {
            await setIsKakaoMap(true);

            var mapContainer = document.getElementById("kakaoMap"), // 지도를 표시할 div
                mapOption = {
                    center: new window.kakao.maps.LatLng(lan, lon), // 지도의 중심좌표
                    level: 3, // 지도의 확대 레벨
                };

            // 지도를 생성합니다
            map = await new window.kakao.maps.Map(mapContainer, mapOption);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
            // LatLngBounds 객체에 좌표를 추가합니다
            var bounds = new window.kakao.maps.LatLngBounds();

            // 지도 중심 좌표 변화 이벤트를 등록한다
            window.kakao.maps.event.addListener(
                map,
                "center_changed",
                function () {
                    if (eventMarker !== null) {
                        eventMarker.setMap(null);
                    }

                    // 마커를 생성합니다
                    eventMarker = new window.kakao.maps.Marker({
                        position: new window.kakao.maps.LatLng(
                            map.getCenter().Ma,
                            map.getCenter().La
                        ),
                    });

                    setLatitude(map.getCenter().Ma);
                    setLongitude(map.getCenter().La);
                    // 마커가 지도 위에 표시되도록 설정합니다
                    eventMarker.setMap(map);
                }
            );

            bounds.extend(new window.kakao.maps.LatLng(lan, lon));

            // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
            map.setBounds(bounds);
        }
    };

    const onChange = (event) => {
        const {
            target: { name, value, checked },
        } = event;

        if (name === "weddingAddress") {
            setWeddingAddress(value);
        } else if (name === "content") {
            setInvatationContent(value);
        } else if (name === "weddingDate") {
            const limitDate = new Date();
            limitDate.setDate(limitDate.getDate() + 210);

            const valueDate = new Date(value);

            if (limitDate > valueDate) {
                setWeddingDate(value);
            } else {
                alert("6개월 이내의 예식일만 선택할 수 있습니다.");
                // return false;
                console.log(value);
            }
        } else if (name === "husbandFamilyName") {
            setHusbandFamilyName(value);
        } else if (name === "husbandName") {
            setHusbandName(value);
        } else if (name === "husbandPhone") {
            setHusbandPhone(value);
        } else if (name === "husbandFirstSon") {
            setHusbandFirstSon(value);
        } else if (name === "husbandPapa") {
            setHusbandPapa(value);
        } else if (name === "husbandPapaPhone") {
            setHusbandPapaPhone(value);
        } else if (name === "husbandMama") {
            setHusbandMama(value);
        } else if (name === "husbandMamaPhone") {
            setHusbandMamaPhone(value);
        } else if (name === "husbandPapaIsDead") {
            setHusbandPapaIsDead(checked);
        } else if (name === "husbandMamaIsDead") {
            setHusbandMamaIsDead(checked);
        } else if (name === "wifeFamilyName") {
            setWifeFamilyName(value);
        } else if (name === "wifeName") {
            setWifeName(value);
        } else if (name === "wifePhone") {
            setWifePhone(value);
        } else if (name === "wifeFirstDau") {
            setWifeFirstDau(value);
        } else if (name === "wifePapa") {
            setWifePapa(value);
        } else if (name === "wifePapaPhone") {
            setWifePapaPhone(value);
        } else if (name === "wifeMama") {
            setWifeMama(value);
        } else if (name === "wifeMamaPhone") {
            setWifeMamaPhone(value);
        } else if (name === "wifePapaIsDead") {
            setWifePapaIsDead(checked);
        } else if (name === "wifeMamaIsDead") {
            setWifeMamaIsDead(checked);
        } else if (name === "isCalendar") {
            setIsCalendar(checked);
        } else if (name === "isDday") {
            setIsDday(checked);
        } else if (name === "isSharu") {
            setIsSharu(checked);
        } else if (name === "isAccountClose") {
            setIsAccountClose(checked);
        } else if (name === "isPhoto") {
            setIsPhoto(checked);
        } else if (name === "autoPlay") {
            setAutoPlay(checked);
            console.log(checked);
        } else if (name === "weddingLocationName") {
            setWeddingLocationName(value);
        } else if (name === "weddingLocationFloor") {
            setWeddingLocationFloor(value);
        } else if (name === "weddingNumber") {
            setWeddingNumber(value);
        } else if (name === "isMap") {
            setIsMap(checked);
        } else if (name === "isMapFixed") {
            setIsMapFixed(checked);
        } else if (name === "accountTitle") {
            setAccountTitle(value);
        } else if (name === "husbandAccountTitle") {
            setHusbandAccountTitle(value);
        } else if (name === "husbandAccount") {
            setHusbandAccount(value);
        } else if (name === "husbandAccountName") {
            setHusbandAccountName(value);
        } else if (name === "husbandAccountBank") {
            setHusbandAccountBank(value);
        } else if (name === "wifeAccount") {
            setWifeAccount(value);
        } else if (name === "wifeAccountTitle") {
            setWifeAccountTitle(value);
        } else if (name === "wifeAccountName") {
            setWifeAccountName(value);
        } else if (name === "wifeAccountBank") {
            setWifeAccountBank(value);
        } else if (name === "husbandPapaAccount") {
            setHusbandPapaAccount(value);
        } else if (name === "husbandPapaAccountTitle") {
            setHusbandPapaAccountTitle(value);
        } else if (name === "husbandPapaAccountName") {
            setHusbandPapaAccountName(value);
        } else if (name === "husbandPapaAccountBank") {
            setHusbandPapaAccountBank(value);
        } else if (name === "husbandMamaAccount") {
            setHusbandMamaAccount(value);
        } else if (name === "husbandMamaAccountTitle") {
            setHusbandMamaAccountTitle(value);
        } else if (name === "husbandMamaAccountName") {
            setHusbandMamaAccountName(value);
        } else if (name === "husbandMamaAccountBank") {
            setHusbandMamaAccountBank(value);
        } else if (name === "wifePapaAccount") {
            setWifePapaAccount(value);
        } else if (name === "wifePapaAccountTitle") {
            setWifePapaAccountTitle(value);
        } else if (name === "wifePapaAccountName") {
            setWifePapaAccountName(value);
        } else if (name === "wifePapaAccountBank") {
            setWifePapaAccountBank(value);
        } else if (name === "wifeMamaAccount") {
            setWifeMamaAccount(value);
        } else if (name === "wifeMamaAccountTitle") {
            setWifeMamaAccountTitle(value);
        } else if (name === "wifeMamaAccountName") {
            setWifeMamaAccountName(value);
        } else if (name === "wifeMamaAccountBank") {
            setWifeMamaAccountBank(value);
        } else if (name === "noticeTitle") {
            setNoticeTitle(value);
        } else if (name === "noticeContent") {
            setNoticeContent(value);
        } else if (name === "surveyTitle") {
            setSurveyTitle(value);
        } else if (name === "surveyContent") {
            setSurveyContent(value);
        } else if (name === "noticeURL") {
            setNoticeURL(value);
        } else if (name === "noticeButtomTitle") {
            setNoticeButtomTitle(value);
        } else if (name === "kakaoTitle") {
            setKakaoTitle(value);
        } else if (name === "kakaoDes") {
            setKakaoDes(value);
        } else if (name === "metaTitle") {
            setMetaTitle(value);
        } else if (name === "metaDes") {
            setMetaDes(value);
        } else if (name === "weddingHowTitle_1") {
            setWeddingHowTitle_1(value);
        } else if (name === "weddingHowTitle_2") {
            setWeddingHowTitle_2(value);
        } else if (name === "weddingHowTitle_3") {
            setWeddingHowTitle_3(value);
        } else if (name === "weddingHowTitle_4") {
            setWeddingHowTitle_4(value);
        } else if (name === "weddingHowContent_1") {
            setWeddingHowContent_1(value);
        } else if (name === "weddingHowContent_2") {
            setWeddingHowContent_2(value);
        } else if (name === "weddingHowContent_3") {
            setWeddingHowContent_3(value);
        } else if (name === "weddingHowContent_4") {
            setWeddingHowContent_4(value);
        } else if (name === "masterPassword") {
            if (value.length < 13) {
                setMasterPassword(value);
            }
        } else if (name === "masterPasswordCheck") {
            if (value.length < 13) {
                setMasterPasswordCheck(value);
            }
        } else if (name === "customVideo") {
            setCustomVideo(value);
        }
    };

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress +=
                    extraAddress !== ""
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            // fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }

        serchMap(fullAddress);
        setWeddingAddress(fullAddress);
        setIsOpenPostcode(false);
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1000,
                1000,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const dataURLtoFile = (dataurl, fileName) => {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    };

    function blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        // theBlob.lastModifiedDate = new Date();
        // theBlob.name = fileName;
        return theBlob;
    }

    const onFileChange = (event, index) => {
        const {
            target: { files, name },
        } = event;

        const theFile = files[0];
        const reader = new FileReader();

        reader.onloadend = async (finishedEvent) => {
            // console.log(finishedEvent.target.result);
            const {
                currentTarget: { result },
            } = finishedEvent;

            if (name === "mainImg") {
                try {
                    new Compressor(theFile, {
                        quality: 0.8,
                        maxWidth: 3000,
                        maxHeight: 3000,
                        success(res) {
                            setMainImg(result);
                            const compressedFile = blobToFile(res, name);
                            setUploadMain(compressedFile);
                        },
                        error(err) {
                            console.log(err.message);
                        },
                    });
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryArray") {
                try {
                    new Compressor(theFile, {
                        quality: 0.8,
                        maxWidth: 3000,
                        maxHeight: 3000,
                        success(res) {
                            const compressedFile = blobToFile(res, name);
                            if (index !== -1 && index !== 0) {
                                var moGalleryArray = [...galleryArray];
                                var moUploadGalleryArray = [
                                    ...uploadGalleryArray,
                                ];
                                moGalleryArray.splice(index, 1, result);
                                moUploadGalleryArray.splice(
                                    index,
                                    1,
                                    compressedFile
                                );

                                setGalleryArray(moGalleryArray);
                                setUploadGalleryArray(moUploadGalleryArray);

                                setGalleryIndex(-1);
                            } else {
                                if (index == 0) {
                                    var moGalleryArray = [...galleryArray];
                                    var moUploadGalleryArray = [
                                        ...uploadGalleryArray,
                                    ];
                                    moGalleryArray.splice(index, 1, result);
                                    moUploadGalleryArray.splice(
                                        index,
                                        1,
                                        compressedFile
                                    );

                                    setGalleryArray(moGalleryArray);
                                    setUploadGalleryArray(moUploadGalleryArray);

                                    setGalleryIndex(-1);
                                } else {
                                    setUploadGalleryArray((oldArray) => [
                                        ...oldArray,
                                        compressedFile,
                                    ]);

                                    setGalleryArray((oldArray) => [
                                        ...oldArray,
                                        result,
                                    ]);
                                }
                            }
                        },
                        error(err) {
                            console.log(err.message);
                        },
                    });
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryArrayBox") {
                try {
                    new Compressor(theFile, {
                        quality: 0.8,
                        maxWidth: 3000,
                        maxHeight: 3000,
                        success(res) {
                            const compressedFile = blobToFile(res, name);

                            setUploadGalleryArray((oldArray) => [
                                ...oldArray,
                                compressedFile,
                            ]);
                            setGalleryArray((oldArray) => [
                                ...oldArray,
                                result,
                            ]);
                        },
                        error(err) {
                            console.log(err.message);
                        },
                    });
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "thumbImg") {
                try {
                    // console.log(event.target.files[0]);

                    const compressedFile = await resizeFile(theFile);
                    setThumbImg(result);

                    setUploadThumb(compressedFile);
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "metaImg") {
                try {
                    // console.log(event.target.files[0]);

                    const compressedFile = await resizeFile(theFile);
                    setMetaImg(result);

                    setUploadMeta(compressedFile);
                } catch (err) {
                    console.log(err);
                }
            }
        };
        theFile && reader.readAsDataURL(theFile);
    };

    const removeArray = (index) => {
        const temp = [...galleryArray];
        temp.splice(index, 1);
        setGalleryArray(temp);
        setUploadGalleryArray(temp);
    };

    const submitFunc = async () => {
        setIsSumbmit(true);
        console.log("submitFunc");

        if (husbandName === "") {
            alert("신랑 성함을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (husbandFirstSon === "") {
            alert("신랑 서열을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (wifeName === "") {
            alert("신부 성함을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (wifeFirstDau === "") {
            alert("신부 서열을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (invatationContent === "") {
            alert("인삿말을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (weddingAddress != "" && weddingLocationName === "") {
            alert("예식장 명을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (
            isComment === "1" &&
            masterPassword !== masterPasswordCheck
        ) {
            alert("마스터 비밀번호를 다시 확인해주세요");
            setIsSumbmit(false);

            return false;
        } else if (isComment === "1" && masterPassword.length < 4) {
            alert("마스터 비밀번호는 4글자 이상 설정가능합니다.");
            setIsSumbmit(false);

            return false;
        } else if (isSurvey === "1" && surveyTitle == "") {
            alert("RSVP 설문지에 표기될 제목을 입력해주세요");
            setIsSumbmit(false);

            return false;
        } else if (isSurvey === "1" && surveyContent == "") {
            alert("RSVP 설문지에 표기될 내용을 입력해주세요");
            setIsSumbmit(false);

            return false;
        }

        let submitMainImg = "";
        let submitGallery = uploadGalleryArray;
        let submitThumb = "";
        let submitMeta = "";
        let galleryOriginArr = galleryArray;
        let originUploadMain = originMainImg;
        let originUploadThumb = originThumbImg;
        let originUploadMeta = originMetaImg;

        if (uploadMain) {
            if (originUploadMain) {
                const originImageUrl = originUploadMain.split("/");
                const originImageName =
                    originImageUrl[originImageUrl.length - 1];
                const dirName = originImageUrl[originImageUrl.length - 2];
                // s3 delete
                const deleteConfig = {
                    bucketName: "self-cryucard",
                    dirName: dirName /* optional */,
                    region: "ap-northeast-2",
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey:
                        process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                };
                const ReactS3ClientDelete = new S3(deleteConfig);
                // s3 delete
                await ReactS3ClientDelete.deleteFile(originImageName)
                    .then((response) => console.log(response))
                    .catch((err) => console.error(err));
            }

            await ReactS3Client.uploadFile(uploadMain, `${userId}-${uuidv4()}`)
                .then((data) => {
                    submitMainImg = data.location;
                })
                .catch((err) => console.error(err));
        }

        if (uploadThumb) {
            if (originUploadThumb) {
                const originImageUrl = originUploadThumb.split("/");
                const originImageName =
                    originImageUrl[originImageUrl.length - 1];
                const dirName = originImageUrl[originImageUrl.length - 2];
                // s3 delete
                const deleteConfig = {
                    bucketName: "self-cryucard",
                    dirName: dirName /* optional */,
                    region: "ap-northeast-2",
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey:
                        process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                };
                const ReactS3ClientDelete = new S3(deleteConfig);
                // s3 delete
                await ReactS3ClientDelete.deleteFile(originImageName)
                    .then((response) => console.log(response))
                    .catch((err) => console.error(err));
            }

            await ReactS3Client.uploadFile(uploadThumb, `${userId}-${uuidv4()}`)
                .then((data) => {
                    submitThumb = data.location;
                })
                .catch((err) => console.error(err));
        }

        if (uploadMeta) {
            if (originUploadMeta) {
                const originImageUrl = originUploadMeta.split("/");
                const originImageName =
                    originImageUrl[originImageUrl.length - 1];
                const dirName = originImageUrl[originImageUrl.length - 2];
                // s3 delete
                const deleteConfig = {
                    bucketName: "self-cryucard",
                    dirName: dirName /* optional */,
                    region: "ap-northeast-2",
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey:
                        process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                };
                const ReactS3ClientDelete = new S3(deleteConfig);
                // s3 delete
                await ReactS3ClientDelete.deleteFile(originImageName)
                    .then((response) => console.log(response))
                    .catch((err) => console.error(err));
            }

            await ReactS3Client.uploadFile(uploadMeta, `${userId}-${uuidv4()}`)
                .then((data) => {
                    submitMeta = data.location;
                })
                .catch((err) => console.error(err));
        }
        if (uploadGalleryArray) {
            // loop 비동기 처리

            await Promise.all(
                uploadGalleryArray.map(async (arr, index) => {
                    console.log(arr);
                    if (typeof arr === "object") {
                        if (index < galleryOriginArr.length) {
                            const originImageUrl =
                                galleryOriginArr[index].split("/");
                            const originImageName =
                                originImageUrl[originImageUrl.length - 1];
                            const dirName =
                                originImageUrl[originImageUrl.length - 2];
                            // s3 delete
                            const deleteConfig = {
                                bucketName: "self-cryucard",
                                dirName: dirName /* optional */,
                                region: "ap-northeast-2",
                                accessKeyId:
                                    process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                                secretAccessKey:
                                    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                            };
                            const ReactS3ClientDelete = new S3(deleteConfig);
                            // s3 delete
                            ReactS3ClientDelete.deleteFile(originImageName)
                                .then((response) => console.log(response))
                                .catch((err) => console.error(err));
                        }
                        await ReactS3Client.uploadFile(
                            arr,
                            `${userId}-${uuidv4()}`
                        )
                            .then((data) => {
                                submitGallery.splice(index, 1, data.location);
                            })
                            .catch((err) => console.error(err));
                    } else {
                        // submitGallery.push(arr);
                    }
                })
            );
            // loop 비동기 처리
        }

        const content = await {
            mainImg: submitMainImg ? submitMainImg : mainImg,
            gallery: submitGallery === "" ? [] : submitGallery,
            thumbImg: submitThumb ? submitThumb : thumbImg,
            metaImg: submitMeta ? submitMeta : metaImg,
            husbandFamilyName,
            husbandName,
            husbandPhone,
            husbandFirstSon,
            husbandPapa,
            husbandPapaPhone,
            husbandMama,
            husbandMamaPhone,
            husbandPapaIsDead,
            husbandMamaIsDead,
            wifeFamilyName,
            wifeName,
            wifePhone,
            wifeFirstDau,
            wifePapa,
            wifePapaPhone,
            wifeMama,
            wifeMamaPhone,
            wifePapaIsDead,
            wifeMamaIsDead,
            invatationContent: invatationContent
                .replace(/</g, "〈")
                .replace(/>/g, "〉")
                .replace(/\n/g, "<br/>"),
            weddingDate,
            isCalendar,
            isDday,
            weddingAddress,
            weddingLocationName,
            weddingLocationFloor,
            weddingNumber,
            longitude,
            latitude,
            isMap,
            isMapFixed,
            weddingHow,
            accountVer: accountVer * 1,
            galleryVer: galleryVer * 1,
            accountTitle: accountTitle
                ? accountTitle
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : accountTitle,
            isHusbandAccount,
            isWifeAccount,
            isHusbandPapaAccount,
            isHusbandMamaAccount,
            isWifePapaAccount,
            isWifeMamaAccount,
            husbandAccountTitle,
            husbandAccount,
            husbandAccountName,
            husbandAccountBank,
            wifeAccountTitle,
            wifeAccount,
            wifeAccountName,
            wifeAccountBank,
            husbandPapaAccountTitle,
            husbandPapaAccount,
            husbandPapaAccountName,
            husbandPapaAccountBank,
            husbandMamaAccountTitle,
            husbandMamaAccount,
            husbandMamaAccountName,
            husbandMamaAccountBank,
            wifePapaAccountTitle,
            wifePapaAccount,
            wifePapaAccountName,
            wifePapaAccountBank,
            wifeMamaAccountTitle,
            wifeMamaAccount,
            wifeMamaAccountName,
            wifeMamaAccountBank,
            videoUrl,
            fontSize: fontSize * 1,
            fontVer: fontVer * 1,
            effect: effect * 1,
            bgm: bgm * 1,
            isSharu,
            isPhoto,
            rootCollapse,
            weddingHowCollapse,
            galleryCollapse,
            accountCollapse,
            flowerPresentCollapse,
            noticeCollapse,
            videoCollapse,
            musicCollapse,
            additionCollapse,
            kakaoTitle,
            kakaoDes: kakaoDes
                ? kakaoDes
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : kakaoDes,
            metaTitle,
            metaDes: metaDes
                ? metaDes
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : metaDes,
            noticeTitle,
            noticeContent: noticeContent
                ? noticeContent
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : noticeContent,
            surveyTitle,
            surveyContent: surveyContent
                ? surveyContent
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : surveyContent,
            noticeButtomTitle,
            noticeURL,
            weddingHowTitle_1,
            weddingHowTitle_2,
            weddingHowTitle_3,
            weddingHowTitle_4,
            weddingHowContent_1: weddingHowContent_1
                ? weddingHowContent_1
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : weddingHowContent_1,
            weddingHowContent_2: weddingHowContent_2
                ? weddingHowContent_2
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : weddingHowContent_2,
            weddingHowContent_3: weddingHowContent_3
                ? weddingHowContent_3
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : weddingHowContent_3,
            weddingHowContent_4: weddingHowContent_4
                ? weddingHowContent_4
                      .replace(/</g, "〈")
                      .replace(/>/g, "〉")
                      .replace(/\n/g, "<br/>")
                : weddingHowContent_4,
            husbandKakao,
            wifeKakao,
            autoPlay,
            isAccountClose,

            commentCollapse,
            surveyCollapse,
            isComment: isComment === "1" ? true : false,
            isSurvey: isSurvey === "1" ? true : false,
            masterPassword,
            customVideo,
            mainEffect,
            layoutCollapse,
            layoutArr,
            isFlowerPresent: isFlowerPresent === "1" ? true : false,
        };

        await dbService
            .collection("letters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                alert("수정이 완료되었습니다.");
                window.location.href = "/#/hisidlist";
            });
    };

    // qrcode decode func
    const handleErrorFile = (error) => {
        console.log(error);
    };

    const husbandHandleScanFile = (result) => {
        if (result) {
            setHusbandKakao(result);
            setIsHusbandKakaoModal(false);
        } else {
            setHusbandKakao("");
            alert("카카오페이가 올바르지 않습니다");
        }
    };

    const wifeHandleScanFile = (result) => {
        if (result) {
            setWifeKakao(result);
            setIsWifeKakaoModal(false);
        } else {
            setWifeKakao("");
            alert("카카오페이가 올바르지 않습니다");
        }
    };

    const onScanFile = () => {
        qrRef.current.openImageDialog();
    };
    // qrcode decode func

    useEffect(() => {
        setWeddingDate(getToday);
        getEmail();
    }, []);

    return (
        <div>
            {isLoading ? (
                <div
                    style={{
                        backgroundColor: "rgb(238 238 238)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#fff",
                            maxWidth: 480,
                        }}
                    >
                        {/* 헤더 */}
                        <HidiHeader userId={userId} />

                        {/* 헤더 */}

                        {/* 메인사진 */}
                        <div>
                            <div style={{ padding: "20px 20px 20px" }}>
                                <p
                                    style={{
                                        padding: 0,
                                        fontSize: 20,
                                        fontWeight: "bold",
                                        lineHeight: "40px",
                                    }}
                                >
                                    메인사진을 선택해주세요 📸
                                </p>
                                <p style={{ fontSize: 12, color: "#777" }}>
                                    가로, 세로에 상관없이 추가가능합니다.
                                </p>
                            </div>

                            <div style={{ margin: 6, padding: "0 14px" }}>
                                <div
                                    style={{
                                        margin: 6,
                                        display: "flex",
                                    }}
                                >
                                    {mainImg !== "" ? (
                                        <div
                                            style={{
                                                width: 200,
                                                height: 200,
                                                border: "1px solid #000",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: "#000",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    src={mainImg}
                                                    style={{
                                                        maxHeight: 200,
                                                        maxWidth: 200,
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(0, 0, 0,0.6)",
                                                        height: 50,
                                                        width: 200,
                                                        position: "absolute",
                                                        bottom: 0,
                                                        display: "flex",
                                                    }}
                                                >
                                                    <label
                                                        for="mainImg"
                                                        style={{
                                                            flex: 1,
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <CreateIcon
                                                            style={{
                                                                color: "#fff",
                                                                fontSize: 22,
                                                            }}
                                                        />
                                                    </label>
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                        onClick={() =>
                                                            setMainImg("")
                                                        }
                                                    >
                                                        <CloseIcon
                                                            style={{
                                                                color: "#fff",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <label
                                            for="mainImg"
                                            style={{
                                                width: 200,
                                                height: 200,
                                                border: "1px dashed rgb(206, 212, 218)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: 70,
                                                    fontWeight: "100",
                                                    color: "#999",
                                                }}
                                            >
                                                +
                                            </span>
                                        </label>
                                    )}

                                    <input
                                        id="mainImg"
                                        name="mainImg"
                                        type="file"
                                        accept="image/*"
                                        onChange={onFileChange}
                                        style={{ width: 1, height: 1 }}
                                    />
                                </div>
                            </div>

                            <div style={{ padding: "0 30px" }}>
                                <p
                                    style={{
                                        fontSize: 12,
                                        color: "#333",
                                        fontWeight: "bold",
                                        paddingBottom: 5,
                                        paddingTop: 5,
                                    }}
                                >
                                    메인 사진 효과
                                </p>
                                <select
                                    onChange={(e) => {
                                        setMainEffect(e.target.value);
                                    }}
                                    value={mainEffect}
                                >
                                    <option value="">없음</option>
                                    <option value="wave">물결효과</option>
                                </select>
                            </div>
                        </div>
                        {/* 메인사진 */}

                        <div style={{ padding: "0px 14px" }}>
                            <form
                                className={classes.root}
                                noValidate
                                autoComplete="off"
                            >
                                <div style={{ padding: "20px 0px 20px" }}>
                                    <p
                                        style={{
                                            padding: 0,
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            lineHeight: "40px",
                                        }}
                                    >
                                        신랑측 정보를 알려주세요 🤵🏻
                                    </p>
                                    <p style={{ fontSize: 12, color: "#777" }}>
                                        기입하시는 내용만 청첩장에 표기됩니다
                                    </p>
                                </div>
                                <div style={{ display: "flex", width: 300 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="신랑 성"
                                        variant="outlined"
                                        name="husbandFamilyName"
                                        value={husbandFamilyName}
                                        onChange={onChange}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="신랑 이름"
                                        variant="outlined"
                                        style={{ marginLeft: 5 }}
                                        name="husbandName"
                                        value={husbandName}
                                        onChange={onChange}
                                    />
                                </div>
                                <TextField
                                    id="outlined-basic"
                                    label="신랑 연락처"
                                    variant="outlined"
                                    name="husbandPhone"
                                    value={husbandPhone}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신랑 서열 (장남, 차남, 아들)"
                                    variant="outlined"
                                    name="husbandFirstSon"
                                    value={husbandFirstSon}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신랑 아버님 성함"
                                    variant="outlined"
                                    name="husbandPapa"
                                    value={husbandPapa}
                                    onChange={onChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            name="husbandPapaIsDead"
                                            checked={husbandPapaIsDead}
                                            onChange={onChange}
                                        />
                                    }
                                    label="고인이시면 체크하여주세요"
                                    id="custom_check"
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신랑 아버님 연락처"
                                    variant="outlined"
                                    name="husbandPapaPhone"
                                    value={husbandPapaPhone}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신랑 어머님 성함"
                                    variant="outlined"
                                    name="husbandMama"
                                    value={husbandMama}
                                    onChange={onChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            name="husbandMamaIsDead"
                                            checked={husbandMamaIsDead}
                                            onChange={onChange}
                                        />
                                    }
                                    label="고인이시면 체크하여주세요"
                                    id="custom_check"
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신랑 어머님 연락처"
                                    variant="outlined"
                                    name="husbandMamaPhone"
                                    value={husbandMamaPhone}
                                    onChange={onChange}
                                />
                                <div style={{ padding: "20px 0px 20px" }}>
                                    <p
                                        style={{
                                            padding: 0,
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            lineHeight: "40px",
                                        }}
                                    >
                                        신부측 정보를 알려주세요 👰🏻
                                    </p>
                                    <p style={{ fontSize: 12, color: "#777" }}>
                                        기입하시는 내용만 청첩장에 표기됩니다
                                    </p>
                                </div>

                                <div style={{ display: "flex", width: 300 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="신부 성"
                                        variant="outlined"
                                        name="wifeFamilyName"
                                        value={wifeFamilyName}
                                        onChange={onChange}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="신부 이름"
                                        variant="outlined"
                                        style={{ marginLeft: 5 }}
                                        name="wifeName"
                                        value={wifeName}
                                        onChange={onChange}
                                    />
                                </div>
                                <TextField
                                    id="outlined-basic"
                                    label="신부 연락처"
                                    variant="outlined"
                                    name="wifePhone"
                                    value={wifePhone}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신부 서열 (장녀, 차녀, 딸)"
                                    variant="outlined"
                                    name="wifeFirstDau"
                                    value={wifeFirstDau}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신부 아버님 성함"
                                    variant="outlined"
                                    name="wifePapa"
                                    value={wifePapa}
                                    onChange={onChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            name="wifePapaIsDead"
                                            checked={wifePapaIsDead}
                                            onChange={onChange}
                                        />
                                    }
                                    label="고인이시면 체크하여주세요"
                                    id="custom_check"
                                />

                                <TextField
                                    id="outlined-basic"
                                    label="신부 아버님 연락처"
                                    variant="outlined"
                                    name="wifePapaPhone"
                                    value={wifePapaPhone}
                                    onChange={onChange}
                                />

                                <TextField
                                    id="outlined-basic"
                                    label="신부 어머님 성함"
                                    variant="outlined"
                                    name="wifeMama"
                                    value={wifeMama}
                                    onChange={onChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            name="wifeMamaIsDead"
                                            checked={wifeMamaIsDead}
                                            onChange={onChange}
                                        />
                                    }
                                    label="고인이시면 체크하여주세요"
                                    id="custom_check"
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="신부 어머님 연락처"
                                    variant="outlined"
                                    name="wifeMamaPhone"
                                    value={wifeMamaPhone}
                                    onChange={onChange}
                                />

                                {/* 인삿말 */}
                                <div>
                                    <div style={{ padding: "20px 0px 20px" }}>
                                        <p
                                            style={{
                                                padding: 0,
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                lineHeight: "40px",
                                            }}
                                        >
                                            인삿말을 알려주세요 📝
                                        </p>
                                        <p
                                            style={{
                                                fontSize: 12,
                                                color: "#777",
                                            }}
                                        >
                                            원하시는 인삿말으로 직접 추가도
                                            가능합니다
                                        </p>
                                    </div>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="인삿말"
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                        variant="outlined"
                                        name={"content"}
                                        value={invatationContent}
                                        onChange={onChange}
                                        style={{ fontSize: 14 }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 20 }}
                                        onClick={() => {
                                            setIsOpenModal(true);
                                        }}
                                    >
                                        샘플 인삿말 보기
                                    </Button>
                                    <Modal
                                        isOpen={isOpenModal}
                                        style={customStyles}
                                        shouldCloseOnOverlayClick
                                        onRequestClose={() =>
                                            setIsOpenModal(false)
                                        }
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <span>
                                                    원하시는 인삿말을 골라주세요
                                                </span>
                                                <CloseIcon
                                                    onClick={() =>
                                                        setIsOpenModal(false)
                                                    }
                                                />
                                            </div>

                                            {invatationContentArray.map(
                                                (item) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                border: "1px solid #333",
                                                                marginTop: 20,
                                                                padding: 10,
                                                                borderRadius: 5,
                                                                fontSize: 14,
                                                            }}
                                                            onClick={() => {
                                                                setInvatationContent(
                                                                    item.content
                                                                );
                                                                setIsOpenModal(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            {ReactHtmlParser(
                                                                item.content.replace(
                                                                    /\n/g,
                                                                    "<br/>"
                                                                )
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </Modal>
                                </div>
                                {/* 인삿말 */}

                                <div style={{ padding: "20px 0px 20px" }}>
                                    <p
                                        style={{
                                            padding: 0,
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            lineHeight: "40px",
                                        }}
                                    >
                                        예식장 위치와
                                        <br />
                                        예식일자를 알려주세요 🗓
                                    </p>
                                </div>

                                <TextField
                                    id="datetime-local"
                                    type="datetime-local"
                                    name="weddingDate"
                                    label="예식일자"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={weddingDate}
                                    onChange={onChange}
                                />

                                <div style={{ marginTop: 10 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                }
                                                style={{ paddingLeft: 0 }}
                                            />
                                        }
                                        label="달력 표시"
                                        id="custom_check"
                                        name="isCalendar"
                                        checked={isCalendar}
                                        onChange={onChange}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                }
                                            />
                                        }
                                        label="디데이 표시"
                                        id="custom_check"
                                        name="isDday"
                                        checked={isDday}
                                        onChange={onChange}
                                    />
                                </div>

                                <TextField
                                    id="outlined-basic"
                                    label="예식장 주소"
                                    name="weddingAddress"
                                    variant="outlined"
                                    value={weddingAddress}
                                    onBlur={() => {
                                        // serchMap(weddingAddress);
                                        // console.log("blur");
                                    }}
                                    onClick={() => {
                                        setIsOpenPostcode(true);
                                    }}
                                    onChange={onChange}
                                />

                                <Modal
                                    isOpen={isOpenPostcode}
                                    style={customStyles}
                                    shouldCloseOnOverlayClick
                                    onRequestClose={() =>
                                        setIsOpenPostcode(false)
                                    }
                                >
                                    <DaumPostcode onComplete={handleComplete} />
                                </Modal>

                                {/* 지도 */}

                                {isKakaoMap ? (
                                    <div>
                                        <div
                                            id={`kakaoMap`}
                                            style={{
                                                width: "100%",
                                                height: "300px",
                                                maxWidth: 300,
                                            }}
                                        ></div>

                                        <div style={{ marginTop: 10 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                        }
                                                        style={{
                                                            paddingLeft: 0,
                                                        }}
                                                    />
                                                }
                                                label="청첩장 내 지도를 표시합니다"
                                                id="custom_check"
                                                name="isMap"
                                                checked={isMap}
                                                onChange={onChange}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                        }
                                                        style={{
                                                            paddingLeft: 0,
                                                        }}
                                                    />
                                                }
                                                label="지도가 움직이지 않도록 고정합니다"
                                                id="custom_check"
                                                name="isMapFixed"
                                                checked={isMapFixed}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    false
                                )}

                                {/* 지도 */}

                                <TextField
                                    id="outlined-basic"
                                    label="예식장 명"
                                    variant="outlined"
                                    name="weddingLocationName"
                                    value={weddingLocationName}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="예식장 층과 홀"
                                    variant="outlined"
                                    name="weddingLocationFloor"
                                    value={weddingLocationFloor}
                                    onChange={onChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="예식장 연락처 (ex.02-000-0000)"
                                    variant="outlined"
                                    name="weddingNumber"
                                    value={weddingNumber}
                                    onChange={onChange}
                                />
                                {/* 오시는길 추가 */}
                                <div>
                                    <ListItem
                                        name="weddingHowCollapse"
                                        button
                                        onClick={() =>
                                            handleClick("weddingHowCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {weddingHowCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="오시는 길 🏃‍♀️"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={weddingHowCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div>
                                            <div style={{ marginTop: 20 }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label="교통수단1 (지하철, 자가용, 버스)"
                                                    variant="outlined"
                                                    name="weddingHowTitle_1"
                                                    value={weddingHowTitle_1}
                                                    onChange={onChange}
                                                />

                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="오시는길 내용"
                                                    multiline
                                                    rows={4}
                                                    defaultValue=""
                                                    variant="outlined"
                                                    style={{ marginTop: 15 }}
                                                    name="weddingHowContent_1"
                                                    value={weddingHowContent_1}
                                                    onChange={onChange}
                                                />
                                            </div>

                                            <div style={{ marginTop: 20 }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label="교통수단2 (지하철, 자가용, 버스)"
                                                    variant="outlined"
                                                    name="weddingHowTitle_2"
                                                    value={weddingHowTitle_2}
                                                    onChange={onChange}
                                                />

                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="오시는길 내용"
                                                    multiline
                                                    rows={4}
                                                    defaultValue=""
                                                    variant="outlined"
                                                    style={{ marginTop: 15 }}
                                                    name="weddingHowContent_2"
                                                    value={weddingHowContent_2}
                                                    onChange={onChange}
                                                />
                                            </div>

                                            <div style={{ marginTop: 20 }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label="교통수단3 (지하철, 자가용, 버스)"
                                                    variant="outlined"
                                                    name="weddingHowTitle_3"
                                                    value={weddingHowTitle_3}
                                                    onChange={onChange}
                                                />

                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="오시는길 내용"
                                                    multiline
                                                    rows={4}
                                                    defaultValue=""
                                                    variant="outlined"
                                                    style={{ marginTop: 15 }}
                                                    name="weddingHowContent_3"
                                                    value={weddingHowContent_3}
                                                    onChange={onChange}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                }}
                                            >
                                                <TextField
                                                    id="outlined-basic"
                                                    label="교통수단4 (지하철, 자가용, 버스)"
                                                    variant="outlined"
                                                    name="weddingHowTitle_4"
                                                    value={weddingHowTitle_4}
                                                    onChange={onChange}
                                                />

                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="오시는길 내용"
                                                    multiline
                                                    rows={4}
                                                    defaultValue=""
                                                    variant="outlined"
                                                    style={{ marginTop: 15 }}
                                                    name="weddingHowContent_4"
                                                    value={weddingHowContent_4}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 오시는길 추가 */}

                                {/* 공지사항 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("noticeCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {noticeCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="공지사항 📋"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={noticeCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div>
                                            <div style={{ marginTop: 20 }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label="제목"
                                                    variant="outlined"
                                                    name="noticeTitle"
                                                    value={noticeTitle}
                                                    onChange={onChange}
                                                />

                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="내용"
                                                    multiline
                                                    rows={4}
                                                    defaultValue=""
                                                    variant="outlined"
                                                    style={{ marginTop: 15 }}
                                                    name="noticeContent"
                                                    value={noticeContent}
                                                    onChange={onChange}
                                                />

                                                <p
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#777",
                                                        marginTop: 15,
                                                    }}
                                                >
                                                    (선택) 링크를 추가하시면
                                                    공지사항 아래에 <br />
                                                    링크로 이동가능한 버튼이
                                                    생성됩니다.
                                                    <br />
                                                    <span
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        (예.
                                                        https://www.youtube.com)
                                                    </span>
                                                </p>

                                                <TextField
                                                    id="outlined-basic"
                                                    label="링크 URL"
                                                    variant="outlined"
                                                    name="noticeURL"
                                                    value={noticeURL}
                                                    onChange={onChange}
                                                    style={{ marginTop: 15 }}
                                                />
                                                <TextField
                                                    id="outlined-basic"
                                                    label="링크 버튼 제목"
                                                    variant="outlined"
                                                    name="noticeButtomTitle"
                                                    value={noticeButtomTitle}
                                                    onChange={onChange}
                                                    style={{ marginTop: 15 }}
                                                />
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 공지사항 */}

                                {/* 갤러리 */}
                                <ListItem
                                    button
                                    onClick={() =>
                                        handleClick("galleryCollapse")
                                    }
                                    style={{ paddingLeft: 0 }}
                                >
                                    {galleryCollapse ? (
                                        <CheckBoxIcon
                                            fontSize="small"
                                            style={{ color: "#f50057" }}
                                        />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon
                                            fontSize="small"
                                            color="#333"
                                        />
                                    )}

                                    <ListItemText
                                        primary="갤러리 사진 🎑 (최대 15장)"
                                        style={{
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                        }}
                                    />
                                </ListItem>
                                <Collapse
                                    in={galleryCollapse}
                                    timeout="auto"
                                    unmountOnExit
                                    style={{ width: "100%" }}
                                >
                                    <div>
                                        <div
                                            style={{
                                                borderBottom:
                                                    "1px solid #9f9f9f",
                                                paddingBottom: 10,
                                                marginBottom: 20,
                                                width: 320,
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    paddingBottom: 10,
                                                }}
                                            >
                                                갤러리 형식
                                            </p>
                                            <RadioGroup
                                                aria-label="galleryVer"
                                                name="galleryVer"
                                                value={galleryVer}
                                                onChange={radioChange}
                                            >
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio />}
                                                    label="슬라이드형 갤러리"
                                                />

                                                <FormControlLabel
                                                    value="2"
                                                    control={<Radio />}
                                                    label="앨범형 갤러리"
                                                />
                                            </RadioGroup>
                                        </div>

                                        {galleryArray.map((item, index) => {
                                            return (
                                                <div
                                                    style={{
                                                        margin: 6,
                                                        display: "inline-block",
                                                        width: 150,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: 150,
                                                            height: 150,
                                                            border: "1px solid #000",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    "#000",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src={item}
                                                                style={{
                                                                    maxHeight: 150,
                                                                    maxWidth: 150,
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        "rgb(0, 0, 0,0.6)",
                                                                    height: 50,
                                                                    width: 150,
                                                                    position:
                                                                        "absolute",
                                                                    bottom: 0,
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <label
                                                                    for="galleryArray"
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                    onClick={() =>
                                                                        setGalleryIndex(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <CreateIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontSize: 22,
                                                                        }}
                                                                    />
                                                                </label>
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                    onClick={() =>
                                                                        removeArray(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <input
                                                        id="galleryArray"
                                                        name="galleryArray"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                            onFileChange(
                                                                e,
                                                                galleryIndex
                                                            );
                                                        }}
                                                        style={{
                                                            width: 1,
                                                            height: 1,
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                        {galleryArray.length < 15 ? (
                                            <div
                                                style={{
                                                    margin: 6,
                                                    display: "inline-block",
                                                    width: 150,
                                                }}
                                            >
                                                <label
                                                    for="galleryArrayBox"
                                                    style={{
                                                        width: 150,
                                                        height: 150,
                                                        border: "1px dashed rgb(206, 212, 218)",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: 70,
                                                            fontWeight: "100",
                                                            color: "#999",
                                                        }}
                                                    >
                                                        +
                                                    </span>
                                                </label>

                                                <input
                                                    id="galleryArrayBox"
                                                    name="galleryArrayBox"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        onFileChange(e);
                                                    }}
                                                    style={{
                                                        width: 1,
                                                        height: 1,
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            false
                                        )}
                                    </div>
                                </Collapse>
                                {/* 갤러리 */}

                                {/* 계좌번호 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("accountCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {accountCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="계좌번호 💌"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={accountCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    borderBottom:
                                                        "1px solid #9f9f9f",
                                                    padding: "25px 0",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                        paddingBottom: 10,
                                                    }}
                                                >
                                                    계좌번호 형식
                                                </p>
                                                <RadioGroup
                                                    aria-label="accountVer"
                                                    name="accountVer"
                                                    value={accountVer}
                                                    onChange={radioChange}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="아코디언형 계좌번호"
                                                    />
                                                    {accountVer === "1" ? (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    icon={
                                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                                    }
                                                                    checkedIcon={
                                                                        <CheckBoxIcon fontSize="small" />
                                                                    }
                                                                    name="isAccountClose"
                                                                    checked={
                                                                        isAccountClose
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    style={{
                                                                        paddingLeft: 0,
                                                                    }}
                                                                />
                                                            }
                                                            label="계좌번호 접힌 상태로 표시"
                                                            id="custom_check"
                                                        />
                                                    ) : (
                                                        false
                                                    )}

                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio />}
                                                        label="팝업형 계좌번호"
                                                    />
                                                </RadioGroup>
                                            </div>

                                            {/* 계좌 선택 섹션 */}
                                            <div
                                                style={{
                                                    borderBottom:
                                                        "1px solid #9f9f9f",
                                                    padding: "25px 0",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                        paddingBottom: 20,
                                                    }}
                                                >
                                                    추가하실 계좌를
                                                    선택하여주세요
                                                </p>

                                                <div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 80,
                                                                flex: 1,
                                                                backgroundColor:
                                                                    isHusbandAccount
                                                                        ? "#f78585"
                                                                        : "#f9f9f9",
                                                                border: isHusbandAccount
                                                                    ? "1px solid #f78585"
                                                                    : "1px solid #333",
                                                                borderRadius: 5,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                color: !isHusbandAccount
                                                                    ? "#333"
                                                                    : "#fff",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setIsHusbandAccount(
                                                                    !isHusbandAccount
                                                                );
                                                                setHusbandAccount(
                                                                    ""
                                                                );
                                                                setHusbandAccountTitle(
                                                                    "신랑측"
                                                                );
                                                                setHusbandAccountBank(
                                                                    ""
                                                                );
                                                                setHusbandAccountName(
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            <span>신랑</span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                height: 80,
                                                                flex: 1,
                                                                backgroundColor:
                                                                    isWifeAccount
                                                                        ? "#f78585"
                                                                        : "#f9f9f9",
                                                                border: isWifeAccount
                                                                    ? "1px solid #f78585"
                                                                    : "1px solid #333",
                                                                marginLeft: 20,
                                                                borderRadius: 5,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                color: !isWifeAccount
                                                                    ? "#333"
                                                                    : "#fff",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setIsWifeAccount(
                                                                    !isWifeAccount
                                                                );
                                                                setWifeAccountTitle(
                                                                    "신부측"
                                                                );
                                                                setWifeAccount(
                                                                    ""
                                                                );
                                                                setWifeAccountBank(
                                                                    ""
                                                                );
                                                                setWifeAccountName(
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            <span>신부</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            marginTop: 20,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 80,
                                                                flex: 1,
                                                                backgroundColor:
                                                                    isHusbandPapaAccount
                                                                        ? "#f78585"
                                                                        : "#f9f9f9",
                                                                border: isHusbandPapaAccount
                                                                    ? "1px solid #f78585"
                                                                    : "1px solid #333",
                                                                borderRadius: 5,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                color: !isHusbandPapaAccount
                                                                    ? "#333"
                                                                    : "#fff",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setIsHusbandPapaAccount(
                                                                    !isHusbandPapaAccount
                                                                );
                                                                setHusbandPapaAccountTitle(
                                                                    "신랑측 아버지"
                                                                );
                                                                setHusbandPapaAccount(
                                                                    ""
                                                                );
                                                                setHusbandPapaAccountBank(
                                                                    ""
                                                                );
                                                                setHusbandPapaAccountName(
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            <span>
                                                                신랑측 아버지
                                                            </span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                height: 80,
                                                                flex: 1,
                                                                backgroundColor:
                                                                    isHusbandMamaAccount
                                                                        ? "#f78585"
                                                                        : "#f9f9f9",
                                                                border: isHusbandMamaAccount
                                                                    ? "1px solid #f78585"
                                                                    : "1px solid #333",
                                                                marginLeft: 20,
                                                                borderRadius: 5,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                color: !isHusbandMamaAccount
                                                                    ? "#333"
                                                                    : "#fff",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setIsHusbandMamaAccount(
                                                                    !isHusbandMamaAccount
                                                                );
                                                                setHusbandMamaAccountTitle(
                                                                    "신랑측 어머니"
                                                                );
                                                                setHusbandMamaAccount(
                                                                    ""
                                                                );
                                                                setHusbandMamaAccountBank(
                                                                    ""
                                                                );
                                                                setHusbandMamaAccountName(
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            <span>
                                                                신랑측 어머니
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            marginTop: 20,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 80,
                                                                flex: 1,
                                                                backgroundColor:
                                                                    isWifePapaAccount
                                                                        ? "#f78585"
                                                                        : "#f9f9f9",
                                                                border: isWifePapaAccount
                                                                    ? "1px solid #f78585"
                                                                    : "1px solid #333",
                                                                borderRadius: 5,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                color: !isWifePapaAccount
                                                                    ? "#333"
                                                                    : "#fff",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setIsWifePapaAccount(
                                                                    !isWifePapaAccount
                                                                );
                                                                setWifePapaAccountTitle(
                                                                    "신부측 아버지"
                                                                );
                                                                setWifePapaAccount(
                                                                    ""
                                                                );
                                                                setWifePapaAccountBank(
                                                                    ""
                                                                );
                                                                setWifePapaAccountName(
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            <span>
                                                                신부측 아버지
                                                            </span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                height: 80,
                                                                flex: 1,
                                                                backgroundColor:
                                                                    isWifeMamaAccount
                                                                        ? "#f78585"
                                                                        : "#f9f9f9",
                                                                border: isWifeMamaAccount
                                                                    ? "1px solid #f78585"
                                                                    : "1px solid #333",
                                                                marginLeft: 20,
                                                                borderRadius: 5,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                color: !isWifeMamaAccount
                                                                    ? "#333"
                                                                    : "#fff",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setIsWifeMamaAccount(
                                                                    !isWifeMamaAccount
                                                                );
                                                                setWifeMamaAccountTitle(
                                                                    "신부측 어머니"
                                                                );
                                                                setWifeMamaAccount(
                                                                    ""
                                                                );
                                                                setWifeMamaAccountBank(
                                                                    ""
                                                                );
                                                                setWifeMamaAccountName(
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            <span>
                                                                신부측 어머니
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 계좌 선택 섹션 */}

                                            <div
                                                style={{
                                                    padding: "25px 0",
                                                }}
                                            >
                                                <TextField
                                                    id="outlined-basic"
                                                    label="제목"
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    name="accountTitle"
                                                    value={accountTitle}
                                                    onChange={onChange}
                                                />
                                            </div>

                                            {/* 신랑측 계좌번호 섹션 */}
                                            {isHusbandAccount ? (
                                                <div
                                                    style={{
                                                        borderTop:
                                                            "1px solid #9f9f9f",
                                                        padding: "25px 0",
                                                    }}
                                                >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="그륩명"
                                                        variant="outlined"
                                                        name="husbandAccountTitle"
                                                        value={
                                                            husbandAccountTitle
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="은행"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandAccountBank"
                                                        value={
                                                            husbandAccountBank
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="계좌번호"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandAccount"
                                                        value={husbandAccount}
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="예금주"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandAccountName"
                                                        value={
                                                            husbandAccountName
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "flex-start",
                                                            }}
                                                            onClick={() => {
                                                                setHusbandKakao(
                                                                    ""
                                                                );
                                                                setIsHusbandKakaoModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            {husbandKakao ? (
                                                                <CheckBoxIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        color: "#feea00",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            )}
                                                            <span
                                                                style={{
                                                                    fontSize: 12,
                                                                    color: "#999",
                                                                    paddingLeft: 7,
                                                                }}
                                                            >
                                                                카카오페이 추가
                                                            </span>
                                                        </div>

                                                        <Modal
                                                            isOpen={
                                                                isHusbandKakaoModal
                                                            }
                                                            style={customStyles}
                                                            shouldCloseOnOverlayClick
                                                            onRequestClose={() =>
                                                                setIsHusbandKakaoModal(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "flex-end",
                                                                    }}
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            color: "#333",
                                                                        }}
                                                                        onClick={() =>
                                                                            setIsHusbandKakaoModal(
                                                                                false
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            kakaopayGuide_1
                                                                        }
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={
                                                                            kakaopayGuide_2
                                                                        }
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    />

                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            justifyContent:
                                                                                "center",
                                                                            backgroundColor:
                                                                                "#feea00",
                                                                            padding: 10,
                                                                            marginTop: 30,
                                                                            borderRadius: 5,
                                                                        }}
                                                                        onClick={
                                                                            onScanFile
                                                                        }
                                                                    >
                                                                        카카오페이
                                                                        등록하기
                                                                    </div>
                                                                </div>
                                                                <QrReader
                                                                    ref={qrRef}
                                                                    delay={300}
                                                                    onError={
                                                                        handleErrorFile
                                                                    }
                                                                    onScan={
                                                                        husbandHandleScanFile
                                                                    }
                                                                    legacyMode
                                                                    style={{
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                />
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            ) : (
                                                false
                                            )}

                                            {/* 신랑측 계좌번호 섹션 */}

                                            {/* 신부측 계좌번호 섹션 */}
                                            {isWifeAccount ? (
                                                <div
                                                    style={{
                                                        borderTop:
                                                            "1px solid #9f9f9f",
                                                        padding: "25px 0",
                                                    }}
                                                >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="그룹명"
                                                        variant="outlined"
                                                        name="wifeAccountTitle"
                                                        value={wifeAccountTitle}
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="은행"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifeAccountBank"
                                                        value={wifeAccountBank}
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="계좌번호"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifeAccount"
                                                        value={wifeAccount}
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="예금주"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifeAccountName"
                                                        value={wifeAccountName}
                                                        onChange={onChange}
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "flex-start",
                                                            }}
                                                            onClick={() => {
                                                                setWifeKakao(
                                                                    ""
                                                                );
                                                                setIsWifeKakaoModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            {wifeKakao ? (
                                                                <CheckBoxIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        color: "#feea00",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            )}
                                                            <span
                                                                style={{
                                                                    fontSize: 12,
                                                                    color: "#999",
                                                                    paddingLeft: 7,
                                                                }}
                                                            >
                                                                카카오페이 추가
                                                            </span>
                                                        </div>

                                                        <Modal
                                                            isOpen={
                                                                isWifeKakaoModal
                                                            }
                                                            style={customStyles}
                                                            shouldCloseOnOverlayClick
                                                            onRequestClose={() =>
                                                                setIsWifeKakaoModal(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "flex-end",
                                                                    }}
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            color: "#333",
                                                                        }}
                                                                        onClick={() =>
                                                                            setIsWifeKakaoModal(
                                                                                false
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            kakaopayGuide_1
                                                                        }
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={
                                                                            kakaopayGuide_2
                                                                        }
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    />

                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            justifyContent:
                                                                                "center",
                                                                            backgroundColor:
                                                                                "#feea00",
                                                                            padding: 10,
                                                                            marginTop: 30,
                                                                            borderRadius: 5,
                                                                        }}
                                                                        onClick={
                                                                            onScanFile
                                                                        }
                                                                    >
                                                                        카카오페이
                                                                        등록하기
                                                                    </div>
                                                                </div>
                                                                <QrReader
                                                                    ref={qrRef}
                                                                    delay={300}
                                                                    onError={
                                                                        handleErrorFile
                                                                    }
                                                                    onScan={
                                                                        wifeHandleScanFile
                                                                    }
                                                                    legacyMode
                                                                    style={{
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                />
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            ) : (
                                                false
                                            )}
                                            {/* 신부측 계좌번호 섹션 */}

                                            {/* 신랑측 아버지 계좌번호 섹션 */}
                                            {isHusbandPapaAccount ? (
                                                <div
                                                    style={{
                                                        borderTop:
                                                            "1px solid #9f9f9f",
                                                        padding: "25px 0",
                                                    }}
                                                >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="그룹명"
                                                        variant="outlined"
                                                        name="husbandPapaAccountTitle"
                                                        value={
                                                            husbandPapaAccountTitle
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="은행"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandPapaAccountBank"
                                                        value={
                                                            husbandPapaAccountBank
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="계좌번호"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandPapaAccount"
                                                        value={
                                                            husbandPapaAccount
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="예금주"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandPapaAccountName"
                                                        value={
                                                            husbandPapaAccountName
                                                        }
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            ) : (
                                                false
                                            )}
                                            {/* 신랑측 아버지 계좌번호 섹션 */}

                                            {/* 신랑측 어머니 계좌번호 섹션 */}
                                            {isHusbandMamaAccount ? (
                                                <div
                                                    style={{
                                                        borderTop:
                                                            "1px solid #9f9f9f",
                                                        padding: "25px 0",
                                                    }}
                                                >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="그룹명"
                                                        variant="outlined"
                                                        name="husbandMamaAccountTitle"
                                                        value={
                                                            husbandMamaAccountTitle
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="은행"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandMamaAccountBank"
                                                        value={
                                                            husbandMamaAccountBank
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="계좌번호"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandMamaAccount"
                                                        value={
                                                            husbandMamaAccount
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="예금주"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="husbandMamaAccountName"
                                                        value={
                                                            husbandMamaAccountName
                                                        }
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            ) : (
                                                false
                                            )}
                                            {/* 신랑측 어머니 계좌번호 섹션 */}

                                            {/* 신부측 아버지 계좌번호 섹션 */}
                                            {isWifePapaAccount ? (
                                                <div
                                                    style={{
                                                        borderTop:
                                                            "1px solid #9f9f9f",
                                                        padding: "25px 0",
                                                    }}
                                                >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="그룹명"
                                                        variant="outlined"
                                                        name="wifePapaAccountTitle"
                                                        value={
                                                            wifePapaAccountTitle
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="은행"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifePapaAccountBank"
                                                        value={
                                                            wifePapaAccountBank
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="계좌번호"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifePapaAccount"
                                                        value={wifePapaAccount}
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="예금주"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifePapaAccountName"
                                                        value={
                                                            wifePapaAccountName
                                                        }
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            ) : (
                                                false
                                            )}
                                            {/* 신부측 아버지 계좌번호 섹션 */}

                                            {/* 신부측 어머니 계좌번호 섹션 */}
                                            {isWifeMamaAccount ? (
                                                <div
                                                    style={{
                                                        borderTop:
                                                            "1px solid #9f9f9f",
                                                        padding: "25px 0",
                                                    }}
                                                >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="그룹명"
                                                        variant="outlined"
                                                        name="wifeMamaAccountTitle"
                                                        value={
                                                            wifeMamaAccountTitle
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="은행"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifeMamaAccountBank"
                                                        value={
                                                            wifeMamaAccountBank
                                                        }
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="계좌번호"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifeMamaAccount"
                                                        value={wifeMamaAccount}
                                                        onChange={onChange}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="예금주"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="wifeMamaAccountName"
                                                        value={
                                                            wifeMamaAccountName
                                                        }
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            ) : (
                                                false
                                            )}
                                            {/* 신부측 어머니 계좌번호 섹션 */}
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 계좌번호 */}

                                {/* 방명록 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("commentCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {commentCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="방명록 📖"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={commentCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div style={{ padding: "20px 0" }}>
                                            <RadioGroup
                                                aria-label="isComment"
                                                name="isComment"
                                                value={isComment}
                                                onChange={radioChange}
                                            >
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio />}
                                                    label="방명록을 추가하지 않습니다."
                                                />
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio />}
                                                    label="방명록을 추가합니다."
                                                />
                                            </RadioGroup>

                                            {isComment === "1" ? (
                                                <div>
                                                    <div
                                                        style={{
                                                            padding: "10px 0px",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                padding: 0,
                                                                fontSize: 20,
                                                                fontWeight:
                                                                    "bold",
                                                                lineHeight:
                                                                    "40px",
                                                            }}
                                                        >
                                                            마스터 비밀번호 설정
                                                        </p>
                                                        <p
                                                            style={{
                                                                fontSize: 12,
                                                                color: "#777",
                                                            }}
                                                        >
                                                            원치않는 방명록의
                                                            삭제를 희망하시면
                                                            <br />
                                                            청첩장 내에서 마스터
                                                            비밀번호로
                                                            삭제가능합니다.
                                                        </p>
                                                    </div>

                                                    <TextField
                                                        id="outlined-basic"
                                                        label="비밀번호 (4~12자)"
                                                        type="password"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="masterPassword"
                                                        value={masterPassword}
                                                        onChange={onChange}
                                                    />

                                                    <TextField
                                                        id="outlined-basic"
                                                        label="비밀번호 확인 (4~12자)"
                                                        type="password"
                                                        variant="outlined"
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                        name="masterPasswordCheck"
                                                        value={
                                                            masterPasswordCheck
                                                        }
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            ) : (
                                                false
                                            )}
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 방명록 */}

                                {/* RSVP */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("surveyCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {surveyCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="RSVP(참석자 응답조사) ✍️"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={surveyCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div
                                            style={{ padding: "10px 0px 20px" }}
                                        >
                                            <RadioGroup
                                                aria-label="isSurvey"
                                                name="isSurvey"
                                                value={isSurvey}
                                                onChange={radioChange}
                                            >
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio />}
                                                    label="RSVP를 추가하지 않습니다."
                                                />
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio />}
                                                    label="RSVP를 추가합니다."
                                                />
                                            </RadioGroup>

                                            {isSurvey == 1 ? (
                                                <div>
                                                    <div
                                                        style={{
                                                            padding:
                                                                "0px 0px 10px",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                padding: 0,
                                                                fontSize: 20,
                                                                fontWeight:
                                                                    "bold",
                                                                lineHeight:
                                                                    "40px",
                                                            }}
                                                        >
                                                            RSVP 설정
                                                        </p>
                                                        <p
                                                            style={{
                                                                fontSize: 12,
                                                                color: "#777",
                                                            }}
                                                        >
                                                            해당 기능을
                                                            추가하시면 청첩장
                                                            내에
                                                            <br />
                                                            참석자 조사 기능이
                                                            추가됩니다.
                                                            <br />
                                                            <span
                                                                style={{
                                                                    fontSize: 10,
                                                                }}
                                                            >
                                                                (설문에 응답한
                                                                참석자는
                                                                분석보기
                                                                페이지에서 확인
                                                                가능합니다)
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginTop: 20,
                                                            marginBottom: 20,
                                                        }}
                                                    >
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="참석자 응답조사 제목"
                                                            variant="outlined"
                                                            name="surveyTitle"
                                                            value={surveyTitle}
                                                            onChange={onChange}
                                                        />

                                                        <TextField
                                                            id="outlined-multiline-static"
                                                            label="참석자 응답조사 내용"
                                                            multiline
                                                            rows={4}
                                                            defaultValue=""
                                                            variant="outlined"
                                                            style={{
                                                                marginTop: 15,
                                                            }}
                                                            name="surveyContent"
                                                            value={
                                                                surveyContent
                                                            }
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                false
                                            )}
                                        </div>
                                    </Collapse>
                                </div>
                                {/* RSVP */}

                                {/* 축하화환 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("flowerPresentCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {flowerPresentCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="축하 화환 🌼"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={flowerPresentCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div
                                            style={{ padding: "10px 0px 20px" }}
                                        >
                                            <p
                                                style={{
                                                    padding: 0,
                                                    fontSize: 20,
                                                    fontWeight: "bold",
                                                    lineHeight: "40px",
                                                }}
                                            >
                                                축하화환 보내기
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    color: "#777",
                                                }}
                                            >
                                                청첩장 내 축하화환 보내기 기능을
                                                추가할 수 있습니다.
                                                <br />
                                                <span style={{ fontSize: 11 }}>
                                                    선물받은 축하화환은
                                                    예식일정에 맞춰 예식장으로
                                                    도착합니다.
                                                </span>
                                            </p>

                                            <div style={{ marginTop: 15 }}>
                                                <RadioGroup
                                                    aria-label="isFlowerPresent"
                                                    name="isFlowerPresent"
                                                    value={isFlowerPresent}
                                                    onChange={radioChange}
                                                >
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio />}
                                                        label="축하화환 보내기를 추가하지 않습니다."
                                                    />
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="축하화환 보내기를 추가합니다."
                                                    />
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 축하화환 */}

                                {/* 동영상 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("videoCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {videoCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="식전 영상 📼"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={videoCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div
                                            style={{ padding: "10px 0px 20px" }}
                                        >
                                            <div
                                                style={{
                                                    padding: "0px 0px 10px",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#777",
                                                    }}
                                                >
                                                    식전영상은 유튜브에 업로드
                                                    후<br />
                                                    URL을 복사하여 추가해주시면
                                                    됩니다.
                                                    <br />
                                                    <span
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        (예.
                                                        https://www.youtube.com/watch?v=ABCDEF1234)
                                                    </span>
                                                </p>
                                            </div>

                                            <TextField
                                                id="outlined-basic"
                                                label="식전영상 URL "
                                                variant="outlined"
                                                name="customVideo"
                                                value={customVideo}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 동영상 */}

                                {/* 배경음악 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("musicCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {musicCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="배경음악 🎵"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={musicCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div style={{ padding: "10px 0" }}>
                                            <RadioGroup
                                                aria-label="bgm"
                                                name="bgm"
                                                value={bgm}
                                                onChange={radioChange}
                                            >
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio />}
                                                    label="추가하지 않음"
                                                />
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio />}
                                                    label="감동적인 배경음악"
                                                />
                                                <FormControlLabel
                                                    value="2"
                                                    control={<Radio />}
                                                    label="경쾌한 배경음악"
                                                />
                                            </RadioGroup>
                                            <div style={{ marginTop: 10 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            }
                                                            checkedIcon={
                                                                <CheckBoxIcon fontSize="small" />
                                                            }
                                                            name="autoPlay"
                                                            checked={autoPlay}
                                                            onChange={onChange}
                                                            style={{
                                                                paddingLeft: 0,
                                                            }}
                                                        />
                                                    }
                                                    label="카카오톡 공유 시 자동재생이 되도록 합니다."
                                                    id="custom_check"
                                                />
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 배경음악 */}

                                {/* 순서 변경 */}

                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("layoutCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {layoutCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="레이아웃 순서 변경 "
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={layoutCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div
                                            style={{ padding: "10px 0px 20px" }}
                                        >
                                            <LayoutChangeComponent
                                                getLayoutArr={getLayoutArr}
                                                layoutArr={layoutArr}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 순서 변경 */}

                                {/* 부가적인 기능 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("additionCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {additionCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="부가적인 기능 ⚙️"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={additionCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div style={{ padding: "10px 0" }}>
                                            <div>
                                                <FormControl
                                                    variant="outlined"
                                                    style={{ width: 300 }}
                                                >
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        글꼴
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={fontVer}
                                                        name="fontVer"
                                                        onChange={handleChange}
                                                        label="fontVer"
                                                    >
                                                        <MenuItem value={1}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        "Noto Serif KR",
                                                                }}
                                                            >
                                                                본명조
                                                            </span>
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        "SunBatang-Light",
                                                                }}
                                                            >
                                                                순명조
                                                            </span>
                                                        </MenuItem>
                                                        <MenuItem value={3}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        "RIDIBatang",
                                                                }}
                                                            >
                                                                리디바탕
                                                            </span>
                                                        </MenuItem>
                                                        <MenuItem value={4}>
                                                            <span
                                                                style={{
                                                                    fontFamily:
                                                                        "Pretendard-Regular",
                                                                }}
                                                            >
                                                                프리텐다드
                                                            </span>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <FormControl
                                                    variant="outlined"
                                                    style={{
                                                        width: 300,
                                                        marginTop: 30,
                                                    }}
                                                >
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        폰트 크기
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={fontSize}
                                                        name="fontSize"
                                                        onChange={handleChange}
                                                        label="fontSize"
                                                    >
                                                        <MenuItem value={1}>
                                                            보통
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            크게
                                                        </MenuItem>
                                                        {/* <MenuItem value={3}>더 크게</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {/* 배경효과 */}
                                            <div style={{ marginTop: 20 }}>
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                        paddingBottom: 10,
                                                    }}
                                                >
                                                    배경효과
                                                </p>
                                                <RadioGroup
                                                    aria-label="effect"
                                                    name="effect"
                                                    value={effect}
                                                    onChange={radioChange}
                                                >
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio />}
                                                        label="추가하지 않음"
                                                    />
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="눈 내리는 효과"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio />}
                                                        label="벚꽃 효과"
                                                    />
                                                </RadioGroup>
                                            </div>
                                            {/* 배경효과 */}

                                            {/* 샤르르 효과 */}
                                            <div style={{ marginTop: 20 }}>
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                        paddingBottom: 10,
                                                    }}
                                                >
                                                    샤르르 효과
                                                </p>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            }
                                                            checkedIcon={
                                                                <CheckBoxIcon fontSize="small" />
                                                            }
                                                            name="isSharu"
                                                            checked={isSharu}
                                                            onChange={onChange}
                                                            style={{
                                                                paddingLeft: 0,
                                                            }}
                                                        />
                                                    }
                                                    label="샤르르 효과를 추가합니다"
                                                    id="custom_check"
                                                />
                                            </div>
                                            {/* 샤르르 효과 */}

                                            {/* 청첩장 확대 금지 */}
                                            <div style={{ marginTop: 20 }}>
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                        paddingBottom: 10,
                                                    }}
                                                >
                                                    청첩장 확대 금지
                                                    <br />
                                                    <span
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        (카카오톡 공유 시 확대
                                                        금지)
                                                    </span>
                                                </p>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            }
                                                            checkedIcon={
                                                                <CheckBoxIcon fontSize="small" />
                                                            }
                                                            name="isPhoto"
                                                            checked={isPhoto}
                                                            onChange={onChange}
                                                            style={{
                                                                paddingLeft: 0,
                                                            }}
                                                        />
                                                    }
                                                    label="청첩장 내 확대를 금지합니다"
                                                    id="custom_check"
                                                />
                                            </div>
                                            {/* 청첩장 확대 금지 */}
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 부가적인 기능 */}

                                {/* 카카오톡 공유 썸네일 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("kakaoCollapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {kakaoCollapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="카카오톡 공유 시"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={kakaoCollapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    margin: 6,
                                                    marginLeft: 0,
                                                    display: "inline-block",
                                                    width: 150,
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        paddingBottom: 15,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    카카오톡 썸네일 사진
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#999",
                                                        }}
                                                    >
                                                        (최적사이즈 400*550)
                                                    </span>
                                                </p>
                                                {thumbImg !== "" ? (
                                                    <div
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            border: "1px solid #000",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    "#000",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src={thumbImg}
                                                                style={{
                                                                    maxHeight: 200,
                                                                    maxWidth: 200,
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        "rgb(0, 0, 0,0.6)",
                                                                    height: 50,
                                                                    width: 200,
                                                                    position:
                                                                        "absolute",
                                                                    bottom: 0,
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <label
                                                                    for="thumbImg"
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <CreateIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontSize: 22,
                                                                        }}
                                                                    />
                                                                </label>
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                    onClick={() =>
                                                                        setThumbImg(
                                                                            ""
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <label
                                                        for="thumbImg"
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            border: "1px dashed rgb(206, 212, 218)",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: 70,
                                                                fontWeight:
                                                                    "100",
                                                                color: "#999",
                                                            }}
                                                        >
                                                            +
                                                        </span>
                                                    </label>
                                                )}

                                                <input
                                                    id="thumbImg"
                                                    name="thumbImg"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={onFileChange}
                                                    style={{
                                                        width: 1,
                                                        height: 1,
                                                    }}
                                                />
                                            </div>
                                            <TextField
                                                id="outlined-basic"
                                                label="카카오톡 제목 (ex.현수❤️아연 결혼합니다)"
                                                variant="outlined"
                                                name="kakaoTitle"
                                                value={kakaoTitle}
                                                onChange={onChange}
                                            />

                                            <TextField
                                                id="outlined-multiline-static"
                                                label="카카오톡 내용 (ex. 식장명, 예식일자)"
                                                multiline
                                                rows={4}
                                                defaultValue=""
                                                variant="outlined"
                                                style={{ marginTop: 15 }}
                                                name="kakaoDes"
                                                value={kakaoDes}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                                {/* 카카오톡 공유 썸네일 */}

                                {/* URL 공유 썸네일 */}
                                <div>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleClick("urlCallapse")
                                        }
                                        style={{ paddingLeft: 0 }}
                                    >
                                        {urlCallapse ? (
                                            <CheckBoxIcon
                                                fontSize="small"
                                                style={{ color: "#f50057" }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                fontSize="small"
                                                color="#333"
                                            />
                                        )}

                                        <ListItemText
                                            primary="URL 공유 시"
                                            style={{
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </ListItem>
                                    <Collapse
                                        in={urlCallapse}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    margin: 6,
                                                    marginLeft: 0,
                                                    display: "inline-block",
                                                    width: 150,
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        paddingBottom: 15,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    URL 썸네일 사진
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#999",
                                                        }}
                                                    >
                                                        (최적사이즈 1200*630)
                                                    </span>
                                                </p>
                                                {metaImg !== "" ? (
                                                    <div
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            border: "1px solid #000",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    "#000",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src={metaImg}
                                                                style={{
                                                                    maxHeight: 200,
                                                                    maxWidth: 200,
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        "rgb(0, 0, 0,0.6)",
                                                                    height: 50,
                                                                    width: 200,
                                                                    position:
                                                                        "absolute",
                                                                    bottom: 0,
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <label
                                                                    for="metaImg"
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <CreateIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontSize: 22,
                                                                        }}
                                                                    />
                                                                </label>
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                        cursor: "pointer",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                    onClick={() =>
                                                                        setMetaImg(
                                                                            ""
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <label
                                                        for="metaImg"
                                                        style={{
                                                            width: 200,
                                                            height: 200,
                                                            border: "1px dashed rgb(206, 212, 218)",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: 70,
                                                                fontWeight:
                                                                    "100",
                                                                color: "#999",
                                                            }}
                                                        >
                                                            +
                                                        </span>
                                                    </label>
                                                )}

                                                <input
                                                    id="metaImg"
                                                    name="metaImg"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={onFileChange}
                                                    style={{
                                                        width: 1,
                                                        height: 1,
                                                    }}
                                                />
                                            </div>
                                            <TextField
                                                id="outlined-basic"
                                                label="URL 제목 (ex.현수❤️아연 결혼합니다)"
                                                variant="outlined"
                                                name="metaTitle"
                                                value={metaTitle}
                                                onChange={onChange}
                                            />

                                            <TextField
                                                id="outlined-multiline-static"
                                                label="URL 내용 (ex. 식장명, 예식일자)"
                                                defaultValue=""
                                                variant="outlined"
                                                style={{ marginTop: 15 }}
                                                name="metaDes"
                                                value={metaDes}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                                {/* URL 공유 썸네일 */}
                            </form>
                        </div>

                        {/* 저장하기 버튼 */}
                        <div
                            style={{
                                backgroundColor: "#F76C6C",
                                height: 50,
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                maxWidth: "480px",
                                cursor: "pointer",
                                marginTop: 100,
                            }}
                            onClick={() => {
                                if (!isSubmit) {
                                    submitFunc();
                                }
                            }}
                        >
                            {!isSubmit ? (
                                "수정하기"
                            ) : (
                                <CircularProgress
                                    style={{
                                        width: 25,
                                        height: 25,
                                        marginTop: 3,
                                    }}
                                />
                            )}
                        </div>
                        {/* 저장하기 버튼 */}
                    </div>
                </div>
            ) : (
                false
            )}
        </div>
    );
};

export default HidiModifyScreen;
