import React from "react";
import Contact from "../../components/NewComponents/Contact/Contact";
import Footer from "../../components/NewComponents/Footer/Footer";
import Header from "../../components/NewComponents/Header/Header";
import EventCollapse from "../../components/EventCollapse/EventCollapse";
import localStorage from "mobx-localstorage";
import HeaderVer2 from "../../components/NewComponents/HeaderVer2/HeaderVer2";
import { useLocation } from "react-router-dom";
import cafe1Img from "../../assets/images/cafe/1.png";
import cafe2Img from "../../assets/images/cafe/2.png";
import cafe3Img from "../../assets/images/cafe/3.png";
import cafe4Img from "../../assets/images/cafe/4.png";

const EVENT_TITLE_1 = (
    <>
        <span style={{ fontWeight: "bold" }}>🎁 모바일 감사장 무료 증정</span>{" "}
        이벤트
    </>
);
const EVENT_TITLE_2 = (
    <>
        <span style={{ fontWeight: "bold" }}>🎁 모바일 청첩장 1+1</span> 이벤트
    </>
);

const EVENT_CONTENT_1 = (
    <>
        <div style={{ padding: "20px 0" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: "yellow",
                    display: "inline-block",
                    lineHeight: "10px",
                }}
            >
                참여대상
            </p>
            <p
                style={{
                    fontSize: 14,
                    lineHeight: "22px",
                }}
            >
                모바일청첩장 구매 고객
            </p>
        </div>

        <div style={{ padding: "20px 0" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: "yellow",
                    display: "inline-block",
                    lineHeight: "10px",
                }}
            >
                미션
            </p>

            <div
                style={{
                    fontSize: 14,
                    lineHeight: "22px",
                }}
            >
                <p>
                    1. 네이버 포토리뷰
                    <span
                        style={{
                            fontSize: 12,
                            color: "#666",
                        }}
                    >
                        (텍스트 100자 이상)
                    </span>{" "}
                    작성 후
                </p>
                <p>
                    2.{" "}
                    <span
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        네이버 포토리뷰
                    </span>
                    를 캡쳐해서
                </p>
                <p>
                    <a
                        href="https://talk.naver.com/W4YT01"
                        target="_blank"
                        style={{
                            color: "#333",
                            fontWeight: "bold",
                        }}
                    >
                        네이버톡톡
                    </a>{" "}
                    으로 보내주시면 이벤트 참여 완료!
                </p>
            </div>
        </div>

        <div style={{ padding: "20px 0" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: "yellow",
                    display: "inline-block",
                    lineHeight: "10px",
                }}
            >
                혜택
            </p>
            <p
                style={{
                    fontSize: 14,
                    lineHeight: "22px",
                }}
            >
                <span style={{ fontWeight: "bold" }}>
                    모바일감사장 무료제작쿠폰
                </span>
                을 지급해드립니다.
            </p>
        </div>
    </>
);

const EVENT_CONTENT_2 = (
    <>
        <div style={{ padding: "20px 0" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: "yellow",
                    display: "inline-block",
                    lineHeight: "10px",
                }}
            >
                참여대상
            </p>
            <p
                style={{
                    fontSize: 14,
                    lineHeight: "22px",
                }}
            >
                모바일청첩장 구매 고객
            </p>
        </div>

        <div style={{ padding: "20px 0" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: "yellow",
                    display: "inline-block",
                    lineHeight: "10px",
                }}
            >
                미션
            </p>
            <p
                style={{
                    fontSize: 14,
                    lineHeight: "22px",
                }}
            >
                웨딩공부, 결혼준비싸게하기, 네이버블로그 중 한 곳에서 후기를
                작성해주세요.
            </p>
            <p style={{ fontSize: 12, color: "#666" }}>
                (카페 가입을 원하지 않는 분들은 개인 블로그 업로드)
            </p>

            <div
                style={{
                    width: "100%",
                    height: 130,
                    backgroundColor: "#fafafa",
                    marginTop: 20,
                    borderRadius: 15,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "30px 5px 20px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <a
                        href="https://cafe.naver.com/llchyll"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                            textDecoration: "none",
                            color: "#000",
                        }}
                    >
                        <img src={cafe1Img} style={{ width: 70 }} />
                        <span
                            style={{
                                marginTop: 5,
                                fontSize: 12,
                            }}
                        >
                            웨딩공부
                        </span>
                    </a>

                    <a
                        href="https://cafe.naver.com/b00k2012"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                            textDecoration: "none",
                            color: "#000",
                        }}
                    >
                        <img src={cafe2Img} style={{ width: 70 }} />
                        <span
                            style={{
                                marginTop: 5,
                                fontSize: 12,
                            }}
                        >
                            결훈준비싸게하기
                        </span>
                    </a>

                    <a
                        href="https://blog.naver.com"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                            textDecoration: "none",
                            color: "#000",
                        }}
                    >
                        <img src={cafe3Img} style={{ width: 70 }} />
                        <span
                            style={{
                                marginTop: 5,
                                fontSize: 12,
                            }}
                        >
                            네이버개인블로그
                        </span>
                    </a>
                </div>

                <p
                    style={{
                        fontSize: 12,
                        color: "#666",
                    }}
                >
                    아이콘을 클릭하시면 해당 사이트로 이동됩니다.
                </p>
            </div>
        </div>

        <div style={{ padding: "20px 0" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: "yellow",
                    display: "inline-block",
                    lineHeight: "10px",
                }}
            >
                리뷰 작성 방법
            </p>

            <div
                style={{
                    fontSize: 14,
                    lineHeight: "24px",
                }}
            >
                <p>1. 5줄 이상 작성 후 청첩장사진 캡쳐 2장 업로드</p>
                <p>
                    2. 리뷰 작성 시 공개 설정은{" "}
                    <span
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        전체공개
                    </span>
                    로 선택해 주세요.
                </p>
                <div
                    style={{
                        width: "100%",
                        height: 130,
                        backgroundColor: "#fafafa",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        marginTop: 15,
                        marginBottom: 15,
                    }}
                >
                    <img src={cafe4Img} style={{ width: 240 }} />
                </div>
                <p>3. 게시글 하단에 필수 해시태그를 넣어주세요.</p>

                <div
                    style={{
                        width: "100%",
                        height: 100,
                        backgroundColor: "#fafafa",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        marginTop: 15,
                        marginBottom: 15,
                    }}
                >
                    <div
                        style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        #크류카드 #모바일청첩장 #내돈내산
                        <br />
                        #청첩장 #셀프제작 #결혼준비
                    </div>
                </div>

                <p>
                    4.{" "}
                    <span
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        카페/블로그 리뷰 URL주소
                    </span>
                    를 복사 후
                </p>

                <p>
                    {" "}
                    <a
                        href="https://talk.naver.com/W4YT01"
                        target="_blank"
                        style={{
                            color: "#333",
                            fontWeight: "bold",
                        }}
                    >
                        네이버톡톡
                    </a>{" "}
                    으로 보내주세요.
                </p>
            </div>
        </div>

        <div style={{ padding: "20px 0" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: "yellow",
                    display: "inline-block",
                    lineHeight: "10px",
                }}
            >
                혜택
            </p>
            <p
                style={{
                    fontSize: 14,
                    lineHeight: "22px",
                }}
            >
                <span style={{ fontWeight: "bold" }}>
                    모바일청첩장 무료제작쿠폰
                </span>
                을 지급해드립니다.
            </p>
        </div>

        <div style={{ padding: "20px 0" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: "yellow",
                    display: "inline-block",
                    lineHeight: "10px",
                }}
            >
                주의사항
            </p>
            <p
                style={{
                    fontSize: 14,
                    lineHeight: "22px",
                }}
            >
                카페, 블로그 리뷰 작성시 하단에 넣어주세요
            </p>

            <div
                style={{
                    backgroundColor: "#fafafa",
                    width: "100%",
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 20,
                    borderRadius: 10,
                }}
            >
                <p style={{ fontSize: 12 }}>
                    본 게시글은 크류카드 청첩장 1+1 받고 작성한 리뷰입니다.
                </p>
            </div>
        </div>
    </>
);

export default function NewEventScreen() {
    const [userId, setUserId] = React.useState("");

    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const getUserId = async () => {
        let _getUserId = "";

        if (localStorage.getItem("userId")) {
            _getUserId = localStorage.getItem("userId");
            setUserId(_getUserId);

            // console.log("userId=>" + _getUserId);

            // await getList(getUserId);
            // await getThanksList(getUserId);
        } else {
            console.log("noEmail");
            // window.location.href = "/#/login";
        }
    };

    React.useEffect(() => {
        getUserId();
    }, []);

    return (
        <div>
            <HeaderVer2 userId={userId} />

            <div
                style={{
                    minHeight: "100vh",
                    padding: "60px 20px",
                    paddingTop: 160,
                }}
            >
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: 30,
                            fontWeight: "bold",
                        }}
                    >
                        진행중인 이벤트
                    </span>
                </div>

                <div style={{ textAlign: "center", marginTop: 30 }}>
                    현재 진행중인 이벤트가 없습니다.
                </div>

                {/* <div style={{ marginTop: 50 }}>
                    <EventCollapse
                        index={1}
                        title={EVENT_TITLE_1}
                        content={EVENT_CONTENT_1}
                    />
                </div>

                <div style={{ marginTop: 0 }}>
                    <EventCollapse
                        index={2}
                        title={EVENT_TITLE_2}
                        content={EVENT_CONTENT_2}
                    />
                </div> */}
            </div>
            {/* <Contact /> */}
            <Footer userId={userId} />
        </div>
    );
}
