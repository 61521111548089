import React, { useState, useEffect } from "react";
import CryuHeader from "../../components/CryuHeader/CryuHeader";
import CardComponent from "../../components/CardComponent/CardComponent";
import localStorage from "mobx-localstorage";
import { dbService } from "../../fbase";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ProductComponent from "../../components/ProductComponent/ProductComponent";
import thanksLetter from "../../assets/images/thanksLetter.png";

const ThanksOrderlistScreen = () => {
    const [userId, setUserId] = useState("");

    const getEmail = async () => {
        let getUserId = "";

        if (localStorage.getItem("userId")) {
            getUserId = await localStorage.getItem("userId");
            // await setIsLoading(true);
            setUserId(getUserId);
        } else {
            console.log("noEmail");
        }

        // await console.log(getUserId);
    };

    useEffect(() => {
        getEmail();
    }, []);

    return (
        <div
            style={{
                backgroundColor: "rgb(238 238 238)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
            }}
        >
            <div
                style={{
                    backgroundColor: "#fff",
                    maxWidth: 480,
                    width: "100%",
                }}
            >
                <CryuHeader userId={userId} />

                <div style={{ padding: "20px 10px 100px" }}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: "500",
                            paddingLeft: 15,
                        }}
                    >
                        모바일감사장
                    </div>

                    <ProductComponent
                        imgUrl={thanksLetter}
                        title="프리미엄 감사장"
                        beforePrice="19,800원"
                        price="6,900원"
                        sampleUrl="https://self.cryucompany.com/thxmax/yEyE7IeZO0tDGlT4hkwc/account"
                        thankProductNum={1}
                    />
                </div>
                <FooterComponent />
            </div>
        </div>
    );
};

export default ThanksOrderlistScreen;
