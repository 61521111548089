import React, { useState, useCallback } from "react";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import { dbService } from "../../fbase";
import { debounce } from "lodash";

export default function EtcComponent({ state, getData, letterId }) {
  const [font, setFont] = useState(state.font !== undefined ? state.font : "2");

  const [fontSize, setFontSize] = useState(
    state.fontSize !== undefined ? state.fontSize : "1"
  );

  const [isSharu, setIsSharu] = useState(
    state.isSharu !== undefined ? state.isSharu : true
  );

  const [isZoom, setIsZoom] = useState(
    state.isZoom !== undefined ? state.isZoom : false
  );

  const [isSmartZoom, setIsSmartZoom] = useState(
    state.isSmartZoom !== undefined ? state.isSmartZoom : false
  );

  const pushData = () => {
    getData({ isSaved: true });
  };

  const debounceSubmit = useCallback(
    debounce(async ({ _font, _fontSize }) => {
      let content = {};

      if (_font) {
        content = {
          font: _font,
        };
      } else if (_fontSize) {
        content = {
          fontSize: _fontSize,
        };
      }

      await dbService
        .collection("newletters")
        .doc(letterId)
        .update(content)
        .then((res) => {
          pushData();
        });
    }, 500),
    []
  );

  const onSubmit = async ({
    _font,
    _fontSize,
    _isSharu,
    _isZoom,
    _isSmartZoom,
  }) => {
    let content = {};

    if (_font !== undefined) {
      content = {
        font: _font,
      };
    } else if (_fontSize !== undefined) {
      content = {
        fontSize: _fontSize,
      };
    } else if (_isSharu !== undefined) {
      content = {
        isSharu: _isSharu,
      };
    } else if (_isZoom !== undefined) {
      content = {
        isZoom: _isZoom,
      };
    } else if (_isSmartZoom !== undefined) {
      content = {
        isSmartZoom: _isSmartZoom,
      };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  return (
    <div>
      <div>
        <SubTitleComponent title="글꼴" />

        <div className="prod-select-container" style={{ width: "auto" }}>
          <select
            name="font"
            id="font-select"
            className="prod-select"
            onChange={(e) => {
              setFont(e.target.value);
              debounceSubmit({ _font: e.target.value });
            }}
            value={font}
          >
            <option value="1">에스코어드림</option>
            <option value="2">나눔명조</option>
            <option value="3">순바탕</option>
            <option value="4">나눔바른펜</option>
            <option value="5">리디바탕(혼주추천)</option>
            <option value="6">프리텐다드</option>
          </select>
        </div>
      </div>

      <div>
        <SubTitleComponent title="폰트 크기" />

        <div className="prod-select-container" style={{ width: "auto" }}>
          <select
            name="font-size"
            id="font-size-select"
            className="prod-select"
            onChange={(e) => {
              setFontSize(e.target.value);
              debounceSubmit({ _fontSize: e.target.value });
            }}
            value={fontSize}
          >
            <option value="1">보통</option>
            <option value="2">크게</option>
            <option value="3">더 크게</option>
          </select>
        </div>
      </div>

      <div>
        <SubTitleComponent
          title="등장효과"
          tooltip={<div>스크롤에 따라 부드럽게 등장하는 효과입니다.</div>}
          index={"1"}
        />

        <div
          onClick={() => {
            setIsSharu((value) => {
              onSubmit({ _isSharu: !value });
              return !value;
            });
          }}
          style={{}}
        >
          <CheckboxComponent
            title="등장효과를 적용합니다."
            isChecked={isSharu}
          />
        </div>
      </div>

      {state.version < 6 && (
        <div style={{ paddingTop: 10 }}>
          <SubTitleComponent title="확대 금지" index={"2"} />
          <div
            onClick={() => {
              setIsZoom((value) => {
                onSubmit({ _isZoom: !value });
                return !value;
              });
            }}
            style={{}}
          >
            <CheckboxComponent
              title="청첩장 확대 금지기능을 적용합니다."
              isChecked={isZoom}
            />
          </div>
        </div>
      )}

      <div style={{ paddingTop: 10 }}>
        <SubTitleComponent
          title="스마트 확대 금지"
          tooltip={<div>확대 시 사진이 블러처리됩니다.</div>}
          index={"3"}
        />
        <div
          onClick={() => {
            setIsSmartZoom((value) => {
              onSubmit({ _isSmartZoom: !value });
              return !value;
            });
          }}
          style={{}}
        >
          <CheckboxComponent
            title="청첩장 스마트 확대 금지기능을 적용합니다."
            isChecked={isSmartZoom}
          />
        </div>
      </div>
    </div>
  );
}
