import React, { useState, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import CryuHeader from "../../components/CryuHeader/CryuHeader";
import CardComponent from "../../components/CardComponent/CardComponent";
import ThanksCardComponent from "../../components/ThanksCardComponent/ThanksCardComponent";
import localStorage from "mobx-localstorage";
import { dbService } from "../../fbase";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BarChartComponent from "../../components/BarChartComponent/BarChartComponent";
import SurveySearchComponent from "../../components/SurveySearchComponent/SurveySearchComponent";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import { useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";

export default function SurveyDetailsScreen() {
    var surveyRef = dbService.collection("survey");

    const { pathname } = useLocation();
    const { letterId } = useParams();

    //side = 0 남편, 1 신부
    //attend = 0 불참, 1참석

    const [isLoading, setIsLoading] = useState("");
    const [userId, setUserId] = useState("");
    const [surveyArr, setSurveyArr] = useState([]);
    const [searchListSurveyArr, setSearchListSurveyArr] = useState([]);

    const [searchSide, setSearchSide] = useState(2); // all, husband, wife
    const [searchAttend, setSearchAttend] = useState(2); // all, attend, nonattend
    const [searchText, setSearchText] = useState("");
    const [deleteList, setDeleteList] = useState([]);

    const getTrigger = (data) => {
        console.log(data);
    };

    const getEmail = async () => {
        let getUserId = "";

        if (localStorage.getItem("userId")) {
            getUserId = await localStorage.getItem("userId");
            setUserId(getUserId);
            getLetter(getUserId);
        } else {
            console.log("noEmail");
            window.location.href = "/#/login";
        }
    };

    const userIdAccess = (letterUserId, deviceUserId) => {
        // letterId조회 후 userid값 가져오기.
        if (letterUserId === deviceUserId || "51543483" == deviceUserId) {
            console.log("같음");
            // getSurvey 불러오기
            getSurvey();
        } else {
            alert("비정상적인 접근입니다.");
            window.location.href = "/#/list";
        }
    };

    const getLetter = (deviceUserId) => {
        var letterRef = dbService.collection("letters");

        letterRef
            .doc(letterId)
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    const { userId } = res.data();
                    userIdAccess(userId, deviceUserId);
                } else {
                    alert("비정상적인 접근입니다.");
                    window.location.href = "/#/list";
                }
            });
    };

    const getSurvey = () => {
        surveyRef
            .where("letterId", "==", `${letterId}`)
            .orderBy("createdAt", "desc")
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    const promise = res.docs.map((item) => {
                        var data = item.data();
                        data.surveyId = item.id;

                        setSurveyArr((oldArr) => [...oldArr, data]);
                        setSearchListSurveyArr((oldArr) => [...oldArr, data]);
                    });
                    await Promise.all(promise);
                    setIsLoading(true);
                    console.log(surveyArr);
                } else {
                    // console.log('none');
                }
            });
    };

    useEffect(() => {
        setSurveyArr([]);
        getEmail();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <div
                style={{
                    backgroundColor: "rgb(238 238 238)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#fff",
                        maxWidth: 480,
                        width: "100%",
                    }}
                >
                    <CryuHeader userId={userId} />
                    <div>
                        <div
                            style={{
                                borderBottom: "1px solid #f9f9f9",
                                textAlign: "center",
                                padding: "10px 5px",
                            }}
                        >
                            자세히 보기
                        </div>
                        <div
                            style={{
                                padding: "20px 16px 100px",
                                backgroundColor: "#f2f3f5",
                            }}
                        >
                            {/* 참석조사 */}
                            <div
                                style={{
                                    borderRadius: 15,
                                    backgroundColor: "#fff",
                                }}
                            >
                                <div
                                    style={{
                                        padding: "15px 15px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        참석자 요약
                                    </span>
                                </div>

                                <div
                                    style={{
                                        margin: "0 15px",
                                        padding: "15px 0px",
                                        borderTop: "1px solid #e9e9e9",
                                    }}
                                >
                                    {isLoading ? (
                                        <BarChartComponent
                                            surveyArr={surveyArr}
                                        />
                                    ) : (
                                        <div>
                                            <SkeletonTheme
                                                baseColor="#fff"
                                                highlightColor="#f9f9f9"
                                            >
                                                <Skeleton height={300} />
                                            </SkeletonTheme>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* 참석조사 */}

                            {/* 검색 옵션 */}
                            <div
                                style={{
                                    borderRadius: 15,
                                    backgroundColor: "#fff",
                                    marginTop: 15,
                                }}
                            >
                                <div
                                    style={{
                                        padding: "15px 15px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        검색 옵션
                                    </span>
                                </div>

                                <div
                                    style={{
                                        margin: "0 15px",
                                        padding: "15px 0px",
                                        borderTop: "1px solid #e9e9e9",
                                    }}
                                >
                                    <div>
                                        <p style={{ fontSize: 14 }}>
                                            하객 분류
                                        </p>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginTop: 10,
                                            }}
                                        >
                                            <div
                                                className="survey_button"
                                                style={{
                                                    color:
                                                        searchSide == 2
                                                            ? "#f76c6c"
                                                            : "#999",
                                                    borderColor:
                                                        searchSide == 2
                                                            ? "#f76c6c"
                                                            : "#eaeaea",
                                                }}
                                                onClick={() => {
                                                    setSearchSide(2);

                                                    const result =
                                                        surveyArr.filter(
                                                            (item) =>
                                                                (item.name.includes(
                                                                    searchText
                                                                ) &&
                                                                searchAttend ==
                                                                    2
                                                                    ? item.attend !==
                                                                      searchAttend
                                                                    : item.attend ==
                                                                      searchAttend) ||
                                                                (item.contact.includes(
                                                                    searchText
                                                                ) &&
                                                                searchAttend ==
                                                                    2
                                                                    ? item.attend !==
                                                                      searchAttend
                                                                    : item.attend ==
                                                                      searchAttend)
                                                        );
                                                    setSearchListSurveyArr(
                                                        result
                                                    );
                                                }}
                                            >
                                                전체
                                            </div>

                                            <div
                                                className="survey_button"
                                                style={{
                                                    color:
                                                        searchSide == 0
                                                            ? "#f76c6c"
                                                            : "#999",
                                                    borderColor:
                                                        searchSide == 0
                                                            ? "#f76c6c"
                                                            : "#eaeaea",
                                                }}
                                                onClick={() => {
                                                    setSearchSide(0);
                                                    const result =
                                                        surveyArr.filter(
                                                            (item) =>
                                                                (item.name.includes(
                                                                    searchText
                                                                ) &&
                                                                    item.side ==
                                                                        0 &&
                                                                    (searchAttend ==
                                                                    2
                                                                        ? item.attend !==
                                                                          searchAttend
                                                                        : item.attend ==
                                                                          searchAttend)) ||
                                                                (item.contact.includes(
                                                                    searchText
                                                                ) &&
                                                                    item.side ==
                                                                        0 &&
                                                                    (searchAttend ==
                                                                    2
                                                                        ? item.attend !==
                                                                          searchAttend
                                                                        : item.attend ==
                                                                          searchAttend))
                                                        );
                                                    setSearchListSurveyArr(
                                                        result
                                                    );
                                                }}
                                            >
                                                신랑측
                                            </div>

                                            <div
                                                className="survey_button"
                                                style={{
                                                    color:
                                                        searchSide == 1
                                                            ? "#f76c6c"
                                                            : "#999",
                                                    borderColor:
                                                        searchSide == 1
                                                            ? "#f76c6c"
                                                            : "#eaeaea",
                                                }}
                                                onClick={() => {
                                                    setSearchSide(1);
                                                    const result =
                                                        surveyArr.filter(
                                                            (item) =>
                                                                (item.name.includes(
                                                                    searchText
                                                                ) &&
                                                                    item.side ==
                                                                        1 &&
                                                                    (searchAttend ==
                                                                    2
                                                                        ? item.attend !==
                                                                          searchAttend
                                                                        : item.attend ==
                                                                          searchAttend)) ||
                                                                (item.contact.includes(
                                                                    searchText
                                                                ) &&
                                                                    item.side ==
                                                                        1 &&
                                                                    (searchAttend ==
                                                                    2
                                                                        ? item.attend !==
                                                                          searchAttend
                                                                        : item.attend ==
                                                                          searchAttend))
                                                        );
                                                    setSearchListSurveyArr(
                                                        result
                                                    );
                                                }}
                                            >
                                                신부측
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: 20 }}>
                                        <p style={{ fontSize: 14 }}>참석여부</p>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginTop: 10,
                                            }}
                                        >
                                            <div
                                                className="survey_button"
                                                style={{
                                                    color:
                                                        searchAttend == 2
                                                            ? "#f76c6c"
                                                            : "#999",
                                                    borderColor:
                                                        searchAttend == 2
                                                            ? "#f76c6c"
                                                            : "#eaeaea",
                                                }}
                                                onClick={() => {
                                                    setSearchAttend(2);

                                                    const result =
                                                        surveyArr.filter(
                                                            (item) =>
                                                                (item.name.includes(
                                                                    searchText
                                                                ) &&
                                                                searchSide == 2
                                                                    ? item.side !==
                                                                      searchSide
                                                                    : item.side ==
                                                                      searchSide) ||
                                                                (item.contact.includes(
                                                                    searchText
                                                                ) &&
                                                                searchSide == 2
                                                                    ? item.side !==
                                                                      searchSide
                                                                    : item.side ==
                                                                      searchSide)
                                                        );
                                                    setSearchListSurveyArr(
                                                        result
                                                    );
                                                }}
                                            >
                                                전체
                                            </div>

                                            <div
                                                className="survey_button"
                                                style={{
                                                    color:
                                                        searchAttend == 0
                                                            ? "#f76c6c"
                                                            : "#999",
                                                    borderColor:
                                                        searchAttend == 0
                                                            ? "#f76c6c"
                                                            : "#eaeaea",
                                                }}
                                                onClick={() => {
                                                    setSearchAttend(0);

                                                    const result =
                                                        surveyArr.filter(
                                                            (item) =>
                                                                (item.name.includes(
                                                                    searchText
                                                                ) &&
                                                                    item.attend ==
                                                                        0 &&
                                                                    (searchSide ==
                                                                    2
                                                                        ? item.side !==
                                                                          searchSide
                                                                        : item.side ==
                                                                          searchSide)) ||
                                                                (item.contact.includes(
                                                                    searchText
                                                                ) &&
                                                                    item.attend ==
                                                                        0 &&
                                                                    (searchSide ==
                                                                    2
                                                                        ? item.side !==
                                                                          searchSide
                                                                        : item.side ==
                                                                          searchSide))
                                                        );
                                                    setSearchListSurveyArr(
                                                        result
                                                    );
                                                }}
                                            >
                                                참석
                                            </div>

                                            <div
                                                className="survey_button"
                                                style={{
                                                    color:
                                                        searchAttend == 1
                                                            ? "#f76c6c"
                                                            : "#999",
                                                    borderColor:
                                                        searchAttend == 1
                                                            ? "#f76c6c"
                                                            : "#eaeaea",
                                                }}
                                                onClick={() => {
                                                    setSearchAttend(1);

                                                    const result =
                                                        surveyArr.filter(
                                                            (item) =>
                                                                (item.name.includes(
                                                                    searchText
                                                                ) &&
                                                                    item.attend ==
                                                                        1 &&
                                                                    (searchSide ==
                                                                    2
                                                                        ? item.side !==
                                                                          searchSide
                                                                        : item.side ==
                                                                          searchSide)) ||
                                                                (item.contact.includes(
                                                                    searchText
                                                                ) &&
                                                                    item.attend ==
                                                                        1 &&
                                                                    (searchSide ==
                                                                    2
                                                                        ? item.side !==
                                                                          searchSide
                                                                        : item.side ==
                                                                          searchSide))
                                                        );
                                                    setSearchListSurveyArr(
                                                        result
                                                    );
                                                }}
                                            >
                                                불참
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: 20 }}>
                                        <input
                                            style={{
                                                width: "98%",
                                                height: 45,
                                                border: "1px solid #eaeaea",
                                                borderRadius: 5,
                                            }}
                                            placeholder="성함 또는 연락처로 검색하세요"
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setSearchText(e.target.value);

                                                const result = surveyArr.filter(
                                                    (item) =>
                                                        (item.name.includes(
                                                            e.target.value
                                                        ) &&
                                                            (searchAttend == 2
                                                                ? item.attend !==
                                                                  searchAttend
                                                                : item.attend ==
                                                                  searchAttend) &&
                                                            (searchSide == 2
                                                                ? item.side !==
                                                                  searchSide
                                                                : item.side ==
                                                                  searchSide)) ||
                                                        (item.contact.includes(
                                                            e.target.value
                                                        ) &&
                                                            (searchAttend == 2
                                                                ? item.attend !==
                                                                  searchAttend
                                                                : item.attend ==
                                                                  searchAttend) &&
                                                            (searchSide == 2
                                                                ? item.side !==
                                                                  searchSide
                                                                : item.side ==
                                                                  searchSide))
                                                );
                                                setSearchListSurveyArr(result);
                                            }}
                                        />
                                    </div>

                                    {/* <div style={{ marginTop: 20 }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                backgroundColor: "#f76c6c",
                                                color: "#fff",
                                                height: 40,
                                                width: 160,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: 5,
                                                margin: "0 auto",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {}}
                                        >
                                            검색하기
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* 검색 옵션 */}

                            {/* 검색 결과 */}
                            <div
                                style={{
                                    borderRadius: 15,
                                    backgroundColor: "#fff",
                                    marginTop: 15,
                                }}
                            >
                                <div
                                    style={{
                                        padding: "15px 15px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        검색 결과 ({searchListSurveyArr.length}
                                        건)
                                    </span>
                                </div>

                                <div>
                                    {isLoading ? (
                                        <PaginationComponent
                                            itemsPerPage={10}
                                            surveyArr={surveyArr}
                                            searchListSurveyArr={
                                                searchListSurveyArr
                                            }
                                            getTrigger={getTrigger}
                                        />
                                    ) : (
                                        false
                                    )}
                                </div>
                            </div>
                            {/* 검색 결과 */}
                        </div>
                    </div>

                    <FooterComponent />
                </div>
            </div>
        </div>
    );
}
