import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CloseIcon from "@material-ui/icons/Close";
import { HiQrcode } from "react-icons/hi";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import { dbService } from "../../fbase";
import QrCodeComponent from "../QrCodeComponent/QrCodeComponent";

export default function AddressComponent({ letterId, state }) {
    const [isModal, setIsModal] = useState(false);
    const [modalNum, setModalNum] = useState(1);

    const getLetter = () => {
        var letterRef = dbService.collection("newletters");

        letterRef
            .doc(letterId)
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    // const letterData = res.data();

                    initKakaoShare({ state: res.data() });
                } else {
                }
            });
    };

    const transDate = ({ weddingDate, weddingTime }) => {
        var weekday = new Array();
        weekday[0] = "일요일";
        weekday[1] = "월요일";
        weekday[2] = "화요일";
        weekday[3] = "수요일";
        weekday[4] = "목요일";
        weekday[5] = "금요일";
        weekday[6] = "토요일";

        var date = "";
        var year = "";
        var month = "";
        var day = "";
        var hours = "";
        var min = "";
        var week = "";

        if (weddingDate) {
            date = new Date(weddingDate + "T09:00:00Z");
            year = date.getFullYear();
            month = date.getMonth() + 1 + "월";
            day = date.getDate() + "일";
            week = date.getDay();
        }
        if (weddingTime) {
            hours =
                weddingTime.slice(0, 2) === "24" ||
                weddingTime.slice(0, 2) === "12"
                    ? "낮 12시"
                    : weddingTime.slice(0, 2) > 12
                    ? " 오후 " + (weddingTime.slice(0, 2) * 1 - 12) + "시"
                    : " 오전 " + weddingTime.slice(0, 2) * 1 + "시";

            min =
                weddingTime.slice(3, 5) === "00"
                    ? ""
                    : weddingTime.slice(3, 5) + "분";
        }

        return `${month} ${day} ${weekday[week]} ${hours}${min}`;
    };

    const urlReturn = (letterId) => {
        let url = "";

        url = `https://mcard.cryucard.com/c/${letterId}`;

        return url;
    };

    const initKakaoShare = async ({ state }) => {
        // console.log(window.Kakao);
        await window.Kakao.Link.cleanup();

        if (state.mainImg) {
            await window.Kakao.Link.createDefaultButton({
                container: `#kakao-link-btn`,
                objectType: "feed",
                content: {
                    title: state.kakaoTitle
                        ? state.kakaoTitle
                        : `${state.hName ? state.hName : "신랑"}❤️${
                              state.wName ? state.wName : "신부"
                          } 결혼합니다.`,
                    description: state.kakaoContent
                        ? state.kakaoContent.split("<br/>").join("\n")
                        : transDate({
                              weddingDate: state.weddingDate,
                              weddingTime: state.weddingTime,
                          }),
                    imageUrl: state.kakaoThumb
                        ? state.kakaoThumb
                        : state.mainImg,
                    imageHeight: 800,
                    link: {
                        mobileWebUrl: urlReturn(letterId),
                        webUrl: urlReturn(letterId),
                        androidExecParams: "invite",
                    },
                },
                buttons: [
                    {
                        title: "청첩장 보기",
                        link: {
                            mobileWebUrl: urlReturn(letterId),
                            webUrl: urlReturn(letterId),
                        },
                    },
                ],
            });
        }
    };
    React.useEffect(() => {
        if (window.Kakao) {
            if (window.Kakao.isInitialized() === false) {
                window.Kakao.init("ceac62315cf4bfcb9030a1c46642f4ca");
            }
        }
    }, []);

    return (
        <div class="prod-address">
            <span class="prod-address-wrap">
                <span class="prod-address-span-1">청첩장 보기:</span>
                <a
                    href={`https://mcard.cryucard.com/c/${letterId}`}
                    target="_blank"
                    rel="noreferrer"
                    class="prod-address-span-2"
                >
                    mcard.cryucard.com/c/{letterId}
                </a>
            </span>

            <div
                class="prod-address-button"
                onClick={() => {
                    setIsModal(true);
                }}
            >
                공유하기
            </div>

            {isModal && (
                <div
                    style={{
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0,0,0,0.4)",
                        top: 0,
                        left: 0,
                        zIndex: 20000,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            maxWidth: 380,
                            margin: 16,
                            borderRadius: 15,
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                position: "absolute",
                                top: -45,
                                right: 0,
                                zIndex: 1000,
                                color: "#fff",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setIsModal(false);
                                setModalNum(1);
                            }}
                        >
                            <CloseIcon />
                        </div>

                        {modalNum === 1 ? (
                            <>
                                <div
                                    style={{
                                        borderBottom: "1px solid #ccc",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: 50,
                                        fontWeight: "bold",
                                        color: "#333",
                                    }}
                                >
                                    공유하기
                                </div>
                                <div
                                    style={{
                                        padding: "20px 30px 20px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: 80,
                                                height: 80,
                                                borderRadius: 40,
                                                cursor: "pointer",
                                                boxShadow:
                                                    "rgba(0, 0, 0, 0.18) 0px 0px 12px",
                                            }}
                                            onClick={() => {
                                                if (state.mainImg) {
                                                    getLetter(letterId);
                                                } else {
                                                    alert(
                                                        "청첩장 제작을 완료해 주세요"
                                                    );
                                                }
                                            }}
                                            id={`kakao-link-btn`}
                                        >
                                            <BsFillChatDotsFill
                                                style={{
                                                    width: 30,
                                                    height: 30,
                                                }}
                                            />
                                        </div>
                                        <span
                                            style={{
                                                fontSize: 12,
                                                marginTop: 8,
                                            }}
                                        >
                                            카카오톡 공유
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <CopyToClipboard
                                            text={`https://mcard.cryucard.com/c/${letterId}`}
                                            onCopy={() => {
                                                alert(
                                                    "청첩장 주소가 복사되었습니다. 필요하신 곳에 붙여넣기 하세요"
                                                );
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: 80,
                                                    height: 80,
                                                    borderRadius: 40,
                                                    cursor: "pointer",
                                                    boxShadow:
                                                        "rgba(0, 0, 0, 0.18) 0px 0px 12px",
                                                }}
                                            >
                                                <FaLink
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                    }}
                                                />
                                            </div>
                                        </CopyToClipboard>
                                        <span
                                            style={{
                                                fontSize: 12,
                                                marginTop: 8,
                                            }}
                                        >
                                            URL 복사하기
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                        onClick={() => {
                                            setModalNum(2);
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: 80,
                                                height: 80,
                                                borderRadius: 40,
                                                cursor: "pointer",
                                                boxShadow:
                                                    "rgba(0, 0, 0, 0.18) 0px 0px 12px",
                                            }}
                                        >
                                            <HiQrcode
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                }}
                                            />
                                        </div>
                                        <span
                                            style={{
                                                fontSize: 12,
                                                marginTop: 8,
                                            }}
                                        >
                                            QR 코드
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : modalNum === 2 ? (
                            <div
                                style={{
                                    maxWidth: 340,
                                    maxHeight: 550,
                                    position: "fixed",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    margin: "auto",
                                    backgroundColor: "#fff",
                                    borderRadius: 10,
                                    zIndex: 10000,
                                    overflow: "hidden",
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        height: 45,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: 14,
                                        borderBottom: `1px solid #ccc`,
                                    }}
                                >
                                    큐알코드 다운로드
                                </div>

                                <div style={{ height: 400 }}>
                                    <QrCodeComponent
                                        url={urlReturn(letterId)}
                                    />
                                </div>
                            </div>
                        ) : (
                            false
                        )}
                    </div>
                </div>
            )}

            {/* <CopyToClipboard
        text={`https://mcard.cryucard.com/c/${letterId}`}
        onCopy={() => {
            alert(
                "주소 복사가 완료되었습니다."
            );
        }}
    >
        <div class="prod-address-button">
            주소 복사
        </div>
    </CopyToClipboard> */}
        </div>
    );
}
