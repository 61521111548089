import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { dbService } from "../../fbase";
import { ThreeDots } from "react-loader-spinner";
import localStorage from "mobx-localstorage";
import axios from "axios";

export default function PathScreen() {
    const history = useHistory();

    const getUserId = new Promise((resolve, reject) => {
        let _getUserId = "";
        if (localStorage.getItem("userId")) {
            _getUserId = localStorage.getItem("userId");
            // setUserId(_getUserId);
        } else {
            // window.location.href = "/#/login";
            // localStorage.setItem("userId", "51543483");
            // reject(new Error("please login"));
        }

        resolve(_getUserId);
    });

    const getIpAddress = async () => {
        const res = await axios.get("https://geolocation-db.com/json/");

        return res.data;
    };

    const createdDate = () => {
        let weddingDate = new Date();
        let data;

        data = `${weddingDate.getFullYear()}-${
            weddingDate.getMonth() + 1 < 10
                ? "0" + (weddingDate.getMonth() + 1).toString()
                : weddingDate.getMonth() + 1
        }-${
            weddingDate.getDate() < 10
                ? "0" + weddingDate.getDate().toString()
                : weddingDate.getDate()
        }`;

        return data;
    };

    const createLetter = async ({ _userId }) => {
        const { userAgent, languages } = await window.navigator;

        const content = await {
            createdAt: Date.now(),
            userId: _userId,
            layoutArr: [
                { key: 0, label: "인삿말" },
                { key: 1, label: "예식일자" },
                { key: 2, label: "갤러리" },
                { key: 4, label: "오시는길" },
                { key: 3, label: "공지사항" },
                { key: 6, label: "방명록" },
                { key: 5, label: "참석여부" },
                {
                    key: 7,
                    label: "마음전하실 곳(화환)",
                },
            ],
            weddingDate: createdDate(),
            weddingTime: "13:00:00",
            waterMark: false,
            gallery: [],
            bgmOption: {
                value: "",
                label: "추가 안함",
            },
            surveyTitle: "참석 여부를 전달해주세요",
            surveyContent:
                "<p>결혼식 참석 전 참석여부를 응답해주시면<br/>결혼식 준비에 있어 큰 도움이 됩니다.<br/>한 분 한 분 더욱 귀하게 모실 수 있도록<br/>아래 버튼을 클릭하여 참석여부를<br/>전달 부탁드립니다.</p>",
            hAccount: "",
            hPapaAccount: "",
            hMamaAccount: "",
            wAccount: "",
            wPapaAccount: "",
            wMamaAccount: "",
            _userAgent: userAgent ? userAgent : "",
            _languages: languages ? languages : "",
        };

        await dbService
            .collection("newletters")
            .add(content)
            .then((res) => {
                history.push(`/product/${res.id}`);
            });
    };

    useEffect(() => {
        // getUserId();
        getUserId
            .then((_userId) => {
                if (history.action === "POP") {
                    history.push("/");
                    console.log("POP");
                } else {
                    // letter 생성
                    createLetter({
                        _userId: _userId,
                    });
                }
            })
            .catch((error) => console.log(error));
    }, []);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100vh",
                width: "100vw",
            }}
        >
            <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="#182e0c"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    );
}
