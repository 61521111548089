import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import NewSurveySearchComponent from "../SurveySearchComponent/NewSurveySearchComponent";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { CSVLink, CSVDownload } from "react-csv";
import { dbService } from "../../fbase";
var surveyRef = dbService.collection("newSurvey");

export default function NewPaginationComponent({
  itemsPerPage,
  surveyArr,
  searchListSurveyArr,
}) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const [downloadArr, setDownLoadArr] = useState([]);
  const [deleteList, setDeleteList] = useState([]);

  // let items = searchListSurveyArr;

  const [items, setItems] = useState(searchListSurveyArr);

  const funcDownloadArr = () => {
    surveyArr.map(
      ({
        side,
        attend,
        name,
        phoneNumber,
        party,
        partyNumber,
        message,
        surveyIsEat,
      }) => {
        const addArr = {
          하객분류: side == 0 ? "신랑측" : "신부측",
          응답자: name,
          참석여부: attend == 0 ? "참석" : "불참",
          식사여부:
            surveyIsEat === 1
              ? "식사 O"
              : surveyIsEat == 0
              ? "식사 X"
              : "미응답",
          연락처: phoneNumber,
          동행인수: partyNumber,
          메시지: message,
        };

        setDownLoadArr((oldArr) => [...oldArr, addArr]);
      }
    );
  };

  const getData = (data) => {
    if (deleteList.includes(data.data)) {
      setDeleteList(deleteList.filter((item) => item !== data.data));
    } else {
      setDeleteList((oldArray) => [...oldArray, data.data]);
    }
  };

  const deleteLetter = async () => {
    if (deleteList.length) {
      var confirm = window.confirm(
        "정말 삭제하시겠습니까? 삭제 후 복구가 불가합니다"
      );

      if (confirm) {
        const promise = deleteList.map(async (item) => {
          await surveyRef
            .doc(item)
            .delete()
            .then(() => {
              console.log("deleted!!");
            });
        });

        await Promise.all(promise);
        console.log("done");

        await window.location.reload();
      } else {
      }
    } else {
      console.log("없어");
      console.log(deleteList);
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  useEffect(() => {
    setItems(searchListSurveyArr);
  }, [searchListSurveyArr]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
    handlePageClick({ selected: 0 });
  }, [items]);

  useEffect(() => {
    funcDownloadArr();
  }, []);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  function Items({ currentItems }) {
    return (
      <div>
        {currentItems &&
          currentItems.map((item, index) => (
            <NewSurveySearchComponent
              attend={item.attend}
              side={item.side}
              contact={item.phoneNumber}
              letterId={item.letterId}
              name={item.name}
              party={item.party}
              partyNumber={item.partyNumber}
              createdAt={item.createdAt}
              message={item.message}
              key={index}
              getData={getData}
              id={item.surveyId}
              eating={item.surveyIsEat}
              isClicked={deleteList.includes(item.surveyId)}
            />
          ))}

        <div
          style={{
            display: "flex",
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: "#182e0c",
              color: "#fff",
              height: 40,
              width: "45%",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              margin: "0 auto",
              cursor: "pointer",
            }}
            onClick={() => {
              deleteLetter();
            }}
          >
            선택내용 삭제
          </div>

          {downloadArr ? (
            <CSVLink
              style={{
                display: "flex",
                backgroundColor: "#182e0c",
                color: "#fff",
                height: 40,
                width: "45%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                margin: "0 auto",
                cursor: "pointer",
                textDecoration: "none",
              }}
              data={downloadArr}
              filename={"크류카드_응답자_엑셀.csv"}
            >
              전체 엑셀 다운로드
            </CSVLink>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: "0 20px",
        padding: "15px 0px",
        borderTop: "1px solid #e9e9e9",
      }}
    >
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<ArrowLeftIcon fontSize={"small"} />}
        nextLabel={<ArrowRightIcon fontSize={"small"} />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
      />
    </div>
  );
}
