import React, { useCallback, useState } from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import { debounce } from "lodash";
import { dbService } from "../../fbase";

const getUrlRegEx = (url) => {
    var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);

    return match !== null ? true : false;
};

export default function VideoComponent({ state, getData, letterId }) {
    const [stateVideoUrl, setStateVideoUrl] = useState(
        state.videoUrl ? state.videoUrl : ""
    );

    const getText = (text, _parameter) => {
        setStateVideoUrl(text);

        if (text === "" || getUrlRegEx(text) !== false) {
            debounceGetText({
                _parameter: _parameter,
                text: text,
            });
        }
    };

    const getBlurText = (text) => {
        if (text === "" || getUrlRegEx(text) !== false) {
            onSubmit({ _videoUrl: text });
        }
    };

    const debounceGetText = useCallback(
        debounce(({ _parameter, text }) => {
            if (_parameter === "videoUrl") {
                onSubmit({ _videoUrl: text });
            }
        }, 500),
        []
    );

    const onSubmit = async ({ _videoUrl }) => {
        let content = {};

        let a = [...state.layoutArr, { key: 8, label: "비디오" }];
        let _layoutArr = state.layoutArr.length > 8 ? state.layoutArr : a;

        if (_videoUrl !== undefined) {
            content = {
                videoUrl: _videoUrl,
                layoutArr: _layoutArr,
            };
        }

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                pushData();
            });
    };

    const pushData = () => {
        getData({ isSaved: true });
    };

    return (
        <div>
            <div>
                <p
                    style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                    }}
                >
                    영상 추가
                </p>
                <p
                    style={{
                        marginTop: 5,
                        fontSize: "0.9rem",
                        color: "rgb(119, 119, 119)",
                    }}
                >
                    청첩장 내 영상추가는 유튜브에 업로드 후<br />
                    URL을 복사하여 추가해주시면 됩니다.
                    <br />
                    (예. https://www.youtube.com/watch?v=ABCDEF1234)
                </p>
            </div>

            <div style={{ marginTop: 5 }}>
                <div>
                    <SubTitleComponent title="유튜브 URL" />
                    <TextInputComponent
                        getText={(e) => {
                            getText(e.text, "videoUrl");
                        }}
                        getBlur={({ isBlur, text }) => {
                            if (isBlur) {
                                debounceGetText.cancel();
                                getBlurText(text);
                            }
                        }}
                        _value={state.videoUrl ? state.videoUrl : ""}
                        warning={
                            getUrlRegEx(stateVideoUrl) === false &&
                            stateVideoUrl.length !== 0
                                ? "url이 바르지 않습니다"
                                : ""
                        }
                    />
                </div>
            </div>
        </div>
    );
}
