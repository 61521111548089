import React, { useEffect, useState, useCallback } from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";

import { debounce } from "lodash";
import { dbService } from "../../fbase";
import CheckIcon from "@material-ui/icons/Check";
import Select from "react-select";

const options = [
  { value: "0", label: "고인이시면 체크해 주세요" },
  { value: "1", label: "故 한자 표기" },
  { value: "2", label: "국화꽃 표기" },
];

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    cursor: "pointer",
  }),
  control: (styles) => ({
    ...styles,
    cursor: "pointer",
    height: 30,
    backgroundColor: "none",
  }),
};

export default function BasicInfoComponent({
  getData,
  parameter,
  state,
  letterId,
}) {
  const pushData = () => {
    getData({ isSaved: true });
  };

  const [papaIsDead, setPapaIsDead] = useState({
    value: "0",
    label: "고인이시면 체크해 주세요",
  });

  const [mamaIsDead, setMamaIsDead] = useState({
    value: "0",
    label: "고인이시면 체크해 주세요",
  });

  const onSubmit = async ({
    _hFamilyName,
    _hName,
    _hPhoneNumber,
    _hPapaName,
    _hPapaPhoneNumber,
    _hMamaName,
    _hMamaPhoneNumber,
    _hRelation,

    _wFamilyName,
    _wName,
    _wPhoneNumber,
    _wPapaName,
    _wPapaPhoneNumber,
    _wMamaName,
    _wMamaPhoneNumber,
    _wRelation,

    _hPapaIsDead,
    _hMamaIsDead,
    _wPapaIsDead,
    _wMamaIsDead,
  }) => {
    let content = {};

    if (_hFamilyName !== undefined) {
      content = {
        hFamilyName: _hFamilyName,
      };
      console.log(_hFamilyName);
    } else if (_hName !== undefined) {
      content = {
        hName: _hName,
      };
      console.log(_hName);
    } else if (_hPhoneNumber !== undefined) {
      content = {
        hPhoneNumber: _hPhoneNumber,
      };
      console.log(_hPhoneNumber);
    } else if (_hPapaName !== undefined) {
      content = {
        hPapaName: _hPapaName,
      };
      console.log(_hPapaName);
    } else if (_hPapaPhoneNumber !== undefined) {
      content = {
        hPapaPhoneNumber: _hPapaPhoneNumber,
      };
      console.log(_hPapaPhoneNumber);
    } else if (_hMamaName !== undefined) {
      content = {
        hMamaName: _hMamaName,
      };
      console.log(_hMamaName);
    } else if (_hMamaPhoneNumber !== undefined) {
      content = {
        hMamaPhoneNumber: _hMamaPhoneNumber,
      };
      console.log(_hMamaPhoneNumber);
    } else if (_hRelation !== undefined) {
      content = { hRelation: _hRelation };
    } else if (_wFamilyName !== undefined) {
      content = {
        wFamilyName: _wFamilyName,
      };
    } else if (_wName !== undefined) {
      content = {
        wName: _wName,
      };
    } else if (_wPhoneNumber !== undefined) {
      content = {
        wPhoneNumber: _wPhoneNumber,
      };
    } else if (_wPapaName !== undefined) {
      content = {
        wPapaName: _wPapaName,
      };
    } else if (_wPapaPhoneNumber !== undefined) {
      content = {
        wPapaPhoneNumber: _wPapaPhoneNumber,
      };
    } else if (_wMamaName !== undefined) {
      content = {
        wMamaName: _wMamaName,
      };
    } else if (_wMamaPhoneNumber !== undefined) {
      content = {
        wMamaPhoneNumber: _wMamaPhoneNumber,
      };
    } else if (_wRelation !== undefined) {
      content = { wRelation: _wRelation };
    } else if (_hPapaIsDead !== undefined) {
      content = { hPapaIsDead: _hPapaIsDead };
    } else if (_wPapaIsDead !== undefined) {
      content = { wPapaIsDead: _wPapaIsDead };
    } else if (_hMamaIsDead !== undefined) {
      content = { hMamaIsDead: _hMamaIsDead };
    } else if (_wMamaIsDead !== undefined) {
      content = { wMamaIsDead: _wMamaIsDead };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  const debounceGetText = useCallback(
    debounce(
      ({
        _parameter,
        dFamilyName,
        dName,
        dPhoneNumber,
        dPapaName,
        dPapaPhoneNumber,
        dMamaName,
        dMamaPhoneNumber,
        dRelation,
      }) => {
        if (_parameter === "husband") {
          if (dFamilyName !== undefined) {
            // console.log(dFamilyName);

            onSubmit({ _hFamilyName: dFamilyName });
          } else if (dName !== undefined) {
            // console.log(dName);
            onSubmit({ _hName: dName });
          } else if (dPhoneNumber !== undefined) {
            onSubmit({ _hPhoneNumber: dPhoneNumber });
          } else if (dPapaName !== undefined) {
            onSubmit({ _hPapaName: dPapaName });
          } else if (dPapaPhoneNumber !== undefined) {
            onSubmit({ _hPapaPhoneNumber: dPapaPhoneNumber });
          } else if (dMamaName !== undefined) {
            onSubmit({ _hMamaName: dMamaName });
          } else if (dMamaPhoneNumber !== undefined) {
            onSubmit({ _hMamaPhoneNumber: dMamaPhoneNumber });
          } else if (dRelation !== undefined) {
            onSubmit({ _hRelation: dRelation });
          }
        } else {
          if (dFamilyName !== undefined) {
            // console.log(dFamilyName);

            onSubmit({ _wFamilyName: dFamilyName });
          } else if (dName !== undefined) {
            // console.log(dName);
            onSubmit({ _wName: dName });
          } else if (dPhoneNumber !== undefined) {
            onSubmit({ _wPhoneNumber: dPhoneNumber });
          } else if (dPapaName !== undefined) {
            onSubmit({ _wPapaName: dPapaName });
          } else if (dPapaPhoneNumber !== undefined) {
            onSubmit({ _wPapaPhoneNumber: dPapaPhoneNumber });
          } else if (dMamaName !== undefined) {
            onSubmit({ _wMamaName: dMamaName });
          } else if (dMamaPhoneNumber !== undefined) {
            onSubmit({ _wMamaPhoneNumber: dMamaPhoneNumber });
          } else if (dRelation !== undefined) {
            onSubmit({ _wRelation: dRelation });
          }
        }
      },
      1500
    ),
    []
  );

  const getText = (data, _parameter) => {
    // console.log(data.text);
    // pushData(data.text);

    if (parameter === "husband") {
      if (_parameter === "familyName") {
        debounceGetText({
          _parameter: parameter,
          dFamilyName: data.text,
        });
      } else if (_parameter === "name") {
        debounceGetText({
          _parameter: parameter,
          dName: data.text,
        });
      } else if (_parameter === "phoneNumber") {
        debounceGetText({
          _parameter: parameter,
          dPhoneNumber: data.text,
        });
      } else if (_parameter === "papaName") {
        debounceGetText({
          _parameter: parameter,
          dPapaName: data.text,
        });
      } else if (_parameter === "papaPhoneNumber") {
        debounceGetText({
          _parameter: parameter,
          dPapaPhoneNumber: data.text,
        });
      } else if (_parameter === "mamaName") {
        debounceGetText({
          _parameter: parameter,
          dMamaName: data.text,
        });
      } else if (_parameter === "mamaPhoneNumber") {
        debounceGetText({
          _parameter: parameter,
          dMamaPhoneNumber: data.text,
        });
      } else if (_parameter === "relation") {
        debounceGetText({
          _parameter: parameter,
          dRelation: data.text,
        });
      }
    } else if (parameter === "wife") {
      if (_parameter === "familyName") {
        debounceGetText({
          _parameter: parameter,
          dFamilyName: data.text,
        });
      } else if (_parameter === "name") {
        debounceGetText({
          _parameter: parameter,
          dName: data.text,
        });
      } else if (_parameter === "phoneNumber") {
        debounceGetText({
          _parameter: parameter,
          dPhoneNumber: data.text,
        });
      } else if (_parameter === "papaName") {
        debounceGetText({
          _parameter: parameter,
          dPapaName: data.text,
        });
      } else if (_parameter === "papaPhoneNumber") {
        debounceGetText({
          _parameter: parameter,
          dPapaPhoneNumber: data.text,
        });
      } else if (_parameter === "mamaName") {
        debounceGetText({
          _parameter: parameter,
          dMamaName: data.text,
        });
      } else if (_parameter === "mamaPhoneNumber") {
        debounceGetText({
          _parameter: parameter,
          dMamaPhoneNumber: data.text,
        });
      } else if (_parameter === "relation") {
        debounceGetText({
          _parameter: parameter,
          dRelation: data.text,
        });
      }
    }
  };

  const getBlurText = (text, _parameter) => {
    // console.log(data.text);
    // pushData(data.text);

    if (parameter === "husband") {
      if (_parameter === "familyName") {
        onSubmit({ _hFamilyName: text });
      } else if (_parameter === "name") {
        onSubmit({ _hName: text });
      } else if (_parameter === "phoneNumber") {
        onSubmit({ _hPhoneNumber: text });
      } else if (_parameter === "papaName") {
        onSubmit({ _hPapaName: text });
      } else if (_parameter === "papaPhoneNumber") {
        onSubmit({ _hPapaPhoneNumber: text });
      } else if (_parameter === "mamaName") {
        onSubmit({ _hMamaName: text });
      } else if (_parameter === "mamaPhoneNumber") {
        onSubmit({ _hMamaPhoneNumber: text });
      } else if (_parameter === "relation") {
        onSubmit({ _hRelation: text });
      }
    } else if (parameter === "wife") {
      if (_parameter === "familyName") {
        onSubmit({ _wFamilyName: text });
      } else if (_parameter === "name") {
        onSubmit({ _wName: text });
      } else if (_parameter === "phoneNumber") {
        onSubmit({ _wPhoneNumber: text });
      } else if (_parameter === "papaName") {
        onSubmit({ _wPapaName: text });
      } else if (_parameter === "papaPhoneNumber") {
        onSubmit({ _wPapaPhoneNumber: text });
      } else if (_parameter === "mamaName") {
        onSubmit({ _wMamaName: text });
      } else if (_parameter === "mamaPhoneNumber") {
        onSubmit({ _wMamaPhoneNumber: text });
      } else if (_parameter === "relation") {
        onSubmit({ _wRelation: text });
      }
    }
  };

  const papaIsDeadHandleChange = (_papaIsDead) => {
    const { value } = _papaIsDead;
    setPapaIsDead(_papaIsDead);

    if (parameter === "husband") {
      onSubmit({ _hPapaIsDead: _papaIsDead });
    } else if (parameter === "wife") {
      onSubmit({ _wPapaIsDead: _papaIsDead });
    }
  };

  const mamaIsDeadHandleChange = (_mamaIsDead) => {
    const { value } = _mamaIsDead;
    setMamaIsDead(_mamaIsDead);

    if (parameter === "husband") {
      onSubmit({ _hMamaIsDead: _mamaIsDead });
    } else if (parameter === "wife") {
      onSubmit({ _wMamaIsDead: _mamaIsDead });
    }
  };

  return (
    <div>
      <div>
        <SubTitleComponent
          title={`${parameter === "husband" ? "신랑" : "신부"} 성함`}
        />

        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <TextInputComponent
              placeholder={`${parameter === "husband" ? "신랑" : "신부"} 성`}
              getText={(e) => {
                getText(e, "familyName");
              }}
              _value={
                parameter === "husband"
                  ? state.hFamilyName
                    ? state.hFamilyName
                    : ""
                  : state.wFamilyName
                  ? state.wFamilyName
                  : ""
              }
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "familyName");
                }
              }}
            />
          </div>

          <div style={{ flex: 2, marginLeft: 8 }}>
            <TextInputComponent
              placeholder={`${parameter === "husband" ? "신랑" : "신부"} 이름`}
              getText={(e) => {
                getText(e, "name");
              }}
              _value={
                parameter === "husband"
                  ? state.hName
                    ? state.hName
                    : ""
                  : state.wName
                  ? state.wName
                  : ""
              }
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "name");
                }
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <SubTitleComponent
          title={`${parameter === "husband" ? "신랑" : "신부"} 연락처`}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <TextInputComponent
              placeholder="01012345678"
              onlyNumber={true}
              getText={(e) => {
                getText(e, "phoneNumber");
              }}
              _value={
                parameter === "husband"
                  ? state.hPhoneNumber
                    ? state.hPhoneNumber
                    : ""
                  : state.wPhoneNumber
                  ? state.wPhoneNumber
                  : ""
              }
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "phoneNumber");
                }
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <SubTitleComponent
          title={`${parameter === "husband" ? "신랑" : "신부"} 서열`}
        />
        <TextInputComponent
          getText={(e) => {
            getText(e, "relation");
          }}
          _value={
            parameter === "husband"
              ? state.hRelation
                ? state.hRelation
                : ""
              : state.wRelation
              ? state.wRelation
              : ""
          }
          placeholder={
            parameter === "husband" ? "아들, 장남, 차남" : "딸, 장녀, 차녀"
          }
          getBlur={({ isBlur, text }) => {
            if (isBlur) {
              debounceGetText.cancel();
              getBlurText(text, "relation");
            }
          }}
        />
      </div>

      <div>
        <SubTitleComponent
          title={`${parameter === "husband" ? "신랑" : "신부"} 아버님 성함`}
        />
        <TextInputComponent
          getText={(e) => {
            getText(e, "papaName");
          }}
          _value={
            parameter === "husband"
              ? state.hPapaName
                ? state.hPapaName
                : ""
              : state.wPapaName
              ? state.wPapaName
              : ""
          }
          getBlur={({ isBlur, text }) => {
            if (isBlur) {
              debounceGetText.cancel();
              getBlurText(text, "papaName");
            }
          }}
        />
        <div style={{ marginTop: 10 }}>
          <Select
            value={papaIsDead}
            onChange={papaIsDeadHandleChange}
            options={options}
            isSearchable={false}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#eef1ed",
                primary: "#182e0c",
              },
            })}
          />
        </div>
      </div>

      <div>
        <SubTitleComponent
          title={`${parameter === "husband" ? "신랑" : "신부"} 아버님 연락처`}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <TextInputComponent
              placeholder="01012345678"
              onlyNumber={true}
              getText={(e) => {
                getText(e, "papaPhoneNumber");
              }}
              _value={
                parameter === "husband"
                  ? state.hPapaPhoneNumber
                    ? state.hPapaPhoneNumber
                    : ""
                  : state.wPapaPhoneNumber
                  ? state.wPapaPhoneNumber
                  : ""
              }
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "papaPhoneNumber");
                }
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <SubTitleComponent
          title={`${parameter === "husband" ? "신랑" : "신부"} 어머님 성함`}
        />
        <TextInputComponent
          getText={(e) => {
            getText(e, "mamaName");
          }}
          _value={
            parameter === "husband"
              ? state.hMamaName
                ? state.hMamaName
                : ""
              : state.wMamaName
              ? state.wMamaName
              : ""
          }
          getBlur={({ isBlur, text }) => {
            if (isBlur) {
              debounceGetText.cancel();
              getBlurText(text, "mamaName");
            }
          }}
        />

        <div style={{ marginTop: 10 }}>
          <Select
            value={mamaIsDead}
            onChange={mamaIsDeadHandleChange}
            options={options}
            isSearchable={false}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#eef1ed",
                primary: "#182e0c",
              },
            })}
          />
        </div>
      </div>

      <div>
        <SubTitleComponent
          title={`${parameter === "husband" ? "신랑" : "신부"} 어머님 연락처`}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <TextInputComponent
              placeholder="01012345678"
              onlyNumber={true}
              getText={(e) => {
                getText(e, "mamaPhoneNumber");
              }}
              _value={
                parameter === "husband"
                  ? state.hMamaPhoneNumber
                    ? state.hMamaPhoneNumber
                    : ""
                  : state.wMamaPhoneNumber
                  ? state.wMamaPhoneNumber
                  : ""
              }
              getBlur={({ isBlur, text }) => {
                if (isBlur) {
                  debounceGetText.cancel();
                  getBlurText(text, "mamaPhoneNumber");
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
