import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import localStorage from "mobx-localstorage";
import buttonImg from "../../assets/images/notice-button.png";

const HidiHeader = (props) => {
    const logOut = () => {
        console.log("logout");
        localStorage.setItem("userId", "");
        // localStorage.setItem("userEmail", "");
        window.location.href = "/#/hidiLogin";
    };

    useEffect(() => {}, []);
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 50,
                    padding: "10px 25px",
                }}
            >
                <Link
                    to="/hidi"
                    style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        textDecoration: "none",
                        color: "#333",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    크류카드
                    <span
                        style={{
                            fontSize: 12,
                            padding: "2px 5px",
                            fontWeight: "500",
                            backgroundColor: "#ffc837",
                            color: "#fff",
                            borderRadius: 5,
                            marginLeft: 7,
                            marginTop: 5,
                        }}
                    >
                        with 하이디
                    </span>
                </Link>

                {props.userId ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 12,
                        }}
                    >
                        {/* <span style={{ padding: "2px 5px", borderRight: "1px solid #999" }}>
              {props.userId}님
            </span> */}
                        <span
                            style={{
                                padding: "2px 5px",
                                color: "#888",
                                cursor: "pointer",
                            }}
                            onClick={() => logOut()}
                        >
                            로그아웃
                        </span>
                    </div>
                ) : (
                    false
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px 20px",
                    backgroundColor: "#f76c6c",
                    position: "relative",
                }}
            >
                {/* <a
                    href="https://four-tartan-efa.notion.site/1a53a52e7d35452eb4aeb7490f40de03?v=2752d41f07d5472aabd36ecf4410d3e1"
                    target="_blank"
                    style={{
                        position: "absolute",

                        bottom: -5,
                        right: 90,
                    }}
                >
                    <img
                        src={buttonImg}
                        style={{
                            width: 100,
                            height: 100,
                        }}
                    />
                </a> */}
                <div>
                    <Link
                        to="/hidi"
                        style={{
                            padding: "2px 5px",
                            fontSize: 18,
                            fontWeight: "500",
                            color: "#fff",
                            textDecoration: "none",
                        }}
                    >
                        M청첩장
                    </Link>

                    {/* <Link
            to="/torderlist"
            style={{
              padding: "2px 5px",
              fontSize: 18,
              fontWeight: "500",
              color: "#fff",
              textDecoration: "none",
            }}
          >
            M감사장
          </Link> */}
                </div>
                <Link
                    to="/hidiList"
                    style={{
                        padding: "2px 5px",
                        fontWeight: "500",
                        color: "#fff",
                        textDecoration: "none",
                    }}
                >
                    제작내역
                </Link>
            </div>
        </div>
    );
};

export default HidiHeader;
