import React, { useState, useRef, useCallback, useEffect } from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import { debounce } from "lodash";
import { dbService } from "../../fbase";
import kakaoPayImg1 from "../../assets/images/kakaopay_1.jpg";
import kakaoPayImg2 from "../../assets/images/kakaopay_2.jpg";
import ModalComponent from "../ModalComponent/ModalComponent";
import QrReader from "react-qr-reader";
import CheckIcon from "@material-ui/icons/Check";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AccountComponent = ({
    title,
    _parameter,
    _kakaoPay,
    _bank,
    _account,
    _accountName,
    getText,
    setParameter,
    setIsModal,
    debounceGetText,
    getBlurText,
    resetQrCode,
}) => {
    return (
        <div style={{ marginTop: 15 }}>
            <SubTitleComponent title={title} />
            <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                    <TextInputComponent
                        placeholder={"은행"}
                        getText={(e) => {
                            getText(e.text, `${_parameter}Bank`);
                        }}
                        _value={_bank}
                        getBlur={({ isBlur, text }) => {
                            if (isBlur) {
                                debounceGetText.cancel();
                                getBlurText(text, `${_parameter}Bank`);
                            }
                        }}
                    />
                </div>

                <div style={{ flex: 1, marginLeft: 8 }}>
                    <TextInputComponent
                        placeholder={"예금주"}
                        getText={(e) => {
                            getText(e.text, `${_parameter}AccountName`);
                        }}
                        _value={_accountName}
                        getBlur={({ isBlur, text }) => {
                            if (isBlur) {
                                debounceGetText.cancel();
                                getBlurText(text, `${_parameter}AccountName`);
                            }
                        }}
                    />
                </div>
            </div>
            <div style={{ marginTop: 8 }}>
                <TextInputComponent
                    placeholder={"계좌번호"}
                    getText={(e) => {
                        getText(e.text, `${_parameter}Account`);
                    }}
                    _value={_account}
                    getBlur={({ isBlur, text }) => {
                        if (isBlur) {
                            debounceGetText.cancel();
                            getBlurText(text, `${_parameter}Account`);
                        }
                    }}
                />
            </div>

            <div
                style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    cursor: "pointer",
                }}
                onClick={() => {
                    setParameter(_parameter);
                    setIsModal(true);

                    resetQrCode("", { _parameter: _parameter });
                }}
            >
                <div
                    style={{
                        width: 16,
                        height: 16,
                        border: "1px solid ",
                        backgroundColor: _kakaoPay ? "#724ff3" : "#e9e9e9",
                        borderColor: _kakaoPay ? "#724ff3" : "#e9e9e9",
                        borderRadius: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontSize: 5,
                    }}
                >
                    <CheckIcon fontSize="small" />
                </div>
                <span
                    style={{
                        fontSize: "0.8rem",
                        color: "#333",
                        lineHeight: "0.8rem",
                        marginLeft: 5,
                    }}
                >
                    카카오페이를 추가합니다
                </span>
            </div>
        </div>
    );
};

export default function AccountNumberComponent({ state, getData, letterId }) {
    const [isModal, setIsModal] = useState(false);
    const [parameter, setParameter] = useState("");
    const [accountTitle, setAccountTitle] = useState(
        state.accountTitle !== undefined ? state.accountTitle : "마음 전하실 곳"
    );

    const [accountContent, setAccountContent] = useState(
        state.accountContent !== undefined ? state.accountContent : ""
    );

    const [hBank, setHBank] = useState(
        state.hBank !== undefined ? state.hBank : ""
    );
    const [hAccountName, setHAccountName] = useState(
        state.hAccountName !== undefined ? state.hAccountName : ""
    );
    const [hAccount, setHAccount] = useState(
        state.hAccount !== undefined ? state.hAccount : ""
    );

    const [hKakao, setHKakao] = useState(
        state.hKakao !== undefined ? state.hKakao : ""
    );

    const [wBank, setWBank] = useState(
        state.wBank !== undefined ? state.wBank : ""
    );
    const [wAccountName, setWAccountName] = useState(
        state.wAccountName !== undefined ? state.wAccountName : ""
    );
    const [wAccount, setWAccount] = useState(
        state.wAccount !== undefined ? state.wAccount : ""
    );

    const [wKakao, setWKakao] = useState(
        state.wKakao !== undefined ? state.wKakao : ""
    );

    const [hPapaBank, setHPapaBank] = useState(
        state.hPapaBank !== undefined ? state.hPapaBank : ""
    );
    const [hPapaAccountName, setHPapaAccountName] = useState(
        state.hPapaAccountName !== undefined ? state.hPapaAccountName : ""
    );
    const [hPapaAccount, setHPapaAccount] = useState(
        state.hPapaAccount !== undefined ? state.hPapaAccount : ""
    );

    const [hPapaKakao, setHPapaKakao] = useState(
        state.hPapaKakao !== undefined ? state.hPapaKakao : ""
    );

    const [wPapaBank, setWPapaBank] = useState(
        state.wPapaBank !== undefined ? state.wPapaBank : ""
    );
    const [wPapaAccountName, setWPapaAccountName] = useState(
        state.wPapaAccountName !== undefined ? state.wPapaAccountName : ""
    );
    const [wPapaAccount, setWPapaAccount] = useState(
        state.wPapaAccount !== undefined ? state.wPapaAccount : ""
    );

    const [wPapaKakao, setWPapaKakao] = useState(
        state.wPapaKakao !== undefined ? state.wPapaKakao : ""
    );

    const [hMamaBank, setHMamaBank] = useState(
        state.hMamaBank !== undefined ? state.hMamaBank : ""
    );
    const [hMamaAccountName, setHMamaAccountName] = useState(
        state.hMamaAccountName !== undefined ? state.hMamaAccountName : ""
    );
    const [hMamaAccount, setHMamaAccount] = useState(
        state.hMamaAccount !== undefined ? state.hMamaAccount : ""
    );

    const [hMamaKakao, setHMamaKakao] = useState(
        state.hMamaKakao !== undefined ? state.hMamaKakao : ""
    );

    const [wMamaBank, setWMamaBank] = useState(
        state.wMamaBank !== undefined ? state.wMamaBank : ""
    );
    const [wMamaAccountName, setWMamaAccountName] = useState(
        state.wMamaAccountName !== undefined ? state.wMamaAccountName : ""
    );
    const [wMamaAccount, setWMamaAccount] = useState(
        state.wMamaAccount !== undefined ? state.wMamaAccount : ""
    );

    const [wMamaKakao, setWMamaKakao] = useState(
        state.wMamaKakao !== undefined ? state.wMamaKakao : ""
    );

    const qrRef = useRef(null);

    const getText = (text, _parameter) => {
        if (_parameter === "husbandBank") {
            setHBank(text);
        } else if (_parameter === "husbandAccount") {
            setHAccount(text);
        } else if (_parameter === "husbandAccountName") {
            setHAccountName(text);
        } else if (_parameter === "wifeBank") {
            setWBank(text);
        } else if (_parameter === "wifeAccount") {
            setWAccount(text);
        } else if (_parameter === "wifeAccountName") {
            setWAccountName(text);
        } else if (_parameter === "hPapaBank") {
            setHPapaBank(text);
        } else if (_parameter === "hPapaAccount") {
            setHPapaAccount(text);
        } else if (_parameter === "hPapaAccountName") {
            setHPapaAccountName(text);
        } else if (_parameter === "hMamaBank") {
            setHMamaBank(text);
        } else if (_parameter === "hMamaAccount") {
            setHMamaAccount(text);
        } else if (_parameter === "hMamaAccountName") {
            setHMamaAccountName(text);
        } else if (_parameter === "wPapaBank") {
            setWPapaBank(text);
        } else if (_parameter === "wPapaAccount") {
            setWPapaAccount(text);
        } else if (_parameter === "wPapaAccountName") {
            setWPapaAccountName(text);
        } else if (_parameter === "wMamaBank") {
            setWMamaBank(text);
        } else if (_parameter === "wMamaAccount") {
            setWMamaAccount(text);
        } else if (_parameter === "wMamaAccountName") {
            setWMamaAccountName(text);
        }

        debounceGetText({
            _parameter: _parameter,
            text: text,
        });
    };

    const getBlurText = (text, _parameter) => {
        if (_parameter === "accountTitle") {
            onSubmit({ _accountTitle: text });
        } else if (_parameter === "accountContent") {
            onSubmit({ _accountContent: text });
        } else if (_parameter === "husbandBank") {
            onSubmit({ _hBank: text });
        } else if (_parameter === "husbandAccount") {
            onSubmit({ _hAccount: text });
        } else if (_parameter === "husbandAccountName") {
            onSubmit({ _hAccountName: text });
        } else if (_parameter === "wifeBank") {
            onSubmit({ _wBank: text });
        } else if (_parameter === "wifeAccount") {
            onSubmit({ _wAccount: text });
        } else if (_parameter === "wifeAccountName") {
            onSubmit({ _wAccountName: text });
        } else if (_parameter === "hPapaBank") {
            onSubmit({ _hPapaBank: text });
        } else if (_parameter === "hPapaAccount") {
            onSubmit({ _hPapaAccount: text });
        } else if (_parameter === "hPapaAccountName") {
            onSubmit({ _hPapaAccountName: text });
        } else if (_parameter === "hMamaBank") {
            onSubmit({ _hMamaBank: text });
        } else if (_parameter === "hMamaAccount") {
            onSubmit({ _hMamaAccount: text });
        } else if (_parameter === "hMamaAccountName") {
            onSubmit({ _hMamaAccountName: text });
        } else if (_parameter === "wPapaBank") {
            onSubmit({ _wPapaBank: text });
        } else if (_parameter === "wPapaAccount") {
            onSubmit({ _wPapaAccount: text });
        } else if (_parameter === "wPapaAccountName") {
            onSubmit({ _wPapaAccountName: text });
        } else if (_parameter === "wMamaBank") {
            onSubmit({ _wMamaBank: text });
        } else if (_parameter === "wMamaAccount") {
            onSubmit({ _wMamaAccount: text });
        } else if (_parameter === "wMamaAccountName") {
            onSubmit({ _wMamaAccountName: text });
        }
    };

    const debounceGetText = useCallback(
        debounce(({ _parameter, text }) => {
            if (_parameter === "accountTitle") {
                onSubmit({ _accountTitle: text });
            } else if (_parameter === "accountContent") {
                onSubmit({ _accountContent: text });
            } else if (_parameter === "husbandBank") {
                onSubmit({ _hBank: text });
            } else if (_parameter === "husbandAccount") {
                onSubmit({ _hAccount: text });
            } else if (_parameter === "husbandAccountName") {
                onSubmit({ _hAccountName: text });
            } else if (_parameter === "wifeBank") {
                onSubmit({ _wBank: text });
            } else if (_parameter === "wifeAccount") {
                onSubmit({ _wAccount: text });
            } else if (_parameter === "wifeAccountName") {
                onSubmit({ _wAccountName: text });
            } else if (_parameter === "hPapaBank") {
                onSubmit({ _hPapaBank: text });
            } else if (_parameter === "hPapaAccount") {
                onSubmit({ _hPapaAccount: text });
            } else if (_parameter === "hPapaAccountName") {
                onSubmit({ _hPapaAccountName: text });
            } else if (_parameter === "hMamaBank") {
                onSubmit({ _hMamaBank: text });
            } else if (_parameter === "hMamaAccount") {
                onSubmit({ _hMamaAccount: text });
            } else if (_parameter === "hMamaAccountName") {
                onSubmit({ _hMamaAccountName: text });
            } else if (_parameter === "wPapaBank") {
                onSubmit({ _wPapaBank: text });
            } else if (_parameter === "wPapaAccount") {
                onSubmit({ _wPapaAccount: text });
            } else if (_parameter === "wPapaAccountName") {
                onSubmit({ _wPapaAccountName: text });
            } else if (_parameter === "wMamaBank") {
                onSubmit({ _wMamaBank: text });
            } else if (_parameter === "wMamaAccount") {
                onSubmit({ _wMamaAccount: text });
            } else if (_parameter === "wMamaAccountName") {
                onSubmit({ _wMamaAccountName: text });
            }
        }, 1500),
        []
    );

    const onSubmit = async ({
        _husbandKakao,
        _wifeKakao,
        _hPapaKakao,
        _hMamaKakao,
        _wPapaKakao,
        _wMamaKakao,
        _accountTitle,
        _accountContent,
        _hBank,
        _hAccount,
        _hAccountName,
        _hPapaBank,
        _hPapaAccount,
        _hPapaAccountName,
        _hMamaBank,
        _hMamaAccount,
        _hMamaAccountName,
        _wBank,
        _wAccount,
        _wAccountName,
        _wPapaBank,
        _wPapaAccount,
        _wPapaAccountName,
        _wMamaBank,
        _wMamaAccount,
        _wMamaAccountName,
    }) => {
        let content = {};

        if (_husbandKakao !== undefined) {
            content = {
                hKakao: _husbandKakao,
            };
        } else if (_wifeKakao !== undefined) {
            content = {
                wKakao: _wifeKakao,
            };
        } else if (_hPapaKakao !== undefined) {
            content = {
                hPapaKakao: _hPapaKakao,
            };
        } else if (_hMamaKakao !== undefined) {
            content = {
                hMamaKakao: _hMamaKakao,
            };
        } else if (_wPapaKakao !== undefined) {
            content = {
                wPapaKakao: _wPapaKakao,
            };
        } else if (_wMamaKakao !== undefined) {
            content = {
                wMamaKakao: _wMamaKakao,
            };
        } else if (_accountTitle !== undefined) {
            content = {
                accountTitle: _accountTitle,
            };
        } else if (_accountContent !== undefined) {
            content = {
                accountContent: _accountContent,
            };
        } else if (_hBank !== undefined) {
            content = {
                hBank: _hBank,
            };
        } else if (_hAccount !== undefined) {
            content = {
                hAccount: _hAccount,
            };
        } else if (_hAccountName !== undefined) {
            content = {
                hAccountName: _hAccountName,
            };
        } else if (_wBank !== undefined) {
            content = {
                wBank: _wBank,
            };
        } else if (_wAccount !== undefined) {
            content = {
                wAccount: _wAccount,
            };
        } else if (_wAccountName !== undefined) {
            content = {
                wAccountName: _wAccountName,
            };
        } else if (_hPapaBank !== undefined) {
            content = {
                hPapaBank: _hPapaBank,
            };
        } else if (_hPapaAccount !== undefined) {
            content = {
                hPapaAccount: _hPapaAccount,
            };
        } else if (_hPapaAccountName !== undefined) {
            content = {
                hPapaAccountName: _hPapaAccountName,
            };
        } else if (_hMamaBank !== undefined) {
            content = {
                hMamaBank: _hMamaBank,
            };
        } else if (_hMamaAccount !== undefined) {
            content = {
                hMamaAccount: _hMamaAccount,
            };
        } else if (_hMamaAccountName !== undefined) {
            content = {
                hMamaAccountName: _hMamaAccountName,
            };
        } else if (_wPapaBank !== undefined) {
            content = {
                wPapaBank: _wPapaBank,
            };
        } else if (_wPapaAccount !== undefined) {
            content = {
                wPapaAccount: _wPapaAccount,
            };
        } else if (_wPapaAccountName !== undefined) {
            content = {
                wPapaAccountName: _wPapaAccountName,
            };
        } else if (_wMamaBank !== undefined) {
            content = {
                wMamaBank: _wMamaBank,
            };
        } else if (_wMamaAccount !== undefined) {
            content = {
                wMamaAccount: _wMamaAccount,
            };
        } else if (_wMamaAccountName !== undefined) {
            content = {
                wMamaAccountName: _wMamaAccountName,
            };
        }

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                pushData();
            });
    };

    const pushData = () => {
        getData({ isSaved: true });
    };

    const handleChange = (text, parameter) => {
        setAccountContent(text);
        getText(text, parameter);
    };

    // qr func
    const getIsModal = (data) => {
        // console.log(data);
        setIsModal(data.isModal);
    };

    const qrErrorFile = (error) => {
        console.log(error);
    };

    const resetQrCode = (result, { _parameter }) => {
        switch (_parameter) {
            case "husband":
                onSubmit({ _husbandKakao: result });
                setHKakao(false);
                break;

            case "wife":
                onSubmit({ _wifeKakao: result });
                setWKakao(false);

                break;

            case "hPapa":
                onSubmit({ _hPapaKakao: result });
                setHPapaKakao(false);

                break;

            case "hMama":
                onSubmit({ _hMamaKakao: result });
                setHMamaKakao(false);

                break;

            case "wPapa":
                onSubmit({ _wPapaKakao: result });
                setWPapaKakao(false);
                break;

            case "wMama":
                onSubmit({ _wMamaKakao: result });
                setWMamaKakao(false);
                break;

            default:
                break;
        }
    };

    const qrHandleScanFile = (result, { _parameter }) => {
        if (result) {
            // setHusbandKakao(result);
            // setIsHusbandKakaoModal(false);
            // console.log(result);
            // console.log(_parameter);

            switch (_parameter) {
                case "husband":
                    onSubmit({ _husbandKakao: result });
                    setHKakao(true);
                    break;

                case "wife":
                    onSubmit({ _wifeKakao: result });
                    setWKakao(true);

                    break;

                case "hPapa":
                    onSubmit({ _hPapaKakao: result });
                    setHPapaKakao(true);

                    break;

                case "hMama":
                    onSubmit({ _hMamaKakao: result });
                    setHMamaKakao(true);

                    break;

                case "wPapa":
                    onSubmit({ _wPapaKakao: result });
                    setWPapaKakao(true);
                    break;

                case "wMama":
                    onSubmit({ _wMamaKakao: result });
                    setWMamaKakao(true);
                    break;

                default:
                    break;
            }
            setIsModal(false);
        } else {
            // setHusbandKakao("");
            alert("카카오페이가 올바르지 않습니다");
        }
    };

    const onScanFile = () => {
        qrRef.current.openImageDialog();
    };

    // qr func

    const ModalContent = () => {
        return (
            <div>
                <div style={{ textAlign: "center", padding: "20px 0" }}>
                    <div
                        style={{
                            display: "flex",
                            width: 20,
                            height: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: 12,
                            backgroundColor: "#724ff3",
                            borderRadius: 15,
                            fontWeight: "bold",
                            margin: "0 auto",
                        }}
                    >
                        1
                    </div>

                    <div style={{ fontSize: 12, paddingTop: 5 }}>
                        카카오톡의 더보기 페이지 상단의
                        <br />
                        <b style={{ color: "#724ff3" }}>큐알코드 아이콘</b>을
                        클릭해주세요
                    </div>
                </div>
                <img src={kakaoPayImg1} style={{ width: "100%" }} />
                <div style={{ textAlign: "center", padding: "20px 0" }}>
                    <div
                        style={{
                            display: "flex",
                            width: 20,
                            height: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: 12,
                            backgroundColor: "#724ff3",
                            borderRadius: 15,
                            fontWeight: "bold",
                            margin: "0 auto",
                        }}
                    >
                        2
                    </div>

                    <div style={{ fontSize: 12, paddingTop: 5 }}>
                        송금코드 버튼 을 선택 후<br />
                        <b style={{ color: "#724ff3" }}>다운로드 버튼</b>을 눌러
                        큐알코드를 다운해주세요
                    </div>
                </div>
                <img src={kakaoPayImg2} style={{ width: "100%" }} />

                <div style={{ textAlign: "center", padding: "20px 0" }}>
                    <div
                        style={{
                            display: "flex",
                            width: 20,
                            height: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: 12,
                            backgroundColor: "#724ff3",
                            borderRadius: 15,
                            fontWeight: "bold",
                            margin: "0 auto",
                        }}
                    >
                        3
                    </div>

                    <div style={{ fontSize: 12, paddingTop: 5 }}>
                        다운로드한 큐알코드를 아래의
                        <br />
                        <b style={{ color: "#724ff3" }}>카카오페이 연동 버튼</b>
                        을 클릭하여 업로드해주세요
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        backgroundColor: "#724ff3",
                        width: 300,
                        height: 50,
                        cursor: "pointer",
                        margin: "0 auto",
                        borderRadius: 10,
                    }}
                    onClick={onScanFile}
                >
                    카카오페이 연동
                </div>

                <QrReader
                    ref={qrRef}
                    delay={300}
                    onError={qrErrorFile}
                    onScan={(props) => {
                        qrHandleScanFile(props, { _parameter: parameter });
                    }}
                    legacyMode
                    style={{
                        display: "none",
                    }}
                />
            </div>
        );
    };

    return (
        <div>
            <div>
                <SubTitleComponent
                    title={"제목"}
                    tooltip={
                        <div>연락처 및 계좌번호 위에 노출될 소제목입니다.</div>
                    }
                />
                <TextInputComponent
                    placeholder={"마음 전하실 곳"}
                    getText={(e) => {
                        getText(e.text, "accountTitle");
                    }}
                    _value={accountTitle}
                    getBlur={({ isBlur, text }) => {
                        if (isBlur) {
                            debounceGetText.cancel();
                            getBlurText(text, "accountTitle");
                        }
                    }}
                />
            </div>
            <div style={{ marginTop: 16 }}>
                <ReactQuill
                    theme={"snow"}
                    onChange={(e) => {
                        handleChange(e, "accountContent");
                    }}
                    value={accountContent}
                    modules={{
                        toolbar: [
                            [
                                "bold",
                                "italic",
                                "underline",
                                // { list: "ordered" },
                                // { list: "bullet" },
                                { align: ["center", "", "right"] },
                            ],
                        ],
                        clipboard: {
                            // toggle to add extra line breaks when pasting HTML:
                            matchVisual: false,
                        },
                    }}
                    formats={[
                        "bold",
                        "italic",
                        "underline",
                        // "list",
                        // "bullet",
                        "align",
                    ]}
                    bounds={".app"}
                    placeholder={"내용을 입력하세요 (선택사항)"}
                    onBlur={() => {
                        debounceGetText.cancel();
                        getBlurText(accountContent, "accountContent");
                    }}
                />
            </div>

            <div>
                <AccountComponent
                    title="신랑"
                    _parameter="husband"
                    _kakaoPay={hKakao}
                    _bank={hBank}
                    _account={hAccount}
                    _accountName={hAccountName}
                    getText={getText}
                    setParameter={setParameter}
                    setIsModal={setIsModal}
                    debounceGetText={debounceGetText}
                    getBlurText={getBlurText}
                    resetQrCode={resetQrCode}
                />
                <AccountComponent
                    title="신부"
                    _parameter="wife"
                    _kakaoPay={wKakao}
                    _bank={wBank}
                    _account={wAccount}
                    _accountName={wAccountName}
                    getText={getText}
                    setParameter={setParameter}
                    setIsModal={setIsModal}
                    debounceGetText={debounceGetText}
                    getBlurText={getBlurText}
                    resetQrCode={resetQrCode}
                />
                <AccountComponent
                    title="신랑 아버지"
                    _parameter="hPapa"
                    _kakaoPay={hPapaKakao}
                    _bank={hPapaBank}
                    _account={hPapaAccount}
                    _accountName={hPapaAccountName}
                    getText={getText}
                    setParameter={setParameter}
                    setIsModal={setIsModal}
                    debounceGetText={debounceGetText}
                    getBlurText={getBlurText}
                    resetQrCode={resetQrCode}
                />
                <AccountComponent
                    title="신랑 어머니"
                    _parameter="hMama"
                    _kakaoPay={hMamaKakao}
                    _bank={hMamaBank}
                    _account={hMamaAccount}
                    _accountName={hMamaAccountName}
                    getText={getText}
                    setParameter={setParameter}
                    setIsModal={setIsModal}
                    debounceGetText={debounceGetText}
                    getBlurText={getBlurText}
                    resetQrCode={resetQrCode}
                />
                <AccountComponent
                    title="신부 아버지"
                    _parameter="wPapa"
                    _kakaoPay={wPapaKakao}
                    _bank={wPapaBank}
                    _account={wPapaAccount}
                    _accountName={wPapaAccountName}
                    getText={getText}
                    setParameter={setParameter}
                    setIsModal={setIsModal}
                    debounceGetText={debounceGetText}
                    getBlurText={getBlurText}
                    resetQrCode={resetQrCode}
                />
                <AccountComponent
                    title="신부 어머니"
                    _parameter="wMama"
                    _kakaoPay={wMamaKakao}
                    _bank={wMamaBank}
                    _account={wMamaAccount}
                    _accountName={wMamaAccountName}
                    getText={getText}
                    setParameter={setParameter}
                    setIsModal={setIsModal}
                    debounceGetText={debounceGetText}
                    getBlurText={getBlurText}
                    resetQrCode={resetQrCode}
                />
            </div>

            {/* <div
                style={{
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    zIndex: 1000,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#fff",
                        maxWidth: 380,
                        width: "100%",
                        height: 500,
                        borderRadius: 10,
                        overflow: "scroll",
                        margin: "0 16px",
                    }}
                >
                    <div
                        style={{
                            borderBottom: "1px solid #e3e3e3",
                            padding: "13px 0",
                            textAlign: "center",
                            fontSize: 14,
                        }}
                    >
                        카카오페이 연동 하기
                    </div>
                    <div style={{ padding: "20px 0" }}>
                        <div style={{ textAlign: "center", padding: "20px 0" }}>
                            <div
                                style={{
                                    display: "flex",
                                    width: 20,
                                    height: 20,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: 12,
                                    backgroundColor: "#724ff3",
                                    borderRadius: 15,
                                    fontWeight: "bold",
                                    margin: "0 auto",
                                }}
                            >
                                1
                            </div>

                            <div style={{ fontSize: 12, paddingTop: 5 }}>
                                카카오톡의 더보기 페이지 상단의
                                <br />
                                <b style={{ color: "#724ff3" }}>
                                    큐알코드 아이콘
                                </b>
                                을 클릭해주세요
                            </div>
                        </div>
                        <img src={kakaoPayImg1} style={{ width: "100%" }} />
                        <div style={{ textAlign: "center", padding: "20px 0" }}>
                            <div
                                style={{
                                    display: "flex",
                                    width: 20,
                                    height: 20,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: 12,
                                    backgroundColor: "#724ff3",
                                    borderRadius: 15,
                                    fontWeight: "bold",
                                    margin: "0 auto",
                                }}
                            >
                                2
                            </div>

                            <div style={{ fontSize: 12, paddingTop: 5 }}>
                                송금코드 버튼 을 선택 후<br />
                                <b style={{ color: "#724ff3" }}>
                                    다운로드 버튼
                                </b>
                                을 눌러 큐알코드를 다운해주세요
                            </div>
                        </div>
                        <img src={kakaoPayImg2} style={{ width: "100%" }} />

                        <div style={{ textAlign: "center", padding: "20px 0" }}>
                            <div
                                style={{
                                    display: "flex",
                                    width: 20,
                                    height: 20,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: 12,
                                    backgroundColor: "#724ff3",
                                    borderRadius: 15,
                                    fontWeight: "bold",
                                    margin: "0 auto",
                                }}
                            >
                                3
                            </div>

                            <div style={{ fontSize: 12, paddingTop: 5 }}>
                                다운로드한 큐알코드를 아래의
                                <br />
                                <b style={{ color: "#724ff3" }}>
                                    카카오페이 연동 버튼
                                </b>
                                을 클릭하여 업로드해주세요
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                                backgroundColor: "#724ff3",
                                width: 300,
                                height: 50,
                                cursor: "pointer",
                                margin: "0 auto",
                                borderRadius: 10,
                            }}
                        >
                            카카오페이 연동
                        </div>
                    </div>
                </div>
            </div> */}

            <ModalComponent
                title="카카오페이 연동 하기"
                isModal={isModal}
                getIsModal={getIsModal}
                item={ModalContent()}
            />
        </div>
    );
}
