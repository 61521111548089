import React from "react";
import Footer from "../../components/NewComponents/Footer/Footer";
import Header from "../../components/NewComponents/Header/Header";
import QnAComponent from "../../components/NewComponents/QnAComponent/QnAComponent";
import localStorage from "mobx-localstorage";
import HeaderVer2 from "../../components/NewComponents/HeaderVer2/HeaderVer2";

import { useLocation } from "react-router-dom";

const qeustion1 = [
  {
    title: "(오늘 당장 필요해요) 제작 기간은 얼마나 걸리나요?",
    des: (
      <div>
        셀프 제작이므로 <b>즉시 제작</b>이 가능합니다.
        <br />
        로그인 후 청첩장 제작 버튼을 누르면 청첩장 제작 페이지로 이동합니다.
      </div>
    ),
  },
  {
    title: "(결제방법, 워터마크 제거) 어떻게 사용하나요?",
    des: (
      <div>
        <p>
          <b>STEP 1. 무료시안 제작</b>
        </p>
        <p>1. 로그인</p>
        <p>2. 청첩장 시안 제작(무료)</p>
        <p>3. 마이페이지에서 제작된 시안 확인</p>

        <p style={{ marginTop: 20 }}>
          <b>STEP 2. 워터마크 제거</b>
        </p>
        <p>4. 네이버쇼핑 구매 페이지에서 결제하기</p>
        <p>5. 제작내역 → 카드 좌측 하단 '워터마크 제거' 버튼 클릭</p>
        <p>6. 네이버쇼핑에서 결제한 네이버ID 입력</p>
      </div>
    ),
  },
  {
    title: "워터마크 제거 후에도 수정이 가능한가요?",
    des: <div>워터마크 제거 후에도 제한없이 수정이 가능합니다.</div>,
  },
  {
    title: "언제까지 사용할 수 있나요?",
    des: (
      <div>
        예식일 <b>6개월 전</b>부터 <b>예식 후 30일</b>까지의 호스팅 기간을
        제공하고 있습니다.
        <br />
        (제작 후 최대 <b>210일</b>간의 호스팅 기간 제공)
        <br />
        <br />
        단, 워터마크를 제거하지 않은 상품의 경우 제작일로부터 7일 이후에 자동
        삭제가 되니 주의 바랍니다.
      </div>
    ),
  },
];

const qeustion2 = [
  {
    title: "예식장 주소를 입력했는데 지도에서 해당 위치가 이상하게 나와요",
    des: (
      <div>
        도로명 주소 말고 <b>지번 주소</b>로 입력해주시면 핀 위치 정확하게 확인
        가능합니다
      </div>
    ),
  },
  {
    title: "갤러리에 몇장 까지 업로드 가능한가요?",
    des: (
      <div>
        <b>최대 30장</b>까지 업로드 가능합니다
      </div>
    ),
  },
  {
    title: "갤러리에서 사진을 추가했는데 정렬이 안맞아요",
    des: (
      <div>
        갤러리 사진 비율은 따로 제한이 없으며 모든 사진의 비율이 똑같게 보여지길
        원하시면 <b>모든 사진의 가로, 세로 비율</b>을 동일하게 편집하셔서 첨부
        해주시면 됩니다.
      </div>
    ),
  },
  {
    title: "계좌번호에서 카카오페이를 추가했는데 수수료가 따로 발생하나요?",
    des: (
      <div>
        저희측에서 따로 가져가는 수수료는 없으며 카카오페이 코드 송금은{" "}
        <b>무료</b>로 이용가능합니다.
      </div>
    ),
  },
  {
    title: "참석자 응답조사 결과는 어디에서 확인하나요?",
    des: (
      <div>
        참석자 응답조사 결과는{" "}
        <b>[크류카드 모바일 청첩장 제작페이지] - [제작내역] - [분석보기]</b>{" "}
        버튼 클릭하여 이동된 페이지에서 조회 가능합니다.
      </div>
    ),
  },
  {
    title: "배경음악을 자동재생이 안됩니다",
    des: (
      <div>
        카카오톡 브라우저가 아닌 다른 브라우저로 열람 시에는 스팸 자동 차단
        정책에 따라 자동재생이 되지 않을 수 있습니다.
      </div>
    ),
  },
  {
    title: "모바일청첩장 썸네일이 수정 되지 않아요",
    des: (
      <div>
        <b>썸네일 내용 수정 후 30분 이후</b>에 수정사항이 반영됩니다. 30분이
        지났는데도 반영되지 않은 경우에는 크류카드 카카오채널로 연락
        부탁드립니다.
      </div>
    ),
  },
];

export default function QuestionScreen() {
  const [userId, setUserId] = React.useState("");

  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getUserId = async () => {
    let _getUserId = "";

    if (localStorage.getItem("userId")) {
      _getUserId = localStorage.getItem("userId");
      setUserId(_getUserId);

      // console.log("userId=>" + _getUserId);

      // await getList(getUserId);
      // await getThanksList(getUserId);
    } else {
      console.log("noEmail");
      // window.location.href = "/#/login";
    }
  };

  React.useEffect(() => {
    getUserId();
  }, []);

  return (
    <div style={{ color: "#222" }}>
      <HeaderVer2 userId={userId} />

      <div style={{ minHeight: "100vh" }}>
        <div className="sub-page-container">
          <p className="sub-page-title">
            <b>크류카드 고객센터입니다</b>
            <br />
            무엇을 도와드릴까요?
          </p>
        </div>

        <div
          style={{
            maxWidth: 800,
            margin: "80px auto 0",
            padding: "0 16px",
          }}
        >
          <div>
            <div className="question-title">제작/결제</div>
            <div className="question-wrap">
              {qeustion1.map((item) => {
                return <QnAComponent title={item.title} des={item.des} />;
              })}
            </div>
          </div>

          <div style={{ paddingTop: 30 }}>
            <div className="question-title">기능</div>
            <div className="question-wrap">
              {qeustion2.map((item) => {
                return <QnAComponent title={item.title} des={item.des} />;
              })}
            </div>
          </div>
        </div>
      </div>

      <Footer userId={userId} />
    </div>
  );
}
