import React, { useState } from "react";

const RadioButtonGroup = ({ options, name, onChange }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
      {options.map((option, index) => (
        <label
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "8px",
            fontSize: 14,
            cursor: "pointer",
          }}
        >
          <input
            type="radio"
            value={option.value}
            name={name}
            checked={selectedValue === option.value}
            onChange={handleChange}
          />
          <span style={{ paddingLeft: 5 }}>{option.label}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
