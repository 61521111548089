import React, { useState } from "react";
import TextInputComponent from "../TextInputComponent/TextInputComponent";

export default function TextInputPhoneComponent({ getPhoneNumber, type }) {
    const [phoneNumberFirst, setPhoneNumberFirst] = useState("010");
    const [phoneNumberMiddle, setPhoneNumberMiddle] = useState("");
    const [phoneNumberLast, setPhoneNumberLast] = useState("");

    const getText = (data) => {
        // console.log(data);
        const { text, type } = data;

        switch (type) {
            case "numberFirst":
                setPhoneNumberFirst(text);

                getPhoneNumber({
                    phoneNumber: `${text}${phoneNumberMiddle}${phoneNumberLast}`,
                    type: type,
                });
                break;

            case "numberMiddle":
                setPhoneNumberMiddle(text);
                getPhoneNumber({
                    phoneNumber: `${phoneNumberFirst}${text}${phoneNumberLast}`,
                    type: type,
                });

                break;

            case "numberLast":
                setPhoneNumberLast(text);
                getPhoneNumber({
                    phoneNumber: `${phoneNumberFirst}${phoneNumberMiddle}${text}`,
                    type: type,
                });

                break;

            default:
                break;
        }
    };
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div style={{ flex: 1 }}>
                    <TextInputComponent
                        placeholder="010"
                        getText={getText}
                        onlyNumber={true}
                        type="numberFirst"
                        maxLength={4}
                        _value={phoneNumberFirst}
                    />
                </div>
                <div
                    style={{
                        display: "inline-block",
                        width: 10,
                        height: 1.5,
                        backgroundColor: "rgb(209 213 219)",
                        margin: "0 5px",
                    }}
                />
                <div style={{ flex: 1 }}>
                    <TextInputComponent
                        placeholder="1234"
                        getText={getText}
                        onlyNumber={true}
                        type="numberMiddle"
                        maxLength={"4"}
                    />
                </div>
                <div
                    style={{
                        display: "inline-block",
                        width: 10,
                        height: 1.5,
                        backgroundColor: "rgb(209 213 219)",
                        margin: "0 5px",
                    }}
                />
                <div style={{ flex: 1 }}>
                    <TextInputComponent
                        placeholder="5678"
                        getText={getText}
                        onlyNumber={true}
                        type="numberLast"
                        maxLength={4}
                    />
                </div>
            </div>
        </div>
    );
}
