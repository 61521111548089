import React, { useState, useCallback } from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";
import { dbService } from "../../fbase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export default function SurveyComponent({ state, getData, letterId }) {
  const [surveyContent, setSurveyContent] = useState(
    state.surveyContent ? state.surveyContent : ""
  );

  const [isSurveyModal, setIsSurveyModal] = useState(
    state.isSurveyModal ? state.isSurveyModal : false
  );

  const [isEat, setIsEat] = useState(state.isEat ? state.isEat : false);

  const handleChange = (text) => {
    setSurveyContent(text);
    getText(text, "content");
  };

  const onChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;

    if (name === "isSurveyModal") {
      setIsSurveyModal(checked);
      onSubmit({ _isSurveyModal: checked });
    } else if (name === "isEat") {
      setIsEat(checked);
      onSubmit({ _isEat: checked });
    }
  };

  const getText = (text, _parameter) => {
    // console.log(data.text);
    // pushData(data.text);

    debounceGetText({
      _parameter: _parameter,
      text: text,
    });
  };

  const debounceGetText = useCallback(
    debounce(({ _parameter, text }) => {
      if (_parameter === "content") {
        onSubmit({ _surveyContent: text });
      } else if (_parameter === "title") {
        onSubmit({ _surveyTitle: text });
      }
    }, 1500),
    []
  );

  const getBlurText = (text, _parameter) => {
    if (_parameter === "content") {
      onSubmit({ _surveyContent: text });
    } else if (_parameter === "title") {
      onSubmit({ _surveyTitle: text });
    }
  };

  const onSubmit = async ({
    _surveyContent,
    _surveyTitle,
    _isSurveyModal,
    _isEat,
  }) => {
    let content = {};

    if (_surveyContent !== undefined) {
      content = {
        surveyContent: _surveyContent,
      };
      console.log(_surveyContent);
    } else if (_surveyTitle !== undefined) {
      content = {
        surveyTitle: _surveyTitle,
      };
      console.log(_surveyTitle);
    } else if (_isSurveyModal !== undefined) {
      content = {
        isSurveyModal: _isSurveyModal,
      };
    } else if (_isEat !== undefined) {
      content = {
        isEat: _isEat,
      };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  const pushData = () => {
    getData({ isSaved: true });
  };

  return (
    <div>
      <div>
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          참석자 응답조사
        </p>
        <p
          style={{
            marginTop: 5,
            fontSize: "0.9rem",
            color: "rgb(119, 119, 119)",
          }}
        >
          청첩장 내에 참석자 조사기능이 추가됩니다.
          <br />
          <span style={{ fontSize: "0.7rem" }}>
            (설문에 응답한 참석자는 분석보기 페이지에서 확인가능합니다.)
          </span>
        </p>
      </div>

      <div style={{ marginTop: 5 }}>
        <div>
          <SubTitleComponent title="참석자 응답조사 제목" />
          <TextInputComponent
            getText={(e) => {
              getText(e.text, "title");
            }}
            _value={state.surveyTitle ? state.surveyTitle : ""}
            getBlur={({ isBlur, text }) => {
              if (isBlur) {
                debounceGetText.cancel();
                getBlurText(text, "title");
              }
            }}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <ReactQuill
            theme={"snow"}
            onChange={(e) => {
              handleChange(e, "content");
            }}
            value={surveyContent}
            modules={{
              toolbar: [
                [
                  "bold",
                  "italic",
                  "underline",
                  // { list: "ordered" },
                  // { list: "bullet" },
                  // { align: ["center", "", "right"] },
                ],
              ],
              clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
              },
            }}
            formats={[
              "bold",
              "italic",
              "underline",
              // "list",
              // "bullet",
              "align",
            ]}
            bounds={".app"}
            placeholder={" 내용을 입력하세요"}
            onBlur={() => {
              debounceGetText.cancel();
              getBlurText(surveyContent, "content");
            }}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name="isEat"
                checked={isEat}
                onChange={onChange}
                style={{
                  paddingLeft: 0,
                }}
              />
            }
            label="응답조사 내용에 식사 여부도 포함합니다."
            id="custom_check"
          />
        </div>

        <div style={{}}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name="isSurveyModal"
                checked={isSurveyModal}
                onChange={onChange}
                style={{
                  paddingLeft: 0,
                }}
              />
            }
            label="청첩장 접속 시 바로 참석조사창이 바로 보이도록 설정합니다."
            id="custom_check"
          />
        </div>
      </div>
    </div>
  );
}
