import React, { useState, useEffect } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

function getSteps() {
  return [
    "사진 업로드",
    "신랑측 정보",
    "신부측 정보",
    "인삿말 선택",
    "예식 장소",
    "제작 완료",
  ];
}
const StepperComponent = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  useEffect(() => {
    console.log(props.step);
    setActiveStep(props.step);
  }, []);

  return (
    <div>
      <p
        style={{ padding: "36px 20px 20px", fontSize: 16, fontWeight: "bold" }}
      >
        모바일청첩장 제작과정
      </p>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default StepperComponent;
