import React, { useState } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const SortableItem = sortableElement(({ value }) => (
    <div
        style={{
            borderLeft: "0.5px solid #555",
            borderRight: "0.5px solid #555",
            borderTop: "0.5px solid #555",
            borderBottom: "0.5px solid #555",
            padding: "12px 12px",
            color: "#333",
            cursor: "pointer",
        }}
    >
        {value}
    </div>
));

const SortableContainer = sortableContainer(({ children }) => {
    return (
        <div
            style={{
                borderLeft: "0.5px solid #555",
                borderRight: "0.5px solid #555",
                borderTop: "0.5px solid #555",
                borderBottom: "0.5px solid #555",
            }}
        >
            {children}
        </div>
    );
});

export default function LayoutChangeComponent(props) {
    const [state, setState] = useState(props.layoutArr);

    const pushData = (data) => {
        props.getLayoutArr({ data });
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const draggedData = arrayMoveImmutable(state, oldIndex, newIndex);
        setState(draggedData);

        pushData(draggedData);
    };

    return (
        <div>
            <SortableContainer onSortEnd={onSortEnd}>
                {state.map((item, index) => (
                    <SortableItem
                        key={item.key}
                        index={index}
                        value={item.label}
                    />
                ))}
            </SortableContainer>
        </div>
    );
}
