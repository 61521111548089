import React, { useState, useEffect } from "react";
import localStorage from "mobx-localstorage";
import { dbService } from "../../fbase";
import eventImg from "../../assets/images/event-1.png";
export default function EventScreen() {
    const [userId, setUserId] = useState("");

    const getEmail = async () => {
        let getUserId = "";

        if (localStorage.getItem("userId")) {
            getUserId = await localStorage.getItem("userId");
            // await setIsLoading(true);
            setUserId(getUserId);
        } else {
            console.log("noEmail");
        }

        // await console.log(getUserId);
    };

    useEffect(() => {
        getEmail();
    }, []);

    return (
        <div>
            <div
                style={{
                    backgroundColor: "rgb(238 238 238)",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    position: "relative",
                    minHeight: "100vh",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#fff",
                        maxWidth: 480,
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            height: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottom: "1px solid #e9e9e9",
                            fontSize: 15,
                        }}
                    >
                        이벤트 참여
                    </div>
                    <img
                        src={eventImg}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                        }}
                    />

                    <div
                        style={{
                            backgroundColor: "#036341",
                            padding: "0px 16px 20px",
                            color: "#e9e9e9",
                            fontSize: "0.8rem",
                            lineHeight: "1.5rem",
                        }}
                    >
                        <div>
                            <p
                                style={{
                                    fontSize: "0.9rem",
                                    fontWeight: "bold",
                                }}
                            >
                                참여방법
                            </p>
                            <p>1. 크류카드에서 청첩장을 구매 후 제작해주세요</p>
                            <p>2. 제작후 네이버 리뷰를 작성합니다.</p>
                            <p>
                                3. 웨딩카페, 블로그, 유튜브, 인스타에 크류카드
                                포토 리뷰 작성 후 아래에 신청서 작성해주시면
                                이벤트 참여 완료!
                            </p>
                        </div>

                        <a
                            href="https://naver.me/5ok3y2iZ"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <div
                                style={{
                                    width: 200,
                                    height: 40,
                                    backgroundColor: "#fff",
                                    color: "#333",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",

                                    borderRadius: 8,

                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    margin: "2rem auto 0rem",
                                }}
                            >
                                이벤트 신청서 작성하기
                            </div>
                        </a>

                        <div
                            style={{
                                marginTop: "3rem",
                                borderTop: "1px dashed #fff",
                                paddingTop: "1.5rem",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "0.9rem",
                                    fontWeight: "bold",
                                }}
                            >
                                주의사항
                            </p>
                            <p>
                                • 리뷰이벤트 적용 대상 : 크류카드 모바일 청첩장
                                구매 고객(타사 제품 제외)
                            </p>

                            <p>
                                • 웨딩카페, 블로그, 유튜브, 인스타 택1 후기 필수
                                작성이며 글은 반드시 전체 공개로 작성 해주세요.
                            </p>

                            <p>
                                • 네이버 상품 구매평은 PC기준 3줄 이상 작성
                                해주세요.
                            </p>

                            <p>
                                • SNS 후기 작성 시 크류카드 모바일 청첩장의
                                언급이 없을 시 제외됩니다.
                            </p>

                            <p>
                                • 카페 멤버 공개,삭제 예정 게시판으로 옮겨진
                                경우 제외됩니다.
                            </p>

                            <p>
                                • 사은품은 한 달 단위로 취합하여 익월 첫째 주에
                                문자로 일괄 발송 됩니다.
                            </p>

                            <p>
                                • 인스타그램 스토리 게시물은 리뷰 이벤트
                                대상에서 제외됩니다.
                            </p>

                            <p>
                                • 유령 계정 및 비공개 계정으로는 참여가
                                불가합니다.
                            </p>

                            <p>• 한 ID당 1회 참여 가능합니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
