import React from "react";
import banner_2 from "../../assets/images/banner_2.png";
export default function FooterComponent() {
  return (
    <div
      style={{
        backgroundColor: "#E5E3DD",
        position: "relative",
        bottom: 0,
        width: "100%",
        maxWidth: 480,
        height: 180,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <img src={banner_2} style={{ width: 120 }} />
      </div>

      <div style={{ fontSize: 12 }}>
        <a
          href="https://www.notion.so/cryu/89b4a7e95a6e40d6b7c046b6eefb3500"
          target="_blank"
          style={{ color: "#333", textDecoration: "none" }}
        >
          이용약관
        </a>
        <span style={{ padding: "0px 5px" }}>|</span>
        <a
          href="https://www.notion.so/cryu/ad53a459ad3c42bab061c3b83bec0376"
          target="_blank"
          style={{ color: "#333", textDecoration: "none" }}
        >
          개인정보처리방침
        </a>
      </div>
      <div
        style={{
          fontSize: 12,
          paddingTop: 5,
          paddingBottom: 20,
          color: "#999",
        }}
      >
        COPYRIGHT© CRYUCARD ALL RIGHT RESERVED{" "}
      </div>
    </div>
  );
}
