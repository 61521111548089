import React, { useState, useCallback } from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import Modal from "react-modal";
import DaumPostcode from "react-daum-postcode";
import { useEffect } from "react";
import Geocode from "react-geocode";
import NaverMapComponent from "../NaverMapComponent/NaverMapComponent";
import { debounce } from "lodash";
import { dbService } from "../../fbase";

const key = "AIzaSyD5sv8gbtmY5KQklXlMtUpgpvP22vldaOU";

export default function WeddingLocationComponent({ state, getData, letterId }) {
    const [isAddressModal, setIsAddressModal] = useState(false);
    const [latitude, setLatitude] = useState(state.lat ? state.lat : 0);
    const [longitude, setLongitude] = useState(state.lng ? state.lng : 0);
    const [weddingAddress, setWeddingAddress] = useState(
        state.weddingAddress ? state.weddingAddress : ""
    );
    const [weddingLocation1, setWeddingLocation1] = useState(
        state.weddingLocation1 ? state.weddingLocation1 : ""
    );
    const [weddingLocation2, setWeddingLocation2] = useState(
        state.weddingLocation2 ? state.weddingLocation2 : ""
    );
    const [zoneCode, setZonecode] = useState("");
    const [isMap, setIsMap] = useState(
        state.isMap !== undefined ? state.isMap : true
    );
    const [isMapFixed, setIsMapFixed] = useState(
        state.isMapFixed !== undefined ? state.isMapFixed : false
    );

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            width: 330,
            maxHeight: 500,
            paddingLeft: 15,
            paddingRight: 15,
        },
        overlay: {
            backgroundColor: "rgb(0,0,0,0.5)",
            zIndex: 3000,
        },
    };

    const getMapGeoData = (data) => {
        setLatitude(data.lat);
        setLongitude(data.lng);

        debounceGetText({ _parameter: "geo", lat: data.lat, lng: data.lng });
    };

    const getIsMapData = (data) => {
        setIsMap(data.isMap);

        onSubmit({ _isMap: data.isMap });
    };

    const getIsMapFixed = (data) => {
        setIsMapFixed(data.isMapFixed);
        onSubmit({ _isMapFixed: data.isMapFixed });
    };

    const handleComplete = (data) => {
        console.log("handleComplete");
        let fullAddress = data.address;

        getGeocode(fullAddress);
        setZonecode(data.zonecode);
        setWeddingAddress(fullAddress);
        getText(fullAddress, "weddingAddress");
        setIsAddressModal(false);
    };

    const getGeocode = (address) => {
        console.log("getGeocode");

        Geocode.fromAddress(address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                console.log(lat, lng);
                setLatitude(lat);
                setLongitude(lng);
                onSubmit({ _weddingAddress: address });

                onSubmit({ _lat: lat });
                onSubmit({ _lng: lng });
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const pushData = () => {
        getData({ isSaved: true });
    };

    const onSubmit = async ({
        _weddingAddress,
        _lat,
        _lng,
        _pinLat,
        _pinLng,
        _isMap,
        _isMapFixed,
        _weddingLocation1,
        _weddingLocation2,
    }) => {
        let content = {};

        if (_weddingAddress !== undefined) {
            content = {
                weddingAddress: _weddingAddress,
            };
            console.log(_weddingAddress);
        } else if (_lat !== undefined) {
            content = {
                lat: _lat,
            };
            console.log(_lat);
        } else if (_lng !== undefined) {
            content = {
                lng: _lng,
            };
            console.log(_lng);
        } else if (_pinLat !== undefined && _pinLng !== undefined) {
            content = {
                lat: _pinLat,
                lng: _pinLng,
            };
        } else if (_isMap !== undefined) {
            content = {
                isMap: _isMap,
            };
        } else if (_isMapFixed !== undefined) {
            content = {
                isMapFixed: _isMapFixed,
            };
        } else if (_weddingLocation1 !== undefined) {
            content = {
                weddingLocation1: _weddingLocation1,
            };
        } else if (_weddingLocation2 !== undefined) {
            content = {
                weddingLocation2: _weddingLocation2,
            };
        }

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                pushData();
            });
    };

    const debounceGetText = useCallback(
        debounce(({ _parameter, text, lat, lng }) => {
            if (_parameter === "weddingAddress") {
                onSubmit({ _weddingAddress: text });
            } else if (_parameter === "lat") {
                onSubmit({ _lat: text });
            } else if (_parameter === "lng") {
                onSubmit({ _lng: text });
            } else if (_parameter === "geo") {
                onSubmit({ _pinLat: lat, _pinLng: lng });
            } else if (_parameter === "weddingLocation1") {
                onSubmit({ _weddingLocation1: text });
            } else if (_parameter === "weddingLocation2") {
                onSubmit({ _weddingLocation2: text });
            }
        }, 1000),
        []
    );

    const getBlurText = (text, _parameter) => {
        if (_parameter === "weddingLocation1") {
            onSubmit({ _weddingLocation1: text });
        } else if (_parameter === "weddingLocation2") {
            onSubmit({ _weddingLocation2: text });
        } else if (_parameter === "weddingAddress") {
            onSubmit({ _weddingAddress: text });
        }
    };

    const getText = (data, _parameter) => {
        // console.log(data.text);
        // pushData(data.text);

        debounceGetText({
            _parameter: _parameter,
            text: data.text,
        });
    };

    useEffect(() => {
        Geocode.setApiKey(key);
        Geocode.setLanguage("ko");
        Geocode.setRegion("ko");
    });

    return (
        <div style={{ position: "relative" }}>
            <div
                onClick={() => {
                    setIsAddressModal(true);
                }}
            >
                <SubTitleComponent title="예식장 주소" />
                <TextInputComponent
                    _value={weddingAddress}
                    getText={(e) => {
                        getText(e, "weddingAddress");
                    }}
                    getBlur={({ isBlur, text }) => {
                        if (isBlur) {
                            debounceGetText.cancel();
                            getBlurText(text, "weddingAddress");
                        }
                    }}
                />
            </div>
            <NaverMapComponent
                lat={latitude}
                lng={longitude}
                getMapGeoData={getMapGeoData}
                getIsMapData={getIsMapData}
                getIsMapFixed={getIsMapFixed}
                _isMap={isMap}
                _isFixed={isMapFixed}
            />

            <div>
                <SubTitleComponent title="예식장 명" />

                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                        <TextInputComponent
                            placeholder={"예식장 명"}
                            _value={weddingLocation1}
                            getText={(e) => {
                                getText(e, "weddingLocation1");
                            }}
                            getBlur={({ isBlur, text }) => {
                                if (isBlur) {
                                    debounceGetText.cancel();
                                    getBlurText(text, "weddingLocation1");
                                }
                            }}
                        />
                    </div>

                    <div style={{ flex: 1, marginLeft: 8 }}>
                        <TextInputComponent
                            placeholder={"예식장 층과 홀"}
                            _value={weddingLocation2}
                            getText={(e) => {
                                getText(e, "weddingLocation2");
                            }}
                            getBlur={({ isBlur, text }) => {
                                if (isBlur) {
                                    debounceGetText.cancel();
                                    getBlurText(text, "weddingLocation2");
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* <div>
                <SubTitleComponent title="예식장 연락처" />
                <TextInputComponent placeholder={"02-000-0000"} />
            </div> */}

            <Modal
                isOpen={isAddressModal}
                style={customStyles}
                shouldCloseOnOverlayClick
                onRequestClose={() => setIsAddressModal(false)}
                ariaHideApp={false}
            >
                <DaumPostcode onComplete={handleComplete} />
            </Modal>
        </div>
    );
}
