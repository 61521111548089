import React from "react";
import StepperComponent from "../../../components/StepperComponent/StepperComponent";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Link } from "react-router-dom";
import NextButton from "../../../components/NextButton/NextButton";
import Header from "../../../components/Header/Header";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: 300,
        },
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

const WifeScreen = () => {
    const classes = useStyles();

    return (
        <div
            style={{
                backgroundColor: "rgb(238 238 238)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ backgroundColor: "#fff", maxWidth: 480 }}>
                <Header />

                <StepperComponent step={2} />

                <div style={{ padding: "20px 20px 20px" }}>
                    <p
                        style={{
                            padding: 0,
                            fontSize: 20,
                            fontWeight: "bold",
                            lineHeight: "40px",
                        }}
                    >
                        신부측 정보를 알려주세요 👰🏻
                    </p>
                    <p style={{ fontSize: 12, color: "#777" }}>
                        기입하시는 내용만 청첩장에 표기됩니다
                    </p>
                </div>
                <div style={{ padding: "0px 14px" }}>
                    <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                    >
                        <div style={{ display: "flex", width: 300 }}>
                            <TextField
                                id="outlined-basic"
                                label="신부 성"
                                variant="outlined"
                            />
                            <TextField
                                id="outlined-basic"
                                label="신부 이름"
                                variant="outlined"
                                style={{ marginLeft: 5 }}
                            />
                        </div>
                        <TextField
                            id="outlined-basic"
                            label="신부 연락처"
                            variant="outlined"
                        />
                        <TextField
                            id="outlined-basic"
                            label="신부 서열 (장남, 차남, 아들)"
                            variant="outlined"
                        />
                        <TextField
                            id="outlined-basic"
                            label="신부 아버님 성함"
                            variant="outlined"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                    }
                                    name="checkedI"
                                />
                            }
                            label="고인이시면 체크하여주세요"
                            id="custom_check"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부 아버님 연락처"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부 어머님 성함"
                            variant="outlined"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                    }
                                    name="checkedI"
                                />
                            }
                            label="고인이시면 체크하여주세요"
                            id="custom_check"
                        />
                        <TextField
                            id="outlined-basic"
                            label="신부 어머님 연락처"
                            variant="outlined"
                        />

                        <div style={{ padding: "20px 0px 20px" }}>
                            <p
                                style={{
                                    padding: 0,
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    lineHeight: "40px",
                                }}
                            >
                                계좌번호를 알려주세요 📝
                            </p>
                            <p style={{ fontSize: 12, color: "#777" }}>
                                기입하시는 계좌만 청첩장에 표기됩니다
                            </p>
                        </div>

                        <TextField
                            id="outlined-basic"
                            label="신부측 계좌 은행명"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부측 계좌번호"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부측 계좌 예금주"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부측 아버지 계좌 은행명"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부측 아버지 계좌번호"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부측 아버지 계좌 예금주"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부측 어머니 계좌 은행명"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부측 어머니 계좌번호"
                            variant="outlined"
                        />

                        <TextField
                            id="outlined-basic"
                            label="신부측 어머니 계좌 예금주"
                            variant="outlined"
                        />
                    </form>
                </div>

                <NextButton path="/order/greeting" />
            </div>
        </div>
    );
};

export default WifeScreen;
