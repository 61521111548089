import React from "react";
import { useParams, useHistory } from "react-router-dom";
import localStorage from "mobx-localstorage";

export default function LogoutScreen() {
    const history = useHistory();

    React.useEffect(async () => {
        await localStorage.setItem("userId", "");
        await history.push("/");
    }, []);
    return <div>LogoutScreen</div>;
}
