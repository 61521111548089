import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import logo from "../../../assets/images/new-logo.png";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import MockupIcon from "@material-ui/icons/Smartphone";
import MockupComponent from "../../MockupComponent/MockupComponent";

export default function Header({ userId, letterId }) {
    const [isMenu, setIsMenu] = useState(false);
    const [isMockup, setIsMockup] = useState(false);
    const MenuComponent = () => {
        return (
            <div
                className={styles.menu}
                style={{
                    position: "fixed",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100vh",
                    zIndex: 10000,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: 60,
                        // backgroundColor: "#fff",
                        position: "fixed",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        zIndex: 1000,
                    }}
                    // className={"header"}
                >
                    <MenuButtonComponent />

                    <div
                        onClick={() => {
                            setIsMenu(!isMenu);
                        }}
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            left: "calc(50% - 17.5px)",
                            top: "calc(50% - 19.5px)",
                        }}
                    ></div>
                </div>

                <div
                    style={{
                        maxWidth: 500,
                        position: "relative",
                        margin: "80px auto 0",
                        padding: "0 16px",
                        height: "100%",
                    }}
                >
                    <Link to="/" className={styles.menuItem}>
                        <div
                            onClick={() => {
                                setIsMenu(!isMenu);
                            }}
                        >
                            HOME
                        </div>
                    </Link>

                    <Link to="/event/1" className={styles.menuItem}>
                        <div
                            onClick={() => {
                                setIsMenu(!isMenu);
                            }}
                        >
                            EVENT
                        </div>
                    </Link>

                    <Link to="/question" className={styles.menuItem}>
                        <div
                            onClick={() => {
                                setIsMenu(!isMenu);
                            }}
                        >
                            FAQ
                        </div>
                    </Link>

                    {userId && (
                        <Link to="/mypage" className={styles.menuItem}>
                            <div
                                onClick={() => {
                                    setIsMenu(!isMenu);
                                }}
                            >
                                제작내역
                            </div>
                        </Link>
                    )}
                    {userId && (
                        <Link to="/first" className={styles.menuItem}>
                            <div>청첩장 제작</div>
                        </Link>
                    )}

                    <Link
                        to={userId ? "logout" : "newlogin"}
                        style={{
                            border: "1px solid",
                            position: "absolute",
                            bottom: 100,
                            left: 16,
                            width: "calc(100% - 32px)",
                            height: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 10,
                            backgroundColor: "#182e0c",
                            color: "#fff",
                            fontWeight: "bold",
                            textDecoration: "none",
                        }}
                    >
                        {userId ? "로그아웃" : "로그인"}
                    </Link>
                </div>
            </div>
        );
    };

    const MenuButtonComponent = () => {
        return (
            <div
                onClick={() => {
                    setIsMenu(!isMenu);
                }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    position: "absolute",
                    right: 16,
                    top: 15,
                    fontSize: 22,
                    color: "#333",
                }}
                className={styles.menu_button}
            >
                {/* <img src={menu} width={22} height={30} /> */}
                <MenuIcon />
            </div>
        );
    };

    const MockupButtonComponent = () => {
        return (
            <div
                onClick={() => {
                    setIsMockup(!isMockup);
                }}
                style={{
                    cursor: "pointer",
                    position: "absolute",
                    left: 16,
                    top: 15,
                    color: "#333",
                }}
                className={styles.menu_button}
            >
                <span
                    style={{
                        fontSize: 12,
                        padding: "5px 5px",
                    }}
                >
                    미리보기
                </span>
            </div>
        );
    };

    const MockupModalComponent = () => {
        return (
            <div
                className={styles.menu}
                style={{
                    position: "fixed",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100vh",
                    zIndex: 10000,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: 60,
                        // backgroundColor: "#fff",
                        position: "fixed",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        zIndex: 1000,
                    }}
                    // className={"header"}
                >
                    <MockupButtonComponent />
                </div>

                <div>
                    <MockupComponent letterId={letterId} bottomMockup={true} />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.header_container}>
            <div className={styles.header}>
                <div className={styles.header_left}>
                    <div style={{ display: "flex" }}>
                        {/* <div className={styles.header_menu}>고객후기</div> */}
                        {/* <Link to="/example" className={styles.header_menu}>
                            제작사례
                        </Link> */}

                        <Link to="/event/1" className={styles.header_menu}>
                            EVENT
                        </Link>

                        <Link to="/question" className={styles.header_menu}>
                            FAQ
                        </Link>
                    </div>
                </div>

                <div
                    style={{ position: "absolute", right: "calc(50% - 20px)" }}
                >
                    <Link to="/" style={{ display: "flex" }}>
                        <img
                            src={logo}
                            style={{
                                width: 40,
                                height: 40,
                                textAlign: "center",
                                cursor: "pointer",
                            }}
                        />
                    </Link>
                </div>

                <div className={styles.header_right}>
                    {/* <div style={{ display: "flex" }}>
                        <div className={styles.header_sub_menu}>공지사항</div>
                        <Link to="/question" className={styles.header_sub_menu}>
                            고객센터
                        </Link>
                    </div> */}

                    {/* <div
                        style={{
                            borderRight: "1px solid",
                            borderColor: colors.LESS_COLOR,
                            height: 10,
                        }}
                    /> */}

                    <div
                        style={{
                            display: "flex",
                            marginLeft: 10,
                            alignItems: "center",
                        }}
                    >
                        {userId ? (
                            <>
                                <Link
                                    to="/logout"
                                    className={styles.header_sub_menu}
                                >
                                    <div
                                        style={{
                                            // marginRight: 10,
                                            color: "#888",
                                            fontSize: 12,
                                        }}
                                    >
                                        로그아웃
                                    </div>
                                </Link>

                                <Link
                                    to="/mypage"
                                    className={styles.header_sub_menu}
                                >
                                    제작내역
                                </Link>
                                <Link
                                    to="/first"
                                    className={styles.header_sub_menu}
                                    style={{
                                        border: "1px solid",
                                        padding: "5px 10px",
                                        borderRadius: 6,
                                        fontWeight: "bold",
                                    }}
                                >
                                    청첩장 제작
                                </Link>
                            </>
                        ) : (
                            <div>
                                <Link
                                    to="/newlogin"
                                    className={styles.header_sub_menu}
                                    style={{
                                        border: "1px solid",
                                        padding: "5px 10px",
                                        borderRadius: 6,
                                        fontWeight: "bold",
                                    }}
                                >
                                    로그인
                                </Link>
                            </div>
                        )}
                    </div>
                </div>

                {/* {letterId && <MockupButtonComponent />}
                {isMockup ? <MockupModalComponent /> : false} */}

                <MenuButtonComponent />

                {isMenu ? <MenuComponent /> : false}
            </div>
        </div>
    );
}
