import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const NextButton = (props) => {
  const classes = useStyles();

  return (
    <div
      style={{
        padding: "0 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Link to={props.path}>
        <IconButton
          aria-label="delete"
          className={classes.margin}
          size="large"
          style={{ backgroundColor: "#f76c6c", color: "#fff" }}
        >
          <ArrowForwardIcon fontSize="medium" />
        </IconButton>
      </Link>
    </div>
  );
};

export default NextButton;
