import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";

import FormTextInputComponent from "../../components/FormTextInputComponent/FormTextInputComponent";
import FormSubTitleComponent from "../../components/FormSubTitleComponent/FormSubTitleComponent";
import type1 from "../../assets/images/type/1.png";
import type2 from "../../assets/images/type/2.png";
import type3 from "../../assets/images/type/3.png";
import type4 from "../../assets/images/type/4.png";
import type5 from "../../assets/images/type/5.png";
import type6 from "../../assets/images/type/6.png";
import type7 from "../../assets/images/type/7.png";
import type8 from "../../assets/images/type/8.png";

import backType1 from "../../assets/images/back_type/1.png";
import backType2 from "../../assets/images/back_type/2.png";
import backType3 from "../../assets/images/back_type/3.png";
import backType4 from "../../assets/images/back_type/4.png";
import backType5 from "../../assets/images/back_type/5.png";
import backType6 from "../../assets/images/back_type/6.png";

import backType7 from "../../assets/images/back_type/7.jpg";
import backType8 from "../../assets/images/back_type/8.jpg";
import backType9 from "../../assets/images/back_type/9.jpg";
import backType10 from "../../assets/images/back_type/10.jpg";

import tracingPaper from "../../assets/images/tracingPaper.jpg";
import ReactQuill from "react-quill";
import RadioButtonGroup from "../../components/RadioButtonGroupComponent/RadioButtonGroupComponent";

const options = [
  { value: "0", label: "제작하지 않습니다" },
  { value: "1", label: "제작합니다" },
];

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    cursor: "pointer",
  }),
  control: (styles) => ({
    ...styles,
    cursor: "pointer",
    height: 50,
  }),
};

export default function WeddingCardScreen() {
  const [template, setTemplate] = useState(0);
  const [backTemplate, setBackTemplate] = useState(0);
  const [naverId, setNaverId] = useState("");
  const [phone, setPhone] = useState("");
  const [greeting, setGreeting] = useState("");
  const [sketchMap, setSketchMap] = useState("");
  const [sheet, setSheet] = useState("");
  const [message, setMessage] = useState("");

  const handleRadioChange = (value) => {
    console.log("Selected Value:", value);
  };

  const personalInfoOption = [
    { value: "0", label: "동의합니다" },
    { value: "1", label: "동의하지 않습니다" },
  ];

  const getText = (text, _parameter) => {
    if (_parameter === "naver_id") {
      setNaverId(text);
    } else if (_parameter === "phone") {
      setPhone(text);
    } else if (_parameter === "greeting") {
      setGreeting(text);
    } else if (_parameter === "sheet") {
      setSheet(text);
    } else if (_parameter === "sketchMap") {
      setSketchMap(text);
    } else if (_parameter === "message") {
      setMessage(text);
    }
  };

  const [sheetSelectedOption, setSheetSelectedOption] = useState({
    value: "0",
    label: "제작하지 않습니다",
  });

  const sheetHandleChange = (_value) => {
    const { value } = _value;
    setSheetSelectedOption(_value);

    if (_value.value === "0") {
      setSheet("");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f4f4f4",
        minHeight: "100vh",
        // overflow: "hidden",
        position: "relative",
      }}
    >
      <div style={{ padding: "16px" }}>
        <div class="form-prod-container" style={{ position: "unset" }}>
          <div className="form-prod-block" style={{ width: "100%" }}>
            <div>
              <div className="form-prod-block-top">
                <div
                  className="form-prod-block-top-title"
                  style={{ textAlign: "center" }}
                >
                  청첩장 신청서
                </div>
              </div>

              <div className="form-prod-block-bottom">
                <div style={{ textAlign: "left", fontSize: 14 }}>
                  📮 신청서는 타입별 각 제출 부탁드립니다
                  <br />
                  <br />
                  📘 시그니처형
                  <br />
                  ✔️ 시안 1개 : 신청서 1건만 제출 + 사진 첨부
                  <br />
                  ✔️ 시안 2개 이상 (뒷면 동일) : 신청서 1건만 제출 + 메일로 사진
                  전송
                  <br />
                  ✔️ 시안 2개 이상 (뒷면 다름) : 신청서 시안별 제출 + 각 사진
                  첨부 or 메일로 사진 전송
                  <br />
                  ※ 혼주성함 / 계좌정보 유무만 다른 경우, 전달 사항에 기재한 후
                  신청서는 1건만 제출
                  <br />
                  <br />
                  📙 포스터형
                  <br />
                  ✔️ 시안 1개 : 신청서 1건만 제출 + 사진 첨부
                  <br />
                  ✔️ 시안 2개 이상 : 신청서 시안별 제출 + 각 사진 첨부
                  <br />
                  <br />
                  🚨 저작권 및 인쇄 오류 A/S 문제로 인해 뒷면에 로고/이미지
                  삽입은 불가합니다
                  <br /> <br />✅ 신청서 제출 후 연락주시면 접수 및 추가 결제
                  도와드리겠습니다
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-prod-container" style={{ position: "unset" }}>
          <div className="form-prod-block" style={{ width: "100%" }}>
            <div className="form-prod-block-top">
              <div className="form-prod-block-top-title">
                모바일 청첩장 링크를 생성하세요
              </div>
            </div>
            <div className="form-prod-block-bottom">
              <span style={{ fontSize: 13, lineHeight: "13px" }}>
                청첩장에 큐알코드를 추가하기 위해서 필요한 과정입니다.
                <br />
                종이 청첩장 수령 후에도 제한없이 수정이 가능하니 안심하세요!
              </span>

              <div style={{ marginTop: 20 }}>
                <div
                  style={{
                    display: "inline-block",
                    backgroundColor: "#182e0c",
                    width: "100%",
                    color: "#fff",
                    height: 42,
                    width: "100%",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>자동 링크 생성</span>
                </div>

                <div
                  style={{
                    display: "inline-block",
                    backgroundColor: "#fff",
                    width: "100%",
                    color: "#182e0c",
                    border: "1px solid #182e0c",
                    height: 42,
                    width: "100%",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                    cursor: "pointer",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    이미 생성해둔게 있어요
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 결제자 정보 */}
        <div class="form-prod-container" style={{ position: "unset" }}>
          <div className="form-prod-block" style={{ width: "100%" }}>
            <div className="form-prod-block-top">
              <div className="form-prod-block-top-title">결제자 정보</div>
            </div>
            <div className="form-prod-block-bottom">
              {/* <span style={{ fontSize: 13, lineHeight: "13px" }}>
              결제한아이디, 결제자연락처
            </span> */}

              <FormSubTitleComponent title={`결제한 아이디`} require={true} />
              {/* weddingcardscreen 페이지용으로 컴포넌트 제작 */}
              <FormTextInputComponent
                placeholder="결제한 아이디"
                getText={(e) => {
                  getText(e.text, "naver_id");
                }}
              />

              <FormSubTitleComponent title={`결제자 연락처`} require={true} />
              <FormTextInputComponent
                placeholder="결제자 연락처"
                getText={(e) => {
                  getText(e.text, "phone");
                }}
                onlyNumber={true}
              />
            </div>
          </div>
        </div>
        {/* 결제자 정보 */}

        {/* 앞면 디자인 */}
        <div class="form-prod-container" style={{ position: "unset" }}>
          <div
            className="form-prod-block"
            style={{ width: "auto", height: "auto", overflow: "hidden" }}
          >
            <div className="form-prod-block-top">
              <div className="form-prod-block-top-title">앞면 디자인</div>
            </div>
            <div className="form-prod-block-bottom">
              <span style={{ fontSize: 13, lineHeight: "13px" }}>
                시그니처형 👉 수정제한 없음
                <br />
                포스터형 👉 수정제한 3회
                <br />
                <br />
                신청서 제출 후 디자인 변경이 불가하니 신중히 선택해 주세요
              </span>

              <div style={{}}>
                <div style={{ display: "flex", overflow: "auto" }}>
                  <div
                    className="main-design-wrap"
                    style={{
                      border: template === 0 ? "1px solid #d4d4d4" : "none",
                    }}
                    onClick={() => {
                      setTemplate(0);

                      if (backTemplate > 5) {
                        setBackTemplate(0);
                      }
                    }}
                  >
                    <img src={type1} style={{ width: 120 }} />

                    <div className="main-design-text">
                      <b>시그니처형</b>
                    </div>
                  </div>
                  <div
                    className="main-design-wrap"
                    style={{
                      border: template === 1 ? "1px solid #d4d4d4" : "none",
                    }}
                    onClick={() => {
                      setTemplate(1);

                      if (backTemplate < 6) {
                        setBackTemplate(6);
                      }
                    }}
                  >
                    <img src={type2} style={{ width: 120 }} />
                    <div className="main-design-text">
                      <b>포스터</b> A Type
                    </div>
                  </div>
                  <div
                    className="main-design-wrap"
                    style={{
                      border: template === 2 ? "1px solid #d4d4d4" : "none",
                    }}
                    onClick={() => {
                      setTemplate(2);
                      if (backTemplate < 6) {
                        setBackTemplate(6);
                      }
                    }}
                  >
                    <img src={type3} style={{ width: 120 }} />
                    <div className="main-design-text">
                      <b>포스터</b> B Type
                    </div>
                  </div>

                  <div
                    className="main-design-wrap"
                    style={{
                      border: template === 3 ? "1px solid #d4d4d4" : "none",
                    }}
                    onClick={() => {
                      setTemplate(3);
                      if (backTemplate < 6) {
                        setBackTemplate(6);
                      }
                    }}
                  >
                    <img src={type4} style={{ width: 120 }} />
                    <div className="main-design-text">
                      <b>포스터</b> C Type
                    </div>
                  </div>
                  <div
                    className="main-design-wrap"
                    style={{
                      border: template === 4 ? "1px solid #d4d4d4" : "none",
                    }}
                    onClick={() => {
                      setTemplate(4);
                      if (backTemplate < 6) {
                        setBackTemplate(6);
                      }
                    }}
                  >
                    <img src={type5} style={{ width: 120 }} />
                    <div className="main-design-text">
                      <b>포스터</b> D-1 Type
                    </div>
                  </div>

                  <div
                    className="main-design-wrap"
                    style={{
                      border: template === 5 ? "1px solid #d4d4d4" : "none",
                    }}
                    onClick={() => {
                      setTemplate(5);
                      if (backTemplate < 6) {
                        setBackTemplate(6);
                      }
                    }}
                  >
                    <img src={type6} style={{ width: 120 }} />
                    <div className="main-design-text">
                      <b>포스터</b> D-2 Type
                    </div>
                  </div>

                  <div
                    className="main-design-wrap"
                    style={{
                      border: template === 6 ? "1px solid #d4d4d4" : "none",
                    }}
                    onClick={() => {
                      setTemplate(6);
                      if (backTemplate < 6) {
                        setBackTemplate(6);
                      }
                    }}
                  >
                    <img src={type7} style={{ width: 120 }} />
                    <div className="main-design-text">
                      <b>포스터</b> E-1 Type
                    </div>
                  </div>

                  <div
                    className="main-design-wrap"
                    style={{
                      border: template === 7 ? "1px solid #d4d4d4" : "none",
                    }}
                    onClick={() => {
                      setTemplate(7);
                      if (backTemplate < 6) {
                        setBackTemplate(6);
                      }
                    }}
                  >
                    <img src={type8} style={{ width: 120 }} />
                    <div className="main-design-text">
                      <b>포스터</b> E-2 Type
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 앞면 디자인 */}

        {/* 뒷면 디자인 */}
        <div class="form-prod-container" style={{ position: "unset" }}>
          <div
            className="form-prod-block"
            style={{ width: "auto", height: "auto", overflow: "hidden" }}
          >
            <div className="form-prod-block-top">
              <div className="form-prod-block-top-title">뒷면 디자인</div>
            </div>
            <div className="form-prod-block-bottom">
              <span style={{ fontSize: 13, lineHeight: "13px" }}>
                뒷면 디자인을 선택해 주세요 (약도 제작 시 2만원 추가금 발생)
              </span>

              <div style={{}}>
                <div style={{ display: "flex", overflow: "auto" }}>
                  {template === 0 ? (
                    <>
                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 0 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(0);
                        }}
                      >
                        <img src={backType1} style={{ width: 120 }} />

                        <div className="main-design-text">심플형</div>
                      </div>

                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 1 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(1);
                        }}
                      >
                        <img src={backType2} style={{ width: 120 }} />

                        <div className="main-design-text">인삿말형</div>
                      </div>

                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 2 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(2);
                        }}
                      >
                        <img src={backType3} style={{ width: 120 }} />

                        <div className="main-design-text">약도형-약도 ONLY</div>
                      </div>

                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 3 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(3);
                        }}
                      >
                        <img src={backType4} style={{ width: 120 }} />

                        <div className="main-design-text">
                          약도형2-인삿말 짧게
                        </div>
                      </div>

                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 4 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(4);
                        }}
                      >
                        <img src={backType1} style={{ width: 120 }} />

                        <div className="main-design-text">
                          계좌형1-인삿말+계좌
                        </div>
                      </div>

                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 5 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(5);
                        }}
                      >
                        <img src={backType1} style={{ width: 120 }} />

                        <div className="main-design-text">
                          계좌형2-약도+계좌
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 6 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(6);
                        }}
                      >
                        <img src={backType7} style={{ width: 120 }} />

                        <div className="main-design-text">인삿말형</div>
                      </div>

                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 7 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(7);
                        }}
                      >
                        <img src={backType8} style={{ width: 120 }} />

                        <div className="main-design-text">약도형</div>
                      </div>

                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 8 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(8);
                        }}
                      >
                        <img src={backType9} style={{ width: 120 }} />

                        <div className="main-design-text">계좌형1</div>
                      </div>

                      <div
                        className="main-design-wrap"
                        style={{
                          border:
                            backTemplate === 9 ? "1px solid #d4d4d4" : "none",
                        }}
                        onClick={() => {
                          setBackTemplate(9);
                        }}
                      >
                        <img src={backType10} style={{ width: 120 }} />

                        <div className="main-design-text">계좌형2</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 뒷면 디자인 */}

        {/* 예식 정보 */}
        <div class="form-prod-container" style={{ position: "unset" }}>
          <div className="form-prod-block" style={{ width: "100%" }}>
            <div className="form-prod-block-top">
              <div className="form-prod-block-top-title">예식 정보</div>
            </div>
            <div className="form-prod-block-bottom">
              <FormSubTitleComponent
                title={`신랑님 한글 성함`}
                require={true}
              />
              <FormTextInputComponent placeholder="김민수" />

              <FormSubTitleComponent
                title={`신랑님 영문 성함`}
                require={true}
              />
              <FormTextInputComponent placeholder="KIM MIN SU" />

              <FormSubTitleComponent
                title={`신부님 한글 성함`}
                require={true}
              />
              <FormTextInputComponent placeholder="김민정" />

              <FormSubTitleComponent
                title={`신부님 영문 성함`}
                require={true}
              />
              <FormTextInputComponent placeholder="KIM MIN JEONG" />

              <FormSubTitleComponent title={`예식일`} require={true} />
              <FormTextInputComponent placeholder="2024년 2월 18일 일요일 낮 12시" />

              <FormSubTitleComponent
                title={`예식장 이름과 웨딩홀 정보`}
                require={true}
                description={
                  "(ex. 크류컨벤션 5층 노블레스홀 / CRYUCONVENTION 5F NOBLESS HALL) <br /> 국문으로만 제작하시길 희망하시면 국문만 기입해주세요"
                }
              />
              <FormTextInputComponent placeholder="크류컨벤션 5층 노블레스홀" />

              <FormSubTitleComponent title={`예식장 주소`} require={true} />
              <FormTextInputComponent placeholder="예식장 주소" />

              <FormSubTitleComponent
                title={`혼주 성함`}
                description={"(ex. 김OO·이OO의 아들 OO / 류OO·문OO의 딸 OO)"}
              />
              <FormTextInputComponent placeholder="혼주 성함" />

              <FormSubTitleComponent
                title={`청첩장 뒷면 문구 입력`}
                description={
                  "🪧 인삿말<br />* 약도형2 / 계좌형1의 경우 짧은 문구로 4줄 정도 추천해요 (공간 부족)<br /><br />🪧 마음 전하실 곳<br />* 신랑측 / 신부측 나눠서 작성해 주세요<br /><br />🚨 저작권 및 인쇄 오류 A/S 문제로 인해 뒷면에 로고/이미지 삽입은 불가합니다"
                }
              />
              <ReactQuill
                theme={"snow"}
                onChange={(e) => {
                  getText(e, "greeting");
                }}
                value={greeting}
                modules={{
                  toolbar: [["bold", "italic", "underline"]],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                }}
                formats={["bold", "italic", "underline"]}
                bounds={".app"}
                placeholder={"인삿말을 입력하세요"}
              />

              <FormSubTitleComponent
                title={`약도 제작시 참고 사항`}
                description={
                  "🪧 오시는길<br/>* 약도 제작 시 기본적인 교통편은 기재해 드립니다<br/>* 별도로 추가 원하는 교통편 / 전달사항이 있다면 기재해 주세요!<br/><br/>※  제작된 약도를 사용하지 않으시더라도 약도추가금에 대한 환불 및 취소는 불가합니다.<br/><br/>✅ 저작권과 인쇄 오류 시 발생하는 A/S 문제로 인해, 저희가 제작한 약도로만 인쇄 가능합니다!"
                }
              />
              <ReactQuill
                theme={"snow"}
                onChange={(e) => {
                  getText(e, "sketchMap");
                }}
                value={sketchMap}
                modules={{
                  toolbar: [["bold", "italic", "underline"]],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                }}
                formats={["bold", "italic", "underline"]}
                bounds={".app"}
                placeholder={"인삿말을 입력하세요"}
              />
            </div>
          </div>
        </div>
        {/* 예식 정보 */}

        {/* 트레싱지 별지 */}
        <div class="form-prod-container" style={{ position: "unset" }}>
          <div className="form-prod-block" style={{ width: "100%" }}>
            <div className="form-prod-block-top">
              <div className="form-prod-block-top-title">별지 제작여부</div>
            </div>
            <div className="form-prod-block-bottom">
              <span style={{ fontSize: 13, lineHeight: "13px" }}>
                ✔️ 약도 없이 제작 : 시안추가비 1.5만원
                <br />
                ✔️ 약도 제작 : 약도추가금 2.0만원
                <br />
                ※ 별지 용지비 별도 : 1.5만원 (50매당)
                <br />
                <br />
                ※ 제작된 약도를 사용하지 않으시더라도 약도추가금에 대한 환불 및
                취소는 불가합니다.
                <br />✅ 약도 별지가 궁금하시면 청첩장 상세페이지 혹은 크류카드
                인스타 릴스를 확인해 주세요!
              </span>

              <div style={{ paddingTop: 20 }}>
                <img src={tracingPaper} style={{ width: "100%" }} />

                <div style={{ paddingTop: 15 }}>
                  <Select
                    value={sheetSelectedOption}
                    onChange={sheetHandleChange}
                    options={options}
                    isSearchable={false}
                    styles={customStyles}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#f2f1ff",
                        primary: "#182e0c",
                      },
                    })}
                  />

                  {sheetSelectedOption.value === "1" && (
                    <div>
                      <FormSubTitleComponent
                        title={`별지에 들어갈 내용을 입력해 주세요`}
                        description={
                          "* 약도 제작 시 기본적인 교통편은 기재해 드립니다<br/>* 별도로 추가 원하는 교통편 / 전달사항이 있다면 기재해 주세요!"
                        }
                      />
                      <ReactQuill
                        theme={"snow"}
                        onChange={(e) => {
                          getText(e, "sheet");
                        }}
                        value={sheet}
                        modules={{
                          toolbar: [["bold", "italic", "underline"]],
                          clipboard: {
                            // toggle to add extra line breaks when pasting HTML:
                            matchVisual: false,
                          },
                        }}
                        formats={["bold", "italic", "underline"]}
                        bounds={".app"}
                        placeholder={"인삿말을 입력하세요"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 트레싱지 별지 */}

        {/* 전달사항 */}
        <div class="form-prod-container" style={{ position: "unset" }}>
          <div className="form-prod-block" style={{ width: "100%" }}>
            <div className="form-prod-block-top">
              <div className="form-prod-block-top-title">전달사항</div>
            </div>
            <div className="form-prod-block-bottom">
              <div
                style={{ fontSize: 13, lineHeight: "13px", paddingBottom: 20 }}
              >
                상담한 내용이나 추가로 기재하실 내용이 있다면 기재해 주세요
              </div>
              <ReactQuill
                theme={"snow"}
                onChange={(e) => {
                  getText(e, "sheet");
                }}
                value={sheet}
                modules={{
                  toolbar: [["bold", "italic", "underline"]],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                }}
                formats={["bold", "italic", "underline"]}
                bounds={".app"}
                placeholder={"인삿말을 입력하세요"}
              />
            </div>
          </div>
        </div>
        {/* 전달사항 */}

        {/* 이용동의 */}
        <div class="form-prod-container" style={{ position: "unset" }}>
          <div className="form-prod-block" style={{ width: "100%" }}>
            <div className="form-prod-block-top">
              <div className="form-prod-block-top-title">이용 동의</div>
            </div>
            <div className="form-prod-block-bottom">
              <div>
                <FormSubTitleComponent
                  title={`개인정보 수집 및 이용 동의`}
                  description={
                    "1. 수집 목적 : 청첩장 제작<br/>2. 수집 항목 : 이름, 예식일자, 네이버 아이디<br/>3. 보유 및 이용 기간 : 입력일로부터 1년까지"
                  }
                  require={true}
                />
                <RadioButtonGroup
                  options={personalInfoOption}
                  name="example"
                  onChange={handleRadioChange}
                />
              </div>

              <div>
                <FormSubTitleComponent
                  title={`청첩장의 활용 용도 확인`}
                  description={
                    "인스타그램 릴스 제작 또는 피드에 업로드될 수 있습니다<br/>릴스로 제작된 청첩장은 모바일 청첩장 평생소장권(2.5만원 상당)을 적용해드리며<br/>선정자에게는 개별 연락을 드립니다 🎁"
                  }
                  require={true}
                />
                <RadioButtonGroup
                  options={personalInfoOption}
                  name="example"
                  onChange={handleRadioChange}
                />
              </div>

              <div>
                <FormSubTitleComponent
                  title={`자랑하고싶은 우리의 연애 스토리가 있나요?`}
                  description={
                    "많은 분들에게 자랑하고싶은 신랑 신부님의 연애 스토리가 있다면 알려주세요!<br/>평생 기억에 남을 수 있도록 크류카드 릴스로 예쁘게 제작해서<br/>많은 분들께 소개시켜 드릴게요 🎉"
                  }
                />
                <ReactQuill
                  theme={"snow"}
                  onChange={(e) => {
                    getText(e, "sheet");
                  }}
                  value={sheet}
                  modules={{
                    toolbar: [["bold", "italic", "underline"]],
                    clipboard: {
                      // toggle to add extra line breaks when pasting HTML:
                      matchVisual: false,
                    },
                  }}
                  formats={["bold", "italic", "underline"]}
                  bounds={".app"}
                  placeholder={"인삿말을 입력하세요"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* 이용동의 */}

        {/* 신청서 제출 */}
        <div className="form-prod-container" style={{ position: "unset" }}>
          <div
            style={{
              backgroundColor: "#182e0d",
              width: "100%",
              maxWidth: "500px",
              padding: "12px 18px",
              borderRadius: "8px",
              marginTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            신청서 제출하기
          </div>
        </div>
        {/* 신청서 제출 */}
      </div>
    </div>
  );
}
