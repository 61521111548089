import React, { forwardRef, useState, useCallback } from "react";
import Switch from "react-switch";
import Compressor from "compressorjs";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import DatePicker from "react-datepicker";
import { dbService } from "../../fbase";

import "react-datepicker/dist/react-datepicker.css";

import { ko } from "date-fns/esm/locale";
import { useEffect } from "react";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import { debounce } from "lodash";

import imgDesign1 from "../../assets/images/design-1.png";
import imgDesign2 from "../../assets/images/design-2.png";
import imgDesign3 from "../../assets/images/design-3.png";
import imgDesign4 from "../../assets/images/design-4.png";
import imgDesign99 from "../../assets/images/design-99.png";

export default function TemplateComponent({
  state,
  getData,
  letterId,
  getTemplate,
}) {
  const [template, setTemplate] = useState(state.template ? state.template : 0);

  const [colorVer, setColorVer] = useState(state.colorVer ? state.colorVer : 0);

  const pushData = () => {
    getData({ isSaved: true });
  };

  const pushTemplate = (_template) => {
    getTemplate({ template: _template });
  };

  const debounceSubmit = useCallback(
    debounce(async ({ _template }) => {
      onSubmit({ _template });
    }, 500),
    []
  );

  const onSubmit = async ({ _template, _colorVer }) => {
    let content = {};
    if (_template !== undefined) {
      content = {
        template: _template,
      };
      // console.log("_template");
    } else if (_colorVer !== undefined) {
      content = {
        colorVer: _colorVer,
      };
      // console.log("_colorVer");
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  return (
    <div>
      <div>
        <div>
          <SubTitleComponent title="디자인" />
          <div style={{ display: "flex", overflow: "auto" }}>
            <div
              className="main-design-wrap"
              style={{
                border: template === 0 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 0 });
                setTemplate(0);
                pushTemplate(0);
              }}
            >
              <img src={imgDesign1} style={{ width: 120 }} />

              <div className="main-design-text">
                <b>type</b> 안나
              </div>
            </div>
            <div
              className="main-design-wrap"
              style={{
                border: template === 1 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 1 });
                setTemplate(1);
                pushTemplate(1);
              }}
            >
              <img src={imgDesign2} style={{ width: 120 }} />
              <div className="main-design-text">
                <b>type</b> 벨라
              </div>
            </div>
            <div
              className="main-design-wrap"
              style={{
                border: template === 2 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 2 });
                setTemplate(2);
                pushTemplate(2);
              }}
            >
              <img src={imgDesign3} style={{ width: 120 }} />
              <div className="main-design-text">
                <b>type</b> 메르디
              </div>
            </div>

            <div
              className="main-design-wrap"
              style={{
                border: template === 3 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 3 });
                setTemplate(3);
                pushTemplate(3);
              }}
            >
              <img src={imgDesign4} style={{ width: 120 }} />
              <div className="main-design-text">
                <b>type</b> 오브
              </div>
            </div>

            <div
              className="main-design-wrap"
              style={{
                border: template === 4 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 4 });
                setTemplate(4);
                pushTemplate(4);
              }}
            >
              <img
                src={
                  "https://storage.cryucard.com/image/2025-03-22/qUGK5rk3kCYYfMunY6vi-42f4ae97-2b05-40fd-94fe-248314d5f482.jpeg"
                }
                style={{ width: 120 }}
              />
              <div className="main-design-text">
                <b>type</b> 레트로
              </div>
            </div>

            <div
              className="main-design-wrap"
              style={{
                border: template === 99 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 99 });
                setTemplate(99);
              }}
            >
              <img src={imgDesign99} style={{ width: 120 }} />
              <div className="main-design-text">
                <b>type</b> 심플
              </div>
            </div>
          </div>
        </div>

        <div>
          <SubTitleComponent title="청첩장 컬러" />
          <div style={{ display: "flex", overflow: "auto" }}>
            <div
              className={`prod-color-picker-wrap ${
                colorVer === 0 ? "prod-color-picker-wrap-click" : ""
              }`}
              onClick={() => {
                onSubmit({ _colorVer: 0 });
                setColorVer(0);
              }}
            >
              <div className="prod-color-picker-border ">
                <div
                  className="prod-color-picker-content"
                  style={{
                    backgroundColor: "#515a15",
                  }}
                ></div>
              </div>
            </div>

            <div
              className={`prod-color-picker-wrap ${
                colorVer === 1 ? "prod-color-picker-wrap-click" : ""
              }`}
              onClick={() => {
                onSubmit({ _colorVer: 1 });
                setColorVer(1);
              }}
            >
              <div className="prod-color-picker-border">
                <div
                  className="prod-color-picker-content"
                  style={{
                    backgroundColor: "#b87e5a",
                  }}
                ></div>
              </div>
            </div>

            <div
              className={`prod-color-picker-wrap ${
                colorVer === 2 ? "prod-color-picker-wrap-click" : ""
              }`}
              onClick={() => {
                onSubmit({ _colorVer: 2 });
                setColorVer(2);
              }}
            >
              <div className="prod-color-picker-border">
                <div
                  className="prod-color-picker-content"
                  style={{
                    backgroundColor: "#d09199",
                  }}
                ></div>
              </div>
            </div>

            <div
              className={`prod-color-picker-wrap ${
                colorVer === 3 ? "prod-color-picker-wrap-click" : ""
              }`}
              onClick={() => {
                onSubmit({ _colorVer: 3 });
                setColorVer(3);
              }}
            >
              <div className="prod-color-picker-border">
                <div
                  className="prod-color-picker-content"
                  style={{
                    backgroundColor: "#ad9f91",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
