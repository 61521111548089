import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { dbService } from "../../fbase";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles({
  root: {
    maxWidth: 480,
    marginBottom: 30,
  },
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    width: "90%",
    maxWidth: 400,
    maxHeight: 500,
    height: 500,
    padding: "10px 0",
  },
  overlay: {
    backgroundColor: "rgb(0,0,0,0.5)",
    zIndex: 3000,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function ThanksCardComponent(props) {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [value, setValue] = useState(0);
  const [naverEmail, setNaverEmail] = useState("");
  const [coupon, setCoupon] = useState("");
  const [isLoad, setIsLoad] = useState(false);

  const thumbURL =
    "https://self-cryucard.s3.ap-northeast-2.amazonaws.com/photoStorage/kakaoThumb.png";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getBuyList = (email, letterId) => {
    var buyListRef = dbService.collection("thanksBuyList");
    var letterRef = dbService.collection("thanksLetter");
    // console.log("func getBuyList");
    if (email) {
      buyListRef
        .where("userEmail", "==", email)
        .get()
        .then(async (res) => {
          if (!res.empty) {
            // console.log(res);

            await Promise.all(
              res.docs.map(async (item) => {
                var data = item.data();
                data.id = item.id;
                // console.log(data);

                await letterRef.doc(letterId).update({
                  waterMark: true,
                });

                if (data.quantity > 1) {
                  await buyListRef
                    .doc(item.id)
                    .update({ quantity: data.quantity - 1 })
                    .then(() => {
                      alert("워터마크가 제거 되었습니다!");
                      window.location.reload();
                    });
                } else {
                  await buyListRef
                    .doc(item.id)
                    .delete()
                    .then(() => {
                      alert("워터마크가 제거 되었습니다!");
                      window.location.reload();
                    })
                    .catch((error) => {
                      console.error("Error removing document: ", error);
                    });
                }
              })
            );
          } else {
            alert("구매내역이 없습니다.");
          }
        });
    } else {
      alert("구매 후 네이버 아이디를 입력하여주세요");
    }
  };

  const deleteLetter = async (letterId) => {
    var confirm = window.confirm(
      "정말 삭제하시겠습니까? 삭제 후 복구가 불가합니다"
    );

    if (confirm) {
      var letterRef = dbService.collection("thanksLetter");
      await letterRef
        .doc(letterId)
        .delete()
        .then((res) => {
          alert("제작하신 청첩장이 삭제 되었습니다");
          window.location.href = "/#/list";
        });
    } else {
    }
  };

  const getCouponList = (couponNumber, letterId) => {
    var couponRef = dbService.collection("couponList");
    var letterRef = dbService.collection("thanksLetter");
    console.log("func getBuyList");
    if (couponNumber) {
      couponRef
        .where("couponNumber", "==", couponNumber)
        .get()
        .then(async (res) => {
          if (!res.empty) {
            // console.log(res);

            await Promise.all(
              res.docs.map(async (item) => {
                var data = item.data();
                data.id = item.id;
                // console.log(data);

                await letterRef.doc(letterId).update({
                  waterMark: true,
                });

                if (data.quantity > 1) {
                  await couponRef
                    .doc(item.id)
                    .update({ quantity: data.quantity - 1 })
                    .then(() => {
                      alert("워터마크가 제거 되었습니다!");
                      window.location.reload();
                    });
                } else {
                  await couponRef
                    .doc(item.id)
                    .delete()
                    .then(() => {
                      alert("워터마크가 제거 되었습니다!");
                      window.location.reload();
                    })
                    .catch((error) => {
                      console.error("Error removing document: ", error);
                    });
                }
              })
            );
          } else {
            alert("쿠폰번호가 일치하지 않습니다.");
          }
        });
    } else {
      alert("쿠폰번호를 입력하여주세요");
    }
  };

  const onChange = (event) => {
    // console.log(event.target.name);
    const {
      target: { name, value, checked },
    } = event;

    if (name === "naverEmail") {
      setNaverEmail(value);
    } else if (name === "coupon") {
      setCoupon(value);
    }
  };

  const urlReturn = (letterId, letterVer) => {
    let url = "";

    if (letterVer === 1 || letterVer === undefined) {
      url = `https://self.cryucompany.com/thxmax/${letterId}/account`;
    }

    return url;
  };

  useEffect(() => {
    // console.log(props);
  }, []);

  return (
    <div>
      <Card className={classes.root}>
        <a
          href={urlReturn(props.user.id, props.user.letterVer)}
          target="_blank"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <CardActionArea>
            {!isLoad && <Skeleton variant="rect" width={"100%"} height={300} />}
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              image={props.user.metaImg ? props.user.metaImg : thumbURL}
              title="Contemplative Reptile"
              onLoad={() => setIsLoad(true)}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={{ fontSize: 15, textDecoration: "none" }}
              >
                {props.user.metaTitle ? props.user.metaTitle : "모바일 감사장"}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ fontSize: 12 }}
              >
                {props.user.metaDes
                  ? ReactHtmlParser(props.user.metaDes)
                  : "감사의 말씀 올립니다."}
              </Typography>
            </CardContent>
          </CardActionArea>
        </a>

        <CardActions>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              {props.user.waterMark ? (
                false
              ) : (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setIsOpenModal(true)}
                >
                  워터마크 제거
                </Button>
              )}
              <Link
                to={`/tmodify/${props.user.id}`}
                style={{ textDecoration: "none" }}
              >
                <Button size="small" color="primary">
                  수정하기
                </Button>
              </Link>
            </div>
            <div style={{}}>
              <Button
                size="small"
                color="primary"
                style={{ paddingRight: 0 }}
                onClick={() => {
                  deleteLetter(props.user.id);
                }}
              >
                <DeleteForeverIcon style={{ color: "#ff6c6c" }} />
              </Button>
            </div>
          </div>
        </CardActions>
      </Card>

      <Modal
        isOpen={isOpenModal}
        style={customStyles}
        shouldCloseOnOverlayClick
        onRequestClose={() => setIsOpenModal(false)}
      >
        <div style={{ padding: "0 16px" }}>
          <div>
            <h4>워터마크 제거 🛠</h4>
            <p style={{ lineHeight: "16px" }}>
              <span
                style={{
                  fontSize: 12,
                  wordBreak: "keep-all",
                  display: "inline-block",
                  lineHeight: "16px",
                }}
              >
                워터마크를 제거하시려면 네이버 스마트스토어 내에서 구매진행 후
                아이디를 입력해주세요
              </span>
              <br />
              <span
                style={{
                  fontSize: 12,
                  wordBreak: "keep-all",
                  display: "inline-block",
                  lineHeight: "16px",
                  color: "#999",
                }}
              >
                (구매 확인까지{" "}
                <span style={{ fontWeight: "600" }}>최대 15분</span>이 소요될 수
                있습니다.)
              </span>
            </p>
          </div>

          <div style={{ paddingTop: 30 }}>
            <AppBar position="static">
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
              >
                <LinkTab
                  label="아이디 입력"
                  href="/drafts"
                  {...a11yProps(0)}
                  style={{}}
                />
                <LinkTab label="쿠폰 입력" href="/trash" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div style={{ paddingTop: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    id="standard-basic"
                    label="아이디"
                    style={{ width: 100 }}
                    name="naverEmail"
                    checked={naverEmail}
                    onChange={onChange}
                  />
                  <span style={{ paddingBottom: 5, color: "#999" }}>
                    @naver.com
                  </span>
                </div>

                <div
                  style={{ position: "absolute", right: 20, bottom: 30 }}
                  onClick={() => getBuyList(naverEmail, props.user.id)}
                >
                  <Fab color="secondary" aria-label="add">
                    <AddIcon />
                  </Fab>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ paddingTop: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    id="standard-basic"
                    label="쿠폰번호"
                    style={{ width: 200 }}
                    name="coupon"
                    checked={coupon}
                    onChange={onChange}
                  />
                </div>

                <div
                  style={{ position: "absolute", right: 20, bottom: 30 }}
                  onClick={() => getCouponList(coupon, props.user.id)}
                >
                  <Fab color="secondary" aria-label="add">
                    <AddIcon />
                  </Fab>
                </div>
              </div>{" "}
            </TabPanel>
          </div>
        </div>
      </Modal>
    </div>
  );
}
