import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Switch from "react-switch";
import Compressor from "compressorjs";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import DatePicker from "react-datepicker";
import { dbService } from "../../fbase";

import "react-datepicker/dist/react-datepicker.css";

import { ko } from "date-fns/esm/locale";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import { debounce } from "lodash";

import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css"; // css도 import해줘야 한다.

const STORAGE_URL = "http://storage.cryucard.com";

function MainPicComponent({ state, getData, letterId, template }) {
  const [isCropperModal, setIsCropperModal] = useState(false);

  const [mainImg, setMainImg] = useState(state.mainImg ? state.mainImg : "");
  const [uploadMain, setUploadMain] = useState("");
  const [weddingDate, setWeddingDate] = useState(
    state.weddingDate ? new Date(state.weddingDate) : new Date()
  );
  const [weddingTime_1, setWeddingTime_1] = useState(
    state.weddingTime
      ? state.weddingTime.slice(0, 2) * 1 - 12 >= 0
        ? "pm"
        : "am"
      : "pm"
  ); // state.weddingTime 14:00:00
  const [weddingTime_2, setWeddingTime_2] = useState(
    state.weddingTime
      ? state.weddingTime.slice(0, 2) * 1 - 12 > 0
        ? state.weddingTime.slice(0, 2) * 1 - 12
        : state.weddingTime.slice(0, 2) * 1
      : "1"
  );
  const [weddingTime_3, setWeddingTime_3] = useState(
    state.weddingTime ? state.weddingTime.slice(3, 5) : "00"
  );
  const [effect, setEffect] = useState(state.effect ? state.effect : "");

  const [isCalendar, setIsCalendar] = useState(
    state.isCalendar ? true : state.isCalendar === undefined ? true : false
  );

  const [isDday, setIsDday] = useState(
    state.isDday ? true : state.isDday === undefined ? true : false
  );

  const [retroHusbandText, setRetroHusbandText] = useState(
    state.retroHusbandText ? state.retroHusbandText : ""
  ); // 기본 텍스트
  const [retroHusbandImg, setRetroHusbandImg] = useState(
    state.retroHusbandImg ? state.retroHusbandImg : ""
  );
  const [retroHusbandPresentImg, setRetroHusbandPresentImg] = useState(
    state.retroHusbandPresentImg ? state.retroHusbandPresentImg : ""
  );
  const [retroWifeText, setRetroWifeText] = useState(
    state.retroWifeText ? state.retroWifeText : ""
  ); // 기본 텍스트
  const [retroWifeImg, setRetroWifeImg] = useState(
    state.retroWifeImg ? state.retroWifeImg : ""
  );
  const [retroWifePresentImg, setRetroWifePresentImg] = useState(
    state.retroWifePresentImg ? state.retroWifePresentImg : ""
  );

  const [originalImg, setOriginalImg] = useState();
  const [selectedImg, setSelectedImg] = useState("");

  const minDate = new Date();
  const maxDate = minDate.setDate(minDate.getDate() + 210);

  // s3설정
  const config = {
    bucketName: "new-cryucard",
    dirName: `image/${state.weddingDate}`,
    region: "ap-northeast-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);
  // s3설정

  const uploadMainImg = async ({ _uploadMain }) => {
    let submitMainImg = "";
    // console.log(_uploadMain);
    if (_uploadMain) {
      await ReactS3Client.uploadFile(_uploadMain, `${letterId}-${uuidv4()}`)
        .then((data) => {
          submitMainImg = data.location.split("image")[1];
          onSubmit({ _mainImg: submitMainImg });
        })
        .catch((err) => console.error(err));
    }
  };

  const uploadRetroImg = async ({ _imgFile, id }) => {
    let submitImgUrl = "";
    if (_imgFile) {
      await ReactS3Client.uploadFile(_imgFile, `${letterId}-${uuidv4()}`)
        .then((data) => {
          // console.log(data);

          submitImgUrl = data.location.split("image")[1];

          switch (id) {
            case "retroHusbandImg":
              onSubmit({ _retroHusbandImg: submitImgUrl });
              break;

            case "retroHusbandPresentImg":
              onSubmit({ _retroHusbandPresentImg: submitImgUrl });
              break;

            case "retroWifeImg":
              onSubmit({ _retroWifeImg: submitImgUrl });
              break;

            case "retroWifePresentImg":
              onSubmit({ _retroWifePresentImg: submitImgUrl });
              break;

            default:
              break;
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const onFileChange = (event, index) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const reader = new FileReader();

    reader.onloadend = async (finishedEvent) => {
      // console.log(finishedEvent.target.result);
      const {
        currentTarget: { result },
      } = finishedEvent;

      try {
        new Compressor(theFile, {
          quality: 0.8,
          maxWidth: 2000,
          maxHeight: 2000,
          success(res) {
            setMainImg(result);
            // setUploadMain(res);
            // console.log(result);

            uploadMainImg({ _uploadMain: res });
          },
          error(err) {
            // console.log(err.message);
          },
        });
      } catch (err) {
        // console.log(err);
      }
    };
    theFile && reader.readAsDataURL(theFile);
  };

  const onRetroFileChange = (event, id) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];

    if (theFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setOriginalImg(reader.result);
        setSelectedImg(id);
        setIsCropperModal(true);
        // console.log(reader.result);
      };
      reader.readAsDataURL(theFile);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      onClick={onClick}
      style={{
        width: 216,
        height: 40,
        border: "1px solid #e9e9e9",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        fontSize: "0.9rem",
        lineHeight: "0.9rem",
        color: "#333",

        cursor: "pointer",
      }}
    >
      {/* 2022년 05월 10일 토요일 */}
      {value}
    </div>
  ));

  const pushData = () => {
    getData({ isSaved: true });
  };

  const debounceWeddingTime = useCallback(
    debounce(async (_weddingTime_1, _weddingTime_2, _weddingTime_3) => {
      // console.log("debounce" + _weddingTime_1);

      let calTime = _weddingTime_1 === "am" ? 0 : 12;
      let calTime2 = _weddingTime_2 * 1;

      let calTime3 =
        calTime + calTime2 < 10
          ? "0" + (calTime + calTime2)
          : calTime + calTime2 === 24
          ? calTime + calTime2 - 12
          : calTime + calTime2;

      // console.log(`${_weddingTime_1}${_weddingTime_2}${_weddingTime_3}`);

      let _weddingTime = `${calTime3}:${_weddingTime_3}:00`;
      // console.log(_weddingTime);
      onSubmit({ _weddingTime });
    }, 1500),
    []
  );

  const debounceSubmit = useCallback(
    debounce(
      async ({
        _weddingDate,
        _weddingTime,
        _isCalendar,
        _isDday,
        _mainImg,
        _effect,
      }) => {
        let content = {};

        if (_weddingDate) {
          content = {
            weddingDate: _weddingDate,
          };
        } else if (_weddingTime) {
          content = {
            weddingTime: _weddingTime,
          };
        } else if (_isCalendar === true || _isCalendar === false) {
          content = {
            isCalendar: _isCalendar,
          };
        } else if (_isDday === true || _isDday === false) {
          content = {
            isDday: _isDday,
          };
        } else if (_effect) {
          content = {
            effect: _effect,
          };
        }

        await dbService
          .collection("newletters")
          .doc(letterId)
          .update(content)
          .then((res) => {
            pushData();
          });
      },
      1500
    ),
    []
  );

  const onSubmit = async ({
    _weddingDate,
    _weddingTime,
    _isCalendar,
    _isDday,
    _mainImg,
    _effect,
    _retroHusbandImg,
    _retroHusbandPresentImg,
    _retroWifeImg,
    _retroWifePresentImg,
    _retroHusbandText,
    _retroWifeText,
  }) => {
    let content = {};

    if (_weddingDate) {
      content = {
        weddingDate: _weddingDate,
      };
    } else if (_weddingTime) {
      content = {
        weddingTime: _weddingTime,
      };
    } else if (_isCalendar === true || _isCalendar === false) {
      content = {
        isCalendar: _isCalendar,
      };
    } else if (_isDday === true || _isDday === false) {
      content = {
        isDday: _isDday,
      };
    } else if (_effect) {
      content = {
        effect: _effect,
      };
    } else if (_mainImg !== undefined) {
      content = {
        mainImg: _mainImg === "" ? _mainImg : `${STORAGE_URL}/image${_mainImg}`,
      };
    } else if (_retroHusbandImg !== undefined) {
      content = {
        retroHusbandImg:
          _retroHusbandImg === ""
            ? _retroHusbandImg
            : `${STORAGE_URL}/image${_retroHusbandImg}`,
      };
    } else if (_retroHusbandPresentImg !== undefined) {
      content = {
        retroHusbandPresentImg:
          _retroHusbandPresentImg === ""
            ? _retroHusbandPresentImg
            : `${STORAGE_URL}/image${_retroHusbandPresentImg}`,
      };
    } else if (_retroWifeImg !== undefined) {
      content = {
        retroWifeImg:
          _retroWifeImg === ""
            ? _retroWifeImg
            : `${STORAGE_URL}/image${_retroWifeImg}`,
      };
    } else if (_retroWifePresentImg !== undefined) {
      content = {
        retroWifePresentImg:
          _retroWifePresentImg === ""
            ? _retroWifePresentImg
            : `${STORAGE_URL}/image${_retroWifePresentImg}`,
      };
    } else if (_retroHusbandText !== undefined) {
      content = {
        retroHusbandText: _retroHusbandText,
      };
    } else if (_retroWifeText !== undefined) {
      content = {
        retroWifeText: _retroWifeText,
      };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  // cropperModal.jsx
  const cropperRef = useRef(null);

  const getCroppedImage = async ({ id }) => {
    const cropper = cropperRef.current.cropper;

    // 크롭된 이미지를 Blob 형식으로 가져오기
    cropper
      .getCroppedCanvas({
        width: 413,
        height: 531,
      })
      .toBlob((blob) => {
        if (blob) {
          // Blob을 File로 변환
          const file = new File([blob], `${id}.png`, { type: "image/png" });

          // console.log("File created:", file);

          // 각 케이스별로 처리
          switch (id) {
            case "retroHusbandImg":
              setRetroHusbandImg(URL.createObjectURL(file)); // 미리보기용
              uploadRetroImg({ _imgFile: file, id: "retroHusbandImg" });
              setIsCropperModal(false);
              break;

            case "retroHusbandPresentImg":
              setRetroHusbandPresentImg(URL.createObjectURL(file));
              uploadRetroImg({ _imgFile: file, id: "retroHusbandPresentImg" });
              setIsCropperModal(false);
              break;

            case "retroWifeImg":
              setRetroWifeImg(URL.createObjectURL(file));
              uploadRetroImg({ _imgFile: file, id: "retroWifeImg" });
              setIsCropperModal(false);
              break;

            case "retroWifePresentImg":
              setRetroWifePresentImg(URL.createObjectURL(file));
              console.log("setRetroWifePresentImg");
              uploadRetroImg({ _imgFile: file, id: "retroWifePresentImg" });
              setIsCropperModal(false);
              break;

            default:
              break;
          }
        }
      }, "image/png"); // MIME 타입 지정
  };

  const returnImgFuc = (data) => {
    switch (data) {
      case "retroHusbandImg":
        return retroHusbandImg;
        break;

      case "retroHusbandPresentImg":
        return retroHusbandPresentImg;
        break;

      case "retroWifeImg":
        return retroWifeImg;
        break;

      case "retroWifePresentImg":
        return retroWifePresentImg;
        break;

      default:
        break;
    }
  };

  const returnResetImgFuc = (data) => {
    switch (data) {
      case "retroHusbandImg":
        setRetroHusbandImg("");
        onSubmit({ _retroHusbandImg: "" });
        break;

      case "retroHusbandPresentImg":
        setRetroHusbandPresentImg("");
        onSubmit({ _retroHusbandPresentImg: "" });
        break;

      case "retroWifeImg":
        setRetroWifeImg("");
        onSubmit({ _retroWifeImg: "" });
        break;

      case "retroWifePresentImg":
        setRetroWifePresentImg("");
        onSubmit({ _retroWifePresentImg: "" });
        break;

      default:
        break;
    }
  };

  const RetroImgComponent = ({ id, present }) => {
    return (
      <div>
        <input
          id={id}
          name={id}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => {
            onRetroFileChange(e, id);
          }}
          style={{
            width: 1,
            height: 1,
            position: "absolute",
            display: "none",
          }}
        />

        {returnImgFuc(id) ? (
          <div
            style={{
              width: 155,
              height: 200,
              border: "1px solid #e9e9e9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 8,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                backgroundColor: "#fefcfc",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <img
                src={returnImgFuc(id)}
                style={{
                  maxHeight: 200,
                  maxWidth: 155,
                }}
              />
              <div
                style={{
                  backgroundColor: "rgb(0, 0, 0,0.6)",
                  height: 50,
                  width: 155,
                  position: "absolute",
                  bottom: 0,
                  display: "flex",
                }}
              >
                <label
                  for={id}
                  style={{
                    flex: 1,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CreateIcon
                    style={{
                      color: "#fff",
                      fontSize: 22,
                    }}
                  />
                </label>
                <div
                  style={{
                    flex: 1,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    returnResetImgFuc(id);
                  }}
                >
                  <CloseIcon
                    style={{
                      color: "#fff",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <label for={id}>
            <div
              style={{
                width: 155,
                height: 200,
                border: "1px solid #e9e9e9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                overflow: "hidden",
                cursor: "pointer",
                flexDirection: "column",
              }}
              onClick={() => {
                // console.log("신랑 어린시절사진 모달");
                // setIsCropperModal(true);
              }}
            >
              {present ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src="https://storage.cryucard.com/image/2025-03-22/qUGK5rk3kCYYfMunY6vi-e1a8d4c7-891a-4857-82c1-0ddbfff9135b.png"
                    style={{ width: 40, height: 40 }}
                  />
                  <span>현재 사진</span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src="https://storage.cryucard.com/image/2025-03-22/qUGK5rk3kCYYfMunY6vi-c9a70593-231f-4e2d-be27-4c1aa95a51a1.png"
                    style={{ width: 40, height: 40 }}
                  />
                  <span>어린 시절 사진</span>
                </div>
              )}
            </div>
          </label>
        )}
      </div>
    );
  };

  // <---글귀 함수
  const editableDivRef = useRef(null);
  const editableDivRef2 = useRef(null);

  useEffect(() => {
    if (editableDivRef.current) {
      editableDivRef.current.innerText = retroHusbandText;
      // console.log(retroHusbandText);
    }
  }, [retroHusbandText]);

  useEffect(() => {
    if (editableDivRef2.current) {
      editableDivRef2.current.innerText = retroWifeText;
      // console.log(retroHusbandText);
    }
  }, [retroWifeText]);

  const handleInput = ({ _parameter }) => {
    // HTML로 가져와 줄바꿈을 반영
    const htmlText = editableDivRef.current.innerHTML;
    const lines = htmlText.split(/<div>|<br>|<\/div>/).filter(Boolean); // 엔터로 생성된 줄바꿈을 인식
    let formattedText = "";

    console.log(lines);

    lines.forEach((line) => {
      while (line.length > 8) {
        formattedText += line.slice(0, 8) + "\n"; // 8글자마다 줄바꿈 추가
        line = line.slice(8);
      }
      formattedText += line + "\n"; // 남은 글자를 추가하고 줄바꿈
    });

    formattedText = formattedText.trim(); // 마지막 \n 제거
    let formattedLines = formattedText.split("\n");

    getText(formattedText, _parameter);

    if (formattedLines.length > 4) {
      // 4줄을 초과할 경우 마지막 줄을 제거
      formattedText = formattedLines.slice(0, 4).join("\n");
      editableDivRef.current.innerText = formattedText;
    }
  };

  const handleInput2 = ({ _parameter }) => {
    // HTML로 가져와 줄바꿈을 반영
    const htmlText = editableDivRef2.current.innerHTML;
    const lines = htmlText.split(/<div>|<br>|<\/div>/).filter(Boolean); // 엔터로 생성된 줄바꿈을 인식
    let formattedText = "";

    console.log(lines);

    lines.forEach((line) => {
      while (line.length > 8) {
        formattedText += line.slice(0, 8) + "\n"; // 8글자마다 줄바꿈 추가
        line = line.slice(8);
      }
      formattedText += line + "\n"; // 남은 글자를 추가하고 줄바꿈
    });

    formattedText = formattedText.trim(); // 마지막 \n 제거
    let formattedLines = formattedText.split("\n");

    getText(formattedText, _parameter);

    if (formattedLines.length > 4) {
      // 4줄을 초과할 경우 마지막 줄을 제거
      formattedText = formattedLines.slice(0, 4).join("\n");
      editableDivRef2.current.innerText = formattedText;
    }
  };

  const getBlurText = ({ _parameter }) => {
    const htmlText = editableDivRef.current.innerHTML;
    const lines = htmlText.split(/<div>|<br>|<\/div>/).filter(Boolean); // 엔터로 생성된 줄바꿈을 인식
    let formattedText = "";

    console.log(lines);

    lines.forEach((line) => {
      while (line.length > 8) {
        formattedText += line.slice(0, 8) + "\n"; // 8글자마다 줄바꿈 추가
        line = line.slice(8);
      }
      formattedText += line + "\n"; // 남은 글자를 추가하고 줄바꿈
    });

    formattedText = formattedText.trim(); // 마지막 \n 제거

    if (_parameter === "retroHusbandText") {
      onSubmit({ _retroHusbandText: formattedText });
    } else if (_parameter === "retroWifeText") {
      onSubmit({ _retroWifeText: formattedText });
    }
  };

  const getBlurText2 = ({ _parameter }) => {
    const htmlText = editableDivRef2.current.innerHTML;
    const lines = htmlText.split(/<div>|<br>|<\/div>/).filter(Boolean); // 엔터로 생성된 줄바꿈을 인식
    let formattedText = "";

    console.log(lines);

    lines.forEach((line) => {
      while (line.length > 8) {
        formattedText += line.slice(0, 8) + "\n"; // 8글자마다 줄바꿈 추가
        line = line.slice(8);
      }
      formattedText += line + "\n"; // 남은 글자를 추가하고 줄바꿈
    });

    formattedText = formattedText.trim(); // 마지막 \n 제거

    if (_parameter === "retroHusbandText") {
      onSubmit({ _retroHusbandText: formattedText });
    } else if (_parameter === "retroWifeText") {
      onSubmit({ _retroWifeText: formattedText });
    }
  };

  const debounceGetText = useCallback(
    debounce(({ _parameter, data }) => {
      if (_parameter === "retroHusbandText") {
        if (data !== undefined) {
          onSubmit({ _retroHusbandText: data });
        }
      } else if (_parameter === "retroWifeText") {
        if (data !== undefined) {
          onSubmit({ _retroWifeText: data });
        }
      }
    }, 1500),
    []
  );

  const getText = (data, _parameter) => {
    // console.log(data.text);
    // pushData(data.text);

    debounceGetText({
      _parameter: _parameter,
      data: data,
    });
  };
  // 글귀 함수-->

  return (
    <div>
      <div>
        {/* 예식일자 */}
        <div>
          <SubTitleComponent title="예식일자" />
          <div>
            <DatePicker
              selected={weddingDate}
              onChange={(weddingDate) => {
                setWeddingDate(weddingDate);
                // console.log(weddingDate);
                onSubmit({
                  _weddingDate: `${weddingDate.getFullYear()}-${
                    weddingDate.getMonth() + 1 < 10
                      ? "0" + (weddingDate.getMonth() + 1).toString()
                      : weddingDate.getMonth() + 1
                  }-${
                    weddingDate.getDate() < 10
                      ? "0" + weddingDate.getDate().toString()
                      : weddingDate.getDate()
                  }`,
                });
              }}
              customInput={<ExampleCustomInput />}
              locale={ko}
              dateFormat="yyyy년 MM월 dd일 E요일"
              minDate={new Date()}
              maxDate={maxDate}
            />
          </div>
        </div>
        {/* 예식일자 */}

        {/* 예식시간 */}
        <div style={{ width: 300 }}>
          <SubTitleComponent title="예식시간" />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "#333",
            }}
          >
            <div style={{ flex: 0.75 }}>
              <div className="prod-select-container" style={{ width: "auto" }}>
                <select
                  name="time"
                  id="time-select"
                  className="prod-select"
                  onChange={(e) => {
                    setWeddingTime_1(e.target.value);
                    // console.log(e.target.value);
                    debounceWeddingTime(
                      e.target.value,
                      weddingTime_2,
                      weddingTime_3
                    );
                  }}
                  value={weddingTime_1}
                >
                  <option value="am">오전</option>
                  <option value="pm">오후</option>
                </select>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: 16,
              }}
            >
              {/* <div style={{ maxWidth: 60 }}>
                                <TextInputComponent />
                            </div> */}

              <div style={{ maxWidth: 80 }}>
                <div
                  className="prod-select-container"
                  style={{ width: "auto" }}
                >
                  <select
                    name="time"
                    id="time-select"
                    className="prod-select"
                    onChange={(e) => {
                      setWeddingTime_2(e.target.value);
                      debounceWeddingTime(
                        weddingTime_1,
                        e.target.value,
                        weddingTime_3
                      );
                    }}
                    value={weddingTime_2}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>
              </div>
              <span style={{ marginLeft: 5 }}>시</span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: 10,
              }}
            >
              <div className="prod-select-container" style={{ width: "auto" }}>
                <select
                  name="time"
                  id="time-select"
                  className="prod-select"
                  onChange={(e) => {
                    setWeddingTime_3(e.target.value);
                    debounceWeddingTime(
                      weddingTime_1,
                      weddingTime_2,
                      e.target.value
                    );
                  }}
                  value={weddingTime_3}
                >
                  <option value="00">00</option>
                  <option value="00">00</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="35">35</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="55">55</option>
                </select>
              </div>
              <span style={{ marginLeft: 5 }}>분</span>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: 10 }}>
            <div
              onClick={() => {
                setIsCalendar((value) => {
                  onSubmit({ _isCalendar: !value });

                  return !value;
                });
              }}
            >
              <CheckboxComponent title="달력 표시" isChecked={isCalendar} />
            </div>
            <div style={{ marginLeft: 10 }} />
            <div
              onClick={() => {
                setIsDday((value) => {
                  onSubmit({ _isDday: !value });

                  return !value;
                });
                // console.log(isDday);
              }}
            >
              <CheckboxComponent title="디데이 표시" isChecked={isDday} />
            </div>
          </div>
        </div>
        {/* 예식시간 */}
      </div>

      {template === 4 ? (
        <div>
          <SubTitleComponent title="신랑 사진" present={false} />
          <div>
            <div style={{ display: "flex" }}>
              {/* 어린시절사진 */}
              <div>
                <input
                  id={"retroHusbandImg"}
                  name={"retroHusbandImg"}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => {
                    onRetroFileChange(e, "retroHusbandImg");
                  }}
                  style={{
                    width: 1,
                    height: 1,
                    position: "absolute",
                    display: "none",
                  }}
                />

                {returnImgFuc("retroHusbandImg") ? (
                  <div
                    style={{
                      width: 155,
                      height: 200,
                      border: "1px solid #e9e9e9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 8,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fefcfc",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <img
                        src={returnImgFuc("retroHusbandImg")}
                        style={{
                          maxHeight: 200,
                          maxWidth: 155,
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(0, 0, 0,0.6)",
                          height: 50,
                          width: 155,
                          position: "absolute",
                          bottom: 0,
                          display: "flex",
                        }}
                      >
                        <label
                          for={"retroHusbandImg"}
                          style={{
                            flex: 1,
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CreateIcon
                            style={{
                              color: "#fff",
                              fontSize: 22,
                            }}
                          />
                        </label>
                        <div
                          style={{
                            flex: 1,
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            returnResetImgFuc("retroHusbandImg");
                          }}
                        >
                          <CloseIcon
                            style={{
                              color: "#fff",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <label for={"retroHusbandImg"}>
                    <div
                      style={{
                        width: 155,
                        height: 200,
                        border: "1px solid #e9e9e9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 8,
                        overflow: "hidden",
                        cursor: "pointer",
                        flexDirection: "column",
                      }}
                      onClick={() => {
                        // console.log("신랑 어린시절사진 모달");
                        // setIsCropperModal(true);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src="https://storage.cryucard.com/image/2025-03-22/qUGK5rk3kCYYfMunY6vi-c9a70593-231f-4e2d-be27-4c1aa95a51a1.png"
                          style={{ width: 40, height: 40 }}
                        />
                        <span>어린 시절 사진</span>
                      </div>
                    </div>
                  </label>
                )}
              </div>
              {/* 어린시절사진 */}

              {/* 현재 사진 */}
              <div style={{ marginLeft: 10 }}>
                <div>
                  <input
                    id={"retroHusbandPresentImg"}
                    name={"retroHusbandPresentImg"}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => {
                      onRetroFileChange(e, "retroHusbandPresentImg");
                    }}
                    style={{
                      width: 1,
                      height: 1,
                      position: "absolute",
                      display: "none",
                    }}
                  />

                  {returnImgFuc("retroHusbandPresentImg") ? (
                    <div
                      style={{
                        width: 155,
                        height: 200,
                        border: "1px solid #e9e9e9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 8,
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#fefcfc",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          src={returnImgFuc("retroHusbandPresentImg")}
                          style={{
                            maxHeight: 200,
                            maxWidth: 155,
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: "rgb(0, 0, 0,0.6)",
                            height: 50,
                            width: 155,
                            position: "absolute",
                            bottom: 0,
                            display: "flex",
                          }}
                        >
                          <label
                            for={"retroHusbandPresentImg"}
                            style={{
                              flex: 1,
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CreateIcon
                              style={{
                                color: "#fff",
                                fontSize: 22,
                              }}
                            />
                          </label>
                          <div
                            style={{
                              flex: 1,
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              returnResetImgFuc("retroHusbandPresentImg");
                            }}
                          >
                            <CloseIcon
                              style={{
                                color: "#fff",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <label for={"retroHusbandPresentImg"}>
                      <div
                        style={{
                          width: 155,
                          height: 200,
                          border: "1px solid #e9e9e9",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 8,
                          overflow: "hidden",
                          cursor: "pointer",
                          flexDirection: "column",
                        }}
                        onClick={() => {
                          // console.log("신랑 어린시절사진 모달");
                          // setIsCropperModal(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src="https://storage.cryucard.com/image/2025-03-22/qUGK5rk3kCYYfMunY6vi-e1a8d4c7-891a-4857-82c1-0ddbfff9135b.png"
                            style={{ width: 40, height: 40 }}
                          />
                          <span>현재 사진</span>
                        </div>
                      </div>
                    </label>
                  )}
                </div>
              </div>
              {/* 현재 사진 */}
            </div>
          </div>

          <SubTitleComponent title="신랑 글귀" />
          <div
            style={{
              display: "flex",
              width: 200,
              height: 260,
              border: "1px solid #e9e9e9",
              borderRadius: 8,
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 20,
                top: 71,
                width: "100%",
                height: "100%",
              }}
            >
              <div style={{ width: 160, borderBottom: "1px solid #333" }}></div>
              <div
                style={{
                  width: 160,
                  borderBottom: "1px solid #333",
                  marginTop: 47,
                }}
              ></div>
              <div
                style={{
                  width: 160,
                  borderBottom: "1px solid #333",
                  marginTop: 47,
                }}
              ></div>
              <div
                style={{
                  width: 160,
                  borderBottom: "1px solid #333",
                  marginTop: 47,
                }}
              ></div>

              {/* 입력 영역 */}
              <div
                ref={editableDivRef}
                contentEditable
                onInput={() => {
                  handleInput({ _parameter: "retroHusbandText" });
                }}
                onBlur={() => {
                  debounceGetText.cancel();
                  getBlurText({ _parameter: "retroHusbandText" });
                }}
                style={{
                  position: "absolute",
                  top: -47,
                  left: 0,
                  width: 130,
                  height: 200,
                  border: "none",
                  outline: "none",
                  fontSize: 16,
                  lineHeight: "47px",
                  whiteSpace: "pre-wrap",
                  overflow: "hidden",
                }}
                placeholder="Start typing..."
                suppressContentEditableWarning={true}
              ></div>
            </div>
          </div>

          <SubTitleComponent title="신부 사진" />
          <div>
            <div style={{ display: "flex" }}>
              {/* 어린시절사진 */}
              <div>
                <input
                  id={"retroWifeImg"}
                  name={"retroWifeImg"}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => {
                    onRetroFileChange(e, "retroWifeImg");
                  }}
                  style={{
                    width: 1,
                    height: 1,
                    position: "absolute",
                    display: "none",
                  }}
                />

                {returnImgFuc("retroWifeImg") ? (
                  <div
                    style={{
                      width: 155,
                      height: 200,
                      border: "1px solid #e9e9e9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 8,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fefcfc",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <img
                        src={returnImgFuc("retroWifeImg")}
                        style={{
                          maxHeight: 200,
                          maxWidth: 155,
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(0, 0, 0,0.6)",
                          height: 50,
                          width: 155,
                          position: "absolute",
                          bottom: 0,
                          display: "flex",
                        }}
                      >
                        <label
                          for={"retroWifeImg"}
                          style={{
                            flex: 1,
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CreateIcon
                            style={{
                              color: "#fff",
                              fontSize: 22,
                            }}
                          />
                        </label>
                        <div
                          style={{
                            flex: 1,
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            returnResetImgFuc("retroWifeImg");
                          }}
                        >
                          <CloseIcon
                            style={{
                              color: "#fff",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <label for={"retroWifeImg"}>
                    <div
                      style={{
                        width: 155,
                        height: 200,
                        border: "1px solid #e9e9e9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 8,
                        overflow: "hidden",
                        cursor: "pointer",
                        flexDirection: "column",
                      }}
                      onClick={() => {
                        // console.log("신랑 어린시절사진 모달");
                        // setIsCropperModal(true);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src="https://storage.cryucard.com/image/2025-03-22/qUGK5rk3kCYYfMunY6vi-c9a70593-231f-4e2d-be27-4c1aa95a51a1.png"
                          style={{ width: 40, height: 40 }}
                        />
                        <span>어린 시절 사진</span>
                      </div>
                    </div>
                  </label>
                )}
              </div>
              {/* 어린시절사진 */}

              {/* 현재 사진 */}
              <div style={{ marginLeft: 10 }}>
                <div>
                  <input
                    id={"retroWifePresentImg"}
                    name={"retroWifePresentImg"}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => {
                      onRetroFileChange(e, "retroWifePresentImg");
                    }}
                    style={{
                      width: 1,
                      height: 1,
                      position: "absolute",
                      display: "none",
                    }}
                  />

                  {returnImgFuc("retroWifePresentImg") ? (
                    <div
                      style={{
                        width: 155,
                        height: 200,
                        border: "1px solid #e9e9e9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 8,
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#fefcfc",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          src={returnImgFuc("retroWifePresentImg")}
                          style={{
                            maxHeight: 200,
                            maxWidth: 155,
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: "rgb(0, 0, 0,0.6)",
                            height: 50,
                            width: 155,
                            position: "absolute",
                            bottom: 0,
                            display: "flex",
                          }}
                        >
                          <label
                            for={"retroWifePresentImg"}
                            style={{
                              flex: 1,
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CreateIcon
                              style={{
                                color: "#fff",
                                fontSize: 22,
                              }}
                            />
                          </label>
                          <div
                            style={{
                              flex: 1,
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              returnResetImgFuc("retroWifePresentImg");
                            }}
                          >
                            <CloseIcon
                              style={{
                                color: "#fff",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <label for={"retroWifePresentImg"}>
                      <div
                        style={{
                          width: 155,
                          height: 200,
                          border: "1px solid #e9e9e9",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 8,
                          overflow: "hidden",
                          cursor: "pointer",
                          flexDirection: "column",
                        }}
                        onClick={() => {
                          // console.log("신랑 어린시절사진 모달");
                          // setIsCropperModal(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src="https://storage.cryucard.com/image/2025-03-22/qUGK5rk3kCYYfMunY6vi-e1a8d4c7-891a-4857-82c1-0ddbfff9135b.png"
                            style={{ width: 40, height: 40 }}
                          />
                          <span>현재 사진</span>
                        </div>
                      </div>
                    </label>
                  )}
                </div>
              </div>
              {/* 현재 사진 */}
            </div>
          </div>
          <SubTitleComponent title="신부 글귀" />
          <div
            style={{
              display: "flex",
              width: 200,
              height: 260,
              border: "1px solid #e9e9e9",
              borderRadius: 8,
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 20,
                top: 71,
                width: "100%",
                height: "100%",
              }}
            >
              <div style={{ width: 160, borderBottom: "1px solid #333" }}></div>
              <div
                style={{
                  width: 160,
                  borderBottom: "1px solid #333",
                  marginTop: 47,
                }}
              ></div>
              <div
                style={{
                  width: 160,
                  borderBottom: "1px solid #333",
                  marginTop: 47,
                }}
              ></div>
              <div
                style={{
                  width: 160,
                  borderBottom: "1px solid #333",
                  marginTop: 47,
                }}
              ></div>

              {/* 입력 영역 */}
              <div
                ref={editableDivRef2}
                contentEditable
                onInput={() => {
                  handleInput2({ _parameter: "retroWifeText" });
                }}
                onBlur={() => {
                  debounceGetText.cancel();
                  getBlurText2({ _parameter: "retroWifeText" });
                }}
                style={{
                  position: "absolute",
                  top: -47,
                  left: 0,
                  width: 130,
                  height: 200,
                  border: "none",
                  outline: "none",
                  fontSize: 16,
                  lineHeight: "47px",
                  whiteSpace: "pre-wrap",
                  overflow: "hidden",
                }}
                placeholder="Start typing..."
                suppressContentEditableWarning={true}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <input
            id="mainImg"
            name="mainImg"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={onFileChange}
            style={{
              width: 1,
              height: 1,
              position: "absolute",
              display: "none",
            }}
          />
          {!mainImg ? (
            <label for={"mainImg"}>
              <div
                style={{
                  height: 200,
                  border: "1px dashed #888",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#888",
                  cursor: "pointer",
                  fontSize: "0.9rem",
                  marginTop: 30,
                }}
              >
                이 곳을 통해 메인사진을 업로드해주세요
              </div>
            </label>
          ) : (
            <div>
              <SubTitleComponent title="메인사진" />
              <div
                style={{
                  width: 200,
                  height: 200,
                  border: "1px solid #e9e9e9",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 8,
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fefcfc",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <img
                    src={mainImg}
                    style={{
                      maxHeight: 200,
                      maxWidth: 200,
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: "rgb(0, 0, 0,0.6)",
                      height: 50,
                      width: 200,
                      position: "absolute",
                      bottom: 0,
                      display: "flex",
                    }}
                  >
                    <label
                      for="mainImg"
                      style={{
                        flex: 1,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CreateIcon
                        style={{
                          color: "#fff",
                          fontSize: 22,
                        }}
                      />
                    </label>
                    <div
                      style={{
                        flex: 1,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setMainImg("");
                        onSubmit({ _mainImg: "" });
                      }}
                    >
                      <CloseIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <SubTitleComponent title="메인사진 효과" />
                <div className="prod-select-container">
                  <select
                    name="effect"
                    id="effect-select"
                    className="prod-select"
                    onChange={(e) => {
                      setEffect(e.target.value);
                      debounceSubmit({ _effect: e.target.value });
                    }}
                    value={effect}
                  >
                    <option value="no">없음</option>
                    <option value="confetti">컨페티</option>
                    <option value="daisy">데이지</option>
                    <option value="star">별</option>
                    <option value="sakura">벚꽃</option>
                    <option value="wave">물결</option>
                    <option value="snow">눈</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isCropperModal === true && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.4)",
            top: 0,
            left: 0,
            zIndex: 10000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              width: "100%",
              maxWidth: 380,
              margin: 16,
              borderRadius: 15,
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                top: -45,
                right: 0,
                zIndex: 1000,
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsCropperModal(false);
              }}
            >
              <CloseIcon />
            </div>
            <div
              style={{
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 50,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              사진 추가하기
            </div>
            <div
              style={{
                padding: "0 16px 20px",
                height: 500,
                overflowY: "scroll",
              }}
            >
              <Cropper
                src={originalImg} // 사용자가 선택한 사진
                ref={cropperRef}
                aspectRatio={0.7777} // 정사각형
                viewMode={1} // 크롭 영역이 이미지를 벗어나지 않게
                background={true}
                guides={true}
                style={{ height: "auto", width: "100%", marginTop: 30 }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  backgroundColor: "#724ff3",
                  width: "100%",
                  height: 45,
                  cursor: "pointer",
                  margin: "0 auto",
                  borderRadius: 10,
                  marginTop: 30,
                  fontSize: 14,
                }}
                onClick={() => {
                  getCroppedImage({ id: selectedImg });
                }}
              >
                이미지 업로드
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(MainPicComponent);
