import React, { useEffect } from "react";
import CheckIcon from "@material-ui/icons/Check";

export default function SurveySearchComponent({
    attend,
    side,
    contact,
    letterId,
    name,
    party,
    partyNumber,
    createdAt,
    message,
    getData,
    id,
    isClicked,
    eating,
}) {
    const HUSBAND_COLOR = "#00b890";
    const WIFE_COLOR = "#fea800";
    const POINT_COLOR_1 = "#66a1fb";
    const POINT_COLOR_2 = "#fd5c81";

    const transDate = (_date) => {
        var weekday = new Array();
        weekday[0] = "(일)";
        weekday[1] = "(월)";
        weekday[2] = "(화)";
        weekday[3] = "(수)";
        weekday[4] = "(목)";
        weekday[5] = "(금)";
        weekday[6] = "(토)";

        var date = new Date(_date);
        var year = date.getFullYear().toString();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();

        minutes = minutes < 10 ? "0" + minutes : minutes;

        var week = date.getDay();

        return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}`;
    };

    const pushData = (data) => {
        getData({ data });
    };

    useEffect(() => {
        console.log(isClicked);
    }, []);

    return (
        <div
            className="survey_search_content"
            onClick={() => {
                pushData(id);
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            padding: "3px 8px",
                            fontSize: 12,
                            backgroundColor:
                                side == 0 ? HUSBAND_COLOR : WIFE_COLOR,
                            color: "#fff",
                            fontWeight: "bold",
                            borderRadius: 5,
                        }}
                    >
                        {side == 0 ? "신랑측" : "신부측"}
                    </div>
                    <div
                        style={{
                            padding: "3px 8px",
                            fontSize: 12,
                            backgroundColor:
                                attend == 0 ? POINT_COLOR_1 : POINT_COLOR_2,
                            color: "#fff",
                            fontWeight: "bold",
                            borderRadius: 5,
                            marginLeft: 5,
                        }}
                    >
                        {attend == 0 ? "참석" : "불참"}
                    </div>

                    {eating === true || eating === false ? (
                        <div
                            style={{
                                padding: "3px 8px",
                                fontSize: 12,
                                backgroundColor:
                                    eating == true
                                        ? POINT_COLOR_1
                                        : POINT_COLOR_2,
                                color: "#fff",
                                fontWeight: "bold",
                                borderRadius: 5,
                                marginLeft: 5,
                            }}
                        >
                            {eating == true ? "식사 가능" : "식사 불가"}
                        </div>
                    ) : (
                        false
                    )}
                </div>

                <div
                    style={{
                        display: "inline-block",
                        width: 20,
                        height: 20,
                        borderRadius: 3,
                        backgroundColor: isClicked ? "#f76c6c" : "#f2f3f5",
                        cursor: "pointer",
                    }}
                >
                    {isClicked ? (
                        <CheckIcon
                            style={{ color: "#fff", width: 20, height: 20 }}
                        />
                    ) : (
                        false
                    )}
                </div>
            </div>
            <div>
                <p
                    style={{
                        fontSize: 14,
                        margin: "5px 0",
                    }}
                >
                    <span
                        style={{
                            fontWeight: "bold",
                            fontSize: 16,
                        }}
                    >
                        {name}
                    </span>
                    님
                    {partyNumber == 0 ? (
                        false
                    ) : (
                        <span> 외 {partyNumber}명</span>
                    )}
                </p>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: 14,
                        margin: "5px 0",
                    }}
                >
                    <span>연락처</span>
                    <span>{contact}</span>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: 14,
                        margin: "5px 0",
                    }}
                >
                    <span>일행</span>
                    <span>{party}</span>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: 14,
                        margin: "5px 0",
                    }}
                >
                    <span>메시지</span>
                    <span>{message}</span>
                </div>

                <div
                    style={{
                        textAlign: "right",
                        fontSize: 12,
                        margin: "10px 0 5px",
                    }}
                >
                    {transDate(createdAt)}
                </div>
            </div>
        </div>
    );
}
