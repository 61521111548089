import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

export default function ModalComponent({ item, title, isModal, getIsModal }) {
    return (
        <>
            {isModal && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        zIndex: 1000,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#fff",
                            maxWidth: 380,
                            width: "100%",
                            height: 500,
                            borderRadius: 10,
                            overflow: "scroll",
                            margin: "0 16px",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                right: 10,
                                top: 13,
                                width: 20,
                                height: 20,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={() => {
                                getIsModal({ isModal: false });
                            }}
                        >
                            <CloseIcon style={{ width: 18 }} />
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid #e3e3e3",
                                padding: "13px 0",
                                textAlign: "center",
                                fontSize: 14,
                            }}
                        >
                            {title}
                        </div>
                        <div style={{ padding: "20px 0" }}>{item}</div>
                    </div>
                </div>
            )}
        </>
    );
}
