import React, { useState } from "react";
import StepperComponent from "../../../components/StepperComponent/StepperComponent";
import Resizer from "react-image-file-resizer";
import NextButton from "../../../components/NextButton/NextButton";
import Header from "../../../components/Header/Header";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";

const ImageUploadScreen = () => {
    const [mainImg, setMainIMg] = useState("");
    const [galleryImg_1, setGalleryImg_1] = useState("");
    const [galleryImg_2, setGalleryImg_2] = useState("");
    const [galleryImg_3, setGalleryImg_3] = useState("");
    const [galleryImg_4, setGalleryImg_4] = useState("");
    const [galleryImg_5, setGalleryImg_5] = useState("");
    const [galleryImg_6, setGalleryImg_6] = useState("");
    const [galleryImg_7, setGalleryImg_7] = useState("");
    const [galleryImg_8, setGalleryImg_8] = useState("");
    const [galleryImg_9, setGalleryImg_9] = useState("");
    const [galleryImg_10, setGalleryImg_10] = useState("");
    const [galleryImg_11, setGalleryImg_11] = useState("");
    const [galleryImg_12, setGalleryImg_12] = useState("");
    const [galleryImg_13, setGalleryImg_13] = useState("");
    const [galleryImg_14, setGalleryImg_14] = useState("");
    const [galleryImg_15, setGalleryImg_15] = useState("");

    const onFileChange = (event) => {
        const {
            target: { files, name },
        } = event;

        const theFile = files[0];
        const reader = new FileReader();

        reader.onloadend = (finishedEvent) => {
            // console.log(finishedEvent.target.result);
            const {
                currentTarget: { result },
            } = finishedEvent;

            if (name === "mainImg") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setMainIMg(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_1") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_1(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_2") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_2(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_3") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_3(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_4") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_4(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_5") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_5(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_6") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_6(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_7") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_7(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_8") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_8(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_9") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_9(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_10") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_10(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_11") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_11(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_12") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_12(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_13") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_13(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_14") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_14(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            } else if (name === "galleryImg_15") {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        1000,
                        1000,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            //   console.log(uri);
                            setGalleryImg_15(uri);
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }
            }
        };
        theFile && reader.readAsDataURL(theFile);
    };

    return (
        <div
            style={{
                backgroundColor: "rgb(238 238 238)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ backgroundColor: "#fff", maxWidth: 480 }}>
                <Header />
                <StepperComponent step={0} />
                <div style={{ padding: "20px 20px 20px" }}>
                    <p
                        style={{
                            padding: 0,
                            fontSize: 20,
                            fontWeight: "bold",
                            lineHeight: "40px",
                        }}
                    >
                        메인사진을 선택해주세요 📸
                    </p>
                    <p style={{ fontSize: 12, color: "#777" }}>
                        가로, 세로에 상관없이 추가가능합니다.
                    </p>
                </div>

                <div style={{ margin: 6, padding: "0 14px" }}>
                    <label
                        for="mainImg"
                        style={{
                            width: 200,
                            height: 200,
                            border: "1px dashed rgb(206, 212, 218)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {mainImg !== "" ? (
                            <div
                                style={{
                                    backgroundColor: "#000",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={mainImg}
                                    style={{ maxHeight: 200, maxWidth: 200 }}
                                />
                            </div>
                        ) : (
                            <span
                                style={{
                                    fontSize: 70,
                                    fontWeight: "100",
                                    color: "#999",
                                }}
                            >
                                +
                            </span>
                        )}
                    </label>

                    <input
                        id="mainImg"
                        name="mainImg"
                        type="file"
                        accept="image/*"
                        onChange={onFileChange}
                        style={{ width: 1, height: 1 }}
                    />
                </div>

                <div style={{ padding: "20px 20px 20px" }}>
                    <p
                        style={{
                            padding: 0,
                            fontSize: 20,
                            fontWeight: "bold",
                            lineHeight: "40px",
                        }}
                    >
                        갤러리 사진을 선택해주세요 🎑
                    </p>
                    <p style={{ fontSize: 12, color: "#777" }}>
                        가로, 세로에 상관없이 추가가능합니다. (최대 15장)
                    </p>
                </div>

                {/* 갤러리 */}
                <div style={{ padding: "0 14px" }}>
                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        {galleryImg_1 !== "" ? (
                            <div
                                style={{
                                    width: 150,
                                    height: 150,
                                    border: "1px solid #000",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                    }}
                                >
                                    <img
                                        src={galleryImg_1}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                    <div
                                        style={{
                                            backgroundColor: "rgb(0, 0, 0,0.6)",
                                            height: 50,
                                            width: 150,
                                            position: "absolute",
                                            bottom: 0,
                                            display: "flex",
                                        }}
                                    >
                                        <label
                                            for="galleryImg_1"
                                            style={{
                                                flex: 1,
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CreateIcon
                                                style={{
                                                    color: "#fff",
                                                    fontSize: 22,
                                                }}
                                            />
                                        </label>
                                        <div
                                            style={{
                                                flex: 1,
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                            onClick={() => setGalleryImg_1("")}
                                        >
                                            <CloseIcon
                                                style={{ color: "#fff" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <label
                                for="galleryImg_1"
                                style={{
                                    width: 150,
                                    height: 150,
                                    border: "1px dashed rgb(206, 212, 218)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            </label>
                        )}

                        <input
                            id="galleryImg_1"
                            name="galleryImg_1"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_2"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_2 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_2}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_2"
                            name="galleryImg_2"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_3"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_3 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_3}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_3"
                            name="galleryImg_3"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_4"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_4 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_4}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_4"
                            name="galleryImg_4"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_5"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_5 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_5}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_5"
                            name="galleryImg_5"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_6"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_6 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_6}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_6"
                            name="galleryImg_6"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_7"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_7 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_7}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_7"
                            name="galleryImg_7"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_8"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_8 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_8}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_8"
                            name="galleryImg_8"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_9"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_9 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_9}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_9"
                            name="galleryImg_9"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>

                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_10"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_10 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_10}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_10"
                            name="galleryImg_10"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>
                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_11"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_11 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_11}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_11"
                            name="galleryImg_11"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>
                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_12"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_12 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_12}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_12"
                            name="galleryImg_12"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>
                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_13"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_13 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_13}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_13"
                            name="galleryImg_13"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>
                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_14"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_14 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_14}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_14"
                            name="galleryImg_14"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>
                    <div
                        style={{
                            margin: 6,
                            display: "inline-block",
                            width: 150,
                        }}
                    >
                        <label
                            for="galleryImg_15"
                            style={{
                                width: 150,
                                height: 150,
                                border: "1px dashed rgb(206, 212, 218)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {galleryImg_15 !== "" ? (
                                <div
                                    style={{
                                        backgroundColor: "#000",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={galleryImg_15}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontSize: 70,
                                        fontWeight: "100",
                                        color: "#999",
                                    }}
                                >
                                    +
                                </span>
                            )}
                        </label>

                        <input
                            id="galleryImg_15"
                            name="galleryImg_15"
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ width: 1, height: 1 }}
                        />
                    </div>
                </div>
                {/* 갤러리 */}

                <NextButton path="/order/husband" />
            </div>
        </div>
    );
};

export default ImageUploadScreen;
