import React, { useState, useEffect } from "react";
import HidiHeader from "../../components/HidiHeader/HidiHeader";
import CardComponent from "../../components/CardComponent/CardComponent";
import localStorage from "mobx-localstorage";
import { dbService } from "../../fbase";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import HidiProductComponent from "../../components/HidiProductComponent/HidiProductComponent";
import premiumLetterImg from "../../assets/images/premiumLetter.jpeg";
import whiteLetterImg from "../../assets/images/whiteLetter.png";
import brownLetterImg from "../../assets/images/brownLetter.png";
import hidiLetterImg from "../../assets/images/hidiLetter.png";

const HidiOrderlistScreen = () => {
    const [userId, setUserId] = useState("");

    const getEmail = async () => {
        let getUserId = "";

        if (localStorage.getItem("userId")) {
            getUserId = await localStorage.getItem("userId");
            // await setIsLoading(true);
            setUserId(getUserId);
        } else {
            console.log("noEmail");
        }

        // await console.log(getUserId);
    };

    useEffect(() => {
        getEmail();
    }, []);

    return (
        <div
            style={{
                backgroundColor: "rgb(238 238 238)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
            }}
        >
            <div
                style={{
                    backgroundColor: "#fff",
                    maxWidth: 480,
                    width: "100%",
                }}
            >
                <HidiHeader userId={userId} />

                <div style={{ padding: "20px 10px 100px" }}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: "500",
                            paddingLeft: 15,
                        }}
                    >
                        모바일청첩장
                    </div>

                    <HidiProductComponent
                        imgUrl={hidiLetterImg}
                        title="하이디 청첩장"
                        beforePrice="23,800원"
                        price="13,900원"
                        sampleUrl="https://self.cryucompany.com/hidi/RnovOhdhD2WXMbdOikod/account"
                        productNum={99}
                    />

                    <HidiProductComponent
                        imgUrl={premiumLetterImg}
                        title="프리미엄 청첩장"
                        beforePrice="23,800원"
                        price="13,900원"
                        sampleUrl="http://m.site.naver.com/0OmGI"
                        productNum={4}
                    />

                    <HidiProductComponent
                        imgUrl={whiteLetterImg}
                        title="순백 청첩장"
                        beforePrice="20,800원"
                        price="13,900원"
                        sampleUrl="http://m.site.naver.com/0OmGN"
                        productNum={5}
                    />

                    <HidiProductComponent
                        imgUrl={brownLetterImg}
                        title="브라운 청첩장"
                        beforePrice="17,800원"
                        price="13,900원"
                        sampleUrl="http://m.site.naver.com/0OmGP"
                        productNum={6}
                    />
                </div>
                <FooterComponent />
            </div>
        </div>
    );
};

export default HidiOrderlistScreen;
