import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import clap from "../../assets/lottie/clap.json";
import letter from "../../assets/lottie/letter.json";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../../components/NewComponents/Header/Header";
import localStorage from "mobx-localstorage";
import ArrowIcon from "../../assets/images/long-arrow-icon.png";
import TextInputComponent from "../../components/TextInputComponent/TextInputComponent";
import Compressor from "compressorjs";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "../../fbase";
import { useParams, useHistory } from "react-router-dom";
const STORAGE_URL = "https://storage.cryucard.com";

var today = new Date().toISOString().split("T")[0];

const defaultOption = {
  loop: true,
  autoplay: true,
  animationData: clap,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOption2 = {
  loop: true,
  autoplay: true,
  animationData: letter,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

// s3설정
const config = {
  bucketName: "new-cryucard",
  dirName: `image/${today}`,
  region: "ap-northeast-2",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

const ReactS3Client = new S3(config);
// s3설정

export default function FirstProduction() {
  const history = useHistory();

  const [userId, setUserId] = useState("");
  const [step, setStep] = useState(1);
  const [mainImg, setMainImg] = useState("");
  const [uploadMainImg, setUploadMainImg] = useState("");

  const [hFamilyName, setHFamilyName] = useState("");
  const [hName, setHName] = useState("");
  const [wFamilyName, setWFamilyName] = useState("");
  const [wName, setWName] = useState("");

  const getUserId = new Promise((resolve, reject) => {
    let _getUserId = "";
    if (localStorage.getItem("userId")) {
      _getUserId = localStorage.getItem("userId");
      // setUserId(_getUserId);
    } else {
      // window.location.href = "/#/login";
      // await localStorage.setItem("userId", "51543483");
      reject(new Error("please login"));
    }

    resolve(_getUserId);
  });

  const onFileChange = (event, index) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const reader = new FileReader();

    reader.onloadend = async (finishedEvent) => {
      // console.log(finishedEvent.target.result);
      const {
        currentTarget: { result },
      } = finishedEvent;

      try {
        new Compressor(theFile, {
          quality: 0.8,
          maxWidth: 2000,
          maxHeight: 2000,
          success(res) {
            setMainImg(result);
            setUploadMainImg(res);
          },
          error(err) {
            console.log(err.message);
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    theFile && reader.readAsDataURL(theFile);
  };

  const ImgPreviewComponent = () => {
    return (
      <div>
        <div
          style={{
            width: 200,
            height: 200,
            border: "1px solid #e9e9e9",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 8,
            overflow: "hidden",
            marginTop: 30,
          }}
        >
          <div
            style={{
              backgroundColor: "#fefcfc",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <img
              src={mainImg}
              style={{
                maxHeight: 200,
                maxWidth: 200,
              }}
            />
            <div
              style={{
                backgroundColor: "rgb(0, 0, 0,0.6)",
                height: 50,
                width: 200,
                position: "absolute",
                bottom: 0,
                display: "flex",
              }}
            >
              <label
                for="mainImg"
                style={{
                  flex: 1,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CreateIcon
                  style={{
                    color: "#fff",
                    fontSize: 22,
                  }}
                />
              </label>
              <div
                style={{
                  flex: 1,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setMainImg("");
                  setUploadMainImg("");
                }}
              >
                <CloseIcon
                  style={{
                    color: "#fff",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ImgComponent = () => {
    return (
      <>
        <label for="mainImg">
          <div
            data-aos="fade-in"
            data-aos-delay="1200"
            data-aos-duration="1000"
            style={{
              height: 150,
              border: "1px dashed #e5e4e4",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#888",
              cursor: "pointer",
              marginTop: 30,
              width: 300,
              fontSize: 12,
              backgroundColor: "#fefcfc",
            }}
          >
            <p style={{ textAlign: "center" }}>메인사진을 업로드해 주세요</p>
          </div>
        </label>
        <p
          data-aos="fade-in"
          data-aos-delay="3000"
          data-aos-duration="1000"
          style={{
            color: "#182e0c",
            fontWeight: "bold",
            textAlign: "center",
            fontSize: 12,
            paddingTop: 8,
          }}
        >
          아직 준비된 사진이 없다면?
          <br />
          아무 사진이나 업로드 후 수정 가능해요!
        </p>
      </>
    );
  };

  const createdDate = () => {
    let weddingDate = new Date();
    let data;

    data = `${weddingDate.getFullYear()}-${
      weddingDate.getMonth() + 1 < 10
        ? "0" + (weddingDate.getMonth() + 1).toString()
        : weddingDate.getMonth() + 1
    }-${
      weddingDate.getDate() < 10
        ? "0" + weddingDate.getDate().toString()
        : weddingDate.getDate()
    }`;

    return data;
  };

  const createLetter = async ({ _mainImg }) => {
    const { userAgent, languages } = await window.navigator;

    const content = await {
      version: 6,
      createdAt: Date.now(),
      mainImg: `${STORAGE_URL}/image${_mainImg}`,
      colorVer: 3,
      hFamilyName: hFamilyName,
      hName: hName,
      wFamilyName: wFamilyName,
      wName: wName,
      userId: userId,
      layoutArr: [
        { key: 0, label: "인삿말" },
        { key: 1, label: "예식일자" },
        { key: 8, label: "비디오" },
        { key: 2, label: "갤러리" },
        { key: 3, label: "공지사항" },
        { key: 4, label: "오시는길" },
        { key: 5, label: "참석여부" },
        { key: 6, label: "방명록" },
        {
          key: 7,
          label: "마음전하실 곳(화환)",
        },
      ],
      weddingDate: createdDate(),
      weddingTime: "13:00:00",
      waterMark: false,
      gallery: [],
      bgmOption: {
        value: "",
        label: "추가 안함",
      },
      surveyTitle: "참석 여부를 전달해주세요",
      surveyContent:
        "<p>결혼식 참석 전 참석여부를 응답해주시면<br/>결혼식 준비에 있어 큰 도움이 됩니다.<br/>한 분 한 분 더욱 귀하게 모실 수 있도록<br/>아래 버튼을 클릭하여 참석여부를<br/>전달 부탁드립니다.</p>",
      hAccount: "",
      hPapaAccount: "",
      hMamaAccount: "",
      wAccount: "",
      wPapaAccount: "",
      wMamaAccount: "",
      _userAgent: userAgent ? userAgent : "",
      _languages: languages ? languages : "",
      greeting:
        "<p>각자 서로 다른 길을</p><p>걸어온 저희가 이제 부부의 연으로</p><p>한 길을 걸어가고자 합니다.</p><p><br></p><p>항상 처음을 생각하며,</p><p>서로 아껴주고 사랑하며 살겠습니다.</p><p><br></p><p>부디 참석해 주시어 저희의 약속을</p><p>따뜻한 격려로 축복해 주시기 바랍니다.</p>",

      outroContent:
        "우리는 매일 우리의 삶을 여행합니다.<br />우리가 할 수 있는건,<br />이 멋진 여행을 즐기기 위해<br />매일 최선을 다하는 것입니다.<br /><br /><em>영화, 어바웃타임</em>",
      outroImg:
        "https://storage.cryucard.com/image/2023-05-20/UC4HKxoQYP0mWzaSNyhG-9a0bf9bc-715f-4bb6-815c-00a81444f239.jpeg",
      outroImgIndex: 3,
      isAuto: true,
      isShare: userId == "18029904" ? true : false,
    };

    await dbService
      .collection("newletters")
      .add(content)
      .then((res) => {
        setTimeout(() => {
          history.push(`/product/${res.id}`);
        }, [3000]);
      });
  };

  const uploadMainImgFunc = async ({ _uploadMain }) => {
    let submitMainImg = "";
    console.log(_uploadMain);
    if (_uploadMain) {
      await ReactS3Client.uploadFile(_uploadMain, `${userId}-${uuidv4()}`)
        .then((data) => {
          submitMainImg = data.location.split("image")[1];
          // console.log(data);
          createLetter({ _mainImg: submitMainImg });
        })
        .catch((err) => console.error(err));
    }
  };

  const submitFunc = () => {
    if (mainImg === "") {
      alert("메인 사진을 업로드해 주세요");
      return false;
    } else if (hName === "") {
      alert("신랑 이름을 입력해 주세요");
      return false;
    } else if (wName === "") {
      alert("신부 이름을 입력해 주세요");
      return false;
    }

    setStep(3);
    uploadMainImgFunc({ _uploadMain: uploadMainImg });
  };

  useEffect(() => {
    AOS.init();

    getUserId
      .then((_userId) => {
        if (history.action === "POP") {
          history.push("/");
          console.log("POP");
        } else {
          setUserId(_userId);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "100vh",
            maxWidth: 430,
            width: "100%",
          }}
        >
          {step === 1 && (
            <>
              <div
                data-aos="fade-in"
                data-aos-delay="800"
                data-aos-duration="1000"
                style={{ fontSize: 20 }}
              >
                안녕하세요
              </div>
              <div
                style={{ margin: "1rem 0rem" }}
                data-aos="fade-in"
                data-aos-delay="2000"
                data-aos-duration="1000"
              >
                <Lottie
                  options={defaultOption}
                  height={130}
                  width={130}
                  style={{ margin: 0 }}
                />
              </div>
              <div
                data-aos="fade-in"
                data-aos-delay="1600"
                data-aos-duration="1000"
                style={{
                  fontSize: 18,
                }}
              >
                결혼을 진심으로 축하드립니다.
              </div>

              <div
                onClick={() => {
                  setStep(2);
                }}
                style={{
                  width: 100,
                  height: 35,
                  backgroundColor: "#182e0c",
                  cursor: "pointer",
                  marginTop: 20,
                  borderRadius: 5,
                  position: "relative",
                }}
                data-aos="fade-in"
                data-aos-delay="3000"
                data-aos-duration="1000"
              >
                <img
                  src={ArrowIcon}
                  style={{
                    transform: "rotate(90deg)",
                    width: 40,
                    height: 40,
                    position: "absolute",
                    top: "-3px",
                    left: 30,
                  }}
                />
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div
                data-aos="fade-in"
                data-aos-delay="500"
                data-aos-duration="1000"
                style={{
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                모바일 청첩장을 제작하시려면
                <br />
                아래의 정보를 입력해주세요
              </div>
              <input
                id="mainImg"
                name="mainImg"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={onFileChange}
                style={{
                  width: 1,
                  height: 1,
                  position: "absolute",
                }}
              />
              {mainImg ? <ImgPreviewComponent /> : <ImgComponent />}

              <div
                data-aos="fade-in"
                data-aos-delay="1700"
                data-aos-duration="1000"
                style={{ marginTop: 20 }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  신랑 성함
                </div>
                <div
                  style={{
                    display: "flex",
                    width: 300,
                    marginTop: 8,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <TextInputComponent
                      placeholder={"신랑 성"}
                      getText={(e) => {
                        setHFamilyName(e.text);
                      }}
                      _value={""}
                      getBlur={() => {}}
                    />
                  </div>

                  <div style={{ flex: 2, marginLeft: 8 }}>
                    <TextInputComponent
                      placeholder={"신랑 이름"}
                      getText={(e) => {
                        setHName(e.text);
                      }}
                      _value={""}
                      getBlur={() => {}}
                    />
                  </div>
                </div>
              </div>

              <div
                data-aos="fade-in"
                data-aos-delay="2200"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
                style={{ marginTop: 20 }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  신부 성함
                </div>
                <div
                  style={{
                    display: "flex",
                    width: 300,
                    marginTop: 8,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <TextInputComponent
                      placeholder={"신부 성"}
                      getText={(e) => {
                        setWFamilyName(e.text);
                      }}
                      _value={""}
                      getBlur={() => {}}
                    />
                  </div>

                  <div style={{ flex: 2, marginLeft: 8 }}>
                    <TextInputComponent
                      placeholder={"신부 이름"}
                      getText={(e) => {
                        setWName(e.text);
                      }}
                      _value={""}
                      getBlur={() => {}}
                    />
                  </div>
                </div>
              </div>

              <div
                onClick={() => {
                  submitFunc();
                }}
                style={{
                  width: 300,
                  height: 40,
                  backgroundColor: "#182e0c",
                  color: "#fff",
                  cursor: "pointer",
                  marginTop: 50,
                  borderRadius: 5,
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                data-aos="fade-in"
                data-aos-delay="3000"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
              >
                청첩장 링크 생성하기
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <Lottie
                options={defaultOption2}
                height={300}
                width={300}
                style={{ margin: 0 }}
              />

              <div
                data-aos="fade-in"
                data-aos-delay="1000"
                data-aos-duration="1000"
                style={{ fontSize: 14 }}
              >
                청첩장 링크 생성 중...
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
