import React, { useEffect, useState } from "react";
import Footer from "../../components/NewComponents/Footer/Footer";
import Header from "../../components/NewComponents/Header/Header";
import { dbService } from "../../fbase";
import { useParams, Link } from "react-router-dom";
import NewBarChartComponent from "../../components/BarChartComponent/NewBarChartComponent";
import localStorage from "mobx-localstorage";
import { useLocation } from "react-router-dom";
import HeaderVer2 from "../../components/NewComponents/HeaderVer2/HeaderVer2";

export default function NewSurveyScreen() {
    const { letterId } = useParams();
    const { pathname } = useLocation();

    const [userLetterList, setUserLetterList] = useState([]);
    const [userId, setUserId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [surveyArr, setSurveyArr] = useState([]);
    const [viewTotal, setViewTotal] = useState(0);
    const [viewToday, setViewToday] = useState(0);

    const getUserId = async () => {
        let _getUserId = "";

        if (localStorage.getItem("userId")) {
            _getUserId = localStorage.getItem("userId");
            setUserId(_getUserId);
            getLetter(_getUserId);

            // console.log("userId=>" + _getUserId);
        } else {
            console.log("noEmail");
            window.location.href = "/#/newlogin";
        }
    };

    const userIdAccess = (letterUserId, deviceUserId) => {
        // letterId조회 후 userid값 가져오기.
        if (letterUserId == deviceUserId || "51543483" == deviceUserId) {
            console.log("같음");
            // getSurvey 불러오기
            getSurvey();

            // getTotal 불러오기
            // getTotal();
        } else {
            alert("비정상적인 접근입니다.");
            window.location.href = "/";
        }
    };

    const getLetter = (deviceUserId) => {
        var letterRef = dbService.collection("newletters");

        letterRef
            .doc(letterId)
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    // console.log(res);
                    const { userId } = res.data();
                    userIdAccess(userId, deviceUserId);
                } else {
                    alert("비정상적인 접근입니다.");
                    window.location.href = "/";
                }
            });
    };

    const getSurvey = () => {
        var surveyRef = dbService.collection("newSurvey");

        surveyRef
            .where("letterId", "==", `${letterId}`)
            .orderBy("createdAt", "desc")
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    const promise = res.docs.map((item) => {
                        var data = item.data();
                        data.surveyId = item.id;

                        setSurveyArr((oldArr) => [...oldArr, data]);
                    });
                    await Promise.all(promise);
                    setIsLoading(true);
                    console.log(surveyArr);
                } else {
                    // console.log('none');
                    setIsLoading(true);
                }
            });
    };

    const getTotal = () => {
        const mcardCollection = dbService.collection("newletters");
        mcardCollection
            .doc(letterId)
            .get()
            .then(async (res) => {
                if (!res.empty) {
                    var { total, today, latestToday } = res.data();

                    const date = new Date();
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();

                    const realLatestToday = `${year}-${month}-${day}`;

                    console.log(today);

                    setViewToday(realLatestToday != latestToday ? 0 : today);
                    setViewTotal(total ? total : 0);
                } else {
                    console.log("none");
                }
            });
    };

    function AddComma(num) {
        var regexp = /\B(?=(\d{3})+(?!\d))/g;
        return num.toString().replace(regexp, ",");
    }

    useEffect(() => {
        getUserId();

        getTotal();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <HeaderVer2 userId={userId} />
            <div style={{ minHeight: "100vh", padding: "0 16px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            fontSize: 40,
                            fontWeight: "bold",
                            paddingTop: 50,
                            color: "#222",
                        }}
                    >
                        분석보기
                    </div>

                    <div
                        style={{
                            maxWidth: "1000px",
                            width: "100%",
                            marginTop: 50,
                            borderRadius: "15px 0px 15px 15px",
                            boxShadow: "10px 10px 50px rgb(0 0 0 / 10%)",
                            position: "relative",
                        }}
                    >
                        <div style={{ padding: "30px 0px" }}>
                            {/* 방문자수 */}
                            <div>
                                <div
                                    style={{
                                        borderRadius: 15,
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "15px 20px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            방문자 수
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            margin: "0 20px",
                                            padding: "15px 0px",
                                            borderTop: "1px solid #e9e9e9",
                                        }}
                                    >
                                        <div>
                                            <div>
                                                <p
                                                    style={{
                                                        textAlign: "left",
                                                        fontSize: 16,
                                                    }}
                                                >
                                                    오늘 방문자
                                                </p>
                                                <p
                                                    style={{
                                                        textAlign: "right",
                                                        fontSize: 16,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        {AddComma(viewToday)}
                                                    </span>
                                                    명
                                                </p>
                                            </div>

                                            <div style={{ marginTop: 10 }}>
                                                <p
                                                    style={{
                                                        textAlign: "left",
                                                        fontSize: 16,
                                                    }}
                                                >
                                                    총 방문자
                                                </p>
                                                <p
                                                    style={{
                                                        textAlign: "right",
                                                        fontSize: 16,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        {AddComma(viewTotal)}
                                                    </span>
                                                    명
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 방문자수 */}
                            {/* 참석조사 */}
                            <div>
                                {isLoading ? (
                                    <div
                                        style={{
                                            borderRadius: 15,
                                            backgroundColor: "#fff",
                                            marginTop: 30,
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: "15px 0",
                                                margin: "0 20px",
                                                borderBottom:
                                                    "1px solid #e9e9e9",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                참석자 요약
                                            </span>
                                        </div>

                                        <div
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    margin: "0 20px",
                                                    padding: "15px 0px",
                                                    borderTop:
                                                        "1px solid #e9e9e9",
                                                }}
                                                className={
                                                    surveyArr.length == 0
                                                        ? "blurEffect"
                                                        : ""
                                                }
                                            >
                                                {surveyArr ? (
                                                    <NewBarChartComponent
                                                        surveyArr={surveyArr}
                                                    />
                                                ) : (
                                                    false
                                                )}

                                                <Link
                                                    to={{
                                                        pathname:
                                                            surveyArr.length ==
                                                            0
                                                                ? `/newsurvey/${letterId}`
                                                                : `/newsurvey/details/${letterId}`,
                                                    }}
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor:
                                                                "#182e0c",
                                                            width: 300,
                                                            height: 50,
                                                            borderRadius: 10,
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                            margin: "0 auto",
                                                            marginTop: 20,
                                                            marginBottom: 10,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#fff",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            자세히 보기
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            {surveyArr.length == 0 ? (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        width: "100%",
                                                        height: "100%",
                                                        top: 0,
                                                        bottom: 20,
                                                        left: 0,
                                                        right: 0,
                                                        opacity: 0.5,
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        현재 응답자가 없습니다
                                                    </div>
                                                </div>
                                            ) : (
                                                false
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            height: 450,
                                            width: "100%",
                                            borderRadius: 15,
                                            backgroundColor: "#fff",
                                            marginTop: 30,
                                        }}
                                    ></div>
                                )}
                            </div>

                            {/* 참석조사 */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer userId={userId} />
        </div>
    );
}
