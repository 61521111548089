import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { dbService } from "../../fbase";
import { Link, useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactHtmlParser from "react-html-parser";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import TextInputPhoneComponent from "../TextInputPhoneComponent/TextInputPhoneComponent";
import axios from "axios";

const useStyles = makeStyles({
    root: {
        maxWidth: 480,
        marginBottom: 30,
    },
});

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#eee",
        width: "90%",
        maxWidth: 400,
        maxHeight: 550,
        height: 600,
        padding: "10px 0",
    },
    overlay: {
        backgroundColor: "rgb(0,0,0,0.5)",
        zIndex: 3000,
    },
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function CardComponent(props) {
    const classes = useStyles();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [value, setValue] = useState(0);
    const [naverEmail, setNaverEmail] = useState("");
    const [coupon, setCoupon] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [pageNum, setPageNum] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getBuyList = (email, letterId) => {
        var buyListRef = dbService.collection("buyList");
        var letterRef = dbService.collection("letters");
        // console.log("func getBuyList");

        if (phoneNumber.length === 0 || phoneNumber.length > 9) {
            if (email) {
                buyListRef
                    .where("userEmail", "==", email)
                    .get()
                    .then(async (res) => {
                        if (!res.empty) {
                            // console.log(res);

                            await Promise.all(
                                res.docs.map(async (item) => {
                                    var data = item.data();
                                    data.id = item.id;
                                    // console.log(data);

                                    await letterRef.doc(letterId).update({
                                        waterMark: true,
                                        waterMarkCode: email,
                                        expire: data.expire
                                            ? data.expire
                                            : false,
                                    });

                                    if (data.quantity > 1) {
                                        await buyListRef
                                            .doc(item.id)
                                            .update({
                                                quantity: data.quantity - 1,
                                            })
                                            .then(async () => {
                                                if (phoneNumber.length !== 0) {
                                                    await axios.get(
                                                        `https://us-central1-self-cryucard.cloudfunctions.net/api/kakao?url=${urlReturnForKakao(
                                                            props.user.id,
                                                            props.user.letterVer
                                                        )}&phoneNumber=${phoneNumber}`
                                                    );
                                                }

                                                await alert(
                                                    "워터마크가 제거 되었습니다!"
                                                );
                                                await window.location.reload();
                                            });
                                    } else {
                                        await buyListRef
                                            .doc(item.id)
                                            .delete()
                                            .then(async () => {
                                                if (phoneNumber.length !== 0) {
                                                    await axios.get(
                                                        `https://us-central1-self-cryucard.cloudfunctions.net/api/kakao?url=${urlReturnForKakao(
                                                            props.user.id,
                                                            props.user.letterVer
                                                        )}&phoneNumber=${phoneNumber}`
                                                    );
                                                }

                                                await alert(
                                                    "워터마크가 제거 되었습니다!"
                                                );
                                                await window.location.reload();
                                            })
                                            .catch((error) => {
                                                console.error(
                                                    "Error removing document: ",
                                                    error
                                                );
                                            });
                                    }
                                })
                            );
                        } else {
                            alert("구매내역이 없습니다.");
                        }
                    });
            } else {
                alert("구매 후 네이버 아이디를 입력하여주세요");
            }
        } else {
            alert("연락처를 다시 한 번 확인해주세요");
        }
    };

    const deleteLetter = async (letterId) => {
        var confirm = window.confirm(
            "정말 삭제하시겠습니까? 삭제 후 복구가 불가합니다"
        );

        if (confirm) {
            var letterRef = dbService.collection("letters");
            await letterRef
                .doc(letterId)
                .delete()
                .then((res) => {
                    alert("제작하신 청첩장이 삭제 되었습니다");
                    window.location.href = "/#/list";
                });
        } else {
        }
    };

    const getCouponList = (couponNumber, letterId) => {
        var couponRef = dbService.collection("couponList");
        var letterRef = dbService.collection("letters");
        console.log("func getBuyList");
        if (couponNumber) {
            couponRef
                .where("couponNumber", "==", couponNumber)
                .get()
                .then(async (res) => {
                    if (!res.empty) {
                        // console.log(res);

                        await Promise.all(
                            res.docs.map(async (item) => {
                                var data = item.data();
                                data.id = item.id;
                                // console.log(data);

                                await letterRef.doc(letterId).update({
                                    waterMark: true,
                                    waterMarkCode: couponNumber,
                                });

                                if (data.quantity > 1) {
                                    await couponRef
                                        .doc(item.id)
                                        .update({ quantity: data.quantity - 1 })
                                        .then(async () => {
                                            if (phoneNumber.length !== 0) {
                                                await axios.get(
                                                    `https://us-central1-self-cryucard.cloudfunctions.net/api/kakao?url=${urlReturnForKakao(
                                                        props.user.id,
                                                        props.user.letterVer
                                                    )}&phoneNumber=${phoneNumber}`
                                                );
                                            }
                                            await alert(
                                                "워터마크가 제거 되었습니다!"
                                            );
                                            await window.location.reload();
                                        });
                                } else {
                                    await couponRef
                                        .doc(item.id)
                                        .delete()
                                        .then(async () => {
                                            if (phoneNumber.length !== 0) {
                                                await axios.get(
                                                    `https://us-central1-self-cryucard.cloudfunctions.net/api/kakao?url=${urlReturnForKakao(
                                                        props.user.id,
                                                        props.user.letterVer
                                                    )}&phoneNumber=${phoneNumber}`
                                                );
                                            }
                                            await alert(
                                                "워터마크가 제거 되었습니다!"
                                            );
                                            await window.location.reload();
                                        })
                                        .catch((error) => {
                                            console.error(
                                                "Error removing document: ",
                                                error
                                            );
                                        });
                                }
                            })
                        );
                    } else {
                        alert("쿠폰번호가 일치하지 않습니다.");
                    }
                });
        } else {
            alert("쿠폰번호를 입력하여주세요");
        }
    };

    const onChange = (event) => {
        // console.log(event.target.name);
        const {
            target: { name, value, checked },
        } = event;

        if (name === "naverEmail") {
            setNaverEmail(value);
        } else if (name === "coupon") {
            setCoupon(value);
        }
    };

    const metaDate = (abc) => {
        var weekday = new Array();
        weekday[0] = "(일)";
        weekday[1] = "(월)";
        weekday[2] = "(화)";
        weekday[3] = "(수)";
        weekday[4] = "(목)";
        weekday[5] = "(금)";
        weekday[6] = "(토)";

        var date = new Date(abc.slice(0, 10) + "T09:00:00Z");
        var year = date.getFullYear();
        var month =
            date.getMonth() + 1 < 10
                ? (date.getMonth() + 1).toString()
                : date.getMonth() + 1;
        var day =
            date.getDate() < 10 ? date.getDate().toString() : date.getDate();
        var hours =
            abc.slice(11, 13) < 10
                ? "오전 " + abc.slice(11, 13).toString() + "시"
                : abc.slice(11, 13) > 12
                ? "오후 " + (abc.slice(11, 13) - 12).toString() + "시"
                : abc.slice(11, 13) == 12
                ? "오후 " + abc.slice(11, 13).toString() + "시"
                : "오전 " + abc.slice(11, 13).toString() + "시";
        var min = abc.slice(14, 16) == 0 ? "" : abc.slice(14, 16) + "분";
        var week = date.getDay();

        return `${month}월 ${day}일 ${weekday[week]} ${hours} ${min}`;
    };

    const urlReturn = (letterId, letterVer) => {
        let url = "";

        if (letterVer === 1 || letterVer === undefined) {
            url = `https://self.cryucompany.com/max/${letterId}/account`;
        } else if (letterVer === 2) {
            url = `https://self.cryucompany.com/rew/${letterId}/account`;
        } else if (letterVer === 3) {
            url = `https://self.cryucompany.com/brw/${letterId}/account`;
        } else if (letterVer === 4) {
            url = `https://self.cryucompany.com/p/${letterId}`;
        } else if (letterVer === 5) {
            url = `https://self.cryucompany.com/w/${letterId}`;
        } else if (letterVer === 6) {
            url = `https://self.cryucompany.com/b/${letterId}`;
        } else if (letterVer === 99) {
            url = `https://self.cryucompany.com/hidi/${letterId}/account`;
        }

        return url;
    };

    const urlReturnForKakao = (letterId, letterVer) => {
        let url = "";

        if (letterVer === 1 || letterVer === undefined) {
            url = `self.cryucompany.com/max/${letterId}/account`;
        } else if (letterVer === 2) {
            url = `self.cryucompany.com/rew/${letterId}/account`;
        } else if (letterVer === 3) {
            url = `self.cryucompany.com/brw/${letterId}/account`;
        } else if (letterVer === 4) {
            url = `self.cryucompany.com/p/${letterId}`;
        } else if (letterVer === 5) {
            url = `self.cryucompany.com/w/${letterId}`;
        } else if (letterVer === 6) {
            url = `self.cryucompany.com/b/${letterId}`;
        } else if (letterVer === 99) {
            url = `self.cryucompany.com/hidi/${letterId}/account`;
        }

        return url;
    };

    const getText = (data) => {
        // console.log(data);

        const { text, type } = data;

        switch (type) {
            case "naverEmail":
                setNaverEmail(text);
                break;

            case "waterMarkCoupon":
                setCoupon(text);
                break;

            default:
                break;
        }
    };

    const getPhoneNumber = (data) => {
        setPhoneNumber(data.phoneNumber);
    };

    const Page0 = () => {
        return (
            <div>
                <div
                    style={{
                        color: "#333",
                        marginTop: "2rem",
                        borderTop: "1px dashed #999",
                        paddingTop: "1rem",
                    }}
                >
                    <div>
                        <SubTitleComponent
                            title="구매한 네이버 아이디 "
                            type="waterMarkId"
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ maxWidth: 120 }}>
                                <TextInputComponent
                                    getText={getText}
                                    type="naverEmail"
                                />
                            </div>
                            <span style={{ marginLeft: 5 }}>@naver.com</span>
                        </div>
                    </div>

                    <div>
                        <SubTitleComponent title="전달받을 연락처" />

                        <TextInputPhoneComponent
                            getPhoneNumber={getPhoneNumber}
                            type="waterMarkPhoneNumber"
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        width: 200,
                        height: 45,
                        backgroundColor: "#182e0c",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        borderRadius: 8,
                        margin: "3rem auto 0",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        if (!isLoading) {
                            setIsLoading(true);
                            getBuyList(naverEmail, props.user.id);
                        }
                    }}
                >
                    워터마크 제거
                </div>
            </div>
        );
    };

    const Page1 = () => {
        return (
            <div>
                <div
                    style={{
                        color: "#333",
                        marginTop: "2rem",
                        borderTop: "1px dashed #999",
                        paddingTop: "1rem",
                    }}
                >
                    <div>
                        <SubTitleComponent title="쿠폰번호" />

                        <TextInputComponent
                            type="waterMarkCoupon"
                            getText={getText}
                        />
                    </div>

                    <div>
                        <SubTitleComponent title="전달받을 연락처" />
                        <TextInputPhoneComponent
                            getPhoneNumber={getPhoneNumber}
                            type="waterMarkPhoneNumber"
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        width: 200,
                        height: 45,
                        backgroundColor: "#182e0c",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        borderRadius: 8,
                        margin: "3rem auto 0",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        if (!isLoading) {
                            setIsLoading(true);
                            getCouponList(coupon, props.user.id);
                        }
                    }}
                >
                    워터마크 제거
                </div>
            </div>
        );
    };

    useEffect(() => {}, []);

    return (
        <div>
            <Card className={classes.root}>
                <a
                    href={urlReturn(props.user.id, props.user.letterVer)}
                    target="_blank"
                    style={{ textDecoration: "none", color: "#000" }}
                >
                    <CardActionArea>
                        {!isLoad && (
                            <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={300}
                            />
                        )}
                        <CardMedia
                            component="img"
                            alt="Contemplative Reptile"
                            image={props.user.mainImg}
                            title="Contemplative Reptile"
                            onLoad={() => setIsLoad(true)}
                        />
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                                style={{ fontSize: 15, textDecoration: "none" }}
                            >
                                {props.user.kakaoTitle
                                    ? props.user.kakaoTitle
                                    : metaDate(props.user.weddingDate)}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                style={{ fontSize: 12 }}
                            >
                                {props.user.kakaoDes
                                    ? ReactHtmlParser(props.user.kakaoDes)
                                    : props.user.weddingLocationName +
                                      " " +
                                      props.user.weddingLocationFloor}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </a>

                <CardActions>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div>
                            {props.user.waterMark ? (
                                false
                            ) : (
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={() => setIsOpenModal(true)}
                                >
                                    워터마크 제거
                                </Button>
                            )}
                            <Link
                                to={
                                    props.isHidi
                                        ? `/hidimodify/${props.user.id}`
                                        : `/modify/${props.user.id}`
                                }
                                style={{ textDecoration: "none" }}
                            >
                                <Button size="small" color="primary">
                                    수정하기
                                </Button>
                            </Link>

                            <Link
                                to={{
                                    pathname: `/survey/${props.user.id}`,
                                }}
                                style={{ textDecoration: "none" }}
                            >
                                <Button size="small" color="primary">
                                    분석보기
                                </Button>
                            </Link>
                        </div>
                        <div style={{}}>
                            <Button
                                size="small"
                                color="primary"
                                style={{ paddingRight: 0 }}
                                onClick={() => {
                                    deleteLetter(props.user.id);
                                }}
                            >
                                <DeleteForeverIcon
                                    style={{ color: "#ff6c6c" }}
                                />
                            </Button>
                        </div>
                    </div>
                </CardActions>
            </Card>

            <Modal
                isOpen={isOpenModal}
                style={customStyles}
                shouldCloseOnOverlayClick
                onRequestClose={() => setIsOpenModal(false)}
            >
                <div style={{ padding: "2rem 1rem" }}>
                    <div style={{ color: "#333" }}>
                        <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                            워터마크 제거
                        </p>

                        <p style={{ fontSize: "0.8rem" }}>
                            워터마크를 제거하시려면{" "}
                            <a
                                href="https://smartstore.naver.com/cryu/products/5209453737"
                                target="_blank"
                                style={{ color: "#333", fontWeight: "bold" }}
                            >
                                네이버 스마트스토어
                            </a>{" "}
                            내에서
                            <br /> 구매 후 아이디를 입력해주세요
                        </p>
                        <p style={{ fontSize: "0.8rem", color: "#999" }}>
                            (구매 확인까지 <b>최대 15분</b>이 소요될 수
                            있습니다.)
                        </p>
                    </div>

                    <div style={{ display: "flex", marginTop: "2rem" }}>
                        <div
                            onClick={() => {
                                setPageNum(0);
                                setNaverEmail("");
                                setCoupon("");
                            }}
                            className="prod-button"
                            style={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor:
                                    pageNum == 0 ? "#182e0c" : "#fff",
                                color: pageNum == 0 ? "#fff" : "#333",
                            }}
                        >
                            아이디 입력
                        </div>
                        <div
                            onClick={() => {
                                setPageNum(1);
                                setNaverEmail("");
                                setCoupon("");
                            }}
                            className="prod-button"
                            style={{
                                flex: 1,
                                marginLeft: 8,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor:
                                    pageNum == 1 ? "#182e0c" : "#fff",
                                color: pageNum == 1 ? "#fff" : "#333",
                            }}
                        >
                            쿠폰 입력
                        </div>
                    </div>

                    {pageNum == 0 ? Page0() : Page1()}
                </div>
            </Modal>

            {/* <Modal
                isOpen={isOpenModal}
                style={customStyles}
                shouldCloseOnOverlayClick
                onRequestClose={() => setIsOpenModal(false)}
            >
                <div style={{ padding: "0 16px" }}>
                    <div>
                        <h4>워터마크 제거 🛠</h4>
                        <p style={{ lineHeight: "16px" }}>
                            <span
                                style={{
                                    fontSize: 12,
                                    wordBreak: "keep-all",
                                    display: "inline-block",
                                    lineHeight: "16px",
                                }}
                            >
                                워터마크를 제거하시려면 네이버 스마트스토어
                                내에서 구매진행 후 아이디를 입력해주세요
                            </span>
                            <br />
                            <span
                                style={{
                                    fontSize: 12,
                                    wordBreak: "keep-all",
                                    display: "inline-block",
                                    lineHeight: "16px",
                                    color: "#999",
                                }}
                            >
                                (구매 확인까지{" "}
                                <span style={{ fontWeight: "600" }}>
                                    최대 15분
                                </span>
                                이 소요될 수 있습니다.)
                            </span>
                        </p>
                    </div>

                    <div style={{ paddingTop: 30 }}>
                        <AppBar position="static">
                            <Tabs
                                variant="fullWidth"
                                value={value}
                                onChange={handleChange}
                                aria-label="nav tabs example"
                            >
                                <LinkTab
                                    label="아이디 입력"
                                    href="/drafts"
                                    {...a11yProps(0)}
                                    style={{}}
                                />
                                <LinkTab
                                    label="쿠폰 입력"
                                    href="/trash"
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <div style={{ paddingTop: 100 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "center",
                                    }}
                                >
                                    <TextField
                                        id="standard-basic"
                                        label="아이디"
                                        style={{ width: 100 }}
                                        name="naverEmail"
                                        checked={naverEmail}
                                        onChange={onChange}
                                    />
                                    <span
                                        style={{
                                            paddingBottom: 5,
                                            color: "#999",
                                        }}
                                    >
                                        @naver.com
                                    </span>
                                </div>

                                <div
                                    style={{
                                        position: "absolute",
                                        right: 20,
                                        bottom: 30,
                                    }}
                                    onClick={() =>
                                        getBuyList(naverEmail, props.user.id)
                                    }
                                >
                                    <Fab color="secondary" aria-label="add">
                                        <AddIcon />
                                    </Fab>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div style={{ paddingTop: 100 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "center",
                                    }}
                                >
                                    <TextField
                                        id="standard-basic"
                                        label="쿠폰번호"
                                        style={{ width: 200 }}
                                        name="coupon"
                                        checked={coupon}
                                        onChange={onChange}
                                    />
                                </div>

                                <div
                                    style={{
                                        position: "absolute",
                                        right: 20,
                                        bottom: 30,
                                    }}
                                    onClick={() =>
                                        getCouponList(coupon, props.user.id)
                                    }
                                >
                                    <Fab color="secondary" aria-label="add">
                                        <AddIcon />
                                    </Fab>
                                </div>
                            </div>{" "}
                        </TabPanel>
                    </div>
                </div>
            </Modal> */}
        </div>
    );
}
