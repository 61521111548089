import logo from "./logo.svg";
import "./App.css";
import AppRouter from "./routes/Router";
import React, { useEffect } from "react";

function App() {
  return <AppRouter />;
}

export default App;
