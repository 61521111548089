import React from "react";
import Footer from "../../components/NewComponents/Footer/Footer";
import Header from "../../components/NewComponents/Header/Header";
import QnAComponent from "../../components/NewComponents/QnAComponent/QnAComponent";
import localStorage from "mobx-localstorage";
import HeaderVer2 from "../../components/NewComponents/HeaderVer2/HeaderVer2";

import { useLocation } from "react-router-dom";

import FreeIcon from "../../assets/images/icon/free.png";
import TimeIcon from "../../assets/images/icon/time-left.png";
import UnlimitIcon from "../../assets/images/icon/unlimited.png";

const MobileComponent = ({ img, title, price, des1, des2, des3 }) => {
    return (
        <div className="price-wrap">
            <div
                style={{
                    // width: "100%",
                    // height: "100%",
                    // paddingLeft: 30,
                    // paddingTop: 22,
                    height: 160,
                }}
            >
                <div
                    style={{
                        paddingLeft: 30,
                        paddingTop: 22,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <img src={img} style={{ width: 25, height: 25 }} />
                        <span
                            style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                marginLeft: 8,
                            }}
                        >
                            {title}
                        </span>
                    </div>

                    <div>
                        <ul style={{ fontSize: 14, paddingLeft: 20 }}>
                            {des1 && (
                                <li style={{ lineHeight: "25px" }}>{des1}</li>
                            )}
                            {des2 && (
                                <li style={{ lineHeight: "25px" }}>{des2}</li>
                            )}
                            {des3 && (
                                <li style={{ lineHeight: "25px" }}>{des3}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    height: "45px",
                    backgroundColor: "#182E0C",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <span
                    style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "bold",
                        // transform: "rotate(-90deg)",
                        // letterSpacing: "5px",
                    }}
                >
                    {price}
                </span>
            </div>
        </div>
    );
};

export default function PricingScreen() {
    const [userId, setUserId] = React.useState("");

    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const getUserId = async () => {
        let _getUserId = "";

        if (localStorage.getItem("userId")) {
            _getUserId = localStorage.getItem("userId");
            setUserId(_getUserId);

            // console.log("userId=>" + _getUserId);

            // await getList(getUserId);
            // await getThanksList(getUserId);
        } else {
            console.log("noEmail");
            // window.location.href = "/#/login";
        }
    };

    React.useEffect(() => {
        getUserId();
    }, []);

    return (
        <div style={{ color: "#222" }}>
            <HeaderVer2 userId={userId} />

            <div style={{ minHeight: "100vh" }}>
                <div className="sub-page-container">
                    <p
                        className="sub-page-title"
                        style={{ fontWeight: "bold" }}
                    >
                        가격 안내
                    </p>

                    <p className="sub-page-des">
                        청첩장은 기간에 따른 가격차이만 있을 뿐
                        <br />
                        기능의 차이는 없습니다.
                    </p>
                </div>

                <div className="price-content-container">
                    <div className="price-container">
                        <MobileComponent
                            img={FreeIcon}
                            title={"청첩장 시안 제작"}
                            price={"무료"}
                            des1={"24시간 언제든 시안 제작 가능"}
                            des2={"시안 최상단에 워터마크 존재"}
                            des3={"워터마크 미제거시 일주일 뒤 삭제"}
                        />
                        <MobileComponent
                            img={TimeIcon}
                            title={"기간제 모바일 청첩장"}
                            price={"13,900원"}
                            des1={"예식일 이후 30일까지 사용"}
                            des2={"모든 기능 엑세스 가능"}
                        />
                        <MobileComponent
                            img={UnlimitIcon}
                            title={"평생 소장 모바일 청첩장"}
                            price={"38,900원"}
                            des1={"링크가 삭제되지 않고 평생 소장"}
                            des2={"비공개 모드 지원 (4월 말 업데이트)"}
                            des3={"모든 기능 엑세스 가능"}
                        />
                    </div>
                </div>
            </div>

            <Footer userId={userId} />
        </div>
    );
}
