import React from "react";
import { useState } from "react";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

export default function QnAComponent({ title, des }) {
    const [isClose, setIsClose] = useState(false);

    return (
        <div
            style={{
                borderBottom: "1px solid rgb(233, 233, 233)",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 10px",
                    cursor: "pointer",
                }}
                className="question-content-title"
                onClick={() => {
                    setIsClose(!isClose);
                }}
            >
                {title}
                {isClose ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>

            {isClose && (
                <div
                    style={{
                        padding: "20px 20px 30px",
                    }}
                    className="question-content-des"
                >
                    {des}
                </div>
            )}
        </div>
    );
}
