import React from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";

export default function FlowerPresentComponent() {
    React.useEffect(() => {
        console.log("flower rerender");
    }, []);

    return (
        <div>
            <div>
                <p
                    style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                    }}
                >
                    축하화환 보내기
                </p>
                <p
                    style={{
                        marginTop: 5,
                        fontSize: "0.9rem",
                        color: "rgb(119, 119, 119)",
                    }}
                >
                    청첩장 내 축하화환 보내기 기능을 추가할 수 있습니다.
                    <br />
                    선물받은 축하화환은 예식일정에 맞춰 예식장으로 도착합니다.
                </p>
            </div>
        </div>
    );
}
