import React, { useState } from "react";
import Compressor from "compressorjs";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

import S3 from "react-aws-s3";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "../../fbase";
import { debounce } from "lodash";
import { RotatingLines } from "react-loader-spinner";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import SortIcon from "@material-ui/icons/Sort";

const STORAGE_URL = "https://storage.cryucard.com";

const MAX_IMG_QUANTITY = 30;

export default function GalleryComponent({ state, getData, letterId }) {
  const [galleryArray, setGalleryArray] = useState(
    state.gallery ? state.gallery : []
  );
  const [galleryType, setGalleryType] = useState(
    state.galleryType ? state.galleryType : "2"
  );

  const [isUploading, setIsUploading] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(99);

  // s3설정
  const config = {
    bucketName: "new-cryucard",
    dirName: `image/${state.weddingDate}`,
    region: "ap-northeast-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);
  // s3설정

  const uploadGalleryImg = async ({ _uploadGallery }) => {
    let submitMainImg = "";

    if (_uploadGallery) {
      await ReactS3Client.uploadFile(_uploadGallery, `${letterId}-${uuidv4()}`)
        .then((data) => {
          submitMainImg = `${STORAGE_URL}/image${
            data.location.split("image")[1]
          }`;
          // console.log(submitMainImg);
        })
        .catch((err) => console.error(err));
    }

    return submitMainImg;
  };

  const removeArray = (index) => {
    const temp = [...galleryArray];
    temp.splice(index, 1);
    setGalleryArray(temp);
    debounceSubmit({ _gallery: temp });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const draggedData = arrayMoveImmutable(galleryArray, oldIndex, newIndex);
    setGalleryArray(draggedData);
    debounceSubmit({ _gallery: draggedData });
  };

  const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
  });

  const SortableItem = sortableElement(({ src, sortIndex }) => (
    <div style={{ display: "inline-block", verticalAlign: "top" }}>
      <ImageSortingComponent src={src} index={sortIndex} />
    </div>
  ));

  const compressImage = (file) => {
    console.log("compressImage");
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 2000,
        maxHeight: 2000,
        success: (res) => {
          const _data = uploadGalleryImg({
            _uploadGallery: res,
          });
          resolve(_data);
        },
        error: (error) => reject(error),
      });
    });
  };

  const onFileChange = async (event, index) => {
    const {
      target: { files, name },
    } = event;

    if (index === 99) {
      setIsUploading(true);
      let imageUrlLists = [...galleryArray];

      const fileList = Array.from(files);

      const uploadGalleryList = (fileList) => {
        const result = Promise.all(
          fileList.map((item, index) => {
            if (imageUrlLists.length + index < MAX_IMG_QUANTITY) {
              return compressImage(item);

              // return index;
            } else {
              return "";
            }
          })
        );

        return result;
      };

      uploadGalleryList(fileList).then((urlList) => {
        // console.log(urlList);
        setGalleryArray((oldArray) => {
          const filtered = urlList.filter((element) => element !== "");
          // console.log([...oldArray, ...filtered]);
          onSubmit({ _gallery: [...oldArray, ...filtered] });

          return [...oldArray, ...filtered];
        });
      });
    } else {
      setIsUploading(true);
      let imageUrlLists = [...galleryArray];

      const fileList = Array.from(files);

      const uploadGalleryList = (fileList) => {
        const result = Promise.all(
          fileList.map((item, index) => {
            if (imageUrlLists.length + index < MAX_IMG_QUANTITY) {
              return compressImage(item);

              // return index;
            } else {
              return "";
            }
          })
        );

        return result;
      };

      uploadGalleryList(fileList).then((urlList) => {
        // console.log(urlList);
        setGalleryArray((oldArray) => {
          const filtered = urlList.filter((element) => element !== "");
          imageUrlLists.splice(index, 1, ...filtered);

          onSubmit({ _gallery: imageUrlLists });

          // console.log(imageUrlLists);

          return imageUrlLists;
        });
      });
    }

    // console.log(imageUrlLists);
  };

  const ImageSortingComponent = ({ src, index }) => {
    return (
      <div className="gallery-img-wrap">
        <div
          style={{
            backgroundColor: "#fefcfc",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <img
            src={src}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
        </div>
      </div>
    );
  };

  const ImageComponent = ({ src, index }) => {
    return (
      <div className="gallery-img-wrap">
        <div
          style={{
            backgroundColor: "#fefcfc",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <img
            src={src}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
          <div
            style={{
              backgroundColor: "rgb(0, 0, 0,0.6)",
              height: 50,
              width: "100%",
              position: "absolute",
              bottom: 0,
              display: "flex",
            }}
          >
            <label
              for="galleryImg"
              style={{
                flex: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                setGalleryIndex(index);
              }}
            >
              <CreateIcon
                style={{
                  color: "#fff",
                  fontSize: 22,
                }}
              />
            </label>
            <div
              style={{
                flex: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                removeArray(index);
              }}
            >
              <CloseIcon
                style={{
                  color: "#fff",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSubmit = async ({ _gallery, _galleryType }) => {
    let content = {};
    // console.log(_galleryType);

    if (_gallery) {
      content = {
        gallery: _gallery,
      };

      // console.log(content.gallery);
    } else if (_galleryType) {
      content = {
        galleryType: _galleryType,
      };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
        setIsUploading(false);
      });
  };

  const debounceSubmit = React.useCallback(
    debounce(({ _gallery, _galleryType }) => {
      onSubmit({ _gallery, _galleryType });
    }, 1500),
    []
  );

  const pushData = () => {
    getData({ isSaved: true });
  };

  return (
    <div>
      <div>
        <SubTitleComponent title="갤러리 형식" />

        <div className="prod-select-container" style={{ width: "auto" }}>
          <select
            name="gallery-type"
            id="gallery-type-select"
            className="prod-select"
            onChange={(e) => {
              setGalleryType(e.target.value);
              onSubmit({ _galleryType: e.target.value });
            }}
            value={galleryType}
          >
            <option value="1">앨범형</option>
            <option value="2">슬라이드형</option>
          </select>
        </div>
      </div>

      {galleryArray.length < MAX_IMG_QUANTITY && (
        <label for="galleryImg">
          <div
            style={{
              height: 200,
              border: "1px dashed #888",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#888",
              cursor: "pointer",
              fontSize: "0.9rem",
              marginTop: 30,
            }}
            onClick={() => {
              setGalleryIndex(99);
            }}
          >
            {isUploading !== true ? (
              <span>갤러리 사진을 업로드해주세요 (최대 30장)</span>
            ) : (
              <RotatingLines
                strokeColor="#888"
                strokeWidth="5"
                animationDuration="0.75"
                width="40"
                visible={true}
              />
            )}
          </div>
        </label>
      )}

      <input
        id="galleryImg"
        name="galleryImg"
        type="file"
        accept="image/*"
        onChange={(e) => {
          onFileChange(e, galleryIndex);
        }}
        style={{
          width: 1,
          height: 1,
          display: "none",
        }}
        multiple
      />

      <div style={{ marginTop: 20 }}>
        {galleryArray.length > 1 && (
          <div style={{ position: "relative", height: 40 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 100,
                height: 30,
                fontSize: 12,
                border: "1px solid #e9e9e9",
                lineHeight: "12px",
                borderRadius: 3,
                cursor: "pointer",
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={() => {
                setIsSorting(!isSorting);
              }}
            >
              <div style={{ marginRight: 2 }}>
                <SortIcon fontSize="small" className="SortIcon" />
              </div>
              {isSorting ? <span>변경 완료</span> : <span>순서 변경</span>}
            </div>
          </div>
        )}

        {isSorting ? (
          <div>
            <SortableContainer onSortEnd={onSortEnd} pressDelay={10} axis="xy">
              {galleryArray.map((item, index) => {
                return (
                  <SortableItem
                    key={`imem-${index}`}
                    src={item}
                    index={index}
                    sortIndex={index}
                  />
                );
              })}
            </SortableContainer>
          </div>
        ) : (
          <div>
            {galleryArray.map((item, index) => {
              return (
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                  }}
                >
                  <ImageComponent src={item} index={index} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
