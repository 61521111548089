const colors = {
    LINE_COLOR: "#ebebeb",
    EMPHASIS_COLOR: "#ff606d",
    KEY_COLOR: "#182e0c",
    KEY_COLOR_2: "#c9bcf7",
    KEY_COLOR_3: "#f5f2ff",
    SERVE_COLOR: "#d0d7f3",
    // BG_COLOR: '#fafafa',
    BG_COLOR: "#f2f3f5",
    WHITE_COLOR: "#fff",
    BLACK_COLOR: "#000",
    GRAY_COLOR: "#8f8d94",
    INPUT_BG_COLOR: "#f6f5f6",
    INPUT_FONT_COLOR: "#aeacae",
    INPUT_TITLE_COLOR: "#444444", //

    HUSBAND_COLOR: "#66a1fb",
    WIFE_COLOR: "#fd5c81",
    POINT_COLOR_1: "#00b890",
    POINT_COLOR_2: "#fea800",
    POINT_COLOR_3: "#204496",
};

export default colors;
