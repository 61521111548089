import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/NewComponents/Footer/Footer";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

import localStorage from "mobx-localstorage";
import logo from "../../assets/images/new-logo.png";
import HeaderVer2 from "../../components/NewComponents/HeaderVer2/HeaderVer2";
import Lottie from "react-lottie";
import arrow from "../../assets/lottie/arrow.json";

import { useLocation } from "react-router-dom";
import ImageComponent from "../../components/ImageComponent/ImageComponent";

const MAIN_BACKGROUND =
    "https://storage.cryucard.com/cryucard/main-background.png";
const MAIN_CONTENT_1 =
    "https://storage.cryucard.com/cryucard/main-content-1.png";

const MAIN_SAMPLE_1 =
    "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/main-sample-1.gif";
const MAIN_SAMPLE_2 =
    "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/main-sample-2.gif";
const MAIN_SAMPLE_3 =
    "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/main-sample-3.gif";
const MAIN_SAMPLE_4 =
    "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/main-sample-4.gif";

const MAIN_MAKING =
    "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/making.gif";

const MAIN_FLOWER =
    "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/flower.gif";

const MAIN_GRAPH =
    "https://new-cryucard.s3.ap-northeast-2.amazonaws.com/cryucard/graph.png";

const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: arrow,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const MainScreen = () => {
    const [userId, setUserId] = useState("");

    const getUserId = async () => {
        let _getUserId = "";

        if (localStorage.getItem("userId")) {
            _getUserId = localStorage.getItem("userId");
            setUserId(_getUserId);

            // console.log("userId=>" + _getUserId);

            // await getList(getUserId);
            // await getThanksList(getUserId);
        } else {
            console.log("noEmail");
            // window.location.href = "/#/login";
        }
    };

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getUserId();
    }, []);
    const scrollSection0 = useRef();
    const scrollSection1 = useRef();
    const sec_1_background = useRef();
    const sec_1_background_opacity = useRef();

    const sec_1_main_message_a = useRef();
    const sec_1_main_message_b = useRef();
    const sec_1_main_message_c = useRef();
    const sec_1_main_message_d = useRef();

    let [currentScene, setCurrentScene] = useState(0); //현재 활성화된(눈 앞에 보고있는) 씬 (scroll-section)
    const [isBackground, setIsBackground] = useState(false);

    let yOffset = 0; //window.pageYOffset 대신 쓸 함수
    let prevScrollHeight = 0; //현재 스크롤 위치(yOffset)보다 이전에 위치한 스크롤 섹션들의 스크롤 높이값의 합
    let enterNewScene = false; // 새로운 scene이 시작된 순간 true

    const sceneInfo = [
        {
            // 0
            type: "sticky",
            heightNum: 3, //브라우저 높이의 5배로 scrollHeight 세팅
            scrollHeight: 0,
            objs: {
                container: scrollSection0,
                messageA: sec_1_main_message_a,
                messageB: sec_1_main_message_b,
                messageC: sec_1_main_message_c,
                messageD: sec_1_main_message_d,
                background: sec_1_background,
                backgroundOpacity: sec_1_background_opacity,
            },
            values: {
                messageA_opacity_in: [0, 1, { start: 0.3, end: 0.4 }],
                messageA_translateY_in: [20, 0, { start: 0.3, end: 0.4 }],
                messageA_opacity_out: [1, 0, { start: 0.7, end: 0.8 }],
                messageA_translateY_out: [0, -20, { start: 0.7, end: 0.8 }],

                background_scale_in: [1, 1.15, { start: 0.4, end: 0.55 }],

                background_opacity_in: [0.4, 0.4, { start: 0, end: 0.8 }],
                background_opacity_out: [0.4, 0, { start: 0.8, end: 1.0 }],
            },
        },
        {
            // 1
            type: "normal",
            heightNum: 5, //브라우저 높이의 5배로 scrollHeight 세팅
            scrollHeight: 0,
            objs: {
                container: scrollSection1,
            },
        },
    ];

    const scrollFunc = () => {
        window.addEventListener("scroll", scrollEvent);
        // window.addEventListener("DomContentLoaded", setLayout); //이미지 로딩 되기전 html만 로드 된 후 실행
        window.addEventListener("load", setLayout);
        window.addEventListener("resize", setLayout);

        setLayout();
    };

    const setLayout = () => {
        //각 스크롤 섹션의 높이 세팅
        for (let i = 0; i < sceneInfo.length; i++) {
            if (sceneInfo[i].type === "sticky") {
                sceneInfo[i].scrollHeight =
                    sceneInfo[i].heightNum * window.innerHeight;
                sceneInfo[
                    i
                ].objs.container.current.style.height = `${sceneInfo[i].scrollHeight}px`;
            } else if (sceneInfo[i].type === "normal") {
                sceneInfo[i].scrollHeight =
                    sceneInfo[i].objs.container.current.offsetHeight;

                sceneInfo[i].objs.container.current.style.height = "auto";
            }
            // sceneInfo[
            //     i
            // ].objs.container.current.style.height = `${sceneInfo[i].scrollHeight}px`;
        }

        yOffset = window.pageYOffset;
        let totalScrollHeight = 0;
        for (let i = 0; i < sceneInfo.length; i++) {
            totalScrollHeight += sceneInfo[i].scrollHeight;
            if (totalScrollHeight >= yOffset) {
                currentScene = i;
                // console.log(currentScene);

                break;
            }
        }
    };

    const calcValues = (values, currentYOffset) => {
        let rv;
        const scrollHeight = sceneInfo[currentScene].scrollHeight;
        const scrollRatio = currentYOffset / scrollHeight;

        if (values.length === 3) {
            // start ~ end 사이에 애니메이션 실행
            const partScrollStart = values[2].start * scrollHeight;
            const partScrollEnd = values[2].end * scrollHeight;
            const partScrollHeight = partScrollEnd - partScrollStart;

            if (
                currentYOffset >= partScrollStart &&
                currentYOffset <= partScrollEnd
            ) {
                rv =
                    ((currentYOffset - partScrollStart) / partScrollHeight) *
                        (values[1] - values[0]) +
                    values[0];
            } else if (currentYOffset < partScrollStart) {
                rv = values[0];
            } else if (currentYOffset > partScrollEnd) {
                rv = values[1];
            }
        } else {
            rv = scrollRatio * (values[1] - values[0]) + values[0];
        }

        // console.log(rv);
        return rv;
    };

    const playAnimation = () => {
        const objs = sceneInfo[currentScene].objs;
        const values = sceneInfo[currentScene].values;
        const currentYOffset = yOffset - prevScrollHeight;
        const scrollHeight = sceneInfo[currentScene].scrollHeight;
        const scrollRatio = (yOffset - prevScrollHeight) / scrollHeight;
        // console.log(currentScene, currentYOffset);

        switch (currentScene) {
            case 0:
                // console.log("0 play");
                let messageA_opacity_in = calcValues(
                    values.messageA_opacity_in,
                    currentYOffset
                );
                let messageA_opacity_out = calcValues(
                    values.messageA_opacity_out,
                    currentYOffset
                );

                let messageA_translateY_in = calcValues(
                    values.messageA_translateY_in,
                    currentYOffset
                );
                let messageA_translateY_out = calcValues(
                    values.messageA_translateY_out,
                    currentYOffset
                );

                let background_scale_in = calcValues(
                    values.background_scale_in,
                    currentYOffset
                );

                let background_opacity_in = calcValues(
                    values.background_opacity_in,
                    currentYOffset
                );

                let background_opacity_out = calcValues(
                    values.background_opacity_out,
                    currentYOffset
                );

                // console.log(scrollRatio);
                if (scrollRatio <= 0.37) {
                    //in
                    objs.messageA.current.style.opacity = messageA_opacity_in;
                    objs.messageA.current.style.transform = `translateY(${messageA_translateY_in}%)`;
                } else {
                    //out
                    objs.messageA.current.style.opacity = messageA_opacity_out;
                    objs.messageA.current.style.transform = `translateY(${messageA_translateY_out}%)`;
                }

                if (scrollRatio <= 0.5) {
                    objs.background.current.style.transform = `scale(${background_scale_in})`;
                }

                if (scrollRatio <= 0.8) {
                    objs.backgroundOpacity.current.style.opacity =
                        background_opacity_in;
                } else {
                    //out
                    objs.backgroundOpacity.current.style.opacity =
                        background_opacity_out;
                }

                // if (scrollRatio < 0.9) {
                //     // console.log(isBackground);
                //     setIsBackground(false);
                //     console.log("------------");
                // } else {
                //     setIsBackground(true);
                //     console.log("!!!!!!!!!!");
                // }

                break;
            case 1:
                // console.log("1 play");
                break;

            default:
                break;
        }
    };

    const scrollLoop = () => {
        prevScrollHeight = 0;
        enterNewScene = false;
        for (let i = 0; i < currentScene; i++) {
            prevScrollHeight += sceneInfo[i].scrollHeight; // prevScrollHeight = prevScrollHeight + sceneInfo[i].scrollHeight;
        }

        if (yOffset > prevScrollHeight + sceneInfo[currentScene].scrollHeight) {
            enterNewScene = true;
            // console.log(currentScene);
            if (0 == currentScene) {
                setCurrentScene(currentScene++);
            }
        }

        if (yOffset < prevScrollHeight) {
            if (currentScene === 0) return;
            enterNewScene = true;
            setCurrentScene(currentScene--);
        }

        if (enterNewScene) return; // 음수가 나와서 예외 처리

        document.body.setAttribute("id", `show-scene-${currentScene}`);
        // console.log(currentScene);

        playAnimation();
    };

    const scrollEvent = () => {
        yOffset = window.pageYOffset;
        scrollLoop();
    };

    useEffect(() => {
        scrollFunc();
        document.body.setAttribute("id", `show-scene-0`);

        return () => {
            document.body.setAttribute("id", "");

            window.removeEventListener("scroll", scrollEvent);
            window.removeEventListener("load", setLayout);
            window.removeEventListener("resize", setLayout);
        };
    }, []);

    return (
        <div>
            <HeaderVer2 userId={userId} />
            <div className="container">
                <section
                    className="scroll-section"
                    id="scroll-section-0"
                    ref={scrollSection0}
                >
                    <div
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "fixed",
                            top: 0,
                            background: `url(${MAIN_BACKGROUND})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                        ref={sec_1_background}
                    ></div>
                    <div
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "fixed",
                            top: 0,
                            backgroundColor: "#000",
                            opacity: 0.4,
                        }}
                        ref={sec_1_background_opacity}
                        className="sec1-background-opacity"
                    ></div>
                    <h1
                        style={{
                            fontSize: 40,
                            position: "relative",
                            margin: 0,
                        }}
                    >
                        합리적인 결혼을 위한
                        <br />
                        모바일 청첩장
                    </h1>

                    <div
                        className="sticky-elem main-message a"
                        ref={sec_1_main_message_a}
                        style={{ opacity: 0 }}
                    >
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 86,
                            }}
                        >
                            <img
                                src={logo}
                                style={{
                                    width: 40,
                                    height: 40,
                                    textAlign: "center",
                                }}
                            />
                            <p
                                style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                }}
                            >
                                CRYUCARD
                            </p>
                        </div>
                    </div>
                    <div className="main-lottie-arrow">
                        <Lottie
                            options={defaultOption}
                            height={50}
                            width={50}
                            style={{ margin: 0 }}
                        />
                    </div>
                </section>
                <section
                    className="scroll-section"
                    id="scroll-section-1"
                    ref={scrollSection1}
                >
                    <div style={{}}>
                        <div className="main-content-container">
                            <div className="main-content-wrap-1">
                                <p className="main-content-title">
                                    예식정보만 입력하세요
                                </p>

                                <p className="main-content-des">
                                    디자인은 크류카드가 해드립니다!
                                </p>

                                <a
                                    href="https://mcard.cryucard.com/c/N7hwccRFBwMdoRlrcxS5"
                                    target="_blank"
                                    className="main-make-button"
                                >
                                    샘플 보러가기
                                </a>
                            </div>

                            <div className="main-content-wrap-2">
                                <Marquee gradientWidth={100}>
                                    <ImageComponent
                                        src={MAIN_SAMPLE_1}
                                        style={{
                                            height: 450,
                                        }}
                                        getLazyLoad={() => {}}
                                    />
                                    <ImageComponent
                                        src={MAIN_SAMPLE_2}
                                        style={{
                                            height: 450,
                                        }}
                                        getLazyLoad={() => {}}
                                    />
                                    <ImageComponent
                                        src={MAIN_SAMPLE_3}
                                        style={{
                                            height: 450,
                                        }}
                                        getLazyLoad={() => {}}
                                    />
                                    <ImageComponent
                                        src={MAIN_SAMPLE_4}
                                        style={{
                                            height: 450,
                                        }}
                                        getLazyLoad={() => {}}
                                    />
                                </Marquee>
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: "#F2F4F6" }}>
                        <div className="main-content-container main-reverse">
                            <div className="main-content-wrap-1 ">
                                <p className="main-content-title">
                                    실시간 제작
                                </p>

                                <p className="main-content-des">
                                    실시간 제작기능 도입으로 빠르면 5분
                                    <br />
                                    길어도 15분만에 청첩장 제작이 가능합니다.
                                </p>

                                <Link
                                    to={userId ? "/first" : "/newlogin"}
                                    className="main-make-button"
                                >
                                    바로 제작하기
                                </Link>
                            </div>

                            <div className="main-content-wrap-2">
                                <ImageComponent
                                    src={MAIN_MAKING}
                                    style={{
                                        width: "100%",
                                    }}
                                    getLazyLoad={() => {}}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{}}>
                        <div className="main-content-container ">
                            <div className="main-content-wrap-1">
                                <p className="main-content-title">
                                    예식 비용 아끼는
                                    <br />
                                    참석 조사 기능
                                </p>

                                <p className="main-content-des">
                                    식사 인원, 답례품 준비 등<br />
                                    다양하게 활용할 수 있습니다.
                                </p>

                                <Link
                                    to={userId ? "/first" : "/newlogin"}
                                    className="main-make-button"
                                >
                                    바로 제작하기
                                </Link>
                            </div>

                            <div className="main-content-wrap-2">
                                <ImageComponent
                                    src={MAIN_GRAPH}
                                    style={{
                                        width: "100%",
                                    }}
                                    getLazyLoad={() => {}}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: "#F2F4F6" }}>
                        <div className="main-content-container main-reverse">
                            <div className="main-content-wrap-1">
                                <p className="main-content-title">
                                    화환 선물하기
                                </p>

                                <p className="main-content-des">
                                    하객 분들께서 따로 찾아볼 필요없이
                                    <br />
                                    청첩장 내에서 선물하기를 지원합니다.
                                </p>

                                <Link
                                    to={userId ? "/first" : "/newlogin"}
                                    className="main-make-button"
                                >
                                    바로 제작하기
                                </Link>
                            </div>

                            <div className="main-content-wrap-2">
                                {" "}
                                <ImageComponent
                                    src={MAIN_FLOWER}
                                    style={{
                                        width: "100%",
                                    }}
                                    getLazyLoad={() => {}}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            background: `url(${MAIN_CONTENT_1})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                        className="main-generate-container"
                    >
                        <div className="main-generate-wrap">
                            <div>
                                <p className="main-generate-title">
                                    결제 먼저할 필요 없이
                                    <br />
                                    무료 시안 제작!
                                </p>
                                <p className="main-generate-des">
                                    디자이너와 제작하면서 씨름할 필요 없습니다.
                                    <br />
                                    시안 제작해보시고 마음에 드시면 결제하세요
                                </p>

                                <Link
                                    to={userId ? "/first" : "/newlogin"}
                                    className="main-make-button"
                                >
                                    시안 제작하기
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer userId={userId} />
            </div>
        </div>
    );
};

export default MainScreen;
