import React, { useEffect } from "react";
import NaverLogin from "react-naver-login";
import banner from "../../assets/images/banner.png";
import localStorage from "mobx-localstorage";

const { naver } = window;
const LoginScreen = () => {
  const naverLogin = () => {
    console.log("fuc naverLogin");
    var naverLogin = new naver.LoginWithNaverId({
      clientId: "UFZAB_49k9HdAHhyPHA4",
      callbackUrl:
        "https://smartstore.cryucompany.com/#/Api/Member/Oauth2ClientCallback/naver2/",
      isPopup: true,
      loginButton: { color: "green", type: 3, height: "47" },
    });
    naverLogin.init();
  };

  useEffect(() => {
    naverLogin();
    // console.log(window);
    // localStorage.setItem("userId", "51543483");

    window.gotoSNSlogin = () => {
      window.location.href = "/#/orderlist";
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center", paddingBottom: 40 }}>
        <img src={banner} style={{ height: 60 }} />
      </div>

      <div id="naverIdLogin"></div>
    </div>
  );
};

export default LoginScreen;
