import React, { useEffect, useState } from "react";
import Footer from "../../components/NewComponents/Footer/Footer";
import Header from "../../components/NewComponents/Header/Header";
import { dbService } from "../../fbase";
import RenewalCardComponent from "../../components/RenewalCardComponent/RenewalCardComponent";
import localStorage from "mobx-localstorage";
import { Link } from "react-router-dom";
import HeaderVer2 from "../../components/NewComponents/HeaderVer2/HeaderVer2";

import { useLocation } from "react-router-dom";
export default function MyPageScreen() {
  const [userLetterList, setUserLetterList] = useState([]);
  const [beforeLetterList, setBeforeLetterList] = useState([]);
  const [userId, setUserId] = useState("");

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getUserId = async () => {
    let _getUserId = "";

    if (localStorage.getItem("userId")) {
      _getUserId = localStorage.getItem("userId");
      setUserId(_getUserId);

      // console.log("userId=>" + _getUserId);

      getList(_getUserId.toString());
      getBeforeLetter(_getUserId.toString());
      // getBeforeLetter(_getUserId.toString());
      // await getThanksList(getUserId);
    } else {
      console.log("noEmail");
      window.location.href = "/#/newlogin";
    }
  };

  const dday = (day) => {
    var Dday = new Date(day);
    var now = new Date();
    // console.log(Dday);
    // console.log(day);

    var gap = now.getTime() - Dday.getTime();
    var result = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1 - 1;
    return result;
  };

  const getList = (_userId) => {
    var letterRef = dbService.collection("newletters");

    letterRef
      .where("userId", "==", _userId)
      .orderBy("createdAt", "desc")
      .get()
      .then(async (res) => {
        if (!res.empty) {
          // console.log(res.docs[0].data());

          await Promise.all(
            res.docs.map(async (item) => {
              var addArr = item.data();
              addArr.id = item.id;

              if (addArr.expire) {
                setUserLetterList((oldArray) => [...oldArray, addArr]);
              } else if (dday(addArr.weddingDate) >= -30) {
                setUserLetterList((oldArray) => [...oldArray, addArr]);
              }
            })
          );
        } else {
          console.log("empty");
        }
      });
  };

  const getBeforeLetter = (_userId) => {
    var letterRef = dbService.collection("letters");

    letterRef
      .where("userId", "==", _userId)
      .orderBy("createdAt", "desc")
      .get()
      .then(async (res) => {
        if (!res.empty) {
          // console.log(res.docs[0].data());

          await Promise.all(
            res.docs.map(async (item) => {
              var addArr = item.data();
              addArr.id = item.id;

              if (addArr.expire) {
                setBeforeLetterList((oldArray) => [...oldArray, addArr]);
                // console.log(addArr);
              } else if (dday(addArr.weddingDate) >= -30) {
                setBeforeLetterList((oldArray) => [...oldArray, addArr]);
                // console.log(addArr);
              }
            })
          );
        } else {
          console.log("empty");
        }
      });
  };

  useEffect(() => {
    // getList([]);

    getUserId();
    // getList(userId);
    // getBeforeLetter(userId);

    if (window.Kakao) {
      if (window.Kakao.isInitialized() === false) {
        window.Kakao.init("ceac62315cf4bfcb9030a1c46642f4ca");
      }
    }

    return () => setUserLetterList([]);
  }, []);

  return (
    <div>
      <HeaderVer2 userId={userId} />
      <div
        style={{
          minHeight: "100vh",
          padding: "0 16px",
          paddingTop: 100,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 40,
              fontWeight: "bold",
              paddingTop: 50,
              color: "#222",
            }}
          >
            제작내역
          </div>

          {beforeLetterList.length > 0 && (
            <div>
              <div
                style={{
                  marginTop: 20,
                  fontSize: 14,
                  color: "#999",
                }}
              >
                앗, 리뉴얼되기 전 청첩장이 있으시네요!
              </div>

              <Link to={"/list"} style={{ textDecoration: "none" }}>
                <div
                  style={{
                    backgroundColor: "#182e0c",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 40,
                    width: 220,
                    margin: "10px auto 0",
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: "pointer",
                    color: "#fff",
                  }}
                >
                  이전 청첩장 제작내역 보기
                </div>
              </Link>
            </div>
          )}

          <div
            style={{
              maxWidth: "1000px",
              width: "100%",
              marginTop: 50,
              borderRadius: "15px 0px 15px 15px",
              boxShadow: "10px 10px 50px rgb(0 0 0 / 10%)",
              position: "relative",
            }}
          >
            {/* <div
                            style={{
                                width: 150,
                                height: 40,
                                borderRadius: "5px 5px 0 0",
                                position: "absolute",
                                right: 0,
                                top: -40,
                                backgroundColor: "#ffa2c0",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                                fontWeight: "bold",
                                cursor: "pointer",
                            }}
                        >
                            모바일 청첩장
                        </div>

                        <div
                            style={{
                                width: 150,
                                height: 40,
                                borderRadius: "5px 5px 0 0",
                                position: "absolute",
                                right: 160,
                                top: -40,
                                backgroundColor: "#ffce73",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                                fontWeight: "bold",
                                cursor: "pointer",
                            }}
                        >
                            모바일 감사장
                        </div> */}

            <div style={{ padding: "30px 20px" }}>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#222",
                  textAlign: "center",
                }}
              >
                모바일 청첩장
              </div>

              <div
                style={{
                  minHeight: 600,
                  // border: "1px solid",
                  marginTop: 30,
                }}
              >
                {userLetterList.length ? (
                  userLetterList.map((item, index) => {
                    return (
                      <div
                        style={{
                          maxWidth: 500,
                          margin: "0 auto",
                        }}
                      >
                        <RenewalCardComponent
                          user={item}
                          key={index}
                          index={index}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: 25,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 100,
                      }}
                    >
                      💌
                    </p>
                    <span
                      style={{
                        fontSize: 14,
                        color: "#999",
                      }}
                    >
                      제작된 청첩장이 없습니다
                    </span>
                  </div>
                )}
              </div>
              {/* 안내 */}
              <div style={{ marginTop: 20 }}>
                <div>
                  <div className={"mypage-box"}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        lineHeight: "26px",
                      }}
                    >
                      사용기간
                    </p>
                    <p style={{ fontSize: 14 }}>
                      예식일 6개월 전부터 예식 후 30일까지의 호스팅기간 제공
                    </p>
                    <p style={{ fontSize: 14 }}>
                      처음 제작 이후 최대 7개월 까지 사용가능
                    </p>
                  </div>

                  <div className={"mypage-box"}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        lineHeight: "26px",
                      }}
                    >
                      워터마크
                    </p>
                    <p style={{ fontSize: 14 }}>
                      청첩장 제작 시 워터마크가 생성된 상태로 시안이 완성되며,
                    </p>
                    <p style={{ fontSize: 14 }}>
                      구매 후 워터마크를 제거 및 사용 가능합니다.
                    </p>
                  </div>

                  <div className={"mypage-box"}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        lineHeight: "26px",
                      }}
                    >
                      결제방법
                    </p>
                    <p style={{ fontSize: 14 }}>
                      시안제작 후 마음에 들면 네이버쇼핑에서 구매 후 워터마크
                      제거
                    </p>
                    <p style={{ fontSize: 14 }}>
                      워터마크 미제거 시 청첩장은 7일 후 삭제됩니다.
                    </p>
                  </div>

                  <div className={"mypage-box"}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        lineHeight: "26px",
                      }}
                    >
                      1:1 문의하기
                    </p>
                    <p style={{ fontSize: 14 }}>
                      평일 10:00-18:00, 업무시간 외 응답은 지연될 수 있습니다
                    </p>
                    <p style={{ fontSize: 14 }}>카카오톡 문의하기 문의하기</p>
                  </div>
                </div>
              </div>
              {/* 안내 */}
            </div>
          </div>
        </div>
      </div>
      <Footer userId={userId} />
    </div>
  );
}
