import React, { useRef, useState, useEffect } from "react";

import PLACE_HOLDER from "../../assets/images/place-holder.png";

function ImageComponent(props) {
    const imgRef = useRef(null);
    const [isLoad, setIsLoad] = useState(false);
    const { src, style } = props;

    useEffect(() => {
        function loadImage() {
            setIsLoad(true);

            setTimeout(() => {
                props.getLazyLoad(true);
            }, 1000);
        }

        const imgEl = imgRef.current;
        imgEl && imgEl.addEventListener(LOAD_IMG_EVENT_TYPE, loadImage);
        return () => {
            imgEl && imgEl.removeEventListener(LOAD_IMG_EVENT_TYPE, loadImage);
        };
    }, []);

    useEffect(() => {
        if (!observer) {
            observer = new IntersectionObserver(onIntersection, {
                // 확인을 위해 이미지 절반이 나타날 때 로딩한다.
                threshold: 0.01,
            });
        }
        imgRef.current && observer.observe(imgRef.current);
    }, []);

    return (
        <img
            ref={imgRef}
            src={isLoad ? src : PLACE_HOLDER}
            style={style}
            className={!isLoad ? "cryu-lazy-loading" : "cryu-lazy-loaded"}
        />
    );
}

let observer = null;
const LOAD_IMG_EVENT_TYPE = "loadImage";

function onIntersection(entries, io) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            io.unobserve(entry.target);
            entry.target.dispatchEvent(new CustomEvent(LOAD_IMG_EVENT_TYPE));
        }
    });
}

export default ImageComponent;
