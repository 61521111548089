import React, { useState, useEffect } from "react";
import localStorage from "mobx-localstorage";
import { dbService } from "../../fbase";
import eventImg from "../../assets/images/event-1.png";
export default function CardQrScreen() {
  const [userId, setUserId] = useState("");

  const getEmail = async () => {
    let getUserId = "";

    if (localStorage.getItem("userId")) {
      getUserId = await localStorage.getItem("userId");
      // await setIsLoading(true);
      setUserId(getUserId);
    } else {
      console.log("noEmail");
    }

    // await console.log(getUserId);
  };

  useEffect(() => {
    getEmail();
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundColor: "rgb(238 238 238)",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          position: "relative",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            maxWidth: 480,
            width: "100%",
            minHeight: "100vh",
            paddingLeft: 20,
            paddingRight: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p
              style={{
                fontSize: 26,
                fontWeight: "bold",
                paddingTop: 50,
              }}
            >
              모바일 청첩장
              <br />
              링크를 생성하세요
            </p>
            <p style={{ fontSize: 12, padding: "10px 0 20px" }}>
              청첩장에 큐알코드를 추가하기 위해서 필요한 과정입니다.
              <br />
              종이 청첩장 수령 후에도 제한없이 수정이 가능하니 안심하세요!
            </p>
          </div>

          <div style={{ paddingBottom: 50 }}>
            <div
              style={{
                display: "inline-block",
                backgroundColor: "#182e0c",
                width: "100%",
                color: "#fff",
                height: 50,
                width: "100%",
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <span style={{ fontWeight: "bold" }}>자동 링크 생성하기</span>
            </div>

            <div
              style={{
                display: "inline-block",
                backgroundColor: "#fff",
                width: "100%",
                color: "#182e0c",
                border: "1px solid #182e0c",
                height: 50,
                width: "100%",
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
                cursor: "pointer",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                제작해둔 모바일 청첩장 링크가 있어요
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
