import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

export default function NewBarChartComponent({ surveyArr }) {
    const [husbandSideAttend, setHusbandSideAttend] = useState(0);
    const [husbandSideNonAttend, setHusbandSideNonAttend] = useState(0);
    const [husbandSidePartyNumber, setHusbandSidePartyNumber] = useState(0);

    const [wifeSideAttend, setWifeSideAttend] = useState(0);
    const [wifeSideNonAttend, setWifeSideNonAttend] = useState(0);
    const [wifeSidePartyNumber, setWifeSidePartyNumber] = useState(0);

    const data = [
        {
            name: "전체",
            동행인원: husbandSidePartyNumber * 1 + wifeSidePartyNumber * 1,
            참석인원: husbandSideAttend * 1 + wifeSideAttend * 1,
            불참인원: husbandSideNonAttend * 1 + wifeSideNonAttend * 1,
        },
        {
            name: "신랑측",
            동행인원: husbandSidePartyNumber,
            참석인원: husbandSideAttend,
            불참인원: husbandSideNonAttend,
        },
        {
            name: "신부측",
            동행인원: wifeSidePartyNumber,
            참석인원: wifeSideAttend,
            불참인원: wifeSideNonAttend,
        },
    ];

    const test = () => {
        surveyArr.map(({ side, attend, partyNumber }) => {
            //side = 0 남편, 1 신부
            //attend = 0 불참, 1참석
            if (side == 0) {
                //남편
                if (attend == 0) {
                    //참석
                    setHusbandSideAttend((count) => count + 1);
                } else {
                    //불참
                    setHusbandSideNonAttend((count) => count + 1);
                }

                setHusbandSidePartyNumber((count) => count + partyNumber * 1);
            } else if (side == 1) {
                //신부
                if (attend == 0) {
                    //참석
                    setWifeSideAttend((count) => count + 1);
                } else {
                    //불참
                    setWifeSideNonAttend((count) => count + 1);
                }
                setWifeSidePartyNumber((count) => count + partyNumber * 1);
            }
        });
    };

    useEffect(() => {
        test();
    }, []);

    return (
        <div>
            <ResponsiveContainer width={"100%"} height={300}>
                <BarChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis width={30} allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="동행인원" stackId="a" fill="#257bff" />
                    <Bar dataKey="참석인원" stackId="a" fill="#66a1fb" />
                    <Bar dataKey="불참인원" fill="#fd5c81" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
