import React from "react";
import ChecIcon from "@material-ui/icons/Check";
import { useState } from "react";

export default function CheckboxComponent({ title, isChecked }) {
    const [checked, setChecked] = useState(isChecked);
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
            }}
            onClick={() => {
                setChecked(!checked);
            }}
        >
            <div
                style={{
                    width: 16,
                    height: 16,
                    border: checked ? "1px solid #182e0c" : "1px solid #e9e9e9",
                    borderRadius: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: checked ? "#182e0c" : "#fff",
                }}
            >
                {checked && (
                    <ChecIcon style={{ fontSize: 16, color: "#fff" }} />
                )}
            </div>
            <span
                style={{
                    fontSize: "0.8rem",
                    color: "#333",
                    lineHeight: "0.8rem",
                    marginLeft: 5,
                }}
            >
                {title}
            </span>
        </div>
    );
}
