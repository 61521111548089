import React, { useEffect, useState } from "react";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import Delete from "@material-ui/icons/DeleteOutline";
import Switch from "react-switch";

function AccordionMenuComponent({
  title,
  component,
  isRequire,
  id,
  parameter,
  getSwitchData,
  isActivity,
  _checked,
}) {
  const [isClicked, setIsClicked] = useState(false);
  const [checked, setChecked] = useState(
    _checked !== undefined ? _checked : true
  );
  const [require, setRequire] = useState(isRequire);

  const handleChange = (check) => {
    setChecked(check);
    getSwitchData({ check, parameter });
  };

  return (
    <div class="prod-block">
      <div
        style={{
          cursor: "pointer",
          paddingBottom: isClicked ? 12 : 0,
          borderBottom: isClicked ? "1px dashed #e9e9e9" : "none",
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          height: 25,
        }}
        onClick={() => {
          if (!isClicked) {
            setIsClicked(!isClicked);
          }
        }}
      >
        <div
          style={{ width: "100%", height: "100%" }}
          onClick={() => {
            setIsClicked(!isClicked);
          }}
        ></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            left: !require && isClicked ? -8 : 5,
          }}
        >
          <div
            style={{
              zIndex: 1000,
              display: "flex",
            }}
          >
            {!require && isClicked ? (
              <Switch
                onChange={handleChange}
                checked={checked}
                width={44}
                height={19}
                onColor={"#182e0c"}
                offColor={"#999"}
                checkedIcon={
                  <div
                    style={{
                      fontSize: 10,
                      color: "#fff",
                      fontWeight: "bold",
                      marginLeft: 6,
                      paddingTop: 1,
                    }}
                  >
                    ON
                  </div>
                }
                uncheckedIcon={
                  <div
                    style={{
                      fontSize: 10,
                      color: "#fff",
                      fontWeight: "bold",
                      marginLeft: 3,
                      paddingTop: 1,
                    }}
                  >
                    OFF
                  </div>
                }
              />
            ) : (
              false
            )}
          </div>

          <span
            style={{
              fontWeight: "bold",
              marginLeft: !require && isClicked ? 5 : 0,
              marginBottom: !require && isClicked ? 5 : 0,
              color: "#333",
            }}
          >
            {title}
          </span>
        </div>

        <div
          style={{
            position: "absolute",
            right: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div
            onClick={() => {
              setIsClicked(!isClicked);
            }}
          >
            {isClicked ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: 18,
          marginBottom: 18,
          height: !isClicked ? 0 : "auto",
          display: !isClicked ? "none" : "block",
        }}
      >
        {component}
      </div>
    </div>
  );
}

export default React.memo(AccordionMenuComponent);
