import React from "react";
import ReactTooltip from "react-tooltip";
import AddIcon from "@material-ui/icons/QuestionAnswer";
import HtmlParser from "react-html-parser";

export default function FormSubTitleComponent({
  title,
  tooltip,
  index,
  require,
  description,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontSize: "1rem",
          fontWeight: "500",
          margin: description ? "15px 0 2px" : "15px 0 8px",
          color: "#333",
        }}
      >
        {title}{" "}
        {require ? (
          <span style={{ color: "red", fontWeight: "bold" }}>*</span>
        ) : (
          <></>
        )}
      </div>

      {description && (
        <div
          style={{
            fontSize: 12,
            display: "inline-block",
            lineHeight: "15px",
            paddingBottom: 10,
            color: "#444",
            paddingTop: 8,
          }}
        >
          {HtmlParser(description)}
        </div>
      )}

      {tooltip ? (
        <div style={{ display: "flex" }}>
          <div
            data-tip
            data-for={`global${index ? index : ""}`}
            style={{
              fontSize: 11,
              fontWeight: "bold",
              backgroundColor: "#333",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 16,
              height: 16,
              borderRadius: 8,
              marginTop: 5,
              marginLeft: 5,
              cursor: "pointer",
            }}
          >
            ?
          </div>
          <ReactTooltip
            id={`global${index ? index : ""}`}
            aria-haspopup="true"
            role="example"
          >
            <div>{tooltip}</div>
          </ReactTooltip>
        </div>
      ) : (
        false
      )}
    </div>
  );
}
