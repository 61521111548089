import React, { useState } from "react";
import QRCode from "qrcode";
import { saveAs } from "file-saver";

export default function QrCodeComponent({ url }) {
    // const [url, setUrl] = useState("");
    const [qr, setQr] = useState("");

    const GenerateQRCode = () => {
        QRCode.toDataURL(
            url,
            {
                width: 300,
                margin: 2,
                color: { dark: "#000", light: "#fff" },
            },
            (err, url) => {
                if (err) return console.error(err);

                console.log(url);
                setQr(url);
            }
        );
    };

    const downloadImage = () => {
        saveAs(qr, "qrcode"); // Put your image url here.
    };

    useState(() => {
        GenerateQRCode();
    }, []);

    return (
        <div
            style={{
                height: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <img src={qr} style={{ width: 200, height: 200 }} />

                <div
                    style={{
                        width: "calc(100% - 32px)",
                        height: 50,
                        backgroundColor: "#182e0c",
                        color: "#fff",
                        margin: "20px auto 0",
                        cursor: "pointer",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={() => {
                        downloadImage();
                    }}
                >
                    큐알코드 다운로드
                </div>

                <div
                    style={{
                        fontSize: 10,
                        textAlign: "center",
                        marginTop: 15,
                        color: "#222",
                        marginBottom: 15,
                    }}
                >
                    청첩장을 수정하더라도 큐알코드는 변경되지 않습니다.
                    <br />
                    다운로드가 되지 않으시면 화면 캡쳐하여 저장하셔도 됩니다.
                </div>
            </div>
        </div>
    );
}
