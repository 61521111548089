import React, { useCallback, useState } from "react";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import { debounce } from "lodash";
import { dbService } from "../../fbase";

export default function CommentComponent({ state, getData, letterId }) {
    const [statePassword, setStatePassword] = useState(
        state.password ? state.password : ""
    );
    const getText = (text, _parameter) => {
        // console.log(data.text);
        // pushData(data.text);
        console.log(text);
        setStatePassword(text);

        if (text.length >= 4 || text.length === 0) {
            debounceGetText({
                _parameter: _parameter,
                text: text,
            });
        }
    };

    const debounceGetText = useCallback(
        debounce(({ _parameter, text }) => {
            if (_parameter === "password") {
                onSubmit({ _password: text });
            } else if (_parameter === "passwordCheck") {
                onSubmit({ _passwordCheck: text });
            }
        }, 500),
        []
    );

    const onSubmit = async ({ _password, _passwordCheck }) => {
        let content = {};

        if (_password !== undefined) {
            content = {
                password: _password,
            };
            console.log(_password);
        } else if (_passwordCheck !== undefined) {
            content = {
                passwordCheck: _passwordCheck,
            };
            console.log(_passwordCheck);
        }

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {
                pushData();
            });
    };

    const pushData = () => {
        getData({ isSaved: true });
    };

    return (
        <div>
            <div>
                <p
                    style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                    }}
                >
                    마스터 비밀번호 설정
                </p>
                <p
                    style={{
                        marginTop: 5,
                        fontSize: "0.9rem",
                        color: "rgb(119, 119, 119)",
                    }}
                >
                    원치않는 방명록의 삭제를 희망하실 때 청첩장 내에서
                    <br />
                    설정하신 마스터 비밀번호로 삭제가능합니다.
                </p>
            </div>

            <div style={{ marginTop: 5 }}>
                <div>
                    <SubTitleComponent title="비밀번호 (4~12자)" />
                    <TextInputComponent
                        getText={(e) => {
                            getText(e.text, "password");
                        }}
                        _value={state.password ? state.password : ""}
                        isPassword={true}
                        warning={
                            statePassword.length < 4 &&
                            statePassword.length !== 0
                                ? "비밀번호가 너무 짧습니다."
                                : ""
                        }
                        getBlur={() => {}}
                    />
                </div>

                {/* <div>
                    <SubTitleComponent title="비밀번호 확인 (4~12자)" />
                    <TextInputComponent
                        getText={(e) => {
                            getText(e.text, "passwordCheck");
                        }}
                        _value={state.passwordCheck ? state.passwordCheck : ""}
                    />
                </div> */}
            </div>
        </div>
    );
}
