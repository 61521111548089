import React from "react";
import Lottie from "react-lottie";
import confetti from "../../assets/lottie/confetti.json";
import partyIcon from "../../assets/images/party-icon.png";
import { dbService } from "../../fbase";

const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: confetti,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export default function TutorialComponent({ state, letterId }) {
    const [isModal, setIsModal] = React.useState(true);

    const onSubmit = async () => {
        let content = { isTutorial: true };

        await dbService
            .collection("newletters")
            .doc(letterId)
            .update(content)
            .then((res) => {});
    };

    return (
        <>
            {isModal &&
                state.isTutorial !== true &&
                state.waterMark !== true && (
                    <div
                        style={{
                            position: "fixed",
                            width: "100%",
                            height: "100vh",
                            backgroundColor: "rgba(0,0,0,0.7)",
                            zIndex: 10000,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                maxWidth: 400,
                                height: 240,
                                backgroundColor: "#fff",
                                display: "flex",
                                position: "relative",
                                margin: "0 16px",
                                borderRadius: 5,
                                boxShadow:
                                    "0px 0px 15px rgb(255 255 255 / 35%)",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: -300,
                                    left: -200,
                                }}
                            >
                                <Lottie
                                    options={defaultOption}
                                    height={"auto"}
                                    width={"400px"}
                                    style={{ margin: 0 }}
                                />
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    top: -300,
                                    right: -200,
                                }}
                            >
                                <Lottie
                                    options={defaultOption}
                                    height={"auto"}
                                    width={"400px"}
                                    style={{ margin: 0 }}
                                />
                            </div>

                            <div
                                style={{
                                    zIndex: 100,
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <div>
                                    <img
                                        src={partyIcon}
                                        style={{ width: 50, height: 50 }}
                                    />
                                    <p
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                            color: "#333",
                                            marginTop: 8,
                                        }}
                                    >
                                        링크 생성이 완료되었습니다.
                                    </p>
                                    <p style={{ fontSize: 12, marginTop: 5 }}>
                                        나머지 내용을 자유롭게 입력하여
                                        <br />
                                        청첩장을 완성해주세요
                                    </p>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: 100,
                                            height: 35,
                                            backgroundColor: "#182e0c",
                                            color: "#fff",
                                            margin: "20px auto 0",
                                            fontSize: 14,
                                            borderRadius: 5,
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setIsModal(false);
                                            onSubmit();
                                        }}
                                    >
                                        창 닫기
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
}
